/**
 * @generated SignedSource<<e17538ba067fe6d2e7fd1e2ff8e5c15b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fileId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "firstLoad"
  }
],
v1 = {
  "kind": "Variable",
  "name": "fileId",
  "variableName": "fileId"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MediaDownloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserScope",
        "kind": "LinkedField",
        "name": "userViewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              {
                "kind": "Variable",
                "name": "firstLoad",
                "variableName": "firstLoad"
              }
            ],
            "kind": "FragmentSpread",
            "name": "MediaDownload_userViewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MediaDownloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserScope",
        "kind": "LinkedField",
        "name": "userViewer",
        "plural": false,
        "selections": [
          {
            "condition": "firstLoad",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v1/*: any*/)
                ],
                "concreteType": "FilePayload",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "file",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fileId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FileType",
                        "kind": "LinkedField",
                        "name": "fileType",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "codec",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "default",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "size",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Error",
                    "kind": "LinkedField",
                    "name": "error",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "message",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "186e724b2f1a7b23244212980dd31adb",
    "id": null,
    "metadata": {},
    "name": "MediaDownloadQuery",
    "operationKind": "query",
    "text": "query MediaDownloadQuery(\n  $fileId: ID!\n  $firstLoad: Boolean!\n) {\n  userViewer {\n    ...MediaDownload_userViewer_p7sTU\n    id\n  }\n}\n\nfragment MediaDownload_userViewer_p7sTU on UserScope {\n  file(fileId: $fileId) @skip(if: $firstLoad) {\n    file {\n      fileId\n      fileType {\n        codec\n        default\n        type\n      }\n      url\n      size\n    }\n    error {\n      code\n      message\n    }\n  }\n  id\n}\n"
  }
};
})();

node.hash = "ba811ee4c25aca062bb73c71b1f9395f";

module.exports = node;
