/**
 * @generated SignedSource<<64fe85b59e9c7ad8dc7f84cc7c2cd71c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folderId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./InboxFolderThreadsRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "Inbox_folderThreadsViewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "items": [
            {
              "kind": "Variable",
              "name": "folderIds.0",
              "variableName": "folderId"
            }
          ],
          "kind": "ListValue",
          "name": "folderIds"
        }
      ],
      "concreteType": "ThreadConnection",
      "kind": "LinkedField",
      "name": "threads",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "UserScope",
  "abstractKey": null
};

node.hash = "f5200df9f0f7f8c4c2ebc4eeb8444fb2";

module.exports = node;
