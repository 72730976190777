import React, { useState } from "react"
import {
  useFragment
} from "react-relay"
import { useTranslation } from "react-i18next"
import graphql from "babel-plugin-relay/macro"

import UserRow from "./UserRow"
import * as Styles from "./SettingsModalStyles"
import ReactTooltip from "../ReactTooltip/ReactTooltip"

const SettingsUsersContainer = ({
  ownUserId,
  isAdmin,
  isInitialAdmin,
  isInitialSuperAdmin,
  ...props
}) => {
  const data = useFragment(
    graphql`
      fragment SettingsUsersContainer_userViewer on UserScope {
        users {
          wUserId
          connected
          email {
            email
            verified
          }
          profilePic
          firstName
          lastName
          profilePic
          role
          initialRole
          channels {
            wChannelId
            integrationIcon
            integrationName
            name
          }
        }
      }
    `,
    props.data
  )

  const { t } = useTranslation(["settings", "common"])
  const [error, setError] = useState("")

  return (
    <Styles.SettingsUsersContainer>
      <Styles.ErrorContainer>
        {error && <div className="error">{error}</div>}
      </Styles.ErrorContainer>
      <table>
        <Styles.RowTHead>
          <tr>
            <td className="icon"></td>
            <td className="email">{t?.("users_settings.EMAIL_table_header")}</td>
            <td className={data?.role === "ADMIN" ? "role-select" : "role"}>{t?.("users_settings.ROLE_table_header")}</td>
            <td className="channels">{t?.("users_settings.CHANNELS_table_header")}</td>
          </tr>
        </Styles.RowTHead>
        {data?.users?.map((user) => (
          <UserRow
            user={user}
            ownUserId={ownUserId}
            isAdmin={isAdmin}
            isInitialAdmin={isInitialAdmin}
            isInitialSuperAdmin={isInitialSuperAdmin}
            onError={(errMsg) => {
              setError(errMsg)
            }}
          />
        ))}
      </table>
      <ReactTooltip
        id="user-row-disable-tooltip"
        place="top"
        noArrow
        offset={2}
      />
    </Styles.SettingsUsersContainer>
  )
}

export default SettingsUsersContainer
