/** @jsxImportSource theme-ui */

import React, { useState, Fragment } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"

const Header = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${props => props.show ? "1" : "0"};
  transition: opacity 0.3s ease-in-out 0.1s;

  .container {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .title {
    font-weight: 500;
    justify-content: center;
    align-items: center;
  }
`

const Body = styled.div`
  height: calc(100% - 44px);
  overflow-y: scroll;
`

export const Item = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0 16px;
  cursor: pointer;

  &:active {
    background-color: #ddd;
  }
`

export const Page = styled.div`
  width: 100%;
  height: 100%;
  background: #F2F2F7;
  padding: 16px;
  
  ${props => props.subPage && css`
    position: absolute;
    left: 0;
    top: 0;
    transition: transform 0.3s ease-in-out; 
    transform: ${props.show ? "translateX(0)" : "translateX(100%)"};
  `}
`

export const Section = styled.div`
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
`

export const Separator = styled.div`
  height: 1px;
  width: calc(100% - 16px);
  float: right;
  background-color: #eee;
`

const SimpleMobileNavigation = ({
  title = "",
  topRightComponent = null,
  className,
  sections = [],
  items = [],
}) => {
  const [path, setPath] = useState("")

  // const allItemsWithSubPage = items.filter(o => o.navTo)
  const subPage = items.find(o => o.id === path)
  const {
    title: subPageTitle = "",
    navTo = {},
  } = subPage || {}
  const {
    component
  } = navTo

  const navBack = () => {
    setPath("")
  }
  return (
    <div
      className={className}
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <Page>
        <Header show>
          <div
            className="container"
          >
            <span
              sx={{
                mr: "auto",
              }}
            >
            </span>
          </div>
          <div
            className="container title"
          >
            {title}
          </div>
          <div
            className="container"
          >
            <span
              sx={{
                ml: "auto"
              }}
            >
              {topRightComponent}
            </span>
          </div>
        </Header>
        <Body>
          {sections.map((section, index) => {
            const {
              items: itemIds = [],
              id: sectionId,
            } = section

            return (
              <Section
                key={sectionId}
                className="section"
                sx={{
                  my: "24px",
                  ...(index === 0 && { mt: "16px" }),
                  ...(index === sections.length - 1 && { mb: "16px" }),
                }}
              >
                {itemIds.map((itemId, i) => {
                  const item = items.find(o => o.id === itemId)
                  const {
                    title: itemTitle,
                    value,
                    onClick = () => { },
                    navTo = null,
                    custom = null,
                  } = item
                  return (
                    <Fragment key={itemId}>
                      {custom ?
                        custom
                        :
                        (
                          <Item
                            onClick={(e) => {
                              if (navTo) {
                                setPath(itemId)
                              } else {
                                onClick(e)
                              }
                            }}
                          >
                            <div>{itemTitle}</div>
                            <div
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {value !== undefined ?
                                <div sx={{ color: "#888", mr: 2 }}>{value}</div>
                                :
                                null
                              }
                              {navTo && (
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                  sx={{
                                    fontSize: "12px",
                                    color: "#bbb",
                                  }}
                                />
                              )}
                            </div>
                          </Item>
                        )
                      }
                      {i < itemIds.length - 1 ?
                        <div
                          sx={{
                            height: "1px",
                            width: "calc(100% - 16px)",
                            float: "right",
                            backgroundColor: "#eee",
                          }}></div>
                        :
                        null
                      }
                    </Fragment>
                  )
                })}
              </Section>
            )
          })}
        </Body>
        
      </Page>
      <Page
        subPage
        show={!!path}
      >
        <Header
          show={!!path}
        >
          <div
            className="container"
          >
            <span
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#4274ff",
                mr: "auto",
                cursor: "pointer",
              }}
              onClick={() => {
                setPath("")
              }}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
              />
              <div
                sx={{
                  ml: 2,
                }}
              >{title}</div>
            </span>
          </div>
          <div
            className="container title"
          >
            {subPageTitle}
          </div>
          <div
            className="container"
          >
            <span
              sx={{
                ml: "auto"
              }}
            >
              {topRightComponent}
            </span>
          </div>
        </Header>
        <Body>
          {component ? component({ navBack }) : null}
        </Body>
      </Page>
      {/* {allItemsWithSubPage.map((item) => {
        const {
          id: itemId,
          title: itemTitle,
          navTo,
        } = item
        const {
          component
        } = navTo
        return (
          
        )
      })} */}
    </div>
  )
}

export default SimpleMobileNavigation
