import React, { forwardRef, useMemo, useCallback, useRef, useState, useImperativeHandle } from "react"
import styled from "@emotion/styled"
import { Editor, Transforms, Range, createEditor } from "slate"
import { withHistory } from "slate-history"
import {
  Slate,
  Editable,
  withReact,
  useSelected,
  useFocused,
} from "slate-react"

const serialize = value => {
  // return text including mention
  const paragraphArray = value.map((node) => {
    const array = []
    node.children.forEach((child) => {
      if (child.text) {
        array.push(child.text)
      } else if (child.type === "mention") {
        array.push(`<@${child?.character?.wUserId}>`)
      }
    })
    return array.join("")
  })
  return paragraphArray.join("\n")
}

const StyledEditable = styled(Editable)`
  color: #000000;
  padding: 10px 8px;
  border: none !important;

  &:focus {
    border: none !important;
    outline: none !important;
  }

`

const initialValue = [{ children: [{ text: "" }] }]

const TextEditor = forwardRef(({
  dataForMention = [],
  mobile,
  onChange,
  onDragOver,
  onDragEnter,
  onDragLeave,
  onDragEnd,
  onDrop,
  onPaste,
  onEnter,
  disabled,
}, ref) => {
  const textEditorRef = useRef()
  const [target, setTarget] = useState()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState("")
  const renderElement = useCallback(props => <Element {...props} />, [])
  const editor = useMemo(() => withMentions(withReact(withHistory(createEditor()))), [])
  const characters = dataForMention?.map((user) => {
    if (user?.firstName && user?.lastName) {
      return {
        email: user?.email?.email,
        character: `${user?.firstName} ${user?.lastName}`,
        wUserId: user?.wUserId,
        displayName: `${user?.firstName} ${user?.lastName} (${user?.email?.email})`
      }
    }
    return {
      ...user,
      email: user?.email?.email,
      character: user?.email?.email,
      wUserId: user?.wUserId,
      displayName: user?.email?.email
    }
  })

  useImperativeHandle(ref, () => ({
    insertText(text) {
      Transforms.insertText(editor, text)
    }
  }))

  const chars = characters.filter(c =>
    c.character.toLowerCase().startsWith(search.toLowerCase())
  ).slice(0, 10)

  const onKeyDown = useCallback(
    event => {
      if (target && chars.length > 0) {
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault()
            const prevIndex = index >= chars.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          case "ArrowUp":
            event.preventDefault()
            const nextIndex = index <= 0 ? chars.length - 1 : index - 1
            setIndex(nextIndex)
            break
          case "Tab":
          case "Enter":
            event.preventDefault()
            Transforms.select(editor, target)
            insertMention(editor, chars[index])
            editor.insertText(" ")
            setTarget(null)
            break
          case "Escape":
            event.preventDefault()
            setTarget(null)
            break
          default:
            break
        }
      } else if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault()
        if (mobile) {
          Transforms.insertText(editor, "\n")
        } else {
          onEnter()
        }
      }
    },
    [chars, editor, index, target]
  )

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {target && chars.length > 0 && (
        <div
          ref={textEditorRef}
          style={{
            position: "absolute",
            zIndex: 999,
            background: "white",
            border: "1px solid rgb(232, 231, 232)",
            borderRadius: "4px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 4px",
            width: "100%",
            bottom: "42px",
          }}
          data-cy="mentions-portal"
        >
          {chars.map((char, i) => (
            <div
              key={char?.wUserId}
              onClick={() => {
                Transforms.select(editor, target)
                insertMention(editor, char)
                editor.insertText(" ")
                setTarget(null)
              }}
              style={{
                padding: "10px 12px",
                background: i === index ? "rgba(0, 82, 204, 0.1)" : "transparent",
                color: "#555555",
              }}
            >
              {char?.displayName}
            </div>
          ))}
        </div>
      )}
      <Slate
        editor={editor}
        initialValue={initialValue}
        onChange={(value) => {          
          const { selection } = editor

          if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection)
            const wordBefore = Editor.before(editor, start, { unit: "word" })
            const before = wordBefore && Editor.before(editor, wordBefore)
            const beforeRange = before && Editor.range(editor, before, start)
            const beforeText = beforeRange && Editor.string(editor, beforeRange)
            const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
            const after = Editor.after(editor, start)
            const afterRange = Editor.range(editor, start, after)
            const afterText = Editor.string(editor, afterRange)
            const afterMatch = afterText.match(/^(\s|$)/)
            const isAstChange = editor.operations.some(
              op => 'set_selection' !== op.type
            )

            if (beforeMatch && afterMatch) {
              setTarget(beforeRange)
              setSearch(beforeMatch[1])
              setIndex(0)
            } else {
              setTarget(null)
            }
            if (isAstChange) {
              const mentions = []
              value.forEach((node) => {
                node.children.forEach((child) => {
                  if (child.type === "mention") {
                    const userDetail = dataForMention.find((user) => user?.wUserId === child?.character?.wUserId)
                    mentions.push(userDetail)
                  }
                })
              })
              onChange(serialize(value), mentions)
            }
          } else {
            setTarget(null)
            onChange(serialize(value), [])
          }
        }}
      >
        <StyledEditable
          renderElement={renderElement}
          onKeyDown={onKeyDown}
          onDragEnd={onDragEnd}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onPaste={onPaste}
          readOnly={disabled}
        />

      </Slate>
    </div>
  )
})

const withMentions = editor => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = element => {
    return element.type === "mention" ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === "mention" ? true : isVoid(element)
  }

  editor.markableVoid = element => {
    return element.type === "mention" || markableVoid(element)
  }

  return editor
}

const insertMention = (editor, character) => {
  const mention = {
    type: "mention",
    character,
    children: [{ text: "" }]
  }
  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
}

const Element = props => {
  const { children, element } = props
  switch (element.type) {
    case "mention":
      return <Mention {...props} />
    default:
      return (
        <p
          style={{
            margin: "0",
            padding: "0",
          }}
        >
          {children}
        </p>
      )
  }
}

const Mention = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  const style = {
    padding: "3px 3px 2px",
    margin: "0 1px",
    verticalAlign: "baseline",
    display: "inline-block",
    borderRadius: "4px",
    backgroundColor: "#eee",
    fontSize: "0.9em",
    boxShadow: selected && focused ? "0 0 0 2px #B4D5FF" : "none"
  }
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element?.character?.character.replace(" ", "-")}`}
      style={style}
    >
      @{element?.character.character}
      {children}
    </span>
  )
}

export default TextEditor
