import InboxQuery from "./pages/Inbox/__generated__/InboxQuery.graphql"
import MemberResultListQuery from "./components/MemberResultList/__generated__/MemberResultListQuery.graphql.js"
import { loadQuery } from "react-relay"

export const routes = (relayEnv) => ([
  {
    component: async () => {
      const module = await import("./pages/Inbox/Inbox")
      return module.default
    },
    path: "inbox",
    preload: () => {
      return {
        query: loadQuery(relayEnv, InboxQuery, {
          folderId: "main",
          threadId: "",
          withoutThreadId: true,
        }),
        searchQuery: loadQuery(relayEnv, MemberResultListQuery),
        threadId: "",
        withoutThreadId: true,
      }
    },
  },
  {
    component: async () => {
      const module = await import("./pages/LoginRedirect/LoginRedirect")
      return module.default
    },
    path: "login_redirect",
  },
  {
    component: async () => {
      const module = await import("./pages/LogoutRedirect/LogoutRedirect")
      return module.default
    },
    path: "logout_redirect",
  },
  {
    component: async () => {
      const module = await import("./pages/Login/Login")
      return module.default
    },
    path: "login",
  },
  {
    component: async () => {
      const module = await import("./pages/Error/Error")
      return module.default
    },
    path: "error",
  },
  {
    component: async () => {
      const module = await import("./pages/Dashboard/Dashboard")
      return module.default
    },
    path: "dashboard",
  },

  // {
  //   component: async () => {
  //     const module = await import("./pages/Inbox/Inbox")
  //     return module.default
  //   },
  //   path: "inbox/:threadId",
  //   preload: ({ threadId }) => {
  //     return {
  //       query: loadQuery(RelayEnvironment(), InboxQuery, {
  //         folderId: "main",
  //         threadId,
  //         withoutThreadId: false,
  //       }),
  //       searchQuery: loadQuery(RelayEnvironment(), MemberResultListQuery),
  //       threadId,
  //       withoutThreadId: false,
  //     }
  //   }
  // },
  // {
  //   component: async () => {
  //     const module = await import("./pages/Countries");

  //     return module.CountriesPage;
  //   },
  //   path: "/continent/:continentCode/countries",
  //   preload: ({ continentCode }) => ({
  //     query: loadQuery(RelayEnvironment, CountriesPageQuery, { continentCode })
  //   })
  // },
  // {
  //   component: async () => {
  //     const module = await import("./pages/Languages");

  //     return module.LanguagesPage;
  //   },
  //   path: "/languages"
  // },
  // {
  //   component: async () => {
  //     const module = await import("./pages/Home");

  //     return module.HomePage;
  //   },
  //   path: "/"
  // },
  {
    path: "*",
    redirectRules: () => "/inbox"
  }
])
