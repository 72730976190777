import styled from "@emotion/styled"
import Color from "color"
import { setLightness } from "polished"

import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"

const LUM_THRESHOLD = 0.6

export const IntegrationsContainer = styled.div`
  width: 320px;
  font-size: 14px;
`

export const IntegrationInputActionsContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  max-height: 400px;
`

export const IntegrationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: ${(props) => props.disabled ? "default" : "pointer"};
  border-radius: 6px;
  width: 34px;
  height: 34px;

  color: ${(props) => {
    if (props.disabled) {
      return "#ccc"
    }
    if (props.color) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return props.theme.gray5
      }
      return props.color
    }
    return props.theme.gray5
  }};

  &:hover {
    background: ${(props) => {
      if (props.disabled) {
        return
      }
      return props.theme.gray1;
    }};
    color: ${(props) => {
      if (props.disabled) {
        return
      }
      return "#0084FF"
    }};
  }

  .icon {
    width: 16px;
    height: 16px;
  }
`

export const IntegrationItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0 16px 16px;
  cursor: pointer;
  justify-content: space-between;

  &:hover {
    background: ${(props) => setLightness(0.95, props.theme.themeColorGreen)};
  }

  .integration-name {
    font-weight: bold;
    color: ${(props) => props.theme.gray9};
  }

  .integration-description {
    color: ${(props) => props.theme.gray7};
    font-size: ${(props) => props.theme.textSm};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const IntegrationCustomActionsContainer = styled.div`
  width: 100%;

  .integration-name {
    font-weight: bold;
    color: ${(props) => props.theme.gray9};
  }

  .integration-description {
    color: ${(props) => props.theme.gray7};
    font-size: ${(props) => props.theme.textSm};
  }
`

export const IntegrationCustomActionButton = styled.div`
  padding: 16px;
  color: ${(props) => {
    if (props.disabled) {
      return props.theme.gray3
    }
    return props.theme.gray7
  }};
  cursor: ${(props) => {
    if (props.disabled) {
      return "not-allowed"
    }
    return "pointer"
  }};

  &:hover {
    background: ${(props) => {
      if (props.disabled) {
        return "white"
      }
      return setLightness(0.95, props.theme.themeColorGreen)
    }};
  }

  .title {
    color: ${(props) => {
      if (props.disabled) {
        return props.theme.gray3
      }
      return props.theme.gray9
    }};
    font-weight: bold;
  }

  .description {
    color: ${(props) => {
      if (props.disabled) {
        return props.theme.gray3
      }
      return props.theme.gray7
    }};
    font-size: ${(props) => props.theme.textSm};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const CustomActionsList = styled.div`
  max-height: 326px;
  overflow-y: scroll;
`

export const IconButton = styled(NewBoxButton)`

  .svg-container {
    width: ${props => {
      if (props.size === "L") {
        return "24px"
      }
      return "16px"
    }};
    height: ${props => {
      if (props.size === "L") {
        return "24px"
      }
      return "16px"
    }};
    display: flex;
    justify-content: center;
    align-items: center;
    .svg-icon {
      width: 100%;
      height: 100%;
      filter: ${(props) => {
        if (!props.mobile) {
          return props.$on ? "invert(100%) sepia(100%) saturate(1%) hue-rotate(178deg) brightness(108%) contrast(101%)" : "invert(45%) sepia(0%) saturate(2%) hue-rotate(163deg) brightness(97%) contrast(95%)"
        }
        return "invert(100%) sepia(100%) saturate(1%) hue-rotate(178deg) brightness(108%) contrast(101%)"
      }};
      opacity: ${(props) => (props.mobile && !props.$on) ? "0.4" : null};
    }
  }
  
  font-size: ${(props) => {
    switch (props.size) {
      case "L":
        return "24px"
      default:
        return "20px"
    }
  }};
  width: ${(props) => {
    switch (props.size) {
      case "L":
        return "40px"
      default:
        return "34px"
    }
  }};
  height: ${(props) => {
    switch (props.size) {
      case "L":
        return "40px"
      default:
        return "34px"
    }
  }};
  background: ${(props) => {
    if (props.inverted) {
      return "transparent"
    }
    return props.$on ? props.theme.themeColorBlue : props.theme.gray1
  }};
  transition: 0.2s linear background;
  .icon {
    width: ${(props) => {
      switch (props.size) {
        case "L":
          return "20px"
        default:
          return "14px"
      }
    }};
    height: ${(props) => {
      switch (props.size) {
        case "L":
          return "20px"
        default:
          return "14px"
      }
    }};
    transition: 0.2s linear color;
    color: ${(props) => {
      if (props.inverted) {
        return props.$on ? "white" : "rgba(255, 255, 255, 0.4)"
      }
      return props.$on ? "white" : props.theme.gray6
    }};
  }

  &:hover {
    background: ${(props) => {
      if (props.inverted) {
        return "rgba(255, 255, 255, 0.1)"
      }
      return props.$on ? props.theme.themeColorBlue : props.theme.gray05
    }};
    .icon {
      color: ${(props) => {
        if (props.inverted) {
          return props.$on ? "white" : "rgba(255, 255, 255, 0.4)"
        }
        return props.$on ? "white" : props.theme.themeColorBlue
      }};
    }

    .svg-container {
      .svg-icon {
         filter: ${(props) => {
          if (!props.mobile) {
            return props.$on ? "invert(100%) sepia(100%) saturate(1%) hue-rotate(178deg) brightness(108%) contrast(101%)" : "invert(45%) sepia(56%) saturate(7323%) hue-rotate(223deg) brightness(92%) contrast(95%)"
          }
        }
      }

    }
    
  }
`

export const ChannelSelectWrapper = styled.div`
  padding: 8px;
`
