import moment from "moment"
import 'moment/locale/es';
import 'moment/locale/zh-cn';
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import HttpApi from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { DEFAULT_LANGUAGE, LANG_QUERY_PARAM, i18n_SUPPORTED_LANGUAGES } from "./const";
const ROUTER_BASE =
  (!process.env.REACT_APP_ROUTER_BASE || process.env.REACT_APP_ROUTER_BASE === "/")
    ? ""
    : process.env.REACT_APP_ROUTER_BASE

const NODE_ENV = process.env.NODE_ENV

const HttpApiWithOptions = new HttpApi(null, {
  requestOptions: {
    cache: "no-cache"
  }
})

function initializei18n(isEmbedded) {
  moment.locale(DEFAULT_LANGUAGE)
  const chainedi18n =
    isEmbedded
      ? i18n
      .use(HttpApiWithOptions)
      .use(initReactI18next)
      .use(LanguageDetector) // detects browser language in the order specified here: (https://github.com/i18next/i18next-browser-languageDetector/blob/9efebe6ca0271c3797bc09b84babf1ba2d9b4dbb/src/index.js#L11)
      : i18n
      // TODO: enable Languague Detector only for standalone app when ready
      .use(HttpApiWithOptions)
      .use(initReactI18next)

  chainedi18n
    .init({
      backend: {
        loadPath: `${ROUTER_BASE}/static/locales/{{lng}}/{{ns}}.json`,
      },
      fallbackLng: DEFAULT_LANGUAGE,
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        format(value, format, lng) {
          moment.locale(lng === "zh" || lng === "zh-HK" ? "zh-cn" : lng)
          if (format === "uppercase") return value.toUpperCase()
          if (value instanceof Date || moment.isMoment(value)) {
            const localMoment = moment(value)
            localMoment.locale(lng === "zh" || lng === "zh-HK" ? "zh-cn" : lng)
            return localMoment.format(format)
          } 
          return value
        }
      },
      detection: {
        order: isEmbedded ? ["querystring"] : ["querystring", "localStorage", "navigator"],
        lookupQuerystring: LANG_QUERY_PARAM,
      },
      supportedLngs: i18n_SUPPORTED_LANGUAGES,
      nonExplicitSupportedLngs: true,
      returnEmptyString: false,
      debug: NODE_ENV !== "production",
      defaultNS: "common",
    }, (error) => {
        if (error) {
          console.error(error)
        }
      })
}

export {
  initializei18n,
}
