import React from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import { MessageBubble } from "../util"

const LiveChatPayload = ({ data, color, isAgent }) => {
  const { t } = useTranslation("common")
  let text
  if (data.title) {
    text = data.title
  } else if (data.payload) {
    if (_.isString(data.payload)) {
      text = data.payload
    } else {
      text = JSON.stringify(data.payload, null, 2)
    }
  }
  return (
    <MessageBubble isAgent={isAgent} color={color} className="live-chat-payload">
      <div className="caption">{t?.("message_types.live_chat_payload.user_pressed")}:</div>
      <div>{text}</div>
    </MessageBubble>
  )
}

export default LiveChatPayload
