import styled from "@emotion/styled";

const Container = styled.div`
    select {
        width: 100%;
        padding: 10px 5px;
        border: 1px solid #d5d5d5;
        border-radius: 5px;
        color: #434343;
        background-color: transparent;
    }
`

const NativeSelect = ({options, value, onChange}) => {

    const processValue = (value) => {
        return value?.value;
    }

    const onChangeHandler = (e) => {
        let find = options.find((option) => option.value === e.target.value)
        onChange({
            label: find?.label,
            value: find?.value
        })
    }

    return (
        <Container>
            <select value={processValue(value)} onChange={onChangeHandler}>
                {options.map((option, key) => {
                    return (
                        <option value={option.value} key={key}>{option.label}</option>
                    )
                })}
            </select>
        </Container>
    )
}

export default NativeSelect;