import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import graphql from "babel-plugin-relay/macro"
import Select from "react-select"

import {
  useQueryLoader,
  usePreloadedQuery,
} from "react-relay"

const UserPickerQuery = graphql`
  query UserPickerQuery ($onlyActivated: Boolean) {
    userViewer {
      users (onlyActivated: $onlyActivated) {
        wUserId
        email {
          email
          verified
        }
        firstName
        lastName
        profilePic
        role
      }
    }
  }
`

const InnerContainer = ({
  queryRef,
  ...props
}) => {
  const data = usePreloadedQuery(
    UserPickerQuery,
    queryRef,
  )
  return (
    <InnerUserPicker
      data={data}
      {...props}
    />
  )
}
const InnerUserPicker = ({
  queryRef,
  onChange,
  userId,
  data,
  disabledUserEmails = [],
  isMulti = false,
  ...props
}) => {
  const userOptions = (data?.userViewer?.users || []).map((user) => {
    return ({
      label: user?.email?.email,
      value: user?.wUserId,
      isDisabled: !!disabledUserEmails.find(email => email === user?.email?.email)
    })
  })

  let value
  if (isMulti) {
    value = userOptions.filter((o) => (userId ?? []).includes(o.value))
  } else {
    value = userOptions.find(o => o.value === userId)
  }

  const { t } = useTranslation("common")

  return (
    <Select
      isMulti={isMulti}
      className="user-picker"
      options={userOptions}
      value={value}
      isClearable={props?.isClearable ?? false}
      onChange={(value) => {
        if (isMulti) {
          if (value) {
            onChange(value.map(o => o.value), value)
          } else {
            onChange([], null)
          }
        } else {
          onChange(value?.value ?? null, value)
        }
      }}
      placeholder={isMulti ? t?.("user_picker.placeholder_select_users") : t?.("user_picker.placeholder_select_user")}
      styles={{
        container: styles => ({
          ...styles,
          width: "100%",
        }),
        menu: styles => ({
          ...styles,
          fontSize: "0.85rem",
          maxHeight: "132px"
        }),
        menuList: styles => ({
          ...styles,
          maxHeight: "132px"
        }),
        option: styles => ({
          ...styles,
          fontSize: "0.85rem"
        }),
        control: styles => ({
          ...styles,
          fontSize: "0.85rem"
        })
      }}
    />
  )
}

const UserPicker = (props) => {
  const [
    queryReference,
    loadQuery,
  ] = useQueryLoader(
    UserPickerQuery,
    props.initialQueryRef, /* e.g. provided by router */
  )

  useEffect(() => {
    loadQuery({
      onlyActivated: props.onlyActivated
    })
  }, [])

  const { t } = useTranslation("common")

  if (queryReference) {
    // const Inner = queryRenderer(InnerUserPicker, {
    //   queryRef: queryReference,
    //   query: UserPickerQuery
    // })
    return (
      <InnerContainer
        queryRef={queryReference}
        onChange={props.onChange}
        userId={props.userId}
        disabledUserEmails={props.disabledUserEmails}
        isMulti={props.isMulti}
        isClearable={props.isClearable}
      />
    )
  }
  return (
    <Select
      className="user-picker"
      placeholder={props.isMulti ? t?.("user_picker.placeholder_select_users") : t?.("user_picker.placeholder_select_user")}
      isDisabled
      styles={{
        container: styles => ({
          ...styles,
          width: "100%",
        }),
        menu: styles => ({
          ...styles,
          fontSize: "0.85rem"
        }),
        option: styles => ({
          ...styles,
          fontSize: "0.85rem"
        }),
        control: styles => ({
          ...styles,
          fontSize: "0.85rem"
        })
      }}
    />
  )
}

export default UserPicker
