/** @jsxImportSource theme-ui */
import React, { useEffect, useRef, useTransition, useState } from "react"
import { useTranslation } from "react-i18next"
import { faTimes, faCircleUser, faComment } from "@fortawesome/free-solid-svg-icons"
import moment from "moment"
import graphql from "babel-plugin-relay/macro"
import {
  usePreloadedQuery,
  usePaginationFragment,
  useMutation,
} from "react-relay"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import * as Styles from "./MentionListStyles"
import { Overlay } from "../../pages/Inbox/InboxStyles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { timestampNormalizer } from "../util"
import { randomString } from "../util"
import { convertMentions } from "./util"
import { LoadingMask } from "../../CommonStyles"
import { ReadMentionMutation } from "./MentionListQuery"

const MentionListQuery = graphql`
  query MentionListQuery {
    userViewer {
      ...MentionList_userViewer
    }
  }
`

const displayAuthorName = (author) => {
  if (!author) {
    return ""
  }
  return (!author?.firstName && !author?.lastName) ? author?.email?.email : `${author?.firstName} ${author?.lastName}`
}

const MentionList = ({
  searchQuery,
  ...props
}) => {
  const data = usePreloadedQuery(
    MentionListQuery,
    searchQuery,
  )
  return (
    <InnerMentionList
      data={data}
      {...props}
    />
  )
}

const InnerMentionList = ({
  mobile = false,
  open = false,
  onClose = () => {},
  onChange = () => {},
  redirectToThread = () => {},
  newMention,
  ...props
}) => {
  const { t } = useTranslation("common")
  const firstLoad = useRef(true)
  const [loading, startTransition] = useTransition()
  const [reverseSort, setReverseSort] = useState(false)
  const [uniq, setUniq] = useState(randomString(6))
  const [readMention] = useMutation(
    ReadMentionMutation
  )
  const [mentions, setMentions] = useState(null)
  useEffect(() => {
    setReverseSort(false)
  }, [uniq])
  const {
    data,
    loadNext,
    hasNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment(
    graphql`
      fragment MentionList_userViewer on UserScope
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 50 }
        after: { type: "String" }
      )
      @refetchable(queryName: "MentionListPaginateQuery") {
        mentions (
          first: $first
          after: $after
        ) @connection(key: "MemberResultList_mentions") {
          edges {
            cursor
            node {
              _id
              mentionedAt
              readAt
              authorId
              thread {
                title
                threadId
                folderId
              }
              author {
                wUserId
                profilePic
                email {
                  email
                }
                firstName
                lastName
              }
              chat {
                messageEvent
                mentions {
                  wUserId
                  firstName
                  lastName
                  email {
                      email
                  }
                }
              }
            }
          }
        }
      }
    `,
    props.data.userViewer
  )
  useEffect(() => {

    if (firstLoad.current) {
      firstLoad.current = false
    } else {
      startTransition(() => {
        refetch({
          first: 50,
          after: null,
        }, {
          fetchPolicy: "network-only",
        })
      })
    }
  }, [JSON.stringify(firstLoad), reverseSort, JSON.stringify(props.data.userViewer)])

  useEffect(() => {
    const mentionList = (data?.mentions?.edges || []).map((edge) => {

      return {
        ...edge.node,
        message: convertMentions(edge.node?.chat?.messageEvent?.data?.text, edge.node?.chat?.mentions, edge.node?.author?.wUserId),
        authorName: displayAuthorName(edge.node?.author),
        mentionedAt: edge.node?.mentionedAt,
      }
    })
    setMentions(mentionList)

  }, [JSON.stringify(data)])
  useEffect(() => {
    if (!mentions || !newMention) {
      return
    }
    const hasMention = mentions.find((i) => i._id === newMention._id)

    if (hasMention) {
      return
    }
    const newMentionObject = {
      ...newMention,
      message: convertMentions(newMention?.chat?.messageEvent?.data?.text, newMention?.chat?.mentions, newMention?.author?.wUserId),
      authorName: displayAuthorName(newMention?.author),
      mentionedAt: newMention?.mentionedAt,

    }
    setMentions([
      newMentionObject,
      ...mentions
    ])

  }, [JSON.stringify(newMention)])

  


  return (
    <>
      {mobile ? (
        <Overlay
          show={open}
          sx={{
            zIndex: 99
          }}
        />
      ) : null}
      <Styles.MentionListContainer mobile={mobile} show={open}>
        <Styles.MentionListTopContainer>
          <NewBoxButton
            className="close-button"
            borderless
            icon={faTimes}
            onClick={() => {
              onClose()
              setUniq(randomString(6))
            }}
          />
        </Styles.MentionListTopContainer>
        <Styles.ContentContainer>
          {loading ? (
            <Styles.LoadingContainer>
              <LoadingMask style={{ height: "100%", width: "100%" }}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </LoadingMask>
            </Styles.LoadingContainer>
          ) : (
            <>
                {mentions?.map((mention) => (
                <MentionRow
                  key={mention._id}
                  mention={mention}
                  redirectToThread={(threadId, folderId, readAt) => {
                    redirectToThread(threadId, folderId, readAt)
                    if (!readAt) {
                      readMention({
                        variables: {
                          input: {
                            mentionId: mention._id
                          }
                        },
                      })
                      firstLoad.current = true
                    }
                    onClose()
                  }}
                />
              ))}
              {hasNext && (
                <div
                  style={{
                    margin: "0 auto",
                    padding: "12px",
                  }}
                >
                  <Styles.LoadMoreButton
                    inverted
                    primary
                    borderless
                    text={t?.("common:buttons.load_more")}
                    onClick={() => {
                      loadNext(50)
                    }}
                    disabled={isLoadingNext}
                  />
                </div>
              )}
            </>
          )}
        </Styles.ContentContainer>
      </Styles.MentionListContainer>
    </>
  )
}

const MentionRow = ({ mention, redirectToThread }) => {
  const { t } = useTranslation("common")

  return (
    <Styles.MentionRowContainer
      isRead={mention?.readAt}
      onClick={() => {
        redirectToThread(mention?.thread?.threadId, mention?.thread?.folderId, mention?.readAt)
      
      }}
    >
      <div className="mention-creation-info">
        <FontAwesomeIcon
          className="icon"
          icon={faComment}
          fixedWidth
        />
        <div className="mention-thread-name">
          {mention?.thread?.title}
        </div>
        <div className="mention-creation-date">
          {t?.("mentions.mentioned_at_date", { date: moment(timestampNormalizer(mention?.mentionedAt)) })}
        </div>
      </div>
      <div className="mention-content-container">
        {mention?.author?.profilePic ? (
          <div className="mention-creator-avatar">
            <img src={mention?.author?.profilePic} alt="Mention creator avatar" />
          </div>
        ) : (
          <div className="mock-image">
            <FontAwesomeIcon
              className="icon"
              icon={faCircleUser}
              fixedWidth
            />
          </div>
        )}
        <div className="mention-content">
          <div className="mention-creator">
            <div className="mention-creator-name">
              {mention?.authorName}
            </div>
            <div className="text">
              {t?.("mentions.mentioned_you")}
            </div>
          </div>
          <div className="mention-message">
            <div dangerouslySetInnerHTML={{ __html: mention?.message }} />
          </div>
        </div>
      </div>
    </Styles.MentionRowContainer>
  )
}

export default MentionList
