import React from "react"
import _ from "lodash"

import "./SearchBar.scss"

const SearchBar = (props) => {
  const { className = "" } = props
  const newProps = _.omit(props, ["type", "className"])
  return (
    <div className={`radiate-theme search-bar-container ${className}`}>
      <input
        type="text"
        className="text-input"
        {...newProps}
      />
    </div>
  )
}

export default SearchBar
