import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ReactTooltip from "../ReactTooltip/ReactTooltip"

function randomString(length) {
  const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
  let result = ""
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}


const Icon = styled(FontAwesomeIcon)`
  color: ${props => props.fontAwesomeIconColor ?? props.theme.gray4};
  font-size: ${(props) => {
    if (props.iconsize === "S") {
      return "0.75em"
    } else if (props.iconsize === "M") {
      return "1em"
    } else if (props.iconsize === "L") {
      return "1.5em"
    }
    return "1.2em"
  }};
  margin-left: 2px;
  padding: 0 2px;
`

export const StyledTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  width: ${(props) => props.width ? props.width : "max-content"};
  z-index: 99999 !important;
`

function TooltipIcon({
  className,
  fontAwesomeIconName,
  fontAwesomeIconColor,
  iconText,
  toolTipOptions,
  withoutArrow,
  backgroundColor,
  textColor,
  iconsize,
  multiline,
  place,
  width,
  isOpen,
  ...props
}) {
  const randomId = randomString(10)
  return (
    <>
      <Icon
        fontAwesomeIconColor={fontAwesomeIconColor}
        className={className}
        fixedWidth
        icon={fontAwesomeIconName !== undefined ? fontAwesomeIconName : "question-circle"}
        data-tooltip-id={randomId}
        iconsize={iconsize}
        {...(multiline ? {"data-tooltip-html": iconText } : {"data-tooltip-content": iconText })}
      />
      <StyledTooltip
        id={randomId}
        style={{ backgroundColor, color: textColor || "#ffffff" }}
        arrowColor={backgroundColor}
        noArrow={withoutArrow}
        place={place || "top"}
        width={width}
        isOpen={isOpen}
        {...toolTipOptions}
      />
    </>
  )
}

export default TooltipIcon
