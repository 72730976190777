import graphql from "babel-plugin-relay/macro"

export const ThreadsUpdateThreadsFolderMutation = graphql`
   mutation ManageThreadsPopperQuery_updateThreadsFolderMutation (
    $input: UpdateThreadsFolderInput!
  ) {
    updateThreadsFolder(input: $input) {
      clientMutationId
      ok
      followUpActions {
        channelNames
        folderName
      }
      error {
        code
        message
      }
    }
  }
`