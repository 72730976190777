/** @jsxImportSource theme-ui */

import {
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import Menu from "./Menu"
import MenuButton from "./MenuButton"

const MessageMenu = ({
  onMarkUnread
}) => {
  const { t } = useTranslation("common")
  return (
    <Menu>
      <MenuButton
        icon={faRotateLeft}
        onClick={onMarkUnread}
      >{t?.("chat_message.mark_unread")}
      </MenuButton>
      {/* <MenuButton
        icon={faBolt}
        onClick={() => {
          setSelectedCustomAction(true)
        }}
      >Custom Action</MenuButton> */}
    </Menu>
  )
}

export default MessageMenu
