

export const convertMentions = (text, mentions, userId) => {
  // show name of mentioned user
  // colored if mentioned user is user self
  if (!text) return text

  let message = text
  const mentionsInMessage = message.match(/<@.*?>/g)

  if (mentionsInMessage) {
    mentionsInMessage.forEach((mention) => {
      const mentionId = mention.replace("<@", "").replace(">", "")
      const mentionedUser = mentions?.find((mention) => mention?.wUserId === mentionId)
      if (mentionedUser) {
        let name = ""
        if (!mentionedUser.firstName && !mentionedUser.lastName) {
          name = `${mentionedUser?.email?.email}`
        } else {
          name = `${mentionedUser?.firstName} ${mentionedUser?.lastName}`
        }
        if (userId === mentionId) {
          // color and with ligter background if mentioned user is user self
          message = message.replace(mention, `
            <span style="color: #405DE6; border-radius: 4px; ">
              @${name}
            </span>
          `)
        } else {
          message = message.replace(mention, `@${name}`)
        }
      } else {
        message = message.replace(mention, `@Unknown`)
      }
    })
  }
  return message
}