import React, { useState } from "react"
import styled from "@emotion/styled"
import { Image } from "theme-ui"

const ImageContainer = styled.div`
  width: ${props => (props.size ? props.size : "28px")};
  height: ${props => (props.size ? props.size : "28px")};
  box-sizing: border-box;
  opacity: ${(props) => {
    if (props.canSelected) {
      if (props.selected) {
        return "1"
      }
      return "0.5"
    }
    return "1"
  }};
  border-radius: ${props => (props.size ? `calc(${props.size} / 5)` : "6px")};
  overflow: hidden ;

  .image {
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: ${props => (props.size ? `calc(${props.size} / 5)` : "6px")};
  }
 `

const MockImage = styled.div`
  margin-right: 6px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.size ? `calc(${props.size} / 2.33) !important` : "12px important")};
  border-radius: 6px;
  background-color: ${props => props.theme.lightButtonIcon};
  color: #ffffff;
`

function integrationIcon(iconName) {
  let firstChar = ""
  if (iconName.length) {
    firstChar = iconName.slice(0, 1)
  }
  if (firstChar) {
    firstChar = firstChar.toUpperCase()
  }
  return firstChar
}

const IntegrationIconDisplay = ({
  icon,
  name,
  size,
  className
}) => {
  const [loadError, setLoadError] = useState(false)

  return (
    <>
      {loadError ?
        <ImageContainer size={size} className={className}>
          <MockImage className="mock-image" size={size}>
            {integrationIcon(name || "")}
          </MockImage>
        </ImageContainer>
        :
        <ImageContainer size={size} className={className}>
          {icon ?
            <Image
              className="image"
              sx={{
                borderRadius: "6px",
                maxHeight: "100%",
              }}
              onError={() => {
                setLoadError(true)
              }}
              src={icon || ""}
            />
          :
            <MockImage className="mock-image" size={size}>
              {integrationIcon(name || "")}
            </MockImage>
          }
        </ImageContainer>
      }
    </>
  )
}

export default IntegrationIconDisplay
