import graphql from "babel-plugin-relay/macro"

export const CreateFolderMutation = graphql`
  mutation FolderInfoPopperQuery_createFolderMutation (
    $input: CreateFolderInput!
  ) {
    createFolder(input: $input) {
      ok
      clientMutationId
      followUpInfo {
        email
        missingChannelNames
      }
      error {
        message
      }
      folder {
        folderId
        name
        wChannelIds
        color
        isPrivate
        userIds
      }
    }
  }
`

export const UpdateFolderMutation = graphql`
  mutation FolderInfoPopperQuery_updateFolderMutation (
    $input: UpdateFolderInput!
  ) {
    updateFolder(input: $input) {
      ok
      clientMutationId
      followUpInfo {
        email
        missingChannelNames
      }
      error {
        message
      }
      folder {
        folderId
        name
        color
        wChannelIds
        wChannels {
          name
          integrationIcon
          integrationName
        }
        isPrivate
        userIds
      }
    }
  }
`