/** @jsxImportSource theme-ui */

import React from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import _ from "lodash"
import moment from "moment"
import styled from "@emotion/styled"
import { darken } from "polished"
import Color from "color"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamationTriangle,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons"

import User from "./ChatBubble/User"
import IncomingWebhook from "./ChatBubble/IncomingWebhook"
import Agent from "./ChatBubble/Agent"
import Bot from "./ChatBubble/Bot"
import { LoadingMask } from "../../CommonStyles"
import { timestampNormalizer } from "./util"
import MessageMenu from "./PopupMenu/MessageMenu"
import ConversationSeparator from "./ConversationSeparator/ConversationSeparator"
import ReactTooltip from "../ReactTooltip/ReactTooltip"

import "./ChatVisualizer.scss"
import theme from "../../stylesheets/theme"
import { isJSONString } from "../util"
const LUM_THRESHOLD = 0.6
const unreadColor = "#0063f00f"

const propTypes = {
  chats: PropTypes.array,
  memberProfile: PropTypes.object
}

const defaultProps = {
  chats: [],
  memberProfile: {}
}

const SystemDisplay = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px;
  position: relative;

  >.text {
    padding: 4px 16px;
    border-radius: 12px;
    border: 1px solid;
    border-color: ${(props) => {
    if (props.color) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return darken(0.18, "#0084FF")
      }
      return darken(0.09, props.color)
    }
    return darken(0.18, "#0084FF")
  }};
    color: ${(props) => {
    if (props.color) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return darken(0.18, "#0084FF")
      }
      return darken(0.09, props.color)
    }
    return darken(0.18, "#0084FF")
  }};
    margin: 16px 0;
  }
`

const TimeDisplayRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  color: ${(props) => props.theme.gray7};
  gap: 4px;

  &:last-child {
    padding-bottom: 0px;
  }

  >.icon {
    font-size: ${(props) => props.theme.textSm};
    width: 16px;
    color: ${(props) => {
    if (props.isFailedAt) {
      return props.theme.error
    } else if (props.isDeliveredAt) {
      return props.theme.gray5
    } else if (props.isReadAt) {
      return props.theme.themeColorHighlight
    }
  }};
  }

  >.title {
    width: 64px;
    color: ${(props) => props.theme.gray9};
  }

  >.time {
    font-size: ${(props) => props.theme.textSm};
  }
`

class ChatVisualizer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    this.scrollToBottom({
      animated: false
    })
    setTimeout(() => {
      this.setState({
        loading: false
      })
    }, 1000)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.chats.length !== prevProps.chats.length && this.props.chats.length) {
      if (!this.props.loadingHistory) {
        this.scrollToBottom({
          animated: !!prevProps.chats.length
        })
      }
    }
  }

  scrollToBottom({
    animated = false
  }) {
    if (this.chatEnd) {
      setTimeout(() => {
        this.chatEnd?.scrollIntoView({
          block: "end",
          behavior: animated ? "smooth" : "auto"
        })
      }, 500)
    }
  }

  render() {
    const {
      chats,
      onResend,
      memberProfile,
      resourceTemplates = [],
      ownUserId,
      statusChats = [],
      app,
      appIntegration,
      channel,
      appIntegrationSignature,
      newMessagesCount,
      onMarkUnread,
      newMessageRef,
      isLiveChat,
    } = this.props
    const t = this.props.t

    const templatesForAll = resourceTemplates.filter(rt => _.isNil(rt?.for))
    const botOnlyTemplates = resourceTemplates.filter(rt => rt?.for?.includes("BOT"))
    const userOnlyTemplates = resourceTemplates.filter(rt => rt?.for?.includes("MEMBER"))
    const botTemplates = _.uniqBy([...templatesForAll, ...botOnlyTemplates], "_id")
    const userTemplates = _.uniqBy([...templatesForAll, ...userOnlyTemplates], "_id")
    let newChats = []
    if (chats.length) {
      let lastReadChatId
      let chatsUnread = false
      if (newMessagesCount > 0) {
        if (chats?.length > newMessagesCount) {
          const lastReadChat = _.nth(chats, -newMessagesCount - 1)
          if (lastReadChat) {
            lastReadChatId = lastReadChat._id
          }
          if (!lastReadChatId) {
            // should not be possible
            chatsUnread = true
          }
        } else {
          // unread count larger than page size, all messages must be unread
          chatsUnread = true
        }
      }
      chats.forEach((chat, i) => {
        const targetStatusChat = _.findLast(statusChats ?? [], (c) => c._id === chat._id)

        let resourceTemplate = null
        if (chat?.from === "BOT") {
          resourceTemplate = (botTemplates || []).find((rt) => rt.alias === chat?.messageEvent?.type)
        } else if (chat?.from === "MEMBER") {
          resourceTemplate = (userTemplates || []).find((rt) => rt.alias === chat?.messageEvent?.type)
        }
        if (chatsUnread && i === 0) {
          newChats.push(
            <ConversationSeparator
              ref={newMessageRef}
              color={theme.themeColor}
              key={`new-message-separator-${i}`}
              message={t?.("chat_message.new_messages")}
              bottomBg={unreadColor}
            />
          )
        }
        if (
          (
            i > 0 &&
            (
              (
                timestampNormalizer(chat?.messageEvent?.timestamp) &&
                timestampNormalizer(chats[i - 1]?.messageEvent?.timestamp) && (
                  moment(timestampNormalizer(chat?.messageEvent?.timestamp)).date() !== moment(timestampNormalizer(chats[i - 1]?.messageEvent?.timestamp)).date() ||
                  moment(timestampNormalizer(chat?.messageEvent?.timestamp)).month() !== moment(timestampNormalizer(chats[i - 1]?.messageEvent?.timestamp)).month() ||
                  moment(timestampNormalizer(chat?.messageEvent?.timestamp)).year() !== moment(timestampNormalizer(chats[i - 1]?.messageEvent?.timestamp)).year()
                )
              ) ||
              (
                timestampNormalizer(chat?.createdAt) &&
                timestampNormalizer(chats[i - 1]?.createdAt) && (
                  moment(timestampNormalizer(chat?.createdAt)).date() !== moment(timestampNormalizer(chats[i - 1]?.createdAt)).date() ||
                  moment(timestampNormalizer(chat?.createdAt)).month() !== moment(timestampNormalizer(chats[i - 1]?.createdAt)).month() ||
                  moment(timestampNormalizer(chat?.createdAt)).year() !== moment(timestampNormalizer(chats[i - 1]?.createdAt)).year()
                )
              )
            )
          ) 
          ||
          (
            i === 0 &&
            timestampNormalizer(chat?.createdAt)
          )
        ) {
          if (chat?.createdAt || chat?.messageEvent?.timestamp) {
            newChats.push(
              <ConversationSeparator
                color={theme.themeColorGreen}
                key={chat.createdAt || chat.messageEvent.timestamp}
                message={t?.("chat_message.conversation_separator_date", { date: moment(timestampNormalizer(chat.createdAt || chat.messageEvent.timestamp)) })}
                bottomBg={chatsUnread ? unreadColor : undefined}
                topBg={chatsUnread ? unreadColor : undefined}
              />
            )
          }
        }
        if (chat.from === "BOT" && chat?.wUserId) {
          newChats.push(
            <Agent
              isConversation
              key={chat?.clientMutationId || chat._id || i}
              chatId={chat?.clientMutationId || chat._id}
              resourceTemplate={resourceTemplate}
              message={chat.messageEvent}
              themeColor={theme.themeColorGreen}
              createChatError={chat?.createChatError}
              createdAt={chat?.createdAt}
              onResend={onResend}
              clientMutationId={chat?.clientMutationId}
              readAt={targetStatusChat?.readAt || chat?.readAt}
              deliveredAt={targetStatusChat?.deliveredAt || chat?.deliveredAt}
              failedAt={targetStatusChat?.failedAt || chat?.failedAt}
              error={targetStatusChat?.error || chat?.error}
              profilePic={chat?.agent?.icon || chat?.user?.profilePic}
              name={chat?.agent?.name}
              email={chat?.user?.email?.email}
              byMe={chat?.wUserId === ownUserId}
              unread={chatsUnread}
              isLiveChat={isLiveChat}
              app={app}
              appIntegration={appIntegration}
              channel={channel}
              appIntegrationSignature={appIntegrationSignature}
              messageMenu={(
                <MessageMenu
                  onMarkUnread={() => {
                    onMarkUnread(chats?.length - i)
                  }}
                />
              )}
              messageMenuStyle={{
                top: "-8px",
                right: "18px",
                position: "absolute",
              }}
              messageMenuPlacement="bottom-start"
            />
          )
        } else if (chat.from === "BOT") {
          if (chat.messageEvent.type !== "WEB_ACTION") {      
            newChats.push(
              <Bot
                isConversation
                key={chat?.clientMutationId || chat._id || i}
                chatId={chat?.clientMutationId || chat._id}
                resourceTemplate={resourceTemplate}
                message={chat.messageEvent}
                readAt={targetStatusChat?.readAt || chat?.readAt}
                deliveredAt={targetStatusChat?.deliveredAt || chat?.deliveredAt}
                failedAt={targetStatusChat?.failedAt || chat?.failedAt}
                error={targetStatusChat?.error || chat?.error}
                createdAt={chat?.createdAt}
                isLast={chats.length === i + 1}
                onAction={(action) => {
                  this.props.onAction(action)
                }}
                clientMutationId={chat?.clientMutationId}
                app={app}
                appIntegration={appIntegration}
                channel={channel}
                appIntegrationSignature={appIntegrationSignature}
                unread={chatsUnread}
                isLiveChat={isLiveChat}
                messageMenu={(
                  <MessageMenu
                    onMarkUnread={() => {
                      onMarkUnread(chats?.length - i)
                    }}
                  />
                )}
                messageMenuStyle={{
                  top: "-8px",
                  right: "18px",
                  position: "absolute",
                }}
                messageMenuPlacement="bottom-end"
              />
            )
          }
        } else if (chat.from === "MEMBER" && chat.messageEvent.type === "SYSTEM") {
          newChats.push(
            <SystemDisplay
              color={theme.themeColorGreen}
              key={chat._id}
              unread={chatsUnread}
            >
              <div className="text">{chat.messageEvent.data.body}</div>
            </SystemDisplay>
          )
        } else if (chat.from === "MEMBER" && chat.memberId !== this.props.memberId) {
          if (chat.messageEvent.type !== "WEB_EVENT") {
            newChats.push(
              <User
                isConversation
                key={chat?.clientMutationId || chat._id || i}
                chatId={chat?.clientMutationId || chat._id}
                resourceTemplate={resourceTemplate}
                message={chat.messageEvent}
                profilePic={memberProfile?.profilePicUrl}
                themeColor={theme.themeColorGreen}
                firstName={memberProfile?.firstName || memberProfile?.name}
                error={targetStatusChat?.error || chat?.error}
                createdAt={chat?.createdAt}
                onResend={onResend}
                clientMutationId={chat?.clientMutationId}
                app={app}
                appIntegration={appIntegration}
                channel={channel}
                appIntegrationSignature={appIntegrationSignature}
                messageMenu={(
                  <MessageMenu
                    onMarkUnread={() => {
                      onMarkUnread(chats?.length - i)
                    }}
                  />
                )}
                messageMenuStyle={{
                  top: "-8px",
                  right: "18px",
                  position: "absolute",
                }}
                messageMenuPlacement="bottom-start"
                unread={chatsUnread}
              />
            )
          }
        } else if (chat.from === "AGENT" && chat.internal) { 
          newChats.push(
            <Agent
              key={chat?.clientMutationId || chat._id || i}
              chatId={chat?.clientMutationId || chat._id}
              message={chat.messageEvent}
              themeColor={theme.commentColor}
              createChatError={chat?.createChatError}
              createdAt={chat?.createdAt}
              onResend={onResend}
              clientMutationId={chat?.clientMutationId}
              readAt={targetStatusChat?.readAt || chat?.readAt}
              deliveredAt={targetStatusChat?.deliveredAt || chat?.deliveredAt}
              failedAt={targetStatusChat?.failedAt || chat?.failedAt}
              error={targetStatusChat?.error || chat?.error}
              profilePic={chat?.agent?.icon || chat?.user?.profilePic}
              name={chat?.agent?.name}
              email={chat?.user?.email?.email}
              ownUserId={ownUserId}
              byMe={chat?.wUserId === ownUserId}
              mentions={chat?.mentions}
              unread={chatsUnread}
              messageMenu={(
                <MessageMenu
                  onMarkUnread={() => {
                    onMarkUnread(chats?.length - i)
                  }}
                />
              )}
              messageMenuStyle={{
                top: "-8px",
                right: "18px",
                position: "absolute",
              }}
              messageMenuPlacement="bottom-end"
            />
          )
        } else if (chat.from === "INCOMING_WEBHOOK") {
          newChats.push(
            <IncomingWebhook
              key={chat?.clientMutationId || chat._id || i}
              chatId={chat?.clientMutationId || chat._id}
              message={chat.messageEvent}
              meta={chat.meta}
              readAt={targetStatusChat?.readAt || chat?.readAt}
              deliveredAt={targetStatusChat?.deliveredAt || chat?.deliveredAt}
              failedAt={targetStatusChat?.failedAt || chat?.failedAt}
              error={targetStatusChat?.error || chat?.error}
              unread={chatsUnread}
              messageMenu={(
                <MessageMenu
                  onMarkUnread={() => {
                    onMarkUnread(chats?.length - i)
                  }}
                />
              )}
              messageMenuStyle={{
                top: "-8px",
                right: "18px",
                position: "absolute",
              }}
              messageMenuPlacement="bottom-end"
            />
          )
        } else {
          if (chat.messageEvent.type !== "WEB_EVENT") {
            newChats.push(
              <User
                isConversation
                key={chat?.clientMutationId || chat._id || i}
                chatId={chat?.clientMutationId || chat._id}
                message={chat.messageEvent}
                resourceTemplate={resourceTemplate}
                profilePic={memberProfile?.profilePicUrl}
                firstName={memberProfile?.firstName || memberProfile?.name}
                themeColor={theme.themeColorGreen}
                error={targetStatusChat?.error || chat?.error}
                createdAt={chat?.createdAt}
                onResend={onResend}
                clientMutationId={chat?.clientMutationId}
                readAt={targetStatusChat?.readAt || chat?.readAt}
                deliveredAt={targetStatusChat?.deliveredAt || chat?.deliveredAt}
                failedAt={targetStatusChat?.failedAt || chat?.failedAt}
                app={app}
                appIntegration={appIntegration}
                channel={channel}
                appIntegrationSignature={appIntegrationSignature}
                messageMenu={(
                  <MessageMenu
                    onMarkUnread={() => {
                      onMarkUnread(chats?.length - i)
                    }}
                  />
                )}
                messageMenuStyle={{
                  top: "-8px",
                  right: "18px",
                  position: "absolute",
                }}
                messageMenuPlacement="bottom-start"
                unread={chatsUnread}
              />
            )
          }
        }
        if (lastReadChatId && lastReadChatId === chat._id) {
          newChats.push(
            <ConversationSeparator
              ref={newMessageRef}
              color={theme.themeColor}
              key={`new-message-separator-${i}`}
              message={t?.("chat_message.new_messages")}
              bottomBg={unreadColor}
            />
          )
          chatsUnread = true
        }
      })
    }
    return (
      <div
        ref={this.props.chatVisualizerRef}
        className="chat-visualizer-container"
      >
        {this.state.loading || this.props.loading ? (
          <LoadingMask
            sx={{
              position: "absolute",
              bottom: 0,
              background: "#f8f8f8",
              zIndex: 9999,
            }}
          >
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </LoadingMask>
        ) : null}
        {newChats}
        <div
          ref={(c) => { this.chatEnd = c }}
          className="scroll-end-dummy"
        >
        </div>
        <ReactTooltip
          id="chat-visualizer-tooltip"
          noArrow
          style={{
            backgroundColor: "#ffffff",
            maxWidth: "280px",
            color: "#3c3c3c",
            boxShadow: "0 1px 4px 0px rgba(0, 0, 0, 0.1)",
          }}
          opacity={1}
          render={({ content, activeAnchor }) => {
            const type = activeAnchor?.getAttribute("data-tt-type")
            if (type === "time-display") {
              if (!content || !isJSONString(content)) {
                return null
              }
              let data
              try {
                data = JSON.parse(content)
              } catch (error) {
                return content
              }
              return (
                <div>
                  {data?.readAt &&
                    <TimeDisplayRow isReadAt>
                      <FontAwesomeIcon
                        className="icon"
                        icon={faCheckDouble}
                      />
                      <div className="title">{t?.("chat_message.read")}</div>
                      <div className="time">{t?.("chat_message.read_time", { date: moment(timestampNormalizer(data?.readAt)) })}</div>
                    </TimeDisplayRow>
                  }
                  {data?.deliveredAt &&
                    <TimeDisplayRow isDeliveredAt>
                      <FontAwesomeIcon
                        className="icon"
                        icon={faCheckDouble}
                      />
                      <div className="title">{t?.("chat_message.delivered")}</div>
                      <div className="time">{t?.("chat_message.delivered_time", { date: moment(timestampNormalizer(data?.deliveredAt)) })}</div>
                    </TimeDisplayRow>
                  }
                  {data?.failedAt &&
                    <TimeDisplayRow isFailedAt>
                      <FontAwesomeIcon
                        className="icon"
                        icon={faExclamationTriangle}
                      />
                      <div className="title">{t?.("chat_message.failed")}</div>
                      <div className="time">{t?.("chat_message.failed_time", { date: moment(timestampNormalizer(data?.failedAt)) })}</div>
                    </TimeDisplayRow>
                  }
                </div>
              )
            }
            return content
          }}
        />
      </div>
    )
  }
}

ChatVisualizer.propTypes = propTypes
ChatVisualizer.defaultProps = defaultProps

export default React.memo(withTranslation("common", { withRef: true })(ChatVisualizer), (prevProps, props) => {
  if (!_.isEqual(prevProps.isLiveChat, props.isLiveChat)) {
    return false
  }
  if (!_.isEqual(prevProps.loadingHistory, props.loadingHistory)){
    return false
  }
  if (!_.isEqual(prevProps.loading, props.loading)){
    return false
  }
  if (!_.isEqual(prevProps.ownUserId, props.ownUserId)) {
    return false
  }
  if (!_.isEqual(prevProps.chats, props.chats)) {
    return false
  }
  if (!_.isEqual(prevProps.statusChats, props.statusChats)) {
    return false
  }
  if (!_.isEqual(prevProps.memberProfile, props.memberProfile)) {
    return false
  }
  if (!_.isEqual(prevProps.resourceTemplates, props.resourceTemplates)) {
    return false
  }
  if (!_.isEqual(prevProps.app, props.app)) {
    return false
  }
  if (!_.isEqual(prevProps.appIntegration, props.appIntegration)) {
    return false
  }
  if (!_.isEqual(prevProps.channel, props.channel)) {
    return false
  }
  if (!_.isEqual(prevProps.appIntegrationSignature, props.appIntegrationSignature)) {
    return false
  }
  if (!_.isEqual(prevProps.newMessagesCount, props.newMessagesCount)) {
    return false
  }
  if (props.onMarkUnread !== prevProps.onMarkUnread) {
    return false
  }
  if (props.onResend !== prevProps.onResend) {
    return false
  }
  return true
})
