import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import styled from "@emotion/styled"
import { setLightness } from "polished"

const propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
}

const defaultProps = {
  label: "",
  options: [],
  disabled: false,
  value: "",
  color: "#39b34a",
  className: "",
  onChange: () => { },
  onFocus: () => { }
}

const RadioSelectFieldContainer = styled.div`
  color: ${props => {
    if (props.disabled) {
      return props.theme.gray3
    } else if (props.readOnly) {
      return props.theme.gray5
    }
    return props.theme.gray7
  }};

  .radio-button-label {
    padding-bottom: 4px;
    font-size: ${(props) => props.theme.textSm};
  }
`

const OptionWrapper = styled.div`
  padding: 4px 0 4px 12px;
  align-items: center;
  display: flex;
  cursor: ${(props) => props.disabled || props.disabledOption || props.readOnly ? "inherit" : "pointer"};
  color: ${(props) => {
    if (props.disabled) {
      return props.theme.gray3
    } else if (props.disabledOption) {
      return props.theme.gray3
    } else if (props.readOnly) {
      return props.theme.gray5
    }
    return props.theme.gray7
  }};
  
  &:hover {
    .radio-button-border {
      transition: border 0.1s ease-in-out;
      border: 2px solid ${(props) => {
        if (props.disabledOption || props.disabled) {
          return props.theme.gray3
        } else if (props.readOnly & props.$on) {
          return props.theme.themeColor
        } else if (props.readOnly & !props.$on) {
          return props.theme.gray3
        } else if (props.$on) {
          return setLightness(0.55, props.theme.themeColor)
        }
        return setLightness(0.55, props.theme.gray4)
      }};

      .radio-button {
        transition: background-color 0.1s ease-in-out;
        background-color: ${(props) => {
          if ((props.disabledOption || props.disabled) && props.$on) {
            return props.theme.gray3
          } else if (props.readOnly && props.$on) {
            return props.theme.themeColor
          } else if (props.readOnly & !props.$on) {
            return "#ffffff"
          } else if (props.disabledOption || props.disabled) {
            return "#ffffff"
          } else if (props.$on) {
            return setLightness(0.55, props.theme.themeColor)
          }
        }};
      }
    }
  }

  .text {
    padding-left: 12px;
    font-size: ${(props) => props.theme.textSm};

    .description {
      font-size: ${(props) => props.theme.textSm};
    }
    
  }
`

const RadioButtonBorder = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transition: border 0.1s ease-in-out;
  border: 2px solid ${(props) => {
    if (props.disabled || props.disabledOption) {
      return props.theme.gray3
    } else if (props.$on) {
      return props.theme.themeColor
    }
    return props.theme.gray3
  }};
  box-sizing: border-box;
`

const RadioButton = styled.div`
  box-sizing: border-box;
  transition: background-color 0.1s ease-in-out;
  background-color: ${(props) => {
    if ((props.disabledOption || props.disabled) && props.$on) {
      return props.theme.gray3
    } else if (props.disabledOption || props.disabled) {
      return "#ffffff"
    } else if (props.$on) {
      return props.theme.themeColor
    } 
    return "#ffffff"
  }};
  width: 14px;
  height: 14px;
  border-radius: 7px;
`

const RadioSelectField = class RadioSelectField extends React.Component {
  render() {
    const {
      label,
      options,
      disabled,
      value,
      className,
      onFocus,
      onChange,
      readOnly
    } = this.props

    const defaultClassName = `radiate-theme radio-select-field-container ${className}`

    return (
      <RadioSelectFieldContainer
        className={defaultClassName}
        disabled={disabled}
        readOnly={readOnly}
      >
        {label ? <div className="radio-button-label">{label}</div> : null}
        {options.map((option, index) => (
          <OptionWrapper
            key={`${index}`}
            className="radio-button-option-wrapper"
            $on={option.value === value}
            disabledOption={option.disabled}
            disabled={disabled}
            readOnly={readOnly}
            onClick={() => {
              if (_.get(option, "disabled") || disabled || readOnly) {
                return null
              } else {
                onChange(option.value)
                onFocus()
              }
            }}
          >
            <RadioButtonBorder
              className="radio-button-border"
              $on={option.value === value}
              disabledOption={option.disabled}
              disabled={disabled}
              readOnly={readOnly}
            >
              <RadioButton
                className="radio-button"
                $on={option.value === value}
                disabledOption={option.disabled}
                disabled={disabled}
                readOnly={readOnly}
              />
            </RadioButtonBorder>
            <div className="text">
              <div className="title">{option.text}</div>
              {option?.description && <div className="description">{option.description}</div>}
            </div>
          </OptionWrapper>
          ))}
      </RadioSelectFieldContainer>
    )
  }
}

RadioSelectField.propTypes = propTypes
RadioSelectField.defaultProps = defaultProps

export default RadioSelectField
