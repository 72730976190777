import React, { useState, useEffect, useRef } from "react"
import _ from "lodash"
const WOZTELL_URL = process.env.REACT_APP_WOZTELL_URL

export const WoztellContext = React.createContext({
  payload: null,
  signedContext: null,
  loading: true,
})

const WoztellContextProvider = ({
  search,
  children
}) => {
  const [payload, setPayload] = useState(null)
  const [signedContext, setSignedContext] = useState(null)
  const [productFruitKey, setProductFruitKey] = useState(null)
  const [loading, setLoading] = useState(true)
  const timer = useRef(null)

  const receiveMessage = (event) => {
    const whitelistedOrigins = WOZTELL_URL.split("||")
    if (!whitelistedOrigins.includes(event.origin)) {
      return
    }
    if (event?.data) {
      if (!_.isEmpty(event.data?.payload)) {
        setPayload(event.data?.payload)
        setSignedContext(event.data?.signedContext)
        setProductFruitKey(event.data?.productFruitKey)
        setTimeout(() => {
          setLoading(false)
        }, 0)
      }
    }
  }

  useEffect(() => {
    if (search && search.get("embed") === "woztell") {
      if (navigator.userAgent !== "ReactSnap") {
        window.addEventListener("message", receiveMessage, false)
        window.parent.postMessage("iframeFinishLoading", "*")

        timer.current = setTimeout(() => {
          setLoading(false)
        }, 10000)

        return () => {
          if (timer.current) {
            clearTimeout(timer.current)
          }
          window.removeEventListener("message", receiveMessage, false)
        }
      }
    } else {
      setLoading(false)
    }
  }, [search?.get("embed")])


  return (
    <WoztellContext.Provider
      value={{
        payload,
        signedContext,
        loading,
        productFruitKey,
      }}
    >
      {children}
    </WoztellContext.Provider>
  )
}

export default WoztellContextProvider
