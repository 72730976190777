import React from "react"
import { Button } from "../util"

const UrlButton = ({ data, isListButton }) =>
  (
    <Button
      isUrlButton
      isListButton={isListButton}
    >
      <a
        className="link"
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="title">{data.title}</div>
      </a>
    </Button>
  )

export default UrlButton
