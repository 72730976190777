/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"

import IconContainer from "../IconContainer/IconContainer"
import ChannelsPicker from "../ChannelsPicker/ChannelsPicker"
import SegmentedSelect from "../Radiate/SegmentedSelect/SegmentedSelect"
import Tag from "../Tag/Tag"
import theme from "../../stylesheets/theme"
import { UserContext } from "../../contexts/UserContext"

import { LoadingMask } from "../../CommonStyles"

import {
  useMutation
} from "react-relay"
import {
  UpdateUserMutation,
  ActivateUserMutation
} from "./SettingsModalQuery"

import * as  Styles from "./SettingsModalStyles"

const UserRow = ({
  user,
  ownUserId,
  isAdmin,
  isInitialAdmin,
  isInitialSuperAdmin,
  onError
}) => {
  const { isSuperAdmin, isSuperAuditor } = useContext(UserContext)
  const channelIds = (user?.channels || []).map(c => c.wChannelId)
  const [selectedChannelIds, setSelectedChannelIds] = useState(channelIds)
  const [updateUser, isInFlightUpdateUser] = useMutation(
    UpdateUserMutation
  )
  const [activateUser, isInFlightActivateUser] = useMutation(
    ActivateUserMutation
  )

  const { t } = useTranslation(["settings", "common"])

  useEffect(() => {
    setSelectedChannelIds(channelIds)
  }, [JSON.stringify(channelIds)])

  const updateUserFunction = ({
    wChannelIds,
    wUserId,
    role
  }) => {
    let clientMutationId = 0
    const mutationParams = {
      variables: {
        input: {
          wChannelIds,
          wUserId,
          role,
          clientMutationId: clientMutationId++,
        },
      },
      onCompleted: (response, errs) => {
        if (!response.updateUser?.error) {
        } else {
          onError(response.updateUser?.error?.message || t?.("common:errors.something_went_wrong"))
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        onError(errorMsg)
      }
    }
    updateUser(mutationParams)
  }

  const activateUserFunction = ({
    wUserId,
    role
  }) => {
    let clientMutationId = 0
    const mutationParams = {
      variables: {
        input: {
          wUserId,
          role,
          clientMutationId: clientMutationId++,
        },
      },
      onCompleted: (response, errs) => {
        if (!response.activateUser?.error) {
        } else {
          onError(response.activateUser?.error?.message || "Something went wrong.")
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        onError(errorMsg)
      }
    }
    activateUser(mutationParams)
  }
  

  const roleOptions = [{
    text: t?.("users_settings.ROLE_ADMIN"),
    value: "ADMIN"
  }, {
    text: t?.("users_settings.ROLE_USER"),
    value: "USER"
  }]

  let disableRoleChange = true
  if (
    (isAdmin || isInitialSuperAdmin) // if your own role is admin or super admin
    && user?.wUserId !== ownUserId // if you are not editing your own role
    && user?.initialRole !== "ADMIN" // if the user's initial role is not admin
    && (
      (user?.role === "ADMIN" && (isInitialAdmin || isInitialSuperAdmin)) // if the user's role is admin and my role is initialAdmin or super admin
      || user?.role === "USER"
    )
  ) {
    disableRoleChange = false
  }

  let userRoleTooltipMessage = null
  if (user?.wUserId === ownUserId) {
    userRoleTooltipMessage = t?.("users_settings.tooltips.no_permission_to_edit_own_role")
  } else if (user?.initialRole === "ADMIN") {
    userRoleTooltipMessage = t?.("users_settings.tooltips.no_permission_to_edit_initial_admin_role")
  } else if (!isAdmin) {
    userRoleTooltipMessage = t?.("users_settings.tooltips.no_permission_to_edit_role")
  } else if (user?.role === "ADMIN" && !isInitialAdmin && isInitialSuperAdmin) {
    userRoleTooltipMessage = null
  } else if (user?.role === "ADMIN" && !isInitialAdmin) {
    userRoleTooltipMessage = t?.("users_settings.tooltips.no_permission_to_edit_admin_role")
  }

  let disableChannelPicker = false
  if (user?.role === "ADMIN") {
    disableChannelPicker = true
  }
  if (user?.role !== "ADMIN" && isAdmin) {
    disableChannelPicker = false
  }
  if (user?.role !== "ADMIN" && isInitialSuperAdmin) {
    disableChannelPicker = false
  }

  const renderRoleColumn = () => {
    let displayContent
    if (!user?.connected) {
      displayContent = (<td></td>)
    } else if (isAdmin) {
      displayContent = (
        <td className="role-select">
          <div
            data-tooltip-content={disableRoleChange ? userRoleTooltipMessage : null}
            data-tooltip-id="user-row-disable-tooltip"
          >
            <SegmentedSelect
              items={roleOptions}
              value={user?.role}
              readOnly={disableRoleChange}
              onChange={(value) => {
                const confirmUpdateRole = t?.("users_settings.confirm_update_role", { email: user?.email?.email })
                const c = confirm(confirmUpdateRole)
                if (c) {
                  updateUserFunction({
                    wUserId: user?.wUserId,
                    role: value,
                  })
                }
              }}
            />
          </div>
        </td>
      )
    } else {
      displayContent = (
        <td className="role">
          <Tag
            backgroundColor={user?.role === "ADMIN" ? theme.success : "#6544f4"}
            color="#ffffff"
          >
            {user?.role}
          </Tag>
        </td>
      )
    }
    return displayContent
  }
  return (
    <Styles.FlexRow>
      <td className="icon">
        <IconContainer
          className="chat-header-profile-pic"
          url={user?.profilePic}
          size="S"
          name={user?.firstName || user?.email?.email}
        />
      </td>
      <td className="email">
        {user?.email?.email}
        <div className="name">{user?.firstName} {user?.lastName}</div>
      </td>
      {renderRoleColumn()}
      <td className="channels">
        {!!user?.connected ?
        <div
          data-tooltip-content={user?.role === "ADMIN" ? t?.("users_settings.tooltips.cannot_update_channels_of_admin"): null}
          data-tooltip-id="user-row-disable-tooltip"
        >
          <ChannelsPicker
            placeholder={t?.("users_settings.select_channels_placeholder")}
            style={{ width: "100%" }}
            selectedChannelIds={selectedChannelIds}
            showAllChannels
            isDisabled={disableChannelPicker || isSuperAdmin || isSuperAuditor}
            onChange={(channelIds) => {
              if (!channelIds?.length) {
                const confirmRemoveAllChannels = t?.("users_settings.confirm_remove_all_channels", { email: user?.email?.email })
                const c = confirm(confirmRemoveAllChannels)
                if (c) {
                  updateUserFunction({
                    wUserId: user?.wUserId,
                    wChannelIds: []
                  })
                }
              } else {
                updateUserFunction({
                  wUserId: user?.wUserId,
                  wChannelIds: channelIds
                })
              }
            }}
          />
          </div>
        :
          <Styles.ConnectButton
            primary
            text={t?.("users_settings.activate_user_button")}
            loading={isInFlightActivateUser}
            disabled={isSuperAdmin || isSuperAuditor}
            onClick={() => {
              activateUserFunction({
                wUserId: user?.wUserId
              })
            }}
          />
        }
      </td>
      {isInFlightUpdateUser &&
        <td colspan="4" style={{ height: "100%", width: "100%", position: "absolute", top: "0", left: "0" }}>
          <LoadingMask style={{ height: "100%", width: "100%", background: "#ffffff", opacity: "0.8", pointerEvents: "none", zIndex: "9" }}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </LoadingMask>
        </td>
      }
    </Styles.FlexRow>
  )
}

export default UserRow
