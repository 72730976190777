export const LOAD_MORE_CHAT_QUANTITY = 50
export const FOLLOW_UP_COLOR = "#5851DB"
export const FOLLOW_UP_TEMP_FOLDER_ID = "20ad3229-f271-4d84-89b1-bc30baf55c7e"
export const IDLE_TIME_MS = 5 * 60 * 1000

export const MOBILE_BREAKPOINT = "500px"
export const MOBILE_MESSAGE_MAX_WIDTH = "212px"

export const DEFAULT_LANGUAGE = "en"
export const LANG_QUERY_PARAM = "lang"
export const i18n_SUPPORTED_LANGUAGES = ["en", "es", "zh", "zh-HK"] // update when supporting new langugage
