import styled from "@emotion/styled"
import { faClose, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import IosPwaPrompt from "react-ios-pwa-prompt"
import { usePWAInstall } from "react-use-pwa-install"

const InstallerPromptWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  box-shadow: 0px 1px 5px 1px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0px 1px 5px 1px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 1px 5px 1px rgba(0,0,0,0.1);
  .flexWrap {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-right: 16px;
    div {
      color: #444;
    }
    .icon {
      color: #0084FF;
      font-size: 22px;
      margin-right: 8px;
    }
  }

  .icon {
    cursor: pointer;
    color: #aaa;
  }
`

const InstallerContainer = styled.div`
  display: ${(props) => props.show ? 'flex' : 'none'};
  width: 100%;
  justify-content: center;
  align-items: center;
`

const InstallerPrompt = () => {
  const install = usePWAInstall()
  const [isShow, setIsShow] = useState(true)
  const [isIos, setIsIos] = useState(false)
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false)

  useEffect(() => {
    // Detects if device is on iOS 
    const checkiOS = () => {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod/.test(userAgent)
    }
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone)
    // Detects if browser is in apple inc
    const isVendorMode = () => {
      const vendor = window.navigator.vendor.toLocaleLowerCase()
      return /apple computer, inc./.test(vendor)
    }
    // Checks if should display install popup notification:
    // console.log("checkiOS", checkiOS())
    // console.log("isInStandaloneMode", isInStandaloneMode())
    // console.log("isVendorMode", isVendorMode())
    if (checkiOS() && !isInStandaloneMode() && isVendorMode()) {
      setIsIos(true)
    }

    const agent = navigator.userAgent || navigator.vendor || window.opera
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(agent)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(agent.substr(0, 4))
    ) {
      // check if it is mobile or tablet
      setIsMobileOrTablet(true)
    }
  }, [])

  if (isIos) {
    return (
      <IosPwaPrompt
        promptOnVisit={1}
        timesToShow={5}
        copyClosePrompt="Close"
        permanentlyHideOnDismiss={false}
      />
    )
  }

  return (
    <>
      {install && (
        <>
          <InstallerContainer show={isShow}>
            <InstallerPromptWrapper>
              <div className="flexWrap" onClick={install}>
                <FontAwesomeIcon className="icon" icon={faPlusCircle} />
                <div>{isMobileOrTablet ? "Add Woztell Inbox to Home Screen" : "Install Woztell Inbox"}</div>
              </div>
              <FontAwesomeIcon className="icon" icon={faClose} onClick={() => setIsShow(false)} />
            </InstallerPromptWrapper>
          </InstallerContainer>
        </>
      )}
    </>
  )
}

export default InstallerPrompt