import moment from "moment"
import _ from "lodash"

export const omitEmptyFieldsBeforeSave = (profile) => {
  let newPhones = null
  let newEmails = null
  let newAddresses = null
  if (profile?.phones?.length) {
    newPhones = []
    profile.phones.forEach((phone) => {
      if (phone?.number?.trim()) {
        newPhones = [...newPhones, phone]
      }
    })
  }
  if (profile?.emails?.length) {
    newEmails = []
    profile.emails.forEach((email) => {
      if (email?.email?.trim()) {
        newEmails = [...newEmails, email]
      }
    })
  }
  if (profile?.addresses?.length) {
    newAddresses = []
    profile.addresses.forEach((address) => {
      if (
        address?.line1?.trim() ||
        address?.line2?.trim() ||
        address?.city?.trim() ||
        address?.state?.trim() ||
        address?.country?.trim() ||
        address?.zip?.trim()
      ) {
        newAddresses = [...newAddresses, address]
      }
    })
  }
  if (!newPhones?.length) {
    newPhones = null
  }
  if (!newEmails?.length) {
    newEmails = null
  }
  if (!newAddresses?.length) {
    newAddresses = null
  }
  const newProfile = {
    ...profile,
    phones: newPhones,
    emails: newEmails,
    addresses: newAddresses,
  }
  return newProfile
}

export const dobValidationCheck = (dob) => {
  let valid = true
  const thisDate = moment(`${dob.date}-${dob.month}-${dob.year}`, "DD-MM-YYYY")
  valid = moment(thisDate).isValid()
  return valid
}

export const duplicateAliasCheck = ({
  data,
}) => {
  const aliasList = data?.map((item) => item.alias)
  const isDuplicated = _.uniq(aliasList).length !== aliasList.length

  return isDuplicated || false
}
