import graphql from "babel-plugin-relay/macro"

export const InitializeLogoutMutation = graphql`
  mutation SettingsModalQuery_initializeLogoutMutation {
    initializeLogout {
      ok
      token
      state
      error {
        code
        message
      }
    }
  }
`

export const LogoutMutation = graphql`
  mutation SettingsModalQuery_logoutMutation (
    $input: LogoutInput!
  ) {
    logout(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`

export const SwitchAppMutation = graphql`
  mutation SettingsModalQuery_switchAppMutation (
    $input: SwitchAppForSessionInput!
  ) {
    switchAppForSession(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`

export const UpdateAgentMutation = graphql`
  mutation SettingsModalQuery_updateAgentMutation (
    $input: UpdateAgentInput!
  ) {
    updateAgent(input: $input) {
      clientMutationId
      agent {
        wUserId
        etag
        email
        name
        icon
        user {
          wUserId
          email {
            email
            verified
          }
          firstName
          lastName
          profilePic
        }
      }
      channel {
        etag
        name
        wAppId
        wChannelId
        users {
          wUserId
          email {
            email
            verified
          }
          firstName
          lastName
          profilePic
          role
        }
        agents {
          _id
          etag
          email
          name
          icon
          role
          user {
            wUserId
            email {
              email
              verified
            }
            profilePic
            firstName
            lastName
            profilePic
            role
            initialRole
            channels {
              wChannelId
              integrationIcon
              integrationName
              name
            }
          }
        }
      }
      error {
        code
        message
      }
    }
  }
`

export const RemoveUserFromChannelMutation = graphql`
  mutation SettingsModalQuery_removeUserFromChannelMutation (
    $input: RemoveUserFromChannelInput!
  ) {
    removeUserFromChannel(input: $input) {
      clientMutationId
      error {
        code
        message
      }
      channel {
        etag
        name
        wAppId
        wChannelId
        agents {
          _id
          wUserId
          etag
          email
          name
          icon
          role
          user {
            wUserId
            email {
              email
              verified
            }
            profilePic
            firstName
            lastName
            profilePic
            role
            initialRole
            channels {
              wChannelId
              integrationIcon
              integrationName
              name
            }
          }
        }
      }
    }
  }
`

export const AddUserToChannelMutation = graphql`
  mutation SettingsModalQuery_addUserToChannelMutation (
    $input: AddUserToChannelInput!
  ) {
    addUserToChannel(input: $input) {
      clientMutationId
      channel {
        etag
        name
        wAppId
        wChannelId
        agents {
          wUserId
          etag
          email
          name
          icon
          role
          user {
            wUserId
            email {
              email
              verified
            }
            profilePic
            firstName
            lastName
            profilePic
            role
            initialRole
            channels {
              wChannelId
              integrationIcon
              integrationName
              name
            }
          }
        }
      }
    }
  }
`

export const UpdateUserMutation = graphql`
  mutation SettingsModalQuery_updateUserMutation (
    $input: UpdateUserInput!
  ) {
    updateUser(input: $input) {
      clientMutationId
      error {
        code
        message
      }
      user {
        wUserId
        connected
        email {
          email
          verified
        }
        profilePic
        firstName
        lastName
        profilePic
        role
        initialRole
        channels {
          wChannelId
          integrationIcon
          integrationName
          name
        }
      }
    }
  }
`


export const ConnectChannelMutation = graphql`
  mutation SettingsModalQuery_connectChannelMutation (
    $input: ConnectChannelInput!
  ) {
    connectChannel(input: $input) {
      clientMutationId
      error {
        code
        message
      }
      channel {
        wChannelId
        integrationIcon
        integrationName
        name
        connected
        etag
      }
    }
  }
`

export const DisconnectChannelMutation = graphql`
  mutation SettingsModalQuery_disconnectChannelMutation (
    $input: DisconnectChannelInput!
  ) {
    disconnectChannel(input: $input) {
      clientMutationId
      error {
        code
        message
      }
      channel {
        wChannelId
        integrationIcon
        integrationName
        name
        connected
        etag
      }
    }
  }
`

export const ActivateUserMutation = graphql`
  mutation SettingsModalQuery_activateUserMutation (
    $input: ActivateUserInput!
  ) {
    activateUser(input: $input) {
      clientMutationId
      error {
        code
        message
      }
      user {
        wUserId
        connected
        email {
          email
          verified
        }
        profilePic
        firstName
        lastName
        profilePic
        role
        initialRole
        channels {
          wChannelId
          integrationIcon
          integrationName
          name
        }
      }
    }
  }
`

