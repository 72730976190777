import React, { useState } from "react"
import styled, { css } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ImageExternalLinkWrapper = styled.a`
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: auto;
  transition: all 0.2s linear;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  ${(props) => {
    if (props?.$displayLinkOutToLeft) {
      return css`
        left: ${props.$xOffset};
      `
    }
    return css`
      right: ${props.$xOffset};
    `
  }} 
  .button {
    background: #F1F0F0;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    color: #888;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    opacity: ${props => (props.$hover ? "1" : "0")};
    display: ${props => (props.$hover ? "flex" : "none")};
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
`

const ExternalLinkOutWrapper = ({
  children,
  url,
  xOffset = "-80px",
  displayLinkOutToLeft = true
}) => {
  const [hover, setHover] = useState(false)
  if (!url) {
    console.error("The ExternalLinkOutWrapper component is missing a URL")
  }
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        position: "relative",
      }}
    >
      <ImageExternalLinkWrapper
        $hover={hover}
        $xOffset={xOffset}
        $displayLinkOutToLeft={displayLinkOutToLeft}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          opacity: hover ? "1" : "0",
          pointerEvents: hover ? "all" : "none",
        }}
        onMouseEnter={() => setHover(true)}
      >
        <div className="button">
          <FontAwesomeIcon
            icon="external-link"
            color="#888"
            className="icon"
            size="sm"
          />
        </div>
      </ImageExternalLinkWrapper>
      {children}
    </div>
  )
}

export default ExternalLinkOutWrapper
