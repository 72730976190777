import React from "react"
import { useTranslation } from "react-i18next"
import { MessageBubble, WhatsAppFlowPayloadTitle } from "../util"
import _ from "lodash"
import { isJSONString } from "../../util"

const Payload = ({ data, color, isAgent }) => {
  const { t } = useTranslation("common")
  let text
  if (data?.title && data?.description) {
    text = data?.title + "\n" + data?.description
  } else if (data.title) {
    text = data.title
  } else if (data.text) {
    text = data.text
  } else if (data.payload) {
    if (_.isString(data.payload)) {
      text = data.payload
    } else {
      text = JSON.stringify(data.payload, null, 2)
    }
    if (data?.payload?.type === "nfm_reply" || data?.payload?.nfm_reply?.name === "flow") {
      if (isJSONString(data?.payload?.nfm_reply?.response_json)) {
        text = JSON.stringify(JSON.parse(data.payload.nfm_reply.response_json), null, 2) 
      }
    }
  }
  return (
    <>
      {(data?.payload?.type === "nfm_reply" || data?.payload?.nfm_reply?.name === "flow") && <WhatsAppFlowPayloadTitle>{t?.("message_types.payload.whatsapp_flow_payload")}:</WhatsAppFlowPayloadTitle> }
      <MessageBubble isAgent={isAgent} color={color} className="text">{text}</MessageBubble>
    </>
  )
}

export default Payload
