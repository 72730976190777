import React, {
  useCallback, useState,
} from "react"

import { createEditor } from "slate"
import {
  Slate, Editable, withReact,
} from "slate-react"

import {
  VARIABLE_TYPE,
  Variable,
  Leaf,
  deserialize,
  withVariables,
  EMAIL_TYPE,
  Email,
  URL_TYPE,
  Url,
} from "./RichTextEditorHelpers"

export const WhatsappMarkdown = ({
  splitTextByLines,
  styles,
  invertColors = false,
}) => {
  const [editor] = useState(() => withVariables(withReact(createEditor())))
  const text = splitTextByLines.join("\n")
  const value = deserialize(text)

  const renderElement = useCallback((props) => {
    const { element } = props

    if (element.type === VARIABLE_TYPE) {
      return (
        <Variable {...props} invertColors={invertColors} />
      )
    }
    if (element.type === URL_TYPE) {
      return (
        <Url {...props} />
      )
    }
    if (element.type === EMAIL_TYPE) {
      return (
        <Email {...props} />
      )
    }
    return (
      <p
        style={{
          margin: 0,
          padding: 0,
        }}
        dir="auto"
        placeholder=" "
        {...props}
        {...props.attributes}
      >
        {props.children}
      </p>
    )
  }, [])

  const renderLeaf = useCallback((p) => <Leaf {...p} />, [])

  return (
    <Slate
      editor={editor}
      initialValue={value}
    >
      <Editable
        style={{
          width: "100%",
          border: "none",
          outline: "none",
          minHeight: "32px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          fontSize: "0.7rem",
          padding: 0,
          ...(styles ?? {}),
        }}
        editor={editor}
        contentEditable={false}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        readOnly
      />
    </Slate>
  )
}
