import React from "react"
import { Button } from "../util"

const PhoneButton = ({ data, isListButton }) =>
  (
    <Button
      isListButton={isListButton}
    >
      <a className="call" href={`tel:${data.payload}`}>
        <div className="title">{data.title}</div>
      </a>
    </Button>
  )

export default PhoneButton
