import React from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./Animation.scss"

const Animation = ({ data }) => {
  const { t } = useTranslation("common")
  const url = data.url || data.downloadUrl || _.get(data, "attachments[0].url")
  const { text } = data
  if (url) {
    return (
      <div className="message-bubble animation">
        <img src={url ? url : ""} alt="" />
        <div className="caption">{text}</div>
      </div>
    )
  }
  return (
    <div className="message-bubble animation placeholder">
      <FontAwesomeIcon
        className="icon"
        icon="image"
      />
      <div>{t?.("message_types.animation.animation_cannot_be_loaded")}</div>
      <div className="caption">{text}</div>
    </div>
  )
}

export default Animation
