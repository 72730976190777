import styled from "@emotion/styled"

export const MemberEditModalContainer = styled.div`
  width: 50%;
  min-width: 520px;
  height: 80%;
  box-shadow: ${(props) => props.theme.modalBoxShadow};
  border-radius: ${(props) => props.theme.modalBorderRadius};
  overflow: hidden;
  background: white;

  .header {
    background-color: #3046ac;
  }

  .basic-info-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: ${(props) => props.mobile ? "0" : "42px"};
    justify-content: ${props => props.mobile ? "space-between" : ""};
    margin: 12px 0;
  }

  .modal-title {
    color: #fff;
    font-size: ${(props) => props.theme.textMd};
    font-weight: bold;
  }

   >.separator {
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.gray1};
  }
  @media (max-width:989px) {
    width: 100% !important;
    min-width: auto !important;
    height: 100% !important;
  }
`

export const MemberInfoContainer = styled.div`
  height: calc(100% - 87.5px - 66px);
  overflow-y: scroll ;
  padding: 16px 32px;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  .member-info-text-field {
    flex-grow: 1;

    &.last-name, &.profile-pic, &.gender, &.company {
      margin-left: 8px;
    }
  }

  .field-label {
    font-size: ${(props) => props.theme.textSm};
    color: ${(props) => props.theme.gray9};
    padding-bottom: 2px;
  }

  .dob-error {
    font-size: 0.7rem;
    color: ${(props) => props.theme.error};
  }
`

export const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  >.error {
    font-size: ${(props) => props.theme.textSm};
    color: ${(props) => props.theme.error};
    padding-right: 16px;
  }

  @media (max-width:989px) {
    justify-content: space-between;
    button.box-button-container {
      min-width: 50% !important;
    }
  }
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2px;

  >.field-label {
    font-size: ${(props) => props.theme.textSm};
    color: ${(props) => props.theme.gray9};
  }

  
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;

  >.field-label {
    font-size: ${(props) => props.theme.textSm};
    color: ${(props) => props.theme.gray9};
    display: flex;
    align-items: center;
  }

  .info {
    display: flex;
    min-height: 20px;
    align-items: center;
    margin-top: 4px;
    gap: 4px;
    font-size: ${(props) => props.theme.textSm};
    color: ${(props) => props.theme.gray6};
  }
`

export const FieldRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  &:last-child {
    padding-bottom: 0px;
  }

  .alias-field {
    width: 120px;
    margin-right: 8px;
  }

  .general-text-field {
    flex-grow: 1;
    margin-right: 8px;
  }
`

export const AddressField = styled.div`
  padding-bottom: 32px;

  .alias-field {
    width: 160px;
    margin-bottom: 4px;
  }
  .address-text-field {
    flex-grow: 1;
    margin-bottom: 4px;

    &.left-field {
      margin-right: 8px;
    }
  }
`

export const MobileUIModal = styled.div`
  display: none;
  @media (max-width:989px) {
    display: block;
  }
  input[type="date"] {
    padding: 10px 15px;
    font-size: 15px;
    font-family: monospace;
    border: 1px solid #d0d0d0;
    border-radius: 0.25rem;
    cursor: pointer;
    width: 100%;
    color: #5b5b5b;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em; 
  }
  input[type="date"]:focus {
    outline:none;
    border-color:#3acfff;
    box-shadow:0 0 0 0.25rem rgba(0, 120, 250, 0.1);
  }
  
  ::-webkit-datetime-edit {}
  ::-webkit-datetime-edit-fields-wrapper {}
  ::-webkit-datetime-edit-month-field:hover,
  ::-webkit-datetime-edit-day-field:hover,
  ::-webkit-datetime-edit-year-field:hover {
    background:rgba(0, 120, 250, 0.1);
  }
  ::-webkit-datetime-edit-text {
    opacity:0;
  }
  ::-webkit-clear-button,
  ::-webkit-inner-spin-button {
    display:none;
  }
  ::-webkit-calendar-picker-indicator {
    position:absolute;
    width:2.5rem;
    height:100%;
    top:0;
    right:0;
    bottom:0;
    
    opacity:0;
    cursor:pointer;
    color:rgba(0, 120, 250, 1);
    background:rgba(0, 120, 250, 1);
  }
  
  input[type="date"]:hover::-webkit-calendar-picker-indicator { opacity:0.05; }
  input[type="date"]:hover::-webkit-calendar-picker-indicator:hover { opacity:0.15; }
`
export const DesktopUIModal = styled.div`
  display: block;
  @media (max-width:989px) {
    display: none;
  }
`