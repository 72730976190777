import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const InputRangeWrapper = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  color: #a5a5a6;

  input[type="range"] {
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    background: #ccc;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    cursor: pointer;
  }

  /* Input range - firefox */
  input[type="range"]::-moz-range-track {
    position: relative;
    background: #ccc;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    cursor: pointer;
  }

  /* played progress length - Chrome & safari*/
  input[type="range"]::before {
    content: "";
    height: 2px;
    background: #3c3c3c;
    width: ${(props) => Math.min(props.$percentComplete, 100)}%;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
  }

  /* played progress length - firefox */
  input[type="range"]::-moz-range-progress {
    background: #3c3c3c;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    height: 2px;
  }

  /* slider thumb - chrome and safari */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: none;
    background-color: #3c3c3c;
    cursor: pointer;
    position: relative;
  }

  /* dragging thumb - chrome and safari */
  input[type="range"]:active::-webkit-slider-thumb {
    transform: scale(1.2);
  }

  /* slider thumb - firefox */
  input[type="range"]::-moz-range-thumb {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #3c3c3c;
    cursor: pointer;
    border: transparent;
    position: relative;
  }
  /* dragging thumb - firefox */
  input[type="range"]:active::-moz-range-thumb {
    transform: scale(1.2);
  }
`

export const MaxWidthContainer = styled.div`
  @media (max-width: 375px) {
    width: 150px;
  }
`

export const AudioPlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 500px) {
    gap: 4px;
  }
`

export const ShowVolumeControlsWrapper = styled.div`
  padding: 4px;
  position: relative;
`;

export const ShowVolumeControlsIcon = styled(FontAwesomeIcon)`
  color: #a5a5a6;
`;

export const ShowVolumeControlsIconWrapper = styled.div`
  padding: 4px;
  border-radius: 50%;
  background: ${props => props.$showVolumeControls ? props.theme.gray1 : "transparent"};
`

export const VolumeSliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 105px;
  left: -70px;
  width: 175px;
  transform: rotate(270deg);
  background: ${props => props.theme.gray1};
  padding: 16px;
  border-radius: 16px;
  z-index: 100;
`
export const VolumeAndCancelButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

