import React, { useEffect, useState, useTransition, useContext } from "react"
import { Flex, Box } from "theme-ui"
import { useTranslation } from "react-i18next"
import {
  faPlus
} from "@fortawesome/free-solid-svg-icons"
import {
  useRefetchableFragment,
  useMutation
} from "react-relay"
import graphql from "babel-plugin-relay/macro"

import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import PopperContainer from "../Radiate/PopperContainer/PopperContainer"
import ChannelPicker from "../ChannelPicker/ChannelPicker"
import UserPicker from "../UserPicker/UserPicker"
import AgentCard from "./AgentCard"
import Tag from "../Tag/Tag"

import { LoadingMask } from "../../CommonStyles"
import * as  Styles from "./SettingsModalStyles"
import theme from "../../stylesheets/theme"

import {
  AddUserToChannelMutation
} from "./SettingsModalQuery"
import ReactTooltip from "../ReactTooltip/ReactTooltip"
import { UserContext } from "../../contexts/UserContext"

const SettingsAgentsContainer = ({
  ownUserId,
  isAdmin,
  isInitialAdmin,
  isInitialSuperAdmin,
  ...props
}) => {
  const { isSuperAdmin, isSuperAuditor } = useContext(UserContext)
  const [
    data,
    refetch
  ] = useRefetchableFragment(
    graphql`
      fragment SettingsAgentsContainer_userViewer on UserScope
      @argumentDefinitions(
        wChannelId: { type: "ID!" }
        hasWChannelId: { type: "Boolean!" }
      )
      @refetchable(queryName: "SettingsAgentsContainerRefetchQuery") {
        channelRole(
          wChannelId: $wChannelId
        ) @include(if: $hasWChannelId)
        channel (
          wChannelId: $wChannelId
        ) @include(if: $hasWChannelId) {
          etag
          name
          wAppId
          wChannelId
          users {
            wUserId
            email {
              email
              verified
            }
            firstName
            lastName
            profilePic
            role
          }
          agents {
            _id
            wUserId
            etag
            email
            name
            icon
            role
            user {
              wUserId
              email {
                email
                verified
              }
              firstName
              lastName
              profilePic
              role
              initialRole
            }
          }
        }
      }
    `,
    props.data
  )
  const [loading, startTransition] = useTransition()
  const [waitingForDefaultChannel, setWaitingForDefaultChannel] = useState(true)
  const [selectedChannelId, setSelectedChannelId] = useState("")
  const [error, setError] = useState("")
  const selectedChannel = data?.channel
  const currentAgents = (selectedChannel?.agents || []).filter(a => a?.user?.email)
  const [agents, setAgents] = useState(currentAgents || [])
  const [selectedUser, setSelectedUser] = useState(null)
  const [showCreateAgentDropDown, setShowCreateAgentDropDown] = useState(false)
  const [addUserToChannel, isInFlightAddUserToChannel] = useMutation(
    AddUserToChannelMutation
  )
  const isManager = data?.channelRole === "MANAGER"

  const { t } = useTranslation(["settings", "common"])

  let allSelectedUsers = []
  selectedChannel?.agents.forEach((agent) => {
    allSelectedUsers = [...allSelectedUsers, agent?.user?.email?.email]
  })

  const refetchData = () => {
    startTransition(() => {
      refetch({
        wChannelId: selectedChannelId,
        hasWChannelId: !!selectedChannelId,
      }, {
        fetchPolicy: "network-only",
      })
    })
  }

  useEffect(() => {
    if (selectedChannelId) {
      refetchData()
    }
  }, [selectedChannelId])

  useEffect(() => {
    if (selectedChannel) {
      const currentAgents = (selectedChannel?.agents || []).filter(a => a?.user?.email) 
      setAgents(currentAgents || [])
    }
  }, [JSON.stringify(selectedChannel)])

  const addUserToChannelFunction = ({
    wChannelId,
    wUserId
  }) => {
    let clientMutationId = 0
    const mutationParams = {
      variables: {
        input: {
          wChannelId,
          wUserId,
          clientMutationId: clientMutationId++,
        },
      },
      onCompleted: (response, errs) => {
        if (!response.addUserToChannel?.error) {
          setShowCreateAgentDropDown(false)
          setSelectedUser(null)
        } else {
          setError(response.addUserToChannel?.error?.messages)
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        setError(errorMsg)
      }
    }
    addUserToChannel(mutationParams)
  }
  const renderContent = () => {
    if (loading || waitingForDefaultChannel) {
      return (
        <div style={{ height: "calc(100% - 62px)", width: "100%", background: "#ffffff" }}>
          <LoadingMask style={{ height: "100%", width: "100%", background: "#ffffff" }}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </LoadingMask>
        </div>
      )
    } else if (selectedChannelId) {
      return (
        <Styles.AgentsContainer>
          <Flex pb={2} sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <div className="agent-title-container">
              <div className="title">{t?.("agent_settings.agents")}</div>
              {error && (
                <div className="error">{error}</div>
              )}
            </div>
            {(isAdmin || isInitialSuperAdmin) &&
              <PopperContainer
                show={showCreateAgentDropDown}
                modifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [0, 8],
                    },
                  },
                ]}
                display={(
                  <NewBoxButton
                    primary
                    disabled={isSuperAdmin || isSuperAuditor}
                    icon={faPlus}
                    text={t?.("agent_settings.add_agent_button")}
                    onClick={() => {
                      if (!showCreateAgentDropDown) {
                        setShowCreateAgentDropDown(true)
                      } else {
                        setShowCreateAgentDropDown(false)
                      }
                    }}
                  />
                )}
                placement="bottom-end"
                onClickOutside={() => {
                  if (showCreateAgentDropDown) {
                    setShowCreateAgentDropDown(false)
                  }
                }}
              >
                {() => (
                  <Styles.CreateAgentContainer>
                    <div className="label">{t?.("agent_settings.assign_woztell_user_as_agent")}</div>
                    <div className="separator"></div>
                    <Box p={3}>
                      <UserPicker
                        onlyActivated={true}
                        userId={selectedUser}
                        disabledUserEmails={allSelectedUsers}
                        onChange={(value, object) => {
                          setSelectedUser(value)
                        }}
                      />
                      <NewBoxButton
                        style={{ marginTop: "8px", width: "100%" }}
                        text={t?.("agent_settings.assign")}
                        primary
                        disabled={!selectedUser}
                        loading={isInFlightAddUserToChannel}
                        onClick={() => {
                          if (selectedUser) {
                            addUserToChannelFunction({
                              wChannelId: selectedChannelId,
                              wUserId: selectedUser
                            })
                          }
                        }}
                      />
                    </Box>
                  </Styles.CreateAgentContainer>
                )}
              </PopperContainer>
            }
          </Flex>
          <Styles.GridAgentsContainer>
            {(agents || []).map((agent, index) => {
              return (
                <AgentCard
                  key={agent?._id || index}
                  agent={agent}
                  wChannelId={selectedChannelId}
                  isAdmin={isAdmin}
                  isManager={isManager}
                  isInitialAdmin={isInitialAdmin}
                  isInitialSuperAdmin={isInitialSuperAdmin}
                  ownUserId={ownUserId}
                  onRefetchData={() => {
                    refetchData()
                  }}
                  onError={(errMsg) => {
                    setError(errMsg)
                  }}
                />
              )
            })}
          </Styles.GridAgentsContainer>
        </Styles.AgentsContainer>
      )
    }
    return null
  }

  return (
    <Styles.SettingsProfileAndAgentContainer>
      <div className="description">{t?.("agent_settings.choose_channel_to_list_agent_info")}</div>
      <ChannelPicker
        isDisabled={isSuperAdmin || isSuperAuditor}
        selectedChannelId={selectedChannelId}
        onReceiveFirstChannel={(channel) => {
          setSelectedChannelId(channel.wChannelId)
          setTimeout(() => {
            setWaitingForDefaultChannel(false)
          }, 100)
        }}
        onChange={(channelId) => {
          setSelectedChannelId(channelId)
        }}
      />
      {renderContent()}
      <ReactTooltip
        id="settings-agent-email-tooltip"
        place="top"
        noArrow
        offset={-8}
        render={({ content, activeAnchor }) => {
          const user = JSON.parse(content)
          const role = activeAnchor?.getAttribute("data-user-role")
          return (
            <div>
              {t?.("agent_settings.link_to_woztell_account")}: {user?.email?.email}
              <Tag
                backgroundColor={role === "ADMIN" ? theme.success : "#6544f4"}
                color="#ffffff"
                style={{ marginLeft: "8px" }}
              >
                {role}
              </Tag>
            </div>
          )
        }}
      />
      <ReactTooltip
        id="settings-agent-role-tooltip"
        place="top"
        noArrow
        offset={2}
        // style={{ backgroundColor: "#707070" }}
      />
    </Styles.SettingsProfileAndAgentContainer>
  )
}

export default SettingsAgentsContainer
