import React from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import Audio from "../Audio/Audio"
import Video from "../Video/Video"
import Image from "../Image/Image"
import File from "../File/File"
import Text from "../Text/Text"
import Contacts from "../Contacts/Contacts"
import Location from "../Location/Location"

import "./Misc.scss"

const Misc = ({ data, isAgent = false, color }) => {
  const { t } = useTranslation("common")
  const attachments = _.get(data, "attachments", []) || []
  const messageCouldNotBeDisplayed = t?.("message_types.misc.message_could_not_be_displayed")
  return attachments?.map((attachment, i) => {
    if (attachment) {
      switch (attachment.type) {
        case "IMAGE": return (<Image key={i} data={attachment} isAgent={isAgent} color={color} />)
        case "TEAMWORK_VOICE":
        case "AUDIO":
          return (<Audio key={i} data={attachment} isAgent={isAgent} color={color} />)
        case "VIDEO": return (<Video key={i} data={attachment} isAgent={isAgent} color={color} />)
        case "FILE":
          return (<File key={i} data={attachment} isAgent={isAgent} color={color} />)
        case "LOCATION":
          return (<Location key={i} data={attachment} isAgent={isAgent} color={color} />)
        case "CONTACTS":
          return (<Contacts key={i} data={attachment} isAgent={isAgent} color={color} />)
        default:
          return <Text data={{ text: messageCouldNotBeDisplayed }} isAgent={isAgent} color={color} />
      }
    } else {
      return <Text data={{ text: messageCouldNotBeDisplayed }} isAgent={isAgent} color={color} />
    }
  })
}

export default Misc
