import React from "react"
import { useTranslation } from "react-i18next"
import { Button } from "../util"

const ShareButton = ({ data, isListButton }) => {
  const { t } = useTranslation("common")
  return (
    <Button
      isListButton={isListButton}
    >
      <div className="title">{t?.("message_types.share_button.share")}</div>
    </Button>
  )
}

export default ShareButton
