import React, { useEffect, useState, useRef } from "react"
import { Manager, Reference, Popper } from "react-popper"
import styled from "@emotion/styled"

import NewBoxButton from "../NewBoxButton/NewBoxButton"

const DropDownContainer = styled.div`
  border-radius: 4px;
  background: "white";
  border: 1px solid #e8e7e8;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${(props) => props.noPadding ? "0" : "4px"};

  .drop-down-button {
    justify-content: flex-start;

    &:hover {
      background: #f8f8f8;
    }
  }
`

const DropdownMenu = ({
  className,
  text,
  icon,
  buttons,
  disabled,
  inverted,
  borderless,
  primary,
  danger,
  placement,
  modifiers,
  positionFixed,
  hideOutOfBoundaries = true,
}) => {
  const [ showMenu, setShowMenu ] = useState(false)
  const dropdownContainerRef = useRef(null)
  const dropdownButtonRef = useRef(null)

  useEffect(() => {
    const handleClick = (e) => {
      if (dropdownContainerRef.current && dropdownContainerRef.current.contains(e.target)) {
        return
      }
      if (dropdownButtonRef.current && dropdownButtonRef.current.contains(e.target)) {
        return
      }
      setShowMenu(false)
    }
    document.addEventListener("mousedown", handleClick, false)

    return () => document.removeEventListener("mousedown", handleClick, false)
  }, [])

  return (
    <div className={className}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <span style={{ display: "inline-block" }} ref={ref}>
              <span ref={dropdownButtonRef}>
                <NewBoxButton
                  inverted={inverted}
                  borderless={borderless}
                  primary={primary}
                  danger={danger}
                  disabled={disabled}
                  dropDownIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowMenu(!showMenu)
                  }}
                >
                </NewBoxButton>
              </span>
            </span>
          )}
        </Reference>
        {showMenu ?
          <Popper
            placement={placement}
            modifiers={modifiers}
            positionFixed={positionFixed}
            disabled={disabled}
          >
            {({
              ref, 
              style, 
              outOfBoundaries, 
            }) => {
              if (outOfBoundaries && hideOutOfBoundaries) {
                return null
              }
              return (
                <div
                  className="popper-dropdown"
                  ref={ref}
                  style={{ 
                    ...style, 
                    zIndex: 8999,
                    top: "4px",
                    background: "#ffffff"
                  }}
                >
                  <DropDownContainer
                    ref={dropdownContainerRef}
                    className="dropdown-container"
                    noPadding={buttons.length <= 1}
                  >
                    
                    {buttons.map((o, i) => {
                      return (
                        <NewBoxButton
                          className="drop-down-button"
                          notAuth={o.notAuth}
                          notAuthPlacement={o.notAuthPlacement}
                          key={`${i}`}
                          borderless
                          text={o.label}
                          icon={o.icon}
                          primary={o.primary}
                          danger={o.danger}
                          disabled={o.disabled}
                          loading={o.loading}
                          onClick={(e) => {
                            e.stopPropagation()
                            o.onClick(e)
                          }}
                        />
                      )
                    })}
                  </DropDownContainer>
                </div>
              )
            }}
          </Popper>
          :
          null
        }
      </Manager>
    </div>
  )
}

export default DropdownMenu