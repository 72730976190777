import React from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "@emotion/styled"
import {
  faMicrophone
} from "@fortawesome/free-solid-svg-icons"
import { MOBILE_MESSAGE_MAX_WIDTH, MOBILE_BREAKPOINT } from "../../../const"

const AudioBubble = styled.div`
  background-color: #f1f3f4;
  border-radius: 8px;
  padding: 0;
  max-width: 100%;

  audio {
    vertical-align: bottom;
     &:focus {
      outline: none;
    }
    @media (max-width: ${MOBILE_BREAKPOINT}) {
      width: ${MOBILE_MESSAGE_MAX_WIDTH};
    }
  }
`

const AudioBubblePlaceholder = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 160px;
  height: 160px;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.isAgent ? props.theme.themeColorGreen : "#FFFFFF"};

  .icon {
    font-size: 50px;
    color: ${(props) => props.isAgent ? "#ffffff" : props.theme.gray4};
    margin-bottom: 8px;
  }

  span {
    font-size: ${(props) => props.theme.textSm};
    text-align: center;
    color: ${(props) => props.isAgent ? "#ffffff" : props.theme.gray7};
  }
`

const Audio = ({ data, isAgent }) => {
  const { t } = useTranslation("common")
  const url = data.url || data.downloadUrl || _.get(data, "attachments[0].url")
  if (url) {
    return (
      <AudioBubble isAgent={isAgent}>
        <audio controls>
          <source src={url} />
        </audio>
      </AudioBubble>
    )
  }
  return (
    <AudioBubblePlaceholder isAgent={isAgent}>
      <FontAwesomeIcon
        className="icon"
        icon={faMicrophone}
      />
      <span>{t?.("message_types.audio.audio_cannot_be_loaded")}</span>
    </AudioBubblePlaceholder>
  )
}

export default Audio
