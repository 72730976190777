import styled from "@emotion/styled"

export const LoadingMask = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  pointer-events: all;
  transition: all 0.3s linear;

  .line {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 6px;
    box-sizing: border-box;
    border-radius: 15px;
    background-color: #e6e6e6;
    z-index: 99;
  }

  .line:nth-of-type(1) {animation: loadingC .6s .1s linear infinite;}
  .line:nth-of-type(2) {animation: loadingC .6s .2s linear infinite;}
  .line:nth-of-type(3) {animation: loadingC .6s .3s linear infinite;}
  @keyframes loadingC {
    0 {transform: translate(0,0);}
    50% {transform: translate(0,15px);}
    100% {transform: translate(0,0);}
  }
`

export const generalStyles = {
  menu: styles => ({
    ...styles,
    width: "100%",
    color: "#626262",
    zIndex: 999,
    fontSize: "0.9rem"
  }),
  option: (styles, state) => ({
    ...styles,
    whitespace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    ":hover": {
      ...styles[":hover"],
      overflow: "visible"
    }
  }),
  singleValue: styles => ({
    ...styles,
    color: "#626262",
    fontSize: "0.9rem"
  }),
  valueContainer: styles => ({
    ...styles,
    color: "#626262",
    fontSize: "0.9rem"
  })
}