import styled from "@emotion/styled"
import { setLightness } from "polished"

export const SegmentedSelectContainer = styled.div`
  background: white;
  display: inline-flex;
  height: 30px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
  align-items: center;
  border-color: ${(props) => props.disabled && "rgba(0, 0, 0, 0.05)"};
`

export const Option = styled.div`
  padding: 0 16px;
  font-size: 0.8rem;
  line-height: 30px;
  background: ${(props) => {
    if (props.selected && props.disabled) {
      return props.theme.palerBackground
    } if (props.selected && props.readOnly) {
      return setLightness(0.7, props.theme.themeColor)
    } else if (props.selected) {
      return props.theme.themeColor
    } else if (props.readOnly) {
      return props.theme.gray05
    }
    return "white"
  }};
  border-right: ${(props) => {
    if (props.disabled || props.readOnly) {
      return "1px solid rgba(0, 0, 0, 0.05)"
    }
    return "1px solid rgba(0, 0, 0, 0.15)"
  }};
    cursor: ${(props) => {
    if (props.disabled || props.readOnly) {
      return "auto"
    }
    return "pointer"
  }};
    color: ${(props) => {
    if (props.disabled && props.selected) {
      return props.theme.placeholderText
    } if (props.selected) {
      return "#ffffff"
    } else if (props.disabled) {
      return "rgba(0, 0, 0, 0.15)"
    } else if (props.readOnly) {
      return props.theme.gray5
    }
    return props.theme.gray9
  }};
    pointer-events: ${(props) => (props.disabled || props.readOnly) && "none"};
    
    
    &:last-child {
      border-right: none;
    }
`