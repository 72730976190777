import React from "react"
import { Button } from "../util"

const PostBackButton = ({ data, isListButton }) =>
  (
    <Button
      isListButton={isListButton}
    >
      <div className="title">{data.title}</div>
    </Button>
  )

export default PostBackButton
