/** @jsxImportSource theme-ui */

import React, { useContext, useEffect, useState, useRef } from "react"
import _ from "lodash"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { ThemeProvider } from "theme-ui"
import { CookiesProvider } from "react-cookie"
import {
  RelayEnvironmentProvider,
} from "react-relay"
import { RouteRenderer, createBrowserRouter, RouterProvider } from "yarr"
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"

import { RelayEnvironment } from "./RelayEnvironment"
import Layout from "./pages/Layout/Layout"
import WoztellContextProvider, { WoztellContext } from "./contexts/WoztellContextProvider"
import { LanguageProvider } from "./contexts/LanguageContext"
import UserContextProvider from "./contexts/UserContext"
import { routes } from "./routes"

import "./App.css"
import theme from "./stylesheets/theme"
import { isInStandaloneMode } from "./pages/Inbox/Inbox"

library.add(fas)

const ROUTER_BASE = process.env.REACT_APP_ROUTER_BASE || "/"
const DISABLE_FIREBASE = process.env.REACT_APP_DISABLE_FIREBASE !== undefined ? JSON.parse(process.env.REACT_APP_DISABLE_FIREBASE) : false
const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID
const FIREBASE_MSG_SENDER_ID = process.env.REACT_APP_FIREBASE_MSG_SENDER_ID
const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: `${FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: `${FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: FIREBASE_MSG_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID
}


if (!DISABLE_FIREBASE) {
  try {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig)
    // eslint-disable-next-line no-unused-vars
    getAnalytics(app)
  } catch (error) {
    console.log("firebase app error", error)
  }
}

// const router = createBrowserRouter({
//   routes,
//   awaitComponent: true,
// }, {
//   basename: ROUTER_BASE.slice(1)
// })

function InnerApp({ search }) {
  const { payload, signedContext, loading } = useContext(WoztellContext)
  
  const [environment, setEnvironment] = useState(null)
  const [router, setRouter] = useState(null)
  const isEmbedded = !isInStandaloneMode()
  // console.log("is embedded 2: ", isEmbedded)

  useEffect(() => {
    if (search && search.get("embed") === "woztell") {
      if (!loading) {
        if (!_.isEmpty(payload) && signedContext) {
          setEnvironment(RelayEnvironment({ payload, signedContext }))
        }
      }
    } else {
      setEnvironment(RelayEnvironment())
    }
  }, [search?.get("embed"), loading, JSON.stringify(payload), signedContext])

  useEffect(() => {
    if (environment) {
      setRouter(createBrowserRouter({
        routes: routes(environment),
        awaitComponent: true,
      }, {
        basename: ROUTER_BASE.slice(1)
      }))
    }
  }, [environment])

  if (environment && router) {
    return (
      <LanguageProvider isEmbedded={isEmbedded}>
          <RelayEnvironmentProvider environment={environment}>
            <UserContextProvider environment={environment}>
              <RouterProvider router={router}>
                <RouteRenderer
                  // pendingIndicator={<p>...pending loading </p>}
                  routeWrapper={({ Route }) => (
                    <Layout>
                      {Route}
                    </Layout>
                  )}
                />
              </RouterProvider>
            </UserContextProvider>
          </RelayEnvironmentProvider>
      </LanguageProvider>
    )
  }
}

function App() {
  const search = useRef(new URL(document.location).searchParams)

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <WoztellContextProvider search={search?.current}>
          <InnerApp search={search?.current} />
        </WoztellContextProvider>
      </ThemeProvider>
    </CookiesProvider>
  )
}

export default App
