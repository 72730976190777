import graphql from "babel-plugin-relay/macro"

export const CheckSessionMutation = graphql`
  mutation LayoutQuery_checkSessionMutation {
    checkSession {
      expiresAt
      error {
        code
        message
      }
      valid
    }
  }
`
