import styled from "@emotion/styled"

export const IntegrationModalContainer = styled.div`
  width: 72%;
  min-width: 560px;
  height: 80%;
  box-shadow: ${(props) => props.theme.modalBoxShadow};
  border-radius: ${(props) => props.theme.modalBorderRadius};
  overflow: hidden;
  background: white;

  .close-button {
    color: ${(props) => props.theme.gray4};
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.gray5};
    }
  }

  >.separator {
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.gray1};
  }

  iframe {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  @media (max-width:989px) {
    width: 100% !important;
    min-width: auto !important;
    height: 100% !important;
  }
`