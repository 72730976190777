/** @jsxImportSource theme-ui */

import React, { useState } from "react"
import {
  usePaginationFragment,
  useMutation,
} from "react-relay"
import graphql from "babel-plugin-relay/macro"
import { Waypoint } from "react-waypoint"

import { Item, Section } from "../SimpleMobileNavigation/SimpleMobileNavigation"
import * as  Styles from "./SettingsModalStyles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

import {
  SwitchAppMutation
} from "./SettingsModalQuery"

const PAGE_SIZE = 30
const LOAD_THRESHOLD = 8

const SettingsOrganizationPage = ({
  currentWAppId,
  navBack,
  ...props
}) => {
  const {
    data,
    loadNext,
    hasNext,
  } = usePaginationFragment(
    graphql`
      fragment SettingsOrganizationPage_userViewer on UserScope
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 30 }
        after: { type: "String" }
      )
      @refetchable(queryName: "SettingsOrganizationPagePaginateQuery") {
        appsPaginated (
          first: $first, 
          after: $after
        ) @connection(key: "SettingsOrganizationPage_appsPaginated") {
          edges {
            node {
              id
              wAppId
              name
            }
          }
        }
      }
    `,
    props.data
  )

  const [error, setError] = useState("")
  const [switchApp] = useMutation(
    SwitchAppMutation
  )
  const apps = data?.appsPaginated?.edges.map((o) => o.node)

  return (
    <div
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "#F2F2F7",
        py: "16px",
      }}
    >
      {error && (
        <Styles.ErrorContainer>
          <div className="error">{error}</div>
        </Styles.ErrorContainer>
      )}
      <Section>
        {(apps || []).map((app, i) => (
          <>
            <Item
              onClick={() => {
                switchApp({
                  variables: {
                    input: {
                      wAppId: app.wAppId,
                    }
                  },
                  onCompleted: (response, errs) => {
                    if (!response.switchAppForSession?.error) {
                      window.location.reload()
                    } else {
                      setError(response.switchAppForSession?.error?.message)
                    }
                  },
                  onError: (err) => {
                    const errorMsg = err.res?.json?.errors[0]?.message || err
                    setError(errorMsg)
                  }
                })
              }}
            >
              <div>{app?.name}</div>
              {currentWAppId === app?.wAppId ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  sx={{
                    color: "#4274ff",
                  }}
                />
              ) : null}
            </Item>
            {i === apps?.length - LOAD_THRESHOLD && hasNext ?
              <Waypoint
                onEnter={() => {
                  loadNext(PAGE_SIZE)
                }}
              />
              :
              null
            }
          </>
        ))}
      </Section>
    </div>
  )
}

export default SettingsOrganizationPage
