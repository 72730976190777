/**
 * @generated SignedSource<<3c765bfd883bbf7e589b24f4417abc31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "folderId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasSelectedFolder"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wChannelIds"
},
v3 = {
  "kind": "Variable",
  "name": "folderId",
  "variableName": "folderId"
},
v4 = {
  "kind": "Variable",
  "name": "wChannelIds",
  "variableName": "wChannelIds"
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "folderId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FolderPickerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserScope",
        "kind": "LinkedField",
        "name": "userViewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v3/*: any*/),
              {
                "kind": "Variable",
                "name": "hasSelectedFolder",
                "variableName": "hasSelectedFolder"
              },
              (v4/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "FolderPicker_userViewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "FolderPickerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserScope",
        "kind": "LinkedField",
        "name": "userViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "FolderConnection",
            "kind": "LinkedField",
            "name": "foldersPaginated",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FolderEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Folder",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": [
              "wChannelIds"
            ],
            "handle": "connection",
            "key": "FolderPicker_foldersPaginated",
            "kind": "LinkedHandle",
            "name": "foldersPaginated"
          },
          {
            "condition": "hasSelectedFolder",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v3/*: any*/)
                ],
                "concreteType": "Folder",
                "kind": "LinkedField",
                "name": "folder",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0610b7cd1594beff48c368d095d2cc43",
    "id": null,
    "metadata": {},
    "name": "FolderPickerQuery",
    "operationKind": "query",
    "text": "query FolderPickerQuery(\n  $wChannelIds: [ID]\n  $folderId: ID\n  $hasSelectedFolder: Boolean!\n) {\n  userViewer {\n    ...FolderPicker_userViewer_3gislA\n    id\n  }\n}\n\nfragment FolderPicker_userViewer_3gislA on UserScope {\n  foldersPaginated(first: 10, wChannelIds: $wChannelIds) {\n    edges {\n      node {\n        folderId\n        name\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  folder(folderId: $folderId) @include(if: $hasSelectedFolder) {\n    folderId\n    name\n    id\n  }\n  id\n}\n"
  }
};
})();

node.hash = "a50908879c3e90f6488fef7ce363313d";

module.exports = node;
