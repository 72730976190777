import graphql from "babel-plugin-relay/macro"

export const ReadMentionMutation = graphql`
  mutation MentionListQuery_readMentionMutation (
    $input: ReadMentionInput!
  ) {
    readMention(input: $input) {
      ok
      error {
        message
      }
    }
  }
`