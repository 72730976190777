/**
 * @generated SignedSource<<44addd5ce0cf6a55415e35ff0a7c305d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "hasWChannelId",
    "value": false
  },
  {
    "kind": "Literal",
    "name": "wChannelId",
    "value": ""
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isOpenSettingsAgents"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isOpenSettingsChannels"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isOpenSettingsProfile"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isOpenSettingsUsers"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./SettingsModalRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "SettingsModal_userViewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePicUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "initialRole",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "App",
      "kind": "LinkedField",
      "name": "app",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wAppId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "isOpenSettingsAgents",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "SettingsAgentsContainer_userViewer"
        }
      ]
    },
    {
      "condition": "isOpenSettingsUsers",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SettingsUsersContainer_userViewer"
        }
      ]
    },
    {
      "condition": "isOpenSettingsChannels",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SettingsChannelsContainer_userViewer"
        }
      ]
    },
    {
      "condition": "isOpenSettingsProfile",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "SettingsProfileContainer_userViewer"
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsOrganizationPage_userViewer"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "UserScope",
  "abstractKey": null
};
})();

node.hash = "09fa0fc0a98963d8f06bcae82fc335db";

module.exports = node;
