import React, { useEffect, useRef, useState } from "react"
import { usePopper } from "react-popper"
import styled from "@emotion/styled"

const DropDownContainer = styled.div`
  border-radius: 4px;
  background: ${props => (props.darkMode ? props.theme.gray12 : "white")};
  border: 1px solid ${(props) => (props.darkMode ? props.theme.gray10 : props.theme.gray1)};
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`

const PopperContainer = ({
  darkMode,
  className,
  display,
  displayDisplay = "inline-block",
  placement,
  modifiers,
  positionFixed,
  show,
  disabled,
  hideOutOfBoundaries,
  children,
  offset = false,
  onClickOutside,
  addDefaultWrapper = true,
}) => {
  const currentNode = useRef(null)
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  // const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes, forceUpdate, update } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers,
    strategy: positionFixed ? "fixed" : "absolute",
  })

  useEffect(() => {
    if (show && forceUpdate) {
      forceUpdate()
    }
  }, [show, forceUpdate])

  useEffect(() => {
    function handleClick(e) {
      if (currentNode?.current && currentNode.current.contains(e.target)) {
        return
      }

      if (onClickOutside) {
        onClickOutside(e)
      }
    }
    document.addEventListener("mousedown", handleClick, false)
    return () => {
      document.removeEventListener("mousedown", handleClick, false)
    }
  }, [currentNode.current, onClickOutside])

  const renderPopper = ({
    forceUpdate, update
  }) => {
    if (!show) {
      return null
    }
    if (addDefaultWrapper) {
      return (
        <DropDownContainer
          darkMode={darkMode}
          className="dropdown-container"
        >
          {children({ forceUpdate, update })}
        </DropDownContainer>
      )
    }
    return children({ forceUpdate, update })
  }

  return (
    <div className={className} ref={currentNode}>
      <span
        className="popper-display-container"
        style={{ display: displayDisplay }}
        ref={setReferenceElement}
      >
        {display}
      </span>
      <div
        className="popper-dropdown"
        ref={setPopperElement}
        style={{ ...styles.popper, zIndex: 8999, ...offset }}
        {...attributes.popper}
      >
        {renderPopper({ forceUpdate, update })}
      </div>
    </div>
  )
}

export default PopperContainer
