import React, { useState, useEffect } from "react"
import _ from "lodash"
import styled, { css } from "styled-components"
import PhoneButton from "../Buttons/PhoneButton"
import PostBackButton from "../Buttons/PostBackButton"
import ShareButton from "../Buttons/ShareButton"
import UrlButton from "../Buttons/UrlButton"

import "./List.scss"
import { detectEmailAndUrl } from "../util"
const ListItemContent = styled.div`
  width: ${props => props.full ? "100%" : "calc(100% - 76px)"};

  .title {
    font-weight: 500;
    font-size: ${(props) => props.theme.textSm};
    white-space: pre-wrap;
  }

  .subtitle {
    color: #777;
    font-size: ${(props) => props.theme.textSm};
    white-space: pre-wrap;
  }

  .link {
    font-size: ${(props) => props.theme.textSm};
    color: #999;
  }

  ${props => props.isCover && css`
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 12px 16px;
    width: 100%;
    z-index: 10;
    pointer-events: none;

    .title {
      margin-bottom: 0;
      white-space: pre-wrap;
    }
  `}
`

function listButton({ button, i, isListButton}) {
  switch (button.type) {
    case "web_url":
      return (
        <UrlButton
          key={i}
          isListButton={isListButton}
          data={button}
        />
      )
    case "postback":
      return (
        <PostBackButton
          key={i}
          isListButton={isListButton}
          data={button}
        />
      )
    case "phone_number":
      return (
        <PhoneButton
          key={i}
          isListButton={isListButton}
          data={button}
        />
      )
    case "element_share":
      return (
        <ShareButton
          key={i}
          isListButton={isListButton}
          data={button}
        />
      )
    default:
      return (
        <PostBackButton
          key={i}
          isListButton={isListButton}
          data={button}
        />
      )
  }
}

const List = ({ data, onAction, color }) => {
  const [ listData, setListData ] = useState([])

  useEffect(() => {
  
    const array = (_.get(data, "list") || []).map((item, i) => {
      let listObj = item
      return { ...listObj, modTitle: detectEmailAndUrl(item.title) }
    })
    const listData = { ...data, modList: array }
    setListData(listData)
  }, [data])

  return (
    <div className="list-template">
      <div className="list-container">
        {_.get(listData, "modList", []).map((listItem, i) => {
          let isCover = false
          if (listData.top_element_style === "large" && i === 0) {
            isCover = true
          }
          if (isCover) {
            if (_.get(listItem, "default_action.url.length") > 0) {
              return (
                <a
                  key={i}
                  className="list-item-container cover"
                  href={listItem.default_action.url}
                  target="_blank"
                  style={{
                    backgroundImage: `url("${listItem.image_url}")`
                  }}
                  rel="noopener noreferrer"
                >
                  <ListItemContent
                    isCover
                    full={!_.get(listItem, "image_url.length")}
                  >
                    <div className="title">{listItem.modTitle}</div>
                    <div className="subtitle">{listItem.subtitle}</div>
                    <div className="link">{listItem.default_action.url}</div>
                  </ListItemContent>
                  <div className="overlay"></div>
                </a>
              )
            }
            return (
              <div
                key={i}
                className="list-item-container cover"
                style={{
                  backgroundImage: `url("${listItem.image_url}")`
                }}
              >
                <ListItemContent full={!_.get(listItem, "image_url.length")}>
                  <div className="title">{listItem.modTitle}</div>
                  <div className="subtitle">{listItem.subtitle}</div>
                </ListItemContent>
                <div className="overlay"></div>
              </div>
            )
          }
          return (
            <div
              key={i}
              className="list-item-container"
            >
              <ListItemContent full={!_.get(listItem, "image_url.length")}>
                <div className="title">{listItem.modTitle}</div>
                <div className="subtitle">{listItem.subtitle}</div>
                {_.get(listItem, "default_action.url.length") > 0 ?
                  <div className="link">{listItem.default_action.url}</div>
                  :
                  null
                }
                {_.get(listItem, "buttons.length") ?
                  _.get(listItem, "buttons", []).map((button, j) => {
                    return listButton({ button, i: j, isListButton: true })
                  })
                : null}
              </ListItemContent>
              {_.get(listItem, "image_url.length", 0) > 0 ?
                <div className="list-item-img">
                  {_.get(listItem, "default_action.url.length") > 0 ?
                    (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={listItem.default_action.url}
                      >
                        <img src={listItem.image_url} alt="" />
                      </a>
                    ) : <img src={listItem.image_url} alt="" />
                  }
                </div>
                :
                null
              }
            </div>
          )
        })}
      </div>
      <div className="list-buttons-container">
        {_.get(listData, "buttons.length") > 0 ?
          listData.buttons.map((button, i) => {
            return listButton({ button, i })
          })
          :
          null
        }
      </div>
    </div>
  )
}

export default List
