/** @jsxImportSource theme-ui */

import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { Box, Flex, Text } from "theme-ui"
import { faTimes, faFilter } from "@fortawesome/free-solid-svg-icons"

import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import MemberResultList from "../MemberResultList/MemberResultList"
import * as Styles from "./ThreadSearchStyles"
import { Overlay } from "../../pages/Inbox/InboxStyles"
import PopperContainer from "../Radiate/PopperContainer/PopperContainer"
import ChannelsPicker from "../ChannelsPicker/ChannelsPicker"
import DateRangePicker from "../DateRangePicker/DateRangePicker"
import { randomString } from "../util"

const ThreadSearch = ({
  mobile = false,
  open = false,
  onClose = () => {},
  onSelectThread = () => {},
  searchQuery,
}) => {
  const { t } = useTranslation("common")
  const [search, setSearch] = useState("")
  const [openFilterOptions, setOpenFilterOptions] = useState(false)
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [channelIds, setChannelIds] = useState([])
  const [filter, setFilter] = useState(null)
  const [uniq, setUniq] = useState(randomString(6))

  const processedFilter = _.pickBy(filter, (o) => !_.isEmpty(o))
  return (
    <>
      {mobile ? (
        <Overlay
          show={open}
          sx={{
            zIndex: 99
          }}
        />
      ) : null}
      <Styles.MemberSearchContainer mobile={mobile} show={open} className="member-search-container">
        <Styles.MemberSearchTopContainer>
          <NewBoxButton
            className="close-member-search-button"
            borderless
            icon={faTimes}
            onClick={() => {
              setFrom(null)
              setTo(null)
              setChannelIds([])
              setSearch("")
              setFilter(null)
              setUniq(randomString(6))
              onClose()
            }}
          />
          <Styles.MemberSearchBar
            placeholder={t?.("threads.search_members")}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
          <Styles.DesktopUISearch>
            <PopperContainer
              display={(
                <NewBoxButton
                  borderless
                  icon={faFilter}
                  sx={{
                    ".icon": {
                      color: _.isEmpty(processedFilter) ? "gray3" : "themeColor",
                    },
                    "&:hover": {
                      ".icon": {
                        color: _.isEmpty(processedFilter) ? "#777779" : "themeColor"
                      }
                    }
                  }}
                  onClick={() => {
                    setOpenFilterOptions(true)
                  }}
                />
              )}
              show={openFilterOptions}
              onClickOutside={() => {
                setOpenFilterOptions(false)
              }}
              placement="bottom-end"
            >
              {() => (
                <Box
                  sx={{
                    width: "280px",
                  }}
                >
                  <Box
                    p={3}
                    sx={{
                      fontSize: 2,
                      color: "gray9",
                      borderBottom: "1px solid",
                      borderColor: "gray1",
                    }}
                  >
                    {t?.("threads.refine_search")}
                  </Box>
                  <Box
                    p={3}
                  >
                    <Box
                      mb={2}
                    >
                      <Text
                        sx={{
                          fontSize: "12px",
                          mb: "2px",
                          display: "inline-block",
                          color: "gray9",
                        }}
                      >
                        {t?.("threads.channels")}
                      </Text>
                      <ChannelsPicker
                        selectedChannelIds={channelIds}
                        onChange={(ids) => {
                          setChannelIds(ids)
                        }}
                      />
                    </Box>
                    <Box>
                      <Text
                        sx={{
                          fontSize: "12px",
                          mb: "2px",
                          display: "inline-block",
                          color: "gray9",
                        }}
                      >
                        {t?.("threads.last_message_time")}
                      </Text>
                      <DateRangePicker
                        defaultFrom={from}
                        defaultTo={to}
                        placement="bottom-start"
                        onRangeChange={({ from, to }) => {
                          setFrom(from)
                          setTo(to)
                        }}
                      />
                    </Box>

                  </Box>
                  <Flex
                    px={3}
                    py={2}
                    sx={{
                      borderTop: "1px solid",
                      borderColor: "gray1",
                    }}
                  >
                    <NewBoxButton
                      text={t?.("common:buttons.cancel")}
                      sx={{
                        mr: 2,
                        flexGrow: 1,
                      }}
                      onClick={() => {
                        setFrom(null)
                        setTo(null)
                        setChannelIds([])
                        setOpenFilterOptions(false)
                      }}
                    />
                    <NewBoxButton
                      primary
                      text={t?.("common:buttons.confirm")}
                      sx={{
                        flexGrow: 1,
                      }}
                      onClick={() => {
                        setFilter({
                          wChannelIds: channelIds?.length ? channelIds : null,
                          lastChatAt: from && to ? {
                            from,
                            to,
                          } : null
                        })
                        setOpenFilterOptions(false)
                      }}
                    />
                  </Flex>
                </Box>
              )}
            </PopperContainer>
          </Styles.DesktopUISearch>
        </Styles.MemberSearchTopContainer>
        <Box sx={{ flexGrow: 1, height: "calc(100% - 56px)" }}>
          <MemberResultList
            cacheSum={uniq}
            search={search}
            filter={filter}
            searchQuery={searchQuery}
            onChangeThreadId={(threadId) => {
              onSelectThread(threadId)
            }}
          />
        </Box>
      </Styles.MemberSearchContainer>
    </>
  )
}

export default ThreadSearch
