import React from "react"
import { convertMentions } from "../../MentionList/util"
import { MessageBubble } from "../util"


const MentionText = ({ data, color, mentions, ownUserId, isAgent }) => {
  const messageText = convertMentions(data.text, mentions, ownUserId)


  return (
    <MessageBubble isAgent={isAgent} color={color}>
      <div dangerouslySetInnerHTML={{ __html: messageText }} />
    </MessageBubble>
  )
}

export default MentionText
