import PropTypes from "prop-types"
import React from "react"

import * as  Styles from "./SegmentedSelectStyles"

const propTypes = {
  disabled: PropTypes.bool,
  items: PropTypes.array,
  value: PropTypes.any,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
}

const defaultProps = {
  disabled: false,
  items: [],
  value: "",
  defaultValue: "",
  className: "",
  onChange: () => {},
}

class SegemnetedSelect extends React.Component {
  render() {
    const {
      disabled,
      items,
      value,
      defaultValue,
      className,
      onChange,
      readOnly
    } = this.props

    let finalValue = defaultValue
    if (value) {
      finalValue = value
    }

    return (
      <Styles.SegmentedSelectContainer
        readOnly={readOnly}
        disabled={disabled}
        className={className}
      >
        {items.map(item => (
          <Styles.Option
            key={item.value}
            readOnly={readOnly}
            disabled={disabled}
            selected={item.value === finalValue}
            className={className}
            onClick={() => {
              if (!disabled && !item.disabled && !readOnly) {
                onChange(item.value)
              }
            }}
          >
            {item.text}
          </Styles.Option>
        ))}
      </Styles.SegmentedSelectContainer>
    )
  }
}

SegemnetedSelect.propTypes = propTypes
SegemnetedSelect.defaultProps = defaultProps

export default SegemnetedSelect
