/** @jsxImportSource theme-ui */

import { forwardRef } from "react"
import { Box, Text } from "theme-ui"
import { setLightness } from "polished"
import theme from "../../../stylesheets/theme"

const ConversationSeparator = forwardRef(function ConversationSeparator({
  message,
  color,
  bottomBg = "transparent",
  topBg = "transparent",
}, ref) {
  return (
    <div
      ref={ref}
      sx={{
        display: "flex",
        height: "36px",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          width: "100%",
          height: "18px",
          borderBottom: `1px solid ${setLightness(0.78, color)}`,
          background: topBg,
        }}
      ></Box>
      <Box
        sx={{
          top: "18px",
          left: 0,
          right: 0,
          position: "absolute",
          width: "100%",
          height: "18px",
          background: bottomBg,
        }}
      ></Box>
      <Text
        py="4px"
        px="16px"
        sx={{
          border: "1px solid",
          background: "white",
          borderRadius: "12px",
          zIndex: "2",
          borderColor: setLightness(0.78, color),
          color: color || "#0084FF",
          fontSize: theme.textSm,
        }}
      >{message}</Text>
    </div>
  )
})

export default ConversationSeparator
