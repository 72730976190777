/** @jsxImportSource theme-ui */
import React, { useState, useEffect, useTransition, useRef, useMemo, useContext } from "react"
import { useTranslation } from "react-i18next"
import graphql from "babel-plugin-relay/macro"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Flex, Text, Box } from "theme-ui"

import { getMessaging, getToken, onMessage } from "firebase/messaging"
import qs from "query-string"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronDown,
  faChevronUp,
  faSearch,
  faTrashCan,
  faGear,
  faBell,
  faBellSlash,
  faCircleExclamation,
  faChevronLeft,
  faFolderTree,
  faCaretDown,
  faTimes,
  faAt,
} from '@fortawesome/free-solid-svg-icons'
import {
  usePreloadedQuery,
  useRefetchableFragment,
  useMutation,
  useSubscription
} from "react-relay"
import { useHistory, useNavigation } from "yarr"
import ContentLoader from "react-content-loader"
import _ from "lodash"
import {
  useWindowWidth,
} from "@react-hook/window-size"
import { ErrorBoundary } from "react-error-boundary"

import Folders from "./Folders"
import Threads from "./Threads"

import ReactTooltip from "../../components/ReactTooltip/ReactTooltip.js"
import Modal from "../../components/Radiate/Modal/Modal"
import SettingsModal from "../../components/SettingsModal/SettingsModal"
import InstallerPrompt from "../../components/InstallerPrompt/InstallerPrompt"
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"
import ConversationHistory from "../../components/ConversationHistory/ConversationHistory"
import IntegrationIconDisplay from "../../components/IntegrationIconDisplay/IntegrationIconDisplay"
import IntegrationActionPopperContainer from "../../components/IntegrationActionPopperContainer/IntegrationActionPopperContainer"
import ThreadSearch from "../../components/ThreadSearch/ThreadSearch"
import MentionList from "../../components/MentionList/MentionList"

import * as Styles from "./InboxStyles"
import { DeleteFolderMutation, SavePushTokenMutation, ToggleNotificationsMutation, MentionCountSubscription, MentionSubscription } from "./InboxQuery"
import FolderInfoPopper from "../../components/FolderInfoPopper/FolderInfoPopper"
import NotificationRequestModal from "../../components/NotificationRequestModal/NotificationRequestModal"
// import MobileAccountSettings from "../../components/SettingsModal/SettingsModal"
import { useSWStore } from "../../index.js"
import iconUnreadMessage from "../../public/img/icon-unread-message.svg"
import { FOLLOW_UP_TEMP_FOLDER_ID } from "../../const"
import { UserContext } from "../../contexts/UserContext"

const DISABLE_FIREBASE = process.env.REACT_APP_DISABLE_FIREBASE !== undefined ? JSON.parse(process.env.REACT_APP_DISABLE_FIREBASE) : false
const FIREBASE_VAPID = process.env.REACT_APP_FIREBASE_VAPID
const PUBLIC_URL = process.env.PUBLIC_URL
const NODE_ENV = process.env.NODE_ENV
const FIREBASE_MSG_SENDER_ID = process.env.REACT_APP_FIREBASE_MSG_SENDER_ID

// Detects if device is in standalone mode
export const isInStandaloneMode = () => (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://')

const DropdownIndicator = ({
  innerProps,
}) => {
  return (
    <div
      style={{
        marginRight: "10px",
        cursor: "pointer",
      }}
      {...innerProps}
    >
      <FontAwesomeIcon
        style={{
          color: "#fff"
        }}
        icon={faCaretDown} 
      />
    </div>
  )
}

const UnauthThread = ({
  message,
  mobile,
  show,
  onBack,
}) => {
  const { t } = useTranslation("common")
  return (
    <Box
      p={3}
      sx={{
        height: "100%",
        width: mobile ? "100vw" : "100%",
        background: "#f9f9f9",
        ...(mobile ? {
          position: "fixed",
          top: 0,
          left: 0,
          transition: "opacity 0.3s ease, transform 0.3s ease-in-out",
          transform: "translateX(100%)",
        } : {}),
        ...(mobile && show ? {
          transform: "translateX(0)"
        } : {})
      }}
    >
      {mobile && (
        <Styles.IconButton
          className="mobile-screen-back left-button"
          icon={faChevronLeft}
          borderless
          noBG
          primary
          onClick={onBack}
          sx={{
            mr: "10px"
          }}
        />
      )}
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <FontAwesomeIcon
          icon={faCircleExclamation}
          sx={{
            fontSize: "60px",
            color: "gray4",
            mb: 3,
          }}
        />
        <Text>{message || t?.("threads.error_thread_not_found_or_not_authorized_to_access")}</Text>
      </Flex>
    </Box>
  )
}

const GenericError = ({
  mobile,
  show,
  onBack,
}) => {
  const { t } = useTranslation("common")
  return (
    <Box
      p={3}
      sx={{
        height: "100%",
        width: mobile ? "100vw" : "100%",
        background: "#f9f9f9",
        ...(mobile ? {
          position: "fixed",
          top: 0,
          left: 0,
          transition: "opacity 0.3s ease, transform 0.3s ease-in-out",
          transform: "translateX(100%)",
        } : {}),
        ...(mobile && show ? {
          transform: "translateX(0)"
        } : {})
      }}
    >
      {mobile && (
        <Styles.IconButton
          className="mobile-screen-back left-button"
          icon={faChevronLeft}
          borderless
          noBG
          primary
          onClick={onBack}
          sx={{
            mr: "10px"
          }}
        />
      )}
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <FontAwesomeIcon
          icon={faCircleExclamation}
          sx={{
            fontSize: "60px",
            color: "gray4",
            mb: 3,
          }}
        />
        <Text>{t?.("errors.something_went_wrong")}</Text>
      </Flex>
    </Box>
  )
}

const FolderNameLoader = (props) => (
  <ContentLoader
    speed={2}
    width={351}
    height={56}
    viewBox="0 0 351 56"
    backgroundColor="#e6e6e6"
    foregroundColor="#f0f0f0"
    {...props}
  >
    <rect x="16" y="22" rx="4" ry="4" width="80" height="12" />
  </ContentLoader>
)

const InboxQuery = graphql`
  query InboxQuery ($folderId: ID, $threadId: ID!, $withoutThreadId: Boolean!) {
    userViewer {
      ...Inbox_userViewer @arguments (folderId: $folderId)
      ...Folders_userViewer
      ...Threads_userViewer
      ...ConversationHistory_userViewer @arguments(threadId: $threadId, withoutThreadId: $withoutThreadId)
      ...MemberContainer_userViewer @arguments(
        threadId: $threadId
        withoutThreadId: $withoutThreadId
      )
    }
    navHintViewer: userViewer {
      ...Inbox_navHintViewer @arguments (withoutNavHint: true)
    }
    folderThreadsViewer: userViewer {
      ...Inbox_folderThreadsViewer @arguments (folderId: $folderId)
    }
  }
`

function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const playNotificationAudio = async (data) => {
  if (data) {
    try {
      let sound = new Audio(`${PUBLIC_URL}/incoming_message.mp3`)
      await sound.play()
    } catch (error) {
      console.info("waiting for interaction")
    }
  }
}

const Inbox = ({
  preloaded,
  ...props
}) => {
  const data = usePreloadedQuery(
    InboxQuery,
    preloaded.query,
  )
  return (
    <InnerInbox
      data={data}
      {...props}
      threadId={preloaded.threadId}
      withoutThreadId={preloaded.withoutThreadId}
      searchQuery={preloaded.searchQuery}
    />
  )
}

const InnerInbox = ({
  onChangeChannelId,
  encryption,
  withoutThreadId,
  searchQuery,
  ...props
}) => {
  const { t } = useTranslation("common")
  const { location } = useHistory()
  const messaging = useRef(null)
  const threadsRef = useRef(null)
  const navigation = useNavigation()
  const screenWidth = useWindowWidth()
  const query = qs.parse(location.search)
  const [showConversationHistory, setShowConversationHistory] = useState(!!query?.thread)
  const firstLoad = useRef(true)
  const [threadId, setThreadId] = useState(query?.thread)
  const [channels, setChannels] = useState(null)
  const channelIds = channels?.map((o) => o.value)
  const [folderId, setFolderId] = useState("main")
  const [search, setSearch] = useState("")
  const [isInBackground, setIsInBackground] = useState(false)
  const [showEditFolderDropDown, setShowEditFolderDropDown] = useState(false)
  const [openRequestNotificationModal, setOpenRequestNotificationModal] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [deleteFolderError, setDeleteFolderError] = useState("")
  const [openMemberSearch, setOpenMemberSearch] = useState(false)
  const [openMentionList, setOpenMentionList] = useState(false)
  const [openSettings, setOpenSettings] = useState(false)
  const [selectingThreads, setSelectingThreads] = useState(false)
  const [isFilteringUnread, setIsFilteringUnread] = useState(false)
  const [unreadMentionCount, setUnreadMentionCount] = useState(0)
  const initialNavHint = {}
  if (query?.eid && query?.cid && !query?.thread) {
    initialNavHint.externalId = query.eid
    initialNavHint.wChannelId = query.cid
  }
  const [navHint, setNavHint] = useState(initialNavHint)
  const [copyFolderIdText, setCopyFolderIdText] = useState(t?.("tooltips.copy"))
  const [notificationPermissionGranted, setNotificationPermissionGranted] = useState(false)
  const [globalNotificationDisabled, setGlobalNotificationDisabled] = useState(false)
  const [notificationError, setNotificationError] = useState(null)
  const [loading, startTransition] = useTransition()
  const [commitDeleteFolder, isInFlightDeleteFolder] = useMutation(
    DeleteFolderMutation
  )
  const [savePushToken] = useMutation(
    SavePushTokenMutation
  )
  const [toggleNotifications, togglingNotifications] = useMutation(
   ToggleNotificationsMutation
  )
  const standalone = useRef(isInStandaloneMode())
  const { isSuperAdmin, isSuperAuditor } = useContext(UserContext)

  const [
    data, 
    refetch
  ] = useRefetchableFragment(
    graphql`
      fragment Inbox_userViewer on UserScope
      @argumentDefinitions(
        folderId: { type: "ID" }
      )
      @refetchable(queryName: "InboxRefetchQuery") {
        mentions(first: 1000, unreadOnly: true) {
          edges {
            node {
              _id
              readAt
            }
          } 
        }
        folder (folderId: $folderId) {
          folderId
          name
          color
          wChannelIds
          wChannels {
            name
            integrationIcon
            integrationName
          }
          isPrivate
          userIds
          default
        }
        role
        folderActions(folderId: $folderId) {
          integrationIcon
          integrationName
          integrationDescription
          integrationId
          integrationBuild
          iframeContext {
            payload
            signedContext
          }
          contextToken
          channel {
            _id
            description
            name
            type
            environments {
              webhooks
              webhooksV2 {
                integrationId
              }
            }
          }
          customActions {
            url
            body
            headers
            method
            description
            modalSchema
            modalView
            title
            type
          }
        }
        disableNotifications
        wAppId
        wUserId
      }
    `,
    props.data.userViewer
  )
  const [newMention, setNewMention] = useState(null)

  const mentionCountMemoizedValue = useMemo(() => {
    return {
      variables: {},
      subscription: MentionCountSubscription,
      updater: (store, data) => {
        setUnreadMentionCount(data?.mentionCount?.count)
      },
      onError: (error) => {
        console.log("MentionCountSubscription error", error)
      },
    }
  }, [])
  const mentionMemoizedValue = useMemo(() => {
    return {
      variables: {},
      subscription: MentionSubscription,
      updater: (store, data) => {
        setNewMention(data?.mention?.mention)
        // setUnreadMentionCount(data?.mentionCount?.count)
      },
      onError: (error) => {
        console.log("MentionSubscription error", error)
      },
    }

  }, [])
  useSubscription(mentionMemoizedValue) 
  useSubscription(mentionCountMemoizedValue)

  const [
    folderThreads, 
    refetchFolderThreads,
  ] = useRefetchableFragment(
    graphql`
      fragment Inbox_folderThreadsViewer on UserScope
      @argumentDefinitions(
        folderId: { type: "ID" }
      )
      @refetchable(queryName: "InboxFolderThreadsRefetchQuery") {
        threads (
          folderIds: [$folderId]
          first: 1
        ) {
          pageInfo {
            totalCount
          }
        }
      }
    `,
    props.data.folderThreadsViewer
  )

  const [
    navHintResult,
    refetchNavHintResult
  ] = useRefetchableFragment(
    graphql`
      fragment Inbox_navHintViewer on UserScope
      @argumentDefinitions(
        wMemberId: { type: "ID" },
        externalId: { type: "ID" },
        wChannelId: { type: "ID" },
        withoutNavHint: { type: "Boolean!" }
      )
      @refetchable(queryName: "InboxNavHintRefetchQuery") {
        thread (
          wMemberId: $wMemberId
          externalId: $externalId
          wChannelId: $wChannelId
        ) @skip(if: $withoutNavHint) {
          threadId
          folderId
        }
      }
    `,
    props.data.navHintViewer
  )

  useEffect(() => {
    setGlobalNotificationDisabled(!!data?.disableNotifications)
  }, [data?.disableNotifications])

  function registerFirebaseServiceWorker() {
    if (!messaging.current) {
      messaging.current = getMessaging()
    }
    if (NODE_ENV === "production") {
      navigator.serviceWorker.getRegistration(`${PUBLIC_URL}/service-worker.js`).then((registration) => {
        // console.log("reg", registration)
        if (registration) {
          getToken(messaging.current, { vapidKey: FIREBASE_VAPID, serviceWorkerRegistration: registration }).then((token) => {
            // console.log("will save token for client: ", token)
            savePushToken({
              variables: {
                input: {
                  token,
                }
              },
              onCompleted: (data) => {
                // console.log("data", data)
              }
            })
          })
        }
      })
    }
  }

  useEffect(() => {
    if (!DISABLE_FIREBASE) {
      if ("serviceWorker" in navigator && "Notification" in window) {
        if (Notification.permission !== "granted" && Notification.permission !== "denied" && !inIframe()) {
          console.log("requesting permission for Notification")
          requestForNotifcationPermission()
        }
        if (isInStandaloneMode() && !openRequestNotificationModal && Notification.permission !== "granted") {
          setOpenRequestNotificationModal(true)
        }
      }
    }
    const onVisibilityChange = () => {
      const state = document.visibilityState;
      if (state === "hidden") {
        // your PWA is now in the background
        setIsInBackground(true)
      }

      if (state === "visible") {
        // your PWA is now in the foreground
        setIsInBackground(false)
      }
    }
    document.addEventListener("visibilitychange", onVisibilityChange)

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange)
    }
  }, [])

  useEffect(() => {
    if (!isInBackground) {
      if ("serviceWorker" in navigator) {
        if ("Notification" in window) {
          if (!DISABLE_FIREBASE) {
            console.log("Notification", Notification.permission)
            if (Notification.permission === "granted") {
              setNotificationPermissionGranted(true)
              setNotificationError(null)
              registerFirebaseServiceWorker()
            } else if (Notification.permission === "denied") {
              setNotificationPermissionGranted(false)
              const errorDeniedPermissionPart1 = t?.("errors.notification_denied_part_1")
              const errorDeniedPermissionPart2 = t?.("errors.notification_denied_part_2")
              setNotificationError(`${errorDeniedPermissionPart1}.\n${errorDeniedPermissionPart2}.`)
            } else {
              // We need to ask the user for permission
              setNotificationPermissionGranted(false)
              setNotificationError(null)
            }
          }
        } else {
          setNotificationError(t?.("errors.notification_not_supported_in_your_browser"))
        }

        navigator.serviceWorker.getRegistration(`${PUBLIC_URL}/service-worker.js`).then((registration) => {
          if (registration?.waiting) {
            // there are already new sw pending to update
            const waitingWorker = registration.waiting
            if (waitingWorker.state === "installed") {
              useSWStore.setState({
                swUpdated: true,
                swRegistration: registration
              })
            }
          }
        })
      }
    }
  }, [isInBackground])

  useEffect(() => {
    setSelectingThreads(false)
    if (firstLoad.current) {
      firstLoad.current = false
    } else if (!isInBackground) {
      startTransition(() => {
        refetch({
          folderId: folderId || null,
        }, {
          fetchPolicy: "network-only",
        })
        refetchFolderThreads({
          folderId: folderId || null,
        }, {
          fetchPolicy: "network-only",
        })
      })
    }
  }, [folderId, isInBackground])

  // code for testing
  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log("set navhint")
  //     setNavHint({
  //       memberId: "64192186d9947fd97a575b83"
  //     })
  //   }, 5000)
  // }, [])

  useEffect(() => {
    if (_.isEmpty(navHint)) {
      refetchNavHintResult({
        withoutNavHint: true,
      })
    }
    if (navHint?.memberId) {
      refetchNavHintResult({
        wMemberId: navHint.memberId,
        withoutNavHint: false,
      }, {
        fetchPolicy: "network-only",
      })
    }
    if (navHint?.externalId && navHint?.wChannelId) {
      refetchNavHintResult({
        externalId: navHint.externalId,
        wChannelId: navHint.wChannelId,
        withoutNavHint: false,
      }, {
        fetchPolicy: "network-only",
      })
    }
  }, [JSON.stringify(navHint), isInBackground])

  useEffect(() => {
    if (navHintResult?.thread?.threadId) {
      setThreadId(navHintResult.thread.threadId)
      setFolderId(navHintResult.thread.folderId)
    }
    setNavHint({})
  }, [JSON.stringify(navHintResult?.thread)])

  useEffect(() => {
    if (messaging.current && !DISABLE_FIREBASE) {
      onMessage(messaging.current, (payload) => {
        console.log("receive foreground notification", payload)
        console.log("visibility in background", isInBackground)
        // if (isInBackground) {
        //   new Notification(payload.notification.title)
        // }
        playNotificationAudio(payload)
      })
      
      const channel = new BroadcastChannel("PUSH_NOTIFICATION")
      channel.addEventListener("message", playNotificationAudio)

      return () => {
        channel.removeEventListener("message", playNotificationAudio)
        channel.close()
      }
    }
  }, [messaging.current])

  useEffect(() => {
    function handleNotificationClick(event) {
      const payload = event.data
      if (!payload.isFirebaseMessaging || payload.messageType !== "notification-clicked" || payload.from !== FIREBASE_MSG_SENDER_ID) {
        return
      }
      if (payload?.data?.wAppId !== data?.wAppId) {
        // the notification clicked does not belong to the viewing app
        return
      }
      if (payload?.data?.threadId) setThreadId(payload.data.threadId)
      if (payload?.data?.folderId) setFolderId(payload.data.folderId)
      setShowConversationHistory(true)
    }
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", handleNotificationClick)
    }

    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", handleNotificationClick)
      }
    }
  }, [data?.wAppId])

  useEffect(() => {
    if (data?.mentions?.edges?.length > 0) {
      setUnreadMentionCount(data.mentions.edges.length)
    }
  }, [data?.mentions?.edges?.length])
  
  const deleteFolder = ({ folderId }) => {
    setDeleteFolderError("")
    let clientMutationId = 0
    const mutationParams = {
      variables: {
        input: {
          folderId,
          clientMutationId: clientMutationId++
        },
      },
      onCompleted: (response, errs) => {
        if (!response.deleteFolder?.error) {
          setShowEditFolderDropDown(false)
          setFolderId("main")
          setChannels(null)
        } else {
          setDeleteFolderError(response?.deleteFolder?.error?.message)
          alert(`[Error] ${response?.deleteFolder?.error?.message}`)
        }
      },
      onError: (err) => {
        // console.log(err)
        const errorMsg = err.res?.json?.errors[0]?.message || err
        setDeleteFolderError(errorMsg)
      }
    }
    commitDeleteFolder(mutationParams)
  }

  let displayFolderName = ""
  if (folderId === "follow-up") {
    displayFolderName = t?.("folders.follow_up")
  } else if (folderId === FOLLOW_UP_TEMP_FOLDER_ID) {
    displayFolderName = t?.("folders.follow_up")
  } else if (data?.folder?.name) {
    displayFolderName = data?.folder?.name
  }

  const totalThreadsInFolder = folderThreads?.threads?.pageInfo?.totalCount ?? 0

  const renderFolderInfo = () => {
    if (loading) {
      return <FolderNameLoader />
    } else if (folderId === FOLLOW_UP_TEMP_FOLDER_ID) {
      return (
        <Styles.FolderInfoButton
          className="folder-info-button"
          sx={{
            cursor: "auto",
          }}
        >
          <div className="folder-name">{displayFolderName}</div>
        </Styles.FolderInfoButton>
      )
    }
    return (
      <Styles.EditFolderPopperContainer
        className="edit-folder-popper-container"
        displayDisplay="block"
        show={showEditFolderDropDown}
        display={(
          <Styles.FolderInfoButton className="folder-info-button"
            onClick={() => {
              setShowEditFolderDropDown(!showEditFolderDropDown)
            }}
          >
            {displayFolderName &&
              <>
                <div className="folder-name">{displayFolderName}</div>
                {folderId && !loading && folderId !== FOLLOW_UP_TEMP_FOLDER_ID &&
                  <FontAwesomeIcon
                    icon={showEditFolderDropDown ? faChevronUp : faChevronDown}
                  />
                }
              </>
            }
          </Styles.FolderInfoButton>
        )}
        placement="bottom-start"
        onClickOutside={() => {
          if (showEditFolderDropDown) {
            setShowEditFolderDropDown(false)
          }
        }}
      >
        {() => (
          <Styles.FolderInfoContainer
            threadsContainerHeight={threadsRef?.current?.clientHeight}
          >
            <Flex
              py={3}
              pl={3}
              pr={1}
              sx={{
                alignItems: "flex-start"
              }}
            >
              <Styles.Folder
                color={data?.folder?.color}
                noHover
              >
                {data?.folder?.name ? [...data?.folder?.name][0] : null}
              </Styles.Folder>
              <Box
                sx={{
                  paddingLeft: "16px",
                  width: "calc(100% - 38px)",
                }}
              >
                <Flex
                  sx={{
                    alignItems: "center",
                  }}
                >
                  <Box
                    className="folder-name"
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "calc(100% - 34px)",
                    }}
                  >
                    {data?.folder?.name}
                  </Box>
                  {(data?.role === "ADMIN" || data?.role === "SUPER_ADMIN") && !data?.folder?.default ? (
                    <Box
                      sx={{
                        flexShrink: 0
                      }}
                      data-tooltip-html={totalThreadsInFolder > 0 ? `${t?.("threads.tooltip_folder_with_threads_cannot_be_deleted_part_1")}.\n${t?.("threads.tooltip_folders_with_threads_cannot_be_deleted_part_2")}.` : null}
                      data-tooltip-id="folder-info-delete-tooltip"
                    >
                      <NewBoxButton
                        borderless
                        icon={faTrashCan}
                        loading={isInFlightDeleteFolder}
                        disabled={totalThreadsInFolder > 0 || isInFlightDeleteFolder}
                        onClick={() => {
                          const confirmDeleteFolder = t?.("threads.confirm_delete_folder")
                          // eslint-disable-next-line no-restricted-globals
                          const c = confirm(confirmDeleteFolder)
                          if (c) {
                            deleteFolder({
                              folderId: data?.folder?.folderId
                            })
                          }
                        }}
                      />
                    </Box>
                  ) : null}
                </Flex>
                
                <CopyToClipboard
                  text={data?.folder?.folderId}
                  onCopy={() => {
                    setCopyFolderIdText(t?.("tooltips.copied"))
                    setTimeout(() => {
                      setCopyFolderIdText(t?.("tooltips.copy"))
                    }, 1000)
                  }}
                >
                  <Flex
                    className="folder-id"
                    sx={{
                      my: 1,
                      fontSize: "12px",
                      borderRadius: "4px",
                      border: "1px solid",
                      borderColor: "themeColor",
                      overflow: "hidden",
                      alignItems: "center",
                      display: "inline-flex",
                      cursor: "pointer",
                    }}
                    data-tooltip-content={copyFolderIdText}
                    data-tooltip-id="folder-info-tooltip"
                  >
                    <Box
                      sx={{
                        background: "themeColor",
                        color: "white",
                        fontWeight: "bold",
                        px: 1,
                        py: "2px",
                      }}
                    >{t?.("threads.id")}</Box>
                    <Box
                      sx={{
                        background: "white",
                        color: "gray9",
                        px: 1,
                        py: "2px",
                      }}
                    >
                      {data?.folder?.folderId}
                    </Box>
                  </Flex>
                </CopyToClipboard>

                <Box
                  className="folder-channels-name"
                >
                  {(data?.folder?.wChannels ?? []).map((o, i) => {
                    return (
                      <Flex key={i} sx={{ margin: "2px 0" }}>
                        <IntegrationIconDisplay
                          icon={o?.integrationIcon}
                          name={o?.integrationName}
                          size="16px"
                        />
                        <Text pl={1}>{o?.name}</Text>
                      </Flex>
                    )
                  })}
                </Box>
              </Box>
            </Flex>
            {data?.role === "ADMIN" || data?.role === "SUPER_ADMIN" ? (
              <>
                <div className="separator"></div>
                <FolderInfoPopper
                  action="UPDATE"
                  embedded
                  wChannelIds={channelIds}
                  folderId={folderId}
                  info={{
                    name: data?.folder?.name,
                    channelIds: data?.folder?.wChannelIds,
                    isPrivate: data?.folder?.isPrivate,
                    userIds: data?.folder?.userIds,
                    color: data?.folder?.color,
                    default: data?.folder?.default,
                  }}
                />
              </>
            ) : null}
            <ReactTooltip
              id="folder-info-tooltip"
              place="right"
            />
            <ReactTooltip
              id="folder-info-delete-tooltip"
              place="right"
              style={{
                minWidth: "200px"
              }}
            />
          </Styles.FolderInfoContainer>
        )}
      </Styles.EditFolderPopperContainer>
    )
  }
  // Detects if device is on iOS 
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod/.test( userAgent ) && ('standalone' in window.navigator) && (window.navigator.standalone)
  }

  const requestForNotifcationPermission = ({ skipCheck = false } = {}) => {
    if (skipCheck) {
      if (!notificationPermissionGranted || globalNotificationDisabled) {
        // request notification permission
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("Notification permission granted.")
            setNotificationPermissionGranted(true)
            registerFirebaseServiceWorker()
          } else {
            console.log("Notification permission not granted.")
          }
        })
      }
    } else if ((!notificationPermissionGranted && notificationError === null) || globalNotificationDisabled) {
      // request notification permission
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.")
          setNotificationPermissionGranted(true)
          registerFirebaseServiceWorker()
        } else {
          console.log("Notification permission not granted.")
        }
      })
    }
  }

  if (screenWidth > 989) {
    // desktop version
    return (
      <Box style={{ height: "100vh" }}>
        <Flex sx={{ height: "100%", overflow: "hidden", background: "#f0f2f7" }}>
          <Box sx={{ height: "100%", borderRight: "1px solid #e8e7e8", zIndex: 99 }}>
            <Flex sx={{ height: "100%", width: "416px" }}>
              <Folders
                standalone={standalone.current}
                isInBackground={isInBackground}
                data={props.data}
                selectedFolder={folderId}
                wChannelIds={channelIds}
                onChangeSelectedFolder={(id) => {
                  setFolderId(id)
                }}
              />
              <Flex sx={{ flexDirection: "column", justifyContent: "flex-end", height: "100%", width: "calc(416px - 65px)" }}>
                <Styles.SearchBarContainer>
                  <div className="member-search-container">
                    <Styles.MemberSearchBar
                      placeholder={t?.("threads.search_members")}
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value)
                      }}
                    />
                    <div
                      className="search-icon-container"
                      onClick={() => {
                        setOpenMemberSearch(true)
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="search-icon"   
                      />
                    </div>
                  </div>
                  <NewBoxButton
                    icon={notificationPermissionGranted && !globalNotificationDisabled ? faBell : faBellSlash}
                    borderless
                    loading={togglingNotifications}
                    data-tooltip-html={notificationError || null}
                    sx={{
                      ".icon": {
                        color: notificationPermissionGranted && !globalNotificationDisabled ? "themeColor" : "danger"
                      }
                    }}
                    data-tooltip-id="notification-tooltip"
                    disabled={!!notificationError || DISABLE_FIREBASE}
                    onClick={() => {
                      if (!notificationPermissionGranted || globalNotificationDisabled) {
                        requestForNotifcationPermission()
                        toggleNotifications({
                          variables: {
                            input: {
                              enable: true
                            }
                          },
                          onCompleted: (res) => {
                            if (res.toggleNotifications.ok) {
                              setGlobalNotificationDisabled(false)
                            }
                          }
                        })
                      } else {
                        toggleNotifications({
                          variables: {
                            input: {
                              enable: false
                            }
                          },
                          onCompleted: (res) => {
                            if (res.toggleNotifications.ok) {
                              setGlobalNotificationDisabled(true)
                            }
                          }
                        })
                      }
                    }}
                  />
                  <Styles.MentionButtonContainer>
                    <Styles.MentionButton
                      className="at-mention-button"
                      icon={faAt}
                      borderless
                      onClick={() => {
                        console.log("open mention modal")
                        setOpenMentionList(true)
                      }}
                    />
                    {unreadMentionCount > 0 && (
                      <div className="mention-count">
                        {unreadMentionCount}
                      </div>
                    )}
                  </Styles.MentionButtonContainer>
                  <ReactTooltip
                    id="notification-tooltip"
                    place="right"
                    style={{
                      maxWidth: "300px",
                    }}
                  />

                </Styles.SearchBarContainer>
                <Styles.ChannelFilterContainer>
                  <div className="channel-picker-container">
                    <Styles.StyledChannelsPicker
                      className="channels-picker"
                      // placeholder="Filter Channels"
                      selectedChannelIds={channels?.map((o) => o.value)}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator,
                        Placeholder: () => null,
                        MultiValueLabel: () => null,
                        MultiValueRemove: () => null,
                        MultiValueContainer: () => null,
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          background: "#405DE6",
                          fontSize: "12px",
                          color: "#fff",
                          borderRadius: "4px",
                          border: "none",
                          minHeight: "32px",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          minWidth: "200px",
                          maxWidth: "300px",
                        }),
                      }}
                      onChange={(channelIds, channels) => {
                        if (channelIds?.length) {
                          setChannels(channels)
                        } else {
                          setChannels(null)
                        }
                      }}
                    />
                    <div
                      className="custom-placeholder"
                    >
                      {t?.("threads.filter_channels")}
                    </div>
                  </div>
                  {channels?.length && (
                    <div
                      className="selected-channels-summary-container"
                    >
                      {channels?.length > 1 ? `${channels.length} ${t?.("threads.channel_selected")}` : channels[0].label}

                      <FontAwesomeIcon
                        className="clear-icon"
                        borderless
                        icon={faTimes}
                        onClick={() => {
                          setChannels(null)
                        }}
                      />
                    </div>
                  )}
                </Styles.ChannelFilterContainer>
                <Styles.FolderInfo>
                  {renderFolderInfo()}
                  <div className="folder-info-buttons-wrapper">
                    {!!data?.folderActions?.length &&
                      <IntegrationActionPopperContainer
                        disable={isSuperAdmin || isSuperAuditor}
                        shouldDisableThreadAction={isSuperAdmin || isSuperAuditor}
                        actions={data?.folderActions}
                        useInThread
                        onChangeNavHint={setNavHint}
                        userId={data?.wUserId}
                      />
                    }
                    <Styles.IconButton
                      className="filter-unread-button"
                      borderless
                      text={(
                        <div
                          className="svg-container"
                        >
                          <img
                            src={iconUnreadMessage}
                            alt="Filter Unread Threads"
                            className="svg-icon"
                          />
                        </div>
                      )}
                      data-tooltip-content={t?.("threads.tooltip_filter_unread_threads")}
                      data-tooltip-id="inbox-tooltip"
                      $on={isFilteringUnread}
                      onClick={() => {
                        setIsFilteringUnread(!isFilteringUnread)
                      }}
                    />
                    {folderId !== FOLLOW_UP_TEMP_FOLDER_ID &&
                      <Styles.IconButton
                        className="multi-select-button"
                        icon={faFolderTree}
                        borderless
                        data-tooltip-content={t?.("threads.tooltip_manage_threads")}
                        data-tooltip-id="inbox-tooltip"
                        $on={selectingThreads}
                        onClick={() => {
                          setSelectingThreads(!selectingThreads)
                        }}
                      />
                    }
                    <ReactTooltip
                      id="inbox-tooltip"
                      place="right"
                    />
                  </div>

                </Styles.FolderInfo>
                <Box ref={threadsRef} sx={{ flexGrow: 1, height: "calc(100% - 56px)" }}>
                  <Threads
                    data={props.data}
                    search={search}
                    threadId={threadId}
                    channelIds={channelIds}
                    folderName={data?.folder?.name}
                    folderId={folderId}
                    isFilteringUnread={isFilteringUnread}
                    selecting={selectingThreads}
                    isInBackground={isInBackground}
                    onChangeThreadId={(threadId) => {
                      setThreadId(threadId)
                      navigation.replace({
                        search: qs.stringify({ ...query, thread: threadId })
                      })
                      setShowConversationHistory(true)
                    }}
                    onFinishUpdateThreadsFolder={(value) => {
                      setSelectingThreads(!value)
                      setThreadId(null)
                      refetchFolderThreads({
                        folderId: folderId || null,
                      }, {
                        fetchPolicy: "network-only",
                      })
                      navigation.replace({
                        search: qs.stringify(_.omit(query, ["thread"]))
                      })
                    }}
                  />
                </Box>
              </Flex>
            </Flex>
          </Box>
          <ThreadSearch
            open={openMemberSearch}
            onClose={() => {
              setOpenMemberSearch(false)
            }}
            onSelectThread={(threadId) => {
              setThreadId(threadId)
              navigation.replace({
                search: qs.stringify({ ...query, thread: threadId })
              })
            }}
            searchQuery={searchQuery}
          />
          <MentionList
            unreadMentionCount={unreadMentionCount}
            newMention={newMention}
            open={openMentionList}
            onClose={() => {
              setOpenMentionList(false)
            }}
            onSelectThread={(threadId) => {
              setThreadId(threadId)
              navigation.replace({
                search: qs.stringify({ ...query, thread: threadId })
              })
            }}
            searchQuery={searchQuery}
            redirectToThread={(threadId, folderId, readAt) => {
              setFolderId(folderId)
              setThreadId(threadId)
              if (!readAt) {
                setUnreadMentionCount(unreadMentionCount - 1)
              }
              navigation.replace({
                search: qs.stringify({ ...query, thread: threadId })
              })
              setShowConversationHistory(true)
            }}
          />
          <Box className="conversation" sx={{ width: "calc(100% - 417px)", height: "100%", background: "#f9f9f9", zIndex: 98 }}>
            <ErrorBoundary
              key={threadId}
              fallbackRender={({ error, resetErrorBoundary }) => {
                const errorMsg = error?.res?.json?.errors?.[0]?.message ? error.res.json.errors[0].message : error?.message
                switch (errorMsg) {
                  case "Thread not found or unauthorized to access":
                    return (
                      <UnauthThread />
                    )
                  case "Unauthorized to access the folder that the thread belongs to":
                    return (
                      <UnauthThread
                        message={t?.("errors.unauthorized_to_access_folder_thread_belongs_to")}
                      />
                    )
                  default:
                    return (
                      <GenericError />
                    )
                }
              }}
            >
              <ConversationHistory
                data={props.data}
                threadSearch={props.searchAuery}
                threadId={threadId}
                onChangeNavHint={setNavHint}
                onClose={() => {
                  setShowConversationHistory(false)
                  setThreadId(null)
                  navigation.replace({
                    search: qs.stringify(_.omit(query, ["thread"]))
                  })
                }}
              />
            </ErrorBoundary>
          </Box>
        </Flex>
        <InstallerPrompt />
      </Box>
    )
  } else {
    return (
      <Styles.MobileUIInbox>
        <Styles.MobileInboxContainer ios={isIos()}>
          <Flex
            sx={{
              backgroundColor: "#4274ff",
              px: 2,
              py: 2,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Styles.IconButton
              icon={faGear}
              borderless
              inverted
              size="L"
              $on
              onClick={() => {
                setOpenSettings(true)
              }}
            />
            <Flex
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Styles.MentionButtonContainer
                style={{
                  marginRight: "4px",
                }}
              >
                <Styles.MentionButton
                  className="at-mention-button"
                  icon={faAt}
                  borderless
                  onClick={() => {
                    setOpenMentionList(true)
                  }}
                  $on
                  size="L"
                  inverted
                />
                {unreadMentionCount > 0 && (
                  <div className="mention-count">
                    {unreadMentionCount}
                  </div>
                )}
              </Styles.MentionButtonContainer>
              <Styles.IconButton
                icon={faSearch}
                borderless
                inverted
                size="L"
                $on
                onClick={() => {
                  setOpenMemberSearch(true)
                }}
              />
            </Flex>
          </Flex>
          <Folders
            isInBackground={isInBackground}
            data={props.data}
            selectedFolder={folderId}
            wChannelIds={channelIds}
            onChangeSelectedFolder={(id) => {
              setFolderId(id)
            }}
            topBarProps={{
              folderId: folderId,
              folderActions: data?.folderActions,
              setNavHint: setNavHint,
              isFilteringUnread: isFilteringUnread,
              setIsFilteringUnread: (value) => setIsFilteringUnread(value),
              selectingThreads: selectingThreads,
              setSelectingThreads: (value) => setSelectingThreads(value)
            }}
            mobile
          />
          <Styles.MobileInboxBody>
            <Threads
              data={props.data}
              threadId={threadId}
              channelIds={channelIds}
              folderName={data?.folder?.name}
              folderId={folderId}
              isFilteringUnread={isFilteringUnread}
              isInBackground={isInBackground}
              selecting={selectingThreads}
              onChangeThreadId={(threadId) => {
                setThreadId(threadId)
                navigation.replace({
                  search: qs.stringify({ ...query, thread: threadId })
                })
                setShowConversationHistory(true)
              }}
              onFinishUpdateThreadsFolder={(value) => {
                setSelectingThreads(!value)
                setThreadId(null)
                refetchFolderThreads({
                  folderId: folderId || null,
                }, {
                  fetchPolicy: "network-only",
                })
                navigation.replace({
                  search: qs.stringify(_.omit(query, ["thread"]))
                })
              }}
              mobile
            />
            {/* {mobileScreenFooter === 'inbox' ? (
              
            ) : (
              <MobileAccountSettings
                isModal={false}
                onClose={() => { }}
              />
            )} */}
          </Styles.MobileInboxBody>
          <ErrorBoundary
            key={threadId}
            fallbackRender={({ error, resetErrorBoundary }) => {
              const errorMsg = error?.res?.json?.errors?.[0]?.message ? error.res.json.errors[0].message : error?.message
              switch(errorMsg) {
                case "Thread not found or unauthorized to access":
                  return (
                    <UnauthThread
                      mobile
                      show={showConversationHistory}
                      onBack={() => {
                        setShowConversationHistory(false)
                        setThreadId(null)
                        navigation.replace({
                          search: qs.stringify(_.omit(query, ["thread"]))
                        })
                      }}
                    />
                  )
                case "Unauthorized to access the folder that the thread belongs to":
                  return (
                    <UnauthThread
                      message={t?.("errors.unauthorized_to_access_folder_thread_belongs_to")}
                      mobile
                      show={showConversationHistory}
                      onBack={() => {
                        setShowConversationHistory(false)
                        setThreadId(null)
                        navigation.replace({
                          search: qs.stringify(_.omit(query, ["thread"]))
                        })
                      }}
                    />
                  )
                default:
                  return (
                    <GenericError
                      mobile
                      show={showConversationHistory}
                      onBack={() => {
                        setShowConversationHistory(false)
                        setThreadId(null)
                        navigation.replace({
                          search: qs.stringify(_.omit(query, ["thread"]))
                        })
                      }}
                    />
                  )
              }
            }}
          >
            <ConversationHistory
              mobile
              show={showConversationHistory}
              data={props.data}
              threadId={threadId}
              onChangeNavHint={setNavHint}
              onClose={() => {
                setShowConversationHistory(false)
                setThreadId(null)
                navigation.replace({
                  search: qs.stringify(_.omit(query, ["thread"]))
                })
              }}
            />
          </ErrorBoundary>
          <Modal
            open={openRequestNotificationModal}
          >
            {({ modalRef }) => (
              <NotificationRequestModal
                open={openRequestNotificationModal}
                ref={modalRef}
                onAllow={() => {
                  requestForNotifcationPermission({ skipCheck: true })
                  setOpenRequestNotificationModal(false)
                }}
                onCancel={() => {
                  setOpenRequestNotificationModal(false)
                }}
              />
            )}
          </Modal>
          <ThreadSearch
            mobile
            open={openMemberSearch}
            onClose={() => {
              setOpenMemberSearch(false)
            }}
            onSelectThread={(threadId) => {
              setThreadId(threadId)
              navigation.replace({
                search: qs.stringify({ ...query, thread: threadId })
              })
              setOpenMemberSearch(false)
              setShowConversationHistory(true)
            }}
            searchQuery={searchQuery}
          />
          <MentionList
            mobile
            open={openMentionList}
            onClose={() => {
              setOpenMentionList(false)
            }}
            onSelectThread={(threadId) => {
              setThreadId(threadId)
              navigation.replace({
                search: qs.stringify({ ...query, thread: threadId })
              })
            }}
            searchQuery={searchQuery}
            redirectToThread={(threadId, folderId, readAt) => {

              setFolderId(folderId)
              setThreadId(threadId)
              if (!readAt) {
                setUnreadMentionCount(unreadMentionCount - 1)
              }
              navigation.replace({
                search: qs.stringify({ ...query, thread: threadId })
              })
              setShowConversationHistory(true)
            }}
          />
          <SettingsModal
            mobile
            standalone={standalone.current}
            open={openSettings}
            onClose={() => {
              setOpenSettings(false)
            }}
          />
        </Styles.MobileInboxContainer>
        <InstallerPrompt />
      </Styles.MobileUIInbox>
    )
  }
}

export default Inbox

