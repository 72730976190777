import React, { forwardRef } from "react"
import { useTranslation } from "react-i18next"

export function withTranslatedUtilOptions(WrappedComponent) {
  const ComponentWithUtils = forwardRef((props, ref) => {
    const { t } = useTranslation("common")

    const localeList = [
      {
        value: "af_ZA",
        text: t?.("locale_picker.af_ZA")
      },
      {
        value: "ar_AR",
        text: t?.("locale_picker.ar_AR")
      },
      {
        value: "as_IN",
        text: t?.("locale_picker.as_IN")
      },
      {
        value: "az_AZ",
        text: t?.("locale_picker.az_AZ")
      },
      {
        value: "be_BY",
        text: t?.("locale_picker.be_BY")
      },
      {
        value: "bg_BG",
        text: t?.("locale_picker.bg_BG")
      },
      {
        value: "bn_IN",
        text: t?.("locale_picker.bn_IN")
      },
      {
        value: "br_FR",
        text: t?.("locale_picker.br_FR")
      },
      {
        value: "bs_BA",
        text: t?.("locale_picker.bs_BA")
      },
      {
        value: "ca_ES",
        text: t?.("locale_picker.ca_ES")
      },
      {
        value: "cb_IQ",
        text: t?.("locale_picker.cb_IQ")
      },
      {
        value: "co_FR",
        text: t?.("locale_picker.co_FR")
      },
      {
        value: "cs_CZ",
        text: t?.("locale_picker.cs_CZ")
      },
      {
        value: "cx_PH",
        text: t?.("locale_picker.cx_PH")
      },
      {
        value: "cy_GB",
        text: t?.("locale_picker.cy_GB")
      },
      {
        value: "da_DK",
        text: t?.("locale_picker.da_DK")
      },
      {
        value: "de_DE",
        text: t?.("locale_picker.de_DE")
      },
      {
        value: "el_GR",
        text: t?.("locale_picker.el_GR")
      },
      {
        value: "en*",
        text: t?.("locale_picker.en*"),
        type: "group",
      },
      {
        value: "en_GB",
        text: t?.("locale_picker.en_GB"),
        type: "child",
      },
      {
        value: "en_UD",
        text: t?.("locale_picker.en_UD"),
        type: "child",
      },
      {
        value: "en_US",
        text: t?.("locale_picker.en_US"),
        type: "child",
      },
      {
        value: "es_ES",
        text: t?.("locale_picker.es_ES")
      },
      {
        value: "es_LA",
        text: t?.("locale_picker.es_LA")
      },
      {
        value: "et_EE",
        text: t?.("locale_picker.et_EE")
      },
      {
        value: "eu_ES",
        text: t?.("locale_picker.eu_ES")
      },
      {
        value: "fa_IR",
        text: t?.("locale_picker.fa_IR")
      },
      {
        value: "ff_NG",
        text: t?.("locale_picker.ff_NG")
      },
      {
        value: "fi_FI",
        text: t?.("locale_picker.fi_FI")
      },
      {
        value: "fo_FO",
        text: t?.("locale_picker.fo_FO")
      },
      {
        value: "fr*",
        text: t?.("locale_picker.fr*"),
        type: "group",
      },
      {
        value: "fr_CA",
        text: t?.("locale_picker.fr_CA"),
        type: "child",
      },
      {
        value: "fr_FR",
        text: t?.("locale_picker.fr_FR"),
        type: "child",
      },
      {
        value: "fy_NL",
        text: t?.("locale_picker.fy_NL")
      },
      {
        value: "ga_IE",
        text: t?.("locale_picker.ga_IE")
      },
      {
        value: "gl_ES",
        text: t?.("locale_picker.gl_ES")
      },
      {
        value: "gn_PY",
        text: t?.("locale_picker.gn_PY")
      },
      {
        value: "gu_IN",
        text: t?.("locale_picker.gu_IN")
      },
      {
        value: "ha_NG",
        text: t?.("locale_picker.ha_NG")
      },
      {
        value: "he_IL",
        text: t?.("locale_picker.he_IL")
      },
      {
        value: "hi_IN",
        text: t?.("locale_picker.hi_IN")
      },
      {
        value: "hr_HR",
        text: t?.("locale_picker.hr_HR")
      },
      {
        value: "hu_HU",
        text: t?.("locale_picker.hu_HU")
      },
      {
        value: "hy_AM",
        text: t?.("locale_picker.hy_AM")
      },
      {
        value: "is_IS",
        text: t?.("locale_picker.is_IS")
      },
      {
        value: "id_ID",
        text: t?.("locale_picker.id_ID")
      },
      {
        value: "it_IT",
        text: t?.("locale_picker.it_IT")
      },
      {
        value: "ja*",
        text: t?.("locale_picker.ja*"),
        type: "group",
      },
      {
        value: "ja_JP",
        text: t?.("locale_picker.ja_JP"),
        type: "child",
      },
      {
        value: "ja_KS",
        text: t?.("locale_picker.ja_KS"),
        type: "child",
      },
      {
        value: "jv_ID",
        text: t?.("locale_picker.jv_ID")
      },
      {
        value: "ka_GE",
        text: t?.("locale_picker.ka_GE")
      },
      {
        value: "kk_KZ",
        text: t?.("locale_picker.kk_KZ")
      },
      {
        value: "km_KH",
        text: t?.("locale_picker.km_KH")
      },
      {
        value: "kn_IN",
        text: t?.("locale_picker.kn_IN")
      },
      {
        value: "ko_KR",
        text: t?.("locale_picker.ko_KR")
      },
      {
        value: "ku_TR",
        text: t?.("locale_picker.ku_TR")
      },
      {
        value: "lt_LT",
        text: t?.("locale_picker.lt_LT")
      },
      {
        value: "lv_LV",
        text: t?.("locale_picker.lv_LV")
      },
      {
        value: "mg_MG",
        text: t?.("locale_picker.mg_MG")
      },
      {
        value: "mk_MK",
        text: t?.("locale_picker.mk_MK")
      },
      {
        value: "ml_IN",
        text: t?.("locale_picker.ml_IN")
      },
      {
        value: "mn_MN",
        text: t?.("locale_picker.mn_MN")
      },
      {
        value: "mr_IN",
        text: t?.("locale_picker.mr_IN")
      },
      {
        value: "ms_MY",
        text: t?.("locale_picker.ms_MY")
      },
      {
        value: "mt_MT",
        text: t?.("locale_picker.mt_MT")
      },
      {
        value: "my_MM",
        text: t?.("locale_picker.my_MM")
      },
      {
        value: "nb_NO",
        text: t?.("locale_picker.nb_NO")
      },
      {
        value: "ne_NP",
        text: t?.("locale_picker.ne_NP")
      },
      {
        value: "nl*",
        text: t?.("locale_picker.nl*"),
        type: "group",
      },
      {
        value: "nl_BE",
        text: t?.("locale_picker.nl_BE"),
        type: "child",
      },
      {
        value: "nl_NL",
        text: t?.("locale_picker.nl_NL"),
        type: "child",
      },
      {
        value: "nn_NO",
        text: t?.("locale_picker.nn_NO")
      },
      {
        value: "or_IN",
        text: t?.("locale_picker.or_IN")
      },
      {
        value: "pa_IN",
        text: t?.("locale_picker.pa_IN")
      },
      {
        value: "pl_PL",
        text: t?.("locale_picker.pl_PL")
      },
      {
        value: "ps_AF",
        text: t?.("locale_picker.ps_AF")
      },
      {
        value: "pt*",
        text: t?.("locale_picker.pt*"),
        type: "group",
      },
      {
        value: "pt_BR",
        text: t?.("locale_picker.pt_BR"),
        type: "child",
      },
      {
        value: "pt_PT",
        text: t?.("locale_picker.pt_PT"),
        type: "child",
      },
      {
        value: "qz_MM",
        text: t?.("locale_picker.qz_MM")
      },
      {
        value: "ro_RO",
        text: t?.("locale_picker.ro_RO")
      },
      {
        value: "ru_RU",
        text: t?.("locale_picker.ru_RU")
      },
      {
        value: "rw_RW",
        text: t?.("locale_picker.rw_RW")
      },
      {
        value: "sc_IT",
        text: t?.("locale_picker.sc_IT")
      },
      {
        value: "si_LK",
        text: t?.("locale_picker.si_LK")
      },
      {
        value: "sk_SK",
        text: t?.("locale_picker.sk_SK")
      },
      {
        value: "sl_SI",
        text: t?.("locale_picker.sl_SI")
      },
      {
        value: "so_SO",
        text: t?.("locale_picker.so_SO")
      },
      {
        value: "sq_AL",
        text: t?.("locale_picker.sq_AL")
      },
      {
        value: "sr_RS",
        text: t?.("locale_picker.sr_RS")
      },
      {
        value: "sv_SE",
        text: t?.("locale_picker.sv_SE")
      },
      {
        value: "sw_KE",
        text: t?.("locale_picker.sw_KE")
      },
      {
        value: "sz_PL",
        text: t?.("locale_picker.sz_PL")
      },
      {
        value: "ta_IN",
        text: t?.("locale_picker.ta_IN")
      },
      {
        value: "te_IN",
        text: t?.("locale_picker.te_IN")
      },
      {
        value: "tg_TJ",
        text: t?.("locale_picker.tg_TJ")
      },
      {
        value: "th_TH",
        text: t?.("locale_picker.th_TH")
      },
      {
        value: "tl_PH",
        text: t?.("locale_picker.tl_PH")
      },
      {
        value: "tr_TR",
        text: t?.("locale_picker.tr_TR")
      },
      {
        value: "tz_MA",
        text: t?.("locale_picker.tz_MA")
      },
      {
        value: "uk_UA",
        text: t?.("locale_picker.uk_UA")
      },
      {
        value: "ur_PK",
        text: t?.("locale_picker.ur_PK")
      },
      {
        value: "uz_UZ",
        text: t?.("locale_picker.uz_UZ")
      },
      {
        value: "vi_VN",
        text: t?.("locale_picker.vi_VN")
      },
      {
        value: "zh*",
        text: t?.("locale_picker.zh*"),
        type: "group",
      },
      {
        value: "zh_CN",
        text: t?.("locale_picker.zh_CN"),
        type: "child",
      },
      {
        value: "zh_HK",
        text: t?.("locale_picker.zh_HK"),
        type: "child",
      },
      {
        value: "zh_TW",
        text: t?.("locale_picker.zh_TW"),
        type: "child",
      }
    ]

    return (
      <WrappedComponent
        {...props}
        i18nLocaleList={localeList}
        ref={ref}
      />
    )
  })

  return ComponentWithUtils
}
