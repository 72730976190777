/** @jsxImportSource theme-ui */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsis } from "@fortawesome/free-solid-svg-icons"
import { Button } from "theme-ui"

const CollapseButton = ({
  onClick
}) => (
  <Button
    sx={{
      width: "20px",
      height: "20px",
      fontSize: "12px",
      background: "white",
      color: "gray5",
      boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.11)",
      cursor: "pointer",
    }}
    p={0}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faEllipsis} />
  </Button>
)

export default CollapseButton
