import React from "react"
import { useTranslation } from "react-i18next"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import Color from "color"

const LUM_THRESHOLD = 0.6

const LocationContainer = styled.div`
  width: 260px;
  border-radius: 8px;
  display: inline-block;
  overflow: hidden;
  line-height: 1.33;
  vertical-align: top;
  margin-bottom: 8px;
  overflow: hidden;
  vertical-align: top;
  white-space: pre-wrap;
  word-break: break-word;
  
  &.text {
    white-space: pre-wrap;
    word-break: break-word;
  }
  background-color: ${(props) => {
    if (props.isAgent) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return "#0084FF"
      }
      return props.color
    }
    return "white"
  }};
  color: ${(props) => {
    if (props.isAgent) {
      return "#ffffff"
    }
    return "#555"
  }};
`

const Flex = styled.a`
  display: flex;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  margin: 8px;
  text-decoration: none;

  >.text {
    color: ${(props) => props.withGoogleUrl ? props.theme.themeColor : props.theme.gray7};
    padding-left: 8px;
  }
  

  >.icon {
    color: ${(props) => {
    if (props.isPlaceholder) {
      return "#ababab"
    } else if (props.withGoogleUrl) {
      return props.theme.themeColor
    }
    return props.theme.gray5
  }};
    width: 24px;
    height: 24px;
  }
`

const TableLabel = styled.tr`
  font-weight: bold;
  min-width: 72px;
`

const Table = styled.div`
  margin: 8px;
  color:${(props) => props.theme.gray7};
  font-size: ${(props) => props.theme.textSm};
`

const Row = styled.div`
  display: flex;
  padding-bottom: 2px;
`

const Location = ({ data, isAgent, color }) => {
  const { t } = useTranslation("common")
  
  let modData = data?.payload || data
  let googleUrl = null
  if (modData?.coordinates?.lat || modData?.coordinates?.long || data.lat || data.long) {
    const params = `${modData?.coordinates?.lat || data.lat},${modData?.coordinates.long || data.long}`
    googleUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(params)}`
  }
  
  return (
    <LocationContainer isAgent={isAgent} color={color} >
      <Flex
        isPlaceholder={!modData.address && !modData.lat && !modData.long}
        href={googleUrl} target="_blank" rel="noopener noreferrer"
        withGoogleUrl={!!googleUrl}
      >
        <FontAwesomeIcon
          className="icon"
          icon={faMapMarker}
        />
        <div className="text" isPlaceholder={!modData.address && !modData.lat && !modData.long}>{modData.text || modData.name || "Location      "}</div>
      </Flex>
      {(modData.address || modData.lat || modData.long || modData?.coordinates?.lat || modData?.coordinates?.long) &&
        <Table>
          {modData.address &&
            <Row>
              <TableLabel>{t?.("message_types.location.address")}  </TableLabel>
              <span>{modData.address}</span>
            </Row>
          }
          {(modData?.lat || modData?.coordinates?.lat) &&
            <Row>
              <TableLabel>{t?.("message_types.location.latitude")}  </TableLabel>
              <span>{modData.lat || modData?.coordinates?.lat}</span>
            </Row>
          }
          {(modData?.long || modData?.coordinates?.long) &&
            <Row>
              <TableLabel>{t?.("message_types.location.longitude")}  </TableLabel>
              <span>{modData.long || modData?.coordinates?.long}</span>
            </Row>
          }
          {modData?.url &&
            <Row>
              <TableLabel>{t?.("message_types.location.url")}  </TableLabel>
              <a href={modData.url} target="_blank" rel="noopener noreferrer">{modData.url}</a>
            </Row>
          }
        </Table>
      }
    </LocationContainer>
  )
}

export default Location

