import React from "react"
import { withTranslation } from "react-i18next"
import styled from "@emotion/styled"
import Color from "color"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./QuickReplies.scss"

const LUM_THRESHOLD = 0.6

const QuickReplyButton = styled.button`
  color: ${(props) => {
    if (props.color) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return "#0084FF"
      }
      return props.color
    }
    return "#0084FF"
  }};
  flex-shrink: 0;
  background: white;
  border: ${(props) => {
    if (props.color) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return "1px solid #0084FF"
      }
      return `1px solid ${props.color}`
    }
    return "1px solid #0084FF"
  }};
  border-radius: 25px;
  margin-right: 10px;
  margin-bottom: 8px;
  max-width: calc(100% - 24px);
  text-align: left;
  padding: 3px 12px;
  outline: none;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  word-wrap:break-word;

  .icon {
    margin-right: 8px;
  }
`

class Quickreplies extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      widthOverflow: false
    }
  }

  componentDidMount() {
    if (_.get(this.container, "offsetWidth") && _.get(this.wrapper, "offsetWidth")) {
      if (this.container.offsetWidth - 32 <= this.wrapper.offsetWidth) {
        this.setState({
          widthOverflow: true
        })
      }
    }
  }

  render() {
    const { data } = this.props
    const t = this.props.t
    let style = {
      justifyContent: "center"
    }
    if (this.state.widthOverflow) {
      style.justifyContent = "flex-start"
    }

    return (
      <div className="quick-replies-template" ref={(c) => { this.container = c }}>
        <div style={style} className="quick-replies-container">
          <div className="quick-replies-wrapper" ref={(c) => { this.wrapper = c }}>
            {(_.get(data, "quickReplies") || []).map((quickReply, i) => {
              switch (quickReply.content_type) {
                case "text":
                  return (
                    <QuickReplyButton
                      key={i}
                      className="quick-reply-button"
                    >
                      {quickReply.title}
                    </QuickReplyButton>
                  )
                case "location":
                  return (
                    <QuickReplyButton
                      key={i}
                      className="quick-reply-button"
                    >
                      <FontAwesomeIcon
                        className="icon"
                        icon="map-marker"
                      />
                      <span>{t?.("message_types.quick_replies.location")}</span>
                    </QuickReplyButton>
                  )
                case "user_phone_number":
                  return (
                    <QuickReplyButton
                      key={i}
                      className="quick-reply-button"
                    >
                      <FontAwesomeIcon
                        className="icon"
                        icon="phone"
                      />
                      <span>{t?.("message_types.quick_replies.phone_number")}</span>
                    </QuickReplyButton>
                  )
                case "user_email":
                  return (
                    <QuickReplyButton
                      key={i}
                      className="quick-reply-button"
                    >
                      <FontAwesomeIcon
                        className="icon"
                        icon="envelope"
                      />
                      <span>{t?.("message_types.quick_replies.email")}</span>
                    </QuickReplyButton>
                  )
                default:
                  return (
                    <QuickReplyButton
                      key={i}
                      className="quick-reply-button"
                    >
                      {quickReply.title}
                    </QuickReplyButton>
                  )
              }
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation("common", { withRef: true })(Quickreplies)
