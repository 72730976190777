import React from "react"
import _ from "lodash"

import PhoneButton from "../Buttons/PhoneButton"
import PostBackButton from "../Buttons/PostBackButton"
import ShareButton from "../Buttons/ShareButton"
import UrlButton from "../Buttons/UrlButton"

import { detectEmailAndUrl } from "../util"
import { useState, useEffect } from "react"

import "./ButtonTemplate.scss"

const ButtonTemplate = ({ data }) => {
  const [messageText, setMessageText] = useState("")

  useEffect(() => {
    setMessageText(detectEmailAndUrl(data.text))
  }, [data.text])

  return (
    <div className="button-template">
      <div className="text">{messageText}</div>
      <div className="buttons">
        {(_.get(data, "buttons") || []).map((button, i) => {
          switch (button.type) {
            case "web_url":
              return (
                <UrlButton
                  key={i}
                  data={button}
                />
              )
            case "postback":
              return (
                <PostBackButton
                  key={i}
                  data={button}
                />
              )
            case "phone_number":
              return (
                <PhoneButton
                  key={i}
                />
              )
            case "element_share":
              return (
                <ShareButton
                  key={i}
                />
              )
            default:
              return (
                <PostBackButton
                  key={i}
                />
              )
          }
        })}
      </div>
    </div>
  )
}

export default ButtonTemplate
