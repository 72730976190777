import React, { useState, useEffect, useRef } from "react"
import i18n from "i18next"
import { initializei18n } from "../initializei18next"
import { isInStandaloneMode } from "../pages/Inbox/Inbox"
import { DEFAULT_LANGUAGE, LANG_QUERY_PARAM } from "../const"

const isEmbedded = !isInStandaloneMode()
initializei18n(isEmbedded)
// console.log("is embedded 1: ", isEmbedded)

const INITIAL_STATE = {
  preferredLanguage: DEFAULT_LANGUAGE,
  changeLanguage: () => {},
  loading: false,
}

export const LanguageContext = React.createContext(INITIAL_STATE)
LanguageContext.displayName = "Language Context"

export function LanguageProvider({ children, isEmbedded }) {
  const searchParams = isEmbedded && new URLSearchParams(window.location.search)

  const [preferredLanguage, setPreferredLanguage] = useState(
    isEmbedded
      ? (searchParams?.get(LANG_QUERY_PARAM) ?? DEFAULT_LANGUAGE)
      :  DEFAULT_LANGUAGE
  )
  const [loading, setLoading] = useState(false)
  const previousLanguage = useRef(
    isEmbedded
      ? (searchParams?.get(LANG_QUERY_PARAM) ?? DEFAULT_LANGUAGE)
      : DEFAULT_LANGUAGE
  )

  function changeLanguage(lng) {
    setLoading(true)
    i18n.changeLanguage(lng, (error) => {
      setLoading(false)
      setPreferredLanguage(lng)
      if (error) {
        console.error(error)
      }
    })
  }

  useEffect(() => {
    i18n.on("languageChanged", (lng) => {
      if (previousLanguage.current !== lng) {
        previousLanguage.current = lng
        if (isEmbedded) {
          changeLanguage(lng)
        } else {
          // TODO: update logic for standalone mode once ready, for now use english in standalone mode
          changeLanguage(DEFAULT_LANGUAGE)
        }
      }
    })
  }, [preferredLanguage])

  return (
    <LanguageContext.Provider
      value={{
        previousLanguage,
        preferredLanguage,
        changeLanguage,
        loading,
        setLoading,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

