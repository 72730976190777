/** @jsxImportSource theme-ui */

import { Box } from "theme-ui"

const Menu = ({
  children
}) => (
  <Box
    sx={{
      boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.11)",
      borderRadius: "4px",
      overflow: "auto",
    }}
  >
    {children}
  </Box>
)

export default Menu
