import React from "react"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Text, Flex, Button } from "theme-ui"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"

const FolderFollowUpActionModal = ({
  followUpInfo,
  onCancel,
  onSkip,
  onAssociate,
}) => {
  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "4px",
        overflow: "hidden",
        maxWidth: "40%",
        position: "relative",
      }}
    >
      <Flex
        sx={{
          p: 4,
          justifyContent: "space-between",
          borderBottom: "1px solid",
          borderColor: "gray1"
        }}
      >
        <Text
          sx={{
            color: "gray9",
            fontSize: "19px"
          }}
        >
          User Channel Mismatch
        </Text>
        <Button
          sx={{
            cursor: "pointer",
            p: 0,
            background: "transparent",
            color: "gray4",
            "&:hover": {
              color: "gray5",
            }
          }}
          onClick={() => {
            onCancel()
          }}
        >
          <FontAwesomeIcon
            icon={faXmark}
            fixedWidth
          />
        </Button>
      </Flex>
      <Box
        sx={{
          px: 4,
          py: 3,
        }}
      >
        <Text
          sx={{
            color: "gray7",
            fontSize: "14px",
          }}
        >
          <span>{`${followUpInfo?.length > 1 ? "These users are" : "This user is"} not associated with the following channel(s):`}</span>
          <br />
          <ul>
            {(followUpInfo || []).map((info) => (
              <li>
                <span>{`${info.email}: `}</span>
                <b>{info.missingChannelNames.join(", ")}</b>
              </li>
            ))}
          </ul>
          <br />
          To proceed, you can either:
          <br />
          <ul>
            <li>
              {`Associate the channel(s) with the ${followUpInfo?.length > 1 ? "users" : "user"}`}
            </li>
            <li>{`Skip and do not add ${followUpInfo?.length > 1 ? "these users" : "this user"} for now`}</li>
          </ul>
        </Text>
      </Box>
      
      <Flex
        sx={{
          justifyContent: "flex-end",
          borderTop: "1px solid",
          borderColor: "gray1",
        }}
      >
        <NewBoxButton
          text="Skip"
          primary
          inverted
          sx={{
            m: "12px",
            mr: 0,
          }}
          onClick={onSkip}
        />
        <NewBoxButton
          text="Associate Channels"
          primary
          inverted
          sx={{
            m: "12px",
          }}
          onClick={onAssociate}
        />
      </Flex>
    </Box>
  )
}

export default FolderFollowUpActionModal
