/**
 * @generated SignedSource<<c9ddd50e0a4d25900b5334ff432103a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateThreadsPayload",
    "kind": "LinkedField",
    "name": "updateThreadsFolder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FollowUpActionsInfo",
        "kind": "LinkedField",
        "name": "followUpActions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "channelNames",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "folderName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageThreadsPopperQuery_updateThreadsFolderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManageThreadsPopperQuery_updateThreadsFolderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9bdf16f93ead7179d46a559305729a51",
    "id": null,
    "metadata": {},
    "name": "ManageThreadsPopperQuery_updateThreadsFolderMutation",
    "operationKind": "mutation",
    "text": "mutation ManageThreadsPopperQuery_updateThreadsFolderMutation(\n  $input: UpdateThreadsFolderInput!\n) {\n  updateThreadsFolder(input: $input) {\n    clientMutationId\n    ok\n    followUpActions {\n      channelNames\n      folderName\n    }\n    error {\n      code\n      message\n    }\n  }\n}\n"
  }
};
})();

node.hash = "93e25ede9dd134358d4cd1b7adb02061";

module.exports = node;
