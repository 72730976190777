import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { lighten } from "polished"

const MINATURE_PREVIEW_SIZE = 50

export const GenericPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  min-height: 300px;
`
export const GenericPreviewText = styled.div`
  margin: 8px auto;
  color: ${(props) => props.theme.gray5};
  font-size: ${(props) => props.fontSize ? props.fontSize : "14px"};
  text-align: center;
`

export const Video = styled.video`
  margin: ${(props) => props.isMiniature ? "8px" : "16px auto"};
  border-radius: ${(props) => props.isMiniature ? "4px" : "8px"};
  max-height: 300px;
  align-self: center;
  &:hover {
    cursor: pointer;
  }
`

export const Image = styled.img`
  margin: ${(props) => props.isMiniature ? "8px" : "16px auto"};
  border-radius: ${(props) => props.isMiniature ? "4px" : "8px"};
  display: block;
  max-width: ${props => props.isMiniature ? "100%" : "calc(100% - 32px)"} ;
  max-height: ${(props) => props.isMiniature ? "20px" : "300px"};
  &:hover {
    cursor: pointer;
  }
`

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 16px;
  top: 16px;
  color: ${(props) => props.theme.gray2};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.gray4};
  }
`

export const MinaturePreviewsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  position: absolute;
  left: 50%;
  bottom: 8px;
  transform: translateX(-50%);
`

export const SingleMinaturePreviewWrapper = styled.div`
  width: ${MINATURE_PREVIEW_SIZE}px;
  height: ${MINATURE_PREVIEW_SIZE}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e7e8;
  border-radius: 4px;
  position: relative;
  &:hover {
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.gray2};
  }
`

export const RemovePreviewButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -4px;
  right: -4px;
  width: 12px;
  height: 12px;
  color: white;
  background-color: ${(props) => lighten(0.18, props.theme.error)};
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.error};
  }
`

export const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

export const AddMoreFilesWrapper = styled.div`
  width: ${MINATURE_PREVIEW_SIZE}px;
  height: ${MINATURE_PREVIEW_SIZE}px;
  border: 1px solid #e8e7e8;
  border-radius: 4px;
  display: flex;
  background-color: #f0f2f7;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.gray2};
  &:hover {
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    color: ${(props) => props.theme.gray4};
    background-color: ${(props) => props.theme.gray1};
  }
`
export const FilePreviewError = styled.div`
  width: ${MINATURE_PREVIEW_SIZE}px;
  height: ${MINATURE_PREVIEW_SIZE}px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Donut = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 0.20rem solid ${props => props.theme.gray2};
  border-top-color: ${props => props.theme.gray1};
  animation: 1.5s spin infinite linear;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

