/** @jsxImportSource theme-ui */

import React from "react"
import { withTranslation } from "react-i18next"
import _ from "lodash"
import { Box, Flex } from "theme-ui"
import moment from "moment"
import ReactMarkdown from "react-markdown"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons"

import { timestampNormalizer } from "../util"
import Raw, { RawMessage } from "../Raw/Raw"
import PopperContainer from "../../Radiate/PopperContainer/PopperContainer"
import PopupMenuCollapseButton from "../PopupMenu/CollapseButton"

const unreadColor = "#0063f00f"
const unreadHoverColor = "#0063f005"

const Row = styled.div`
  transition: background 0.1s linear;
  background: ${(props) => props.unread ? unreadColor : "transparent"};
  .popup-menu {
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    background: ${(props) => props.unread ? unreadHoverColor : "rgba(255, 255, 255, 0.5)"};
    .popup-menu {
      opacity: 1;
      pointer-events: all;
    }
  }
`

class IncomingWebhook extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openMenu: false
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !_.isEqual(this.props.message, nextProps.message)
      || this.props.readOnly !== nextProps.readOnly
      || this.props.isLast !== nextProps.isLast
      || this.props.noTimeDisplay !== nextProps.noTimeDisplay
      || this.props.readAt !== nextProps.readAt
      || this.props.deliveredAt !== nextProps.deliveredAt
      || this.props.failedAt !== nextProps.failedAt
      || this.props.chatId !== nextProps.chatId
      || this.props.error !== nextProps.error
    ) {
      return true
    }
    return false
  }

  renderMessage(t) {
    const {
      message,
      meta,
      error,
      noTimeDisplay,
      readAt,
      deliveredAt,
      failedAt,
      messageMenu,
      messageMenuStyle,
      messageMenuPlacement,
    } = this.props

    const timeInfo = {
      readAt,
      deliveredAt,
      failedAt
    }

    const accessoryDisplay = (
      <Flex
        sx={{
          alignItems: "center",
          position: "relative",
        }}
      >
        {error &&
          <>
            <FontAwesomeIcon
              className="error-icon"
              icon={faExclamationTriangle}
              data-tooltip-content={error}
              data-tooltip-id="chat-visualizer-tooltip"
              data-tooltip-place="right"
            />
          </>
        }
        {noTimeDisplay ?
          null
          :
          <div
            className="time-display"
            data-tooltip-content={(failedAt || readAt || deliveredAt) ? JSON.stringify(timeInfo) : null}
            data-tooltip-id="chat-visualizer-tooltip"
            data-tt-type="time-display"
            data-tooltip-place="right"
          >
            {timestampNormalizer(message.timestamp) ? moment(timestampNormalizer(message.timestamp)).format("HH:mm") : null}
          </div>
        }
        {messageMenu && (
          <PopperContainer
            className="popup-menu"
            display={(
              <PopupMenuCollapseButton
                onClick={() => {
                  this.setState({
                    openMenu: true
                  })
                }}
              />
            )}
            sx={messageMenuStyle}
            placement={messageMenuPlacement}
            show={this.state.openMenu}
            onClickOutside={() => {
              this.setState({
                openMenu: false
              })
            }}
            addDefaultWrapper={false}
          >
            {() => messageMenu}
          </PopperContainer>
        )}
      </Flex>
    )

    if (message?.fallback) {
      return (
        <RawMessage
          backgroundColor={message?.style?.backgroundColor}
          textColor={message?.style?.textColor}
          borderless={message?.style?.borderless ?? false}
        >
          <Flex
            className="text header"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>{meta?.__source__?.integrationId ?? t?.("message_types.incoming_webhook.incoming_webhook")}</Box>
            {accessoryDisplay}
          </Flex>
          <Box
            px={2}
            py={message?.style?.markdown ? 0 : 3}
            sx={{
              overflow: "auto"
            }}
          >
            {message?.style?.markdown ?
              (
                <ReactMarkdown
                  components={{
                    // eslint-disable-next-line jsx-a11y/heading-has-content
                    h1: ({ node, ...props }) => <h1 style={{ fontSize: "1.7em" }} {...props} />,
                  }}
                  linkTarget="_blank"
                >
                  {message.fallback}
                </ReactMarkdown>
              )
              :
              message.fallback
            } 
          </Box>
          
        </RawMessage>
      )
    } else {
      return (
        <Raw
          data={message}
          header={meta?.__source__?.integrationId ?? t?.("message_types.incoming_webhook.incoming_webhook")}
          timestamp={message?.timestamp}
        />
      )
    }
  }

  render() {
    const t = this.props.t
    const {
      message,
      unread,
    } = this.props

    let customPosition
    switch(message?.style?.position) {
      case "BOT":
        customPosition = "flex-start"
        break
      case "USER":
        customPosition = "flex-end"
        break
      case "CENTER":
        customPosition = "center"
        break
      default:
        customPosition = "center"
    }
    
    return (
      <Row
        className="row incoming-webhook"
        sx={{
          justifyContent: customPosition
        }}
        unread={unread}
        onMouseLeave={() => {
          this.setState({
            openMenu: false
          })
        }}
      >
        <div
          className="content-info"
          sx={{
            position: "relative",
            flexDirection: customPosition === "center" ? "column" : "row",
            alignItems: customPosition === "center" ? "center !important" : "flex-end",
          }}
        >
          <div
            className="content"
          >
            {this.renderMessage(t)}
          </div>
        </div>
      </Row>
    )
  }
}

export default withTranslation("common", { withRef: true })(IncomingWebhook)
