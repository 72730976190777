import React from "react"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import PhoneButton from "../Buttons/PhoneButton"
import PostBackButton from "../Buttons/PostBackButton"
import ShareButton from "../Buttons/ShareButton"
import UrlButton from "../Buttons/UrlButton"

import "./Media.scss"

function mediaContent(data) {
  if (data.url) {
    switch (data.media_type) {
      case "video":
        return (
          <video src={data.url || _.get(data, "attachments[0].url")} controls>
          </video>
        )
      case "image":
        return (
          <img src={data.url || _.get(data, "attachments[0].url")} alt="" />
        )
      default:
        return (
          <div>MEDIA URL: <a href={data.url || _.get(data, "attachments[0].url")} target="_blank" rel="noopener noreferrer">{data.url || _.get(data, "attachments[0].url")}</a></div>
        )
    }
  } else if (data.attachment_id) {
    return (
      <div className="placeholder">
        <FontAwesomeIcon
          className="icon"
          icon="image"
        />
        <span>Media Attachment ID: {data.attachment_id}</span>
      </div>
    )
  } else {
    return (
      <div className="placeholder">
        <FontAwesomeIcon
          className="icon"
          icon="image"
        />
        <span>Media cannot be loaded</span>
      </div>
    )
  }
}

const Media = ({ data }) => {
  const { buttons } = data
  return (
    <div className="media-template">
      <div className="media">
        {mediaContent(data)}
      </div>
      {_.get(buttons, "length", 0) > 0 ?
        <div className="buttons-container">
          {buttons.map((button, i) => {
            switch (button.type) {
              case "web_url":
                return (
                  <UrlButton
                    key={i}
                    data={button}
                  />
                )
              case "postback":
                return (
                  <PostBackButton
                    key={i}
                    data={button}
                  />
                )
              case "phone_number":
                return (
                  <PhoneButton
                    key={i}
                    data={button}
                  />
                )
              case "element_share":
                return (
                  <ShareButton
                    key={i}
                    data={button}
                  />
                )
              default:
                return (
                  <PostBackButton
                    key={i}
                    data={button}
                  />
                )
            }
          })}
        </div>
        :
        null
      }
    </div>
  )
}

export default Media
