import {
  faEllipsisH,
  faTrash,
  faVolumeLow,
} from "@fortawesome/free-solid-svg-icons"
import { useState, useCallback, useEffect } from "react"
import { useRef } from "react"
import {
  PlayPauseButton,
  CancelRecordingButton,
} from "../AudioRecorder/AudioRecorderStyles"
import * as Styles from "./AudioPlayerStyles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const convertSecondsToMMSSformat = (timeInSeconds) => {
  const totalSeconds = parseInt(timeInSeconds, 10)
  let minutes = Math.floor(totalSeconds / 60)
  let seconds = totalSeconds - minutes * 60

  if (minutes < 10) {
    minutes = "0" + minutes
  }
  if (seconds < 10) {
    seconds = "0" + seconds
  }
  return +minutes + ":" + seconds
}

function getBlobDuration({
  blobDuration,
  fallbackDuration,
}) {
  if (blobDuration !== Infinity) {
    return blobDuration
  }
  if (fallbackDuration !== Infinity) {
    return fallbackDuration
  }
  return Infinity
}


const ProgressBar = ({
  fallbackDuration,
  setTimeProgress,
  timeProgress,
  percentComplete,
  audioRef,
  setPercentComplete,
  blobDuration,
}) => {

  const duration = getBlobDuration({
    blobDuration,
    fallbackDuration,
  })
  const handleProgressChange = (e) => {
    if (!audioRef?.current) return

    audioRef.current.currentTime =
      (parseFloat(e.target.value) * duration) / 100
    setPercentComplete(e.target.value)
    setTimeProgress((parseFloat(e.target.value) * duration) / 100)
  }

  return (
    <Styles.MaxWidthContainer>
      <Styles.InputRangeWrapper $percentComplete={percentComplete}>
        <span style={{ padding: "0 2px" }}>
          {convertSecondsToMMSSformat(Math.floor(timeProgress))}
        </span>
        <input
          type="range"
          value={percentComplete}
          onChange={handleProgressChange}
        />
        <span style={{ padding: "0 2px" }}>
          {duration !== Infinity && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {convertSecondsToMMSSformat(Math.floor(duration))}
            </div>
          )}
          {duration === Infinity && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faEllipsisH}
                style={{
                  color: "#bbbbbc",
                  fontSize: "16px"
                }}
              />
            </div>
          )}
        </span>
      </Styles.InputRangeWrapper>
    </Styles.MaxWidthContainer>
  )
}

const AudioTrack = ({
  src,
  audioRef,
  setIsPlaying,
  setTimeProgress,
  fallbackDuration,
  setPercentComplete,
  setAudioDuration,
  blobDuration,
}) => {
  const duration = getBlobDuration({
    blobDuration,
    fallbackDuration,
  })
  const playAnimationRef = useRef()

  const updateCurrentTime = useCallback(() => {
    if (!audioRef?.current) return
    const currentTime = audioRef.current.currentTime
    const deltaBetweenCurrentTimeAndDuration = Math.abs(duration - currentTime)
    const QUARTER_SECOND = 0.25
    setTimeProgress(currentTime)
    if (duration === Infinity) {
      setPercentComplete(0)
    } else {
      setPercentComplete(
        deltaBetweenCurrentTimeAndDuration < QUARTER_SECOND
          ? 100
          : (currentTime / duration) * 100,
      )
    }
    playAnimationRef.current = requestAnimationFrame(updateCurrentTime)
  }, [audioRef, duration, setPercentComplete, setTimeProgress])

  return (
    <audio
      ref={audioRef}
      src={src}
      onDurationChange={(e) => {
        const duration = audioRef?.current?.duration
        if (duration !== Infinity) {
          setAudioDuration(duration)
        }
      }}
      onPause={() => {
        setIsPlaying(false)
        cancelAnimationFrame(playAnimationRef.current)
      }}
      onEnded={() => {
        setIsPlaying(false)
        cancelAnimationFrame(playAnimationRef.current)
      }}
      onPlay={() => {
        setIsPlaying(true)
        playAnimationRef.current = requestAnimationFrame(updateCurrentTime)
      }}
    />
  )
}

const Controls = ({ audioRef, isPlaying }) => {
  const togglePlayPause = () => {
    if (audioRef?.current) {
      if (audioRef.current.paused) {
        audioRef?.current.play()
      } else {
        audioRef?.current.pause()
      }
    }
  }

  return (
    <PlayPauseButton
      onClick={() => togglePlayPause()}
      $isPaused={!isPlaying}
      $isRecording
    />
  )
}

const CancelRecording = ({ cancelRecording }) => {
  return (
    <div
      onClick={() => {
        cancelRecording()
      }}
      style={{
        padding: "4px",
      }}
    >
      <CancelRecordingButton
        className="icon"
        icon={faTrash}
        fixedWidth
        fontSize="18px"
      />
    </div>
  )
}

const VolumeControls = ({
  volume,
  setVolume,
  audioRef,
  showVolumeControls,
  setShowVolumeControls,
}) => {
  return (
    <Styles.ShowVolumeControlsWrapper>
      <Styles.ShowVolumeControlsIconWrapper
        $showVolumeControls={showVolumeControls}
        onClick={() => {
          setShowVolumeControls(prev => !prev)
        }}
      >
        <Styles.ShowVolumeControlsIcon icon={faVolumeLow} fixedWidth />
      </Styles.ShowVolumeControlsIconWrapper>
      {showVolumeControls && (
        <VolumeSlider
          volume={volume}
          setVolume={setVolume}
          audioRef={audioRef}
        />
      )}
    </Styles.ShowVolumeControlsWrapper>
  )
}

const VolumeSlider = ({ volume, setVolume, audioRef }) => {
  const handleVolumeChange = (e) => {
    setVolume(parseInt(e.target.value))
    if (audioRef?.current) {
      audioRef.current.volume = volume / 100
    }
  }
  return (
    <Styles.VolumeSliderWrapper>
      <Styles.InputRangeWrapper>
        <input
          style={{
            background: "#707070",
          }}
          type="range"
          min={0}
          max={100}
          value={volume}
          onChange={handleVolumeChange}
        />
      </Styles.InputRangeWrapper>
    </Styles.VolumeSliderWrapper>
  )
}

export const AudioPlayer = ({ src, cancelRecording, blobDuration }) => {
  const progressBarRef = useRef()
  const audioRef = useRef()
  const [audioRefPassDown, setAudioRefPassDown] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [timeProgress, setTimeProgress] = useState(0)
  const [percentComplete, setPercentComplete] = useState(0)
  const [volume, setVolume] = useState(60)
  const [showVolumeControls, setShowVolumeControls] = useState(false)
  const [audioDuration, setAudioDuration] = useState(Infinity)

  useEffect(() => {
    if (audioRef?.current) {
      setAudioRefPassDown(audioRef)
    }
  }, [audioRef])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Styles.AudioPlayerWrapper>
        <Controls audioRef={audioRefPassDown} isPlaying={isPlaying} />
        <AudioTrack
          src={src}
          audioRef={audioRef}
          setIsPlaying={setIsPlaying}
          setTimeProgress={setTimeProgress}
          setPercentComplete={setPercentComplete}
          progressBarRef={progressBarRef}
          fallbackDuration={audioDuration}
          blobDuration={blobDuration}
          setAudioDuration={setAudioDuration}
        />
        <ProgressBar
          isPlaying={isPlaying}
          fallbackDuration={audioDuration}
          progressBarRef={progressBarRef}
          audioRef={audioRefPassDown}
          timeProgress={timeProgress}
          setTimeProgress={setTimeProgress}
          percentComplete={percentComplete}
          setPercentComplete={setPercentComplete}
          blobDuration={blobDuration}
        />
        <Styles.VolumeAndCancelButtonsWrapper>
          <VolumeControls
            volume={volume}
            setVolume={setVolume}
            audioRef={audioRefPassDown}
            showVolumeControls={showVolumeControls}
            setShowVolumeControls={setShowVolumeControls}
          />
          <CancelRecording cancelRecording={cancelRecording} />
        </Styles.VolumeAndCancelButtonsWrapper>
      </Styles.AudioPlayerWrapper>
    </div>
  )
}
