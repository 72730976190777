import React from "react"
import { createRoot } from "react-dom/client"
import * as Sentry from "@sentry/react"
import { create } from "zustand"

import "./index.css"
import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

// import reportWebVitals from './reportWebVitals';
if (process.env.NODE_ENV === "production" && process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: "https://edb00ae2a6f34e409368881361d98d53@o4505452019777536.ingest.sentry.io/4505452023316480",
    integrations: [
      new Sentry.Replay(),
    ],
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENV,
    enableTracing: false,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

export const useSWStore = create((set) => ({
  swInitialized: false,
  swUpdated: false,
  swRegistration: null,
}))

const root = createRoot(document.getElementById("root"))
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)

serviceWorkerRegistration.register({
  onSuccess: reg => useSWStore.setState({ swInitialized: true, swRegistration: reg }),
  onUpdate: reg => useSWStore.setState({ swUpdated: true, swRegistration: reg }),
})
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
