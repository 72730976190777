import styled from "@emotion/styled"

const Tag = styled.div`
  display: inline-block;
  white-space: nowrap;
  padding: 4px 6px;
  border-radius: 4px;
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  font-weight: normal;
  font-size: 0.7rem;
`

export default Tag
