/**
 * @generated SignedSource<<32a1914138afabbeb91f73d5ccec6b4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "externalId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wChannelId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wMemberId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withoutNavHint"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = {
  "kind": "Variable",
  "name": "externalId",
  "variableName": "externalId"
},
v7 = {
  "kind": "Variable",
  "name": "wChannelId",
  "variableName": "wChannelId"
},
v8 = {
  "kind": "Variable",
  "name": "wMemberId",
  "variableName": "wMemberId"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InboxNavHintRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "kind": "Variable",
                "name": "withoutNavHint",
                "variableName": "withoutNavHint"
              }
            ],
            "kind": "FragmentSpread",
            "name": "Inbox_navHintViewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "InboxNavHintRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v9/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "condition": "withoutNavHint",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "concreteType": "Thread",
                    "kind": "LinkedField",
                    "name": "thread",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "threadId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "folderId",
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "type": "UserScope",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9045b3119c9d13c7c3a214a1121e1828",
    "id": null,
    "metadata": {},
    "name": "InboxNavHintRefetchQuery",
    "operationKind": "query",
    "text": "query InboxNavHintRefetchQuery(\n  $externalId: ID\n  $wChannelId: ID\n  $wMemberId: ID\n  $withoutNavHint: Boolean!\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...Inbox_navHintViewer_Hkq7h\n    id\n  }\n}\n\nfragment Inbox_navHintViewer_Hkq7h on UserScope {\n  thread(wMemberId: $wMemberId, externalId: $externalId, wChannelId: $wChannelId) @skip(if: $withoutNavHint) {\n    threadId\n    folderId\n    id\n  }\n  id\n}\n"
  }
};
})();

node.hash = "0c7c45ffbca192b923424986308275cd";

module.exports = node;
