/** @jsxImportSource theme-ui */

import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFileLines,
  faXmark,
  faPlus,
  faExclamationTriangle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import * as Styles from "./ConversationHistoryPreviewStyles"
import TooltipIcon from "../TooltipIcon/TooltipIcon"

export const MAX_FILES_TO_UPLOAD = 10
const COMMONLY_SUPPORTED_IMAGE_FORMATS = ["jpg", "jpeg", "png", "gif", "bmp", "webp"]
const COMMONLY_SUPPORTED_VIDEO_FORMATS = ["ogg", "mp4", "webm"]

function getFileFormatFromBlob(blob) {
  return blob.substring(blob.indexOf("/") + 1, blob.indexOf(";")).toLowerCase()
}

function MinatureGenericPreview() {
  return (
    <FontAwesomeIcon
      className="icon"
      icon={faFileLines}
      size="lg"
      fixedWidth
      style={{
        color: "#ccc"
      }}
    />
  )
}

function GenericPreview({ previewFileExtension, isMiniature = false, previewName }) {
  const { t } = useTranslation("common")
  if (isMiniature) {
    return <MinatureGenericPreview />
  }
  return (
    <Styles.GenericPreviewContainer>
      <FontAwesomeIcon
        className="icon"
        icon={faFileLines}
        size={"8x"}
        fixedWidth
        style={{
          color: "#ccc"
        }}
      />
      <div>
        <Styles.GenericPreviewText fontSize="16px">{t?.("file_upload.no_preview_available")}</Styles.GenericPreviewText>
        <Styles.GenericPreviewText fontSize="11.2px" style={{ maxWidth: "400px", marginBottom: 0 }}>{previewName}</Styles.GenericPreviewText>
        <Styles.GenericPreviewText fontSize="11.2px" style={{ marginTop: 0 }}>{previewFileExtension.toUpperCase()} {t?.("file_upload.file")}</Styles.GenericPreviewText>
      </div>
    </Styles.GenericPreviewContainer>
  )
}

function VideoPreview({ previewBlob, previewType, isMiniature = false }) {
  return (
    <Styles.Video
      isMiniature={isMiniature}
      controls={isMiniature ? false : true}
      autoPlay={false}
      src={previewBlob}
      type={previewType}
      width={isMiniature ? "60%" : "80%"}
      height="auto"
    />
  )
}

function ImagePreview({ previewBlob, previewName, isMiniature = false }) {
  return (
    <Styles.Image
      src={previewBlob}
      alt={`Preview of ${previewName}`}
      width="auto"
      height="auto"
      isMiniature={isMiniature}
    />
  )
}

function SingleFilePreview({ previewBlob, previewType, previewName, previewFileExtension, isMiniature = false }) {
  if (!previewBlob || !previewType || !previewName) {
    return null
  }
  const fileFormat = getFileFormatFromBlob(previewBlob)
  if (previewType.startsWith("image") && COMMONLY_SUPPORTED_IMAGE_FORMATS.includes(fileFormat)) {
    return <ImagePreview key={previewName} previewBlob={previewBlob} previewName={previewName} isMiniature={isMiniature} />
  }
  else if (previewType.startsWith("video") && COMMONLY_SUPPORTED_VIDEO_FORMATS.includes(fileFormat)) {
    return <VideoPreview key={previewName} previewBlob={previewBlob} previewName={previewName} isMiniature={isMiniature} />
  }
  return <GenericPreview key={previewName} previewFileExtension={previewFileExtension} isMiniature={isMiniature} previewName={previewName} />
}

function RemovePreviewsButton({ setPreviews, setFilesToUpload }) {
  return (
    <Styles.StyledFontAwesomeIcon
      className="icon"
      icon={faXmark}
      size={"xl"}
      fixedWidth
      onClick={() => {
        setPreviews(null)
        setFilesToUpload(null)
      }}
    />
  )
}

async function convertFilesToBlob(files) {
  const promisesOfFiles = Array.from(files).map(file => {
    return new Promise(resolve => {
      let fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.addEventListener("load", () => {
        resolve({
          blob: fileReader.result,
          name: file.name,
          size: file.size,
          type: file.type,
          fileExtension: file.name.split(".").pop()
        })
      })
    })
  })
  return Promise.all(promisesOfFiles)
}


export function FileAttachmentsPreview({
  previews,
  setPreviews,
  filesToUpload,
  setFilesToUpload,
  isUploading,
  failedToUploadError,
  setFailedToUploadError,
  tooManyFilesAttachedError,
  setTooManyFilesAttachedError
}) {
  const { t } = useTranslation("common")
  const [focusedPreview, setFocusedPreview] = useState(previews && previews.length > 0 ? previews[previews.length - 1] : [])
  const addMoreFilesRef = useRef(null)
  const [removePreviewButton, setRemovePreviewButton] = useState(null)

  // focus the preview on the most recently added file
  useEffect(() => {
    if (previews && previews.length > 0) {
      setFocusedPreview(previews[previews.length - 1])
    }
  }, [previews.length])

  if (isUploading) {
    return (
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "16px", height: "450px", color: "black" }}>
        <Styles.Donut />
      </div>
    )
  }
  
  let errorText = ""

  if (failedToUploadError.error &&  tooManyFilesAttachedError.error) {
    errorText = `${failedToUploadError.error ? failedToUploadError.message : ""} <br /> ${tooManyFilesAttachedError.error ? tooManyFilesAttachedError.message : ""}`
  } else if (failedToUploadError.error) {
    errorText = failedToUploadError.message
  } else {
    errorText = tooManyFilesAttachedError.message
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "16px", height: "450px" }}>
      <RemovePreviewsButton setPreviews={setPreviews} setFilesToUpload={setFilesToUpload} />
      <div style={{ display: "flex" }}>
        {previews.length > 0
          ?
          <SingleFilePreview
            previewBlob={focusedPreview.blob}
            previewFileExtension={focusedPreview.fileExtension}
            previewType={focusedPreview.type}
            previewName={focusedPreview.name}
          />
          :
          <Styles.GenericPreviewText>{t?.("file_upload.no_files_attached")}</Styles.GenericPreviewText>
        }
      </div>
      <Styles.MinaturePreviewsWrapper>
        {previews.length > 0 && previews.map(p =>
          // minature single file previews
          <Styles.SingleMinaturePreviewWrapper
            onClick={() => {
              const selectedPreview = previews.find(preview => preview.name === p.name)
              setFocusedPreview(selectedPreview)
            }}
            key={p?.name}
            onMouseEnter={() => {
              setRemovePreviewButton(p.name)
            }}
            onMouseLeave={() => {
              setRemovePreviewButton(null)
            }}
          >
            <>
              <SingleFilePreview
                previewBlob={p.blob}
                previewType={p.type}
                previewName={p.name}
                previewFileExtension={p.fileExtension}
                isMiniature
              />

              {
                removePreviewButton === p.name &&
                <Styles.RemovePreviewButtonWrapper
                  onClick={() => {
                    setPreviews(prevPreviews => prevPreviews.filter(preview => preview.name !== p.name))
                    setFilesToUpload(prevFiles => prevFiles.filter(file => file.name !== p.name))
                  }}
                >
                  <FontAwesomeIcon
                    className="icon"
                    icon={faTimes}
                    size="2xs"
                    fixedWidth
                  />
                </Styles.RemovePreviewButtonWrapper>
              }
            </>
          </Styles.SingleMinaturePreviewWrapper>
        )}
        <Styles.AddMoreFilesWrapper
          disabled={previews.length >= MAX_FILES_TO_UPLOAD}
          onClick={e => {
            if (previews.length < MAX_FILES_TO_UPLOAD) {
              addMoreFilesRef?.current.click()
            }
          }}>
          <label htmlFor="upload-another-attachment" style={{
            cursor: previews.length >= MAX_FILES_TO_UPLOAD ? "not-allowed" : "pointer",
            pointerEvents: previews.length >= MAX_FILES_TO_UPLOAD ? "none" : "auto"
          }}>
            <FontAwesomeIcon
              className="icon"
              icon={faPlus}
              fixedWidth
              size="xl"
              onClick={e => {
                e.stopPropagation()
              }}
            />
            <Styles.FileInput
              type="file"
              id="upload-another-attachment"
              multiple
              ref={addMoreFilesRef}
              style={{
                display: "none"
              }}
              onChange={async (e) => {
                const failed = [...Array.from(e.target.files).filter(f => f.size > 20.48 * 1000 * 1000)]
                const selected = [...Array.from(e.target.files).filter(f => f.size <= 20.48 * 1000 * 1000)]
                if (failed?.length > 0) {
                  setFailedToUploadError({ error: true, message: `${t?.("file_upload.error_failed_to_upload_large_files")}: ${failed.map(f => f.name).join(", ")}` })
                } else {
                  setFailedToUploadError({ error: false, message: "" })
                }

                const newFilesNotAlreadyAttached = Array.from(selected).filter(newFile => !filesToUpload.find(f => f.name === newFile.name))
                setFilesToUpload(prevFiles => [...prevFiles, ...newFilesNotAlreadyAttached].slice(0, MAX_FILES_TO_UPLOAD))

                if ([...filesToUpload, ...newFilesNotAlreadyAttached].length > MAX_FILES_TO_UPLOAD) {
                  setTooManyFilesAttachedError({ error: true, message: t?.("file_upload.error_can_only_upload_max_files_at_a_time", { count: MAX_FILES_TO_UPLOAD }) })
                } else {
                  setTooManyFilesAttachedError({ error: false, message: "" })
                }

                const newFilePreviews = await convertFilesToBlob(newFilesNotAlreadyAttached)
                setPreviews(prevPreviews => [...prevPreviews, ...newFilePreviews].slice(0, MAX_FILES_TO_UPLOAD))

                // reset the input so that the same file can be uploaded again
                e.target.value = null
              }}
            />
          </label>
        </Styles.AddMoreFilesWrapper>
        {(failedToUploadError?.error || tooManyFilesAttachedError.error) &&
          <Styles.FilePreviewError>
            <TooltipIcon
              fontAwesomeIconName={faExclamationTriangle}
              fontAwesomeIconColor={"#942b2b"}
              iconText={errorText}
              backgroundColor={"#942b2b"}
              multiline={failedToUploadError?.error && tooManyFilesAttachedError.error}
            />
          </Styles.FilePreviewError>
        }
      </Styles.MinaturePreviewsWrapper>
    </div>
  )
}
