import React, { useEffect, useTransition } from "react"
import graphql from "babel-plugin-relay/macro"
import styled from "@emotion/styled"
import { Flex, Text } from "theme-ui"
import {
  useQueryLoader,
  usePreloadedQuery,
  usePaginationFragment
} from "react-relay"

import { AsyncPaginate } from "react-select-async-paginate"
import Select from "react-select"
import IntegrationIconDisplay from "../../components/IntegrationIconDisplay/IntegrationIconDisplay"

import { useRef } from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"

const PickerWrapper = styled.div`
  position: relative;

  >.overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    cursor: inherit;
    display: flex;
    justify-content:  center;
    z-index: 9999;
  }
`

const LoadingContainer = styled.div`
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  background: #f1f0f1;
  opacity: 0.4;
  width: 100%;

  .dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #999999;
    animation: anim 1.5s infinite;
    margin-right: 2px;

    &:nth-of-type(2) {
      animation-delay: 0.1s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.2s;
    }
  }

  @keyframes anim {
    0% {
      transform: scale(1);
      opacity:1;
    }
    50% {
      transform: scale(0.1);
      opacity: 0.5;
    }

    100% {
      transform: scale(1);
      opacity:1;
    }
  }
`

const ChannelPickerQuery = graphql`
  query ChannelPickerQuery ($wChannelId: ID!, $hasSelectedChannel: Boolean!) {
    userViewer {
      ...ChannelPicker_userViewer @arguments(
        wChannelId: $wChannelId
        hasSelectedChannel: $hasSelectedChannel
      )
    }
  }
`

const InnerContainer = ({
  queryRef,
  ...props
}) => {
  const data = usePreloadedQuery(
    ChannelPickerQuery,
    queryRef,
  )
  return (
    <InnerChannelPicker
      data={data}
      {...props}
    />
  )
}
const InnerChannelPicker = ({
  queryRef,
  onChange,
  wChannelId,
  onReceiveFirstChannel,
  isDisabled,
  ...props
}) => {
  // const [channelId, setChannelId] = useState(selectedChannelId)
  const firstLoadedData = useRef(false)
  const initialQueryLoading = useRef(true)
  const [loading, startTransition] = useTransition()
  const { t } = useTranslation("common")

  const {
    data,
    loadNext,
    hasNext,
    refetch
  } = usePaginationFragment(
    graphql`
      fragment ChannelPicker_userViewer on UserScope
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
        wChannelId: { type: "ID!" }
        hasSelectedChannel: { type: "Boolean!" }
      )
      @refetchable(queryName: "ChannelPickerPaginateQuery") {
        channels (
          first: $first, 
          after: $after
        ) @connection(key: "ChannelPicker_channels") {
          edges {
            node {
              id
              wChannelId
              name
              integrationName
              integrationIcon
            }
          }
        }
        channel (wChannelId: $wChannelId) @include(if: $hasSelectedChannel)  {
          id
          wChannelId
          name
          integrationName
          integrationIcon
        }
      }
    `,
    props.data.userViewer
  )
  // console.log("data", data)

  useEffect(() => {
    if (wChannelId) {
      // console.log("going to refetch", wChannelId)
      startTransition(() => {
        refetch({
          wChannelId: wChannelId,
          hasSelectedChannel: true
        }, {
          fetchPolicy: "network-only",
        })
      })
    }
  }, [wChannelId, refetch])

  const channels = (data?.channels?.edges ?? []).map(o => o.node)

  useEffect(() => {
    if (!firstLoadedData.current && _.isArray(data?.channels?.edges)) {
      firstLoadedData.current = true
      initialQueryLoading.current = false
      if (onReceiveFirstChannel) {
        const processedEdges = data.channels.edges.map(o => o.node)
        onReceiveFirstChannel(processedEdges?.[0] ?? {})
      }
    }
  }, [JSON.stringify(data?.channels?.edges), onReceiveFirstChannel])

  const loadOptions = async (search, loadedOptions, additional) => {
    // console.log("search", search)
    if (additional && additional.search === search) {
      // console.log("load next in loadOptions")
      loadNext()
    } else {
      // console.log("refetch in loadOptions")
      if (!initialQueryLoading.current) {
        refetch({
          wChannelId: wChannelId,
          hasSelectedChannel: !!wChannelId
        }, {
          fetchPolicy: "network-only",
        })
      }
    }
    // console.log("data", data)
    return {
      options: channels.map(o => ({
        value: o.wChannelId,
        label: (
          <Flex sx={{ alignItems: "center" }}>
            <IntegrationIconDisplay
              style={{ marginLeft: "8px" }}
              icon={o?.integrationIcon}
              name={o?.integrationName}
              size="20px"
            />
            <Text pl={1}>{o.name}</Text>
          </Flex>
        )
      })),
      hasMore: hasNext,
      additional: {
        search
      }
    }
  }
  let selectedValue = null
  if (data?.channel) {
    selectedValue = {
      label: (
        <Flex sx={{ alignItems: "center" }}>
          <IntegrationIconDisplay
            style={{ marginLeft: "8px" }}
            icon={data?.channel?.integrationIcon}
            name={data?.channel?.integrationName}
            size="20px"
          />
          <Text pl={1}>{data?.channel?.name}</Text>
        </Flex>
      ),
      value: data.channel.id
    }
  }
  return (
    <PickerWrapper className={props.className}>
      {!!loading &&
        <div className="overlay">
          <LoadingContainer className="loading-container">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </LoadingContainer>
        </div>
      }
      <AsyncPaginate
        isDisabled={isDisabled}
        isSearchable={props.isSearchable}
        value={selectedValue}
        loadOptions={loadOptions}
        menuPortalTarget={props.menuPortalTarget}
        defaultOptions
        reduceOptions={(p, n) => n}
        menuShouldScrollIntoView={true}
        cacheUniqs={(channels ?? []).map(o => o.wChannelId)}
        onChange={(value) => {
          onChange(value.value)
        }}
        placeholder={t?.("channel_picker.placeholder_select_channel")}
        styles={{
          container: styles => ({
            ...styles,
            backgroundColor: "#ffffff"
            // width: "400px"
          }),
          menu: styles => ({
            ...styles,
            fontSize: "0.85rem"
          }),
          option: styles => ({
            ...styles,
            fontSize: "0.85rem"
          }),
          control: styles => ({
            ...styles,
            fontSize: "0.85rem"
          }),
          ...(props?.customStyles || {})
        }}
      />
    </PickerWrapper>
    
  )
}

const ChannelPicker = (props) => {
  const [
    queryReference,
    loadQuery,
  ] = useQueryLoader(
    ChannelPickerQuery,
    props.initialQueryRef, /* e.g. provided by router */
  )
  useEffect(() => {
    loadQuery({
      hasSelectedChannel: !!props.selectedChannelId,
      wChannelId: props.selectedChannelId || ""
    })
  }, [])

  const { t } = useTranslation("common")

  if (queryReference) {
    // const Inner = queryRenderer(InnerChannelPicker, {
    //   queryRef: queryReference,
    //   query: ChannelPickerQuery
    // })
    return (
      <InnerContainer
        queryRef={queryReference}
        wChannelId={props.selectedChannelId}
        onChange={props.onChange}
        onReceiveFirstChannel={props.onReceiveFirstChannel}
        customStyles={props.customStyles}
        className={props.className}
        menuPortalTarget={props.menuPortalTarget}
        isSearchable={props.isSearchable}
        isDisabled={props.isDisabled}
      />
    )
  }
  return (
    <PickerWrapper className={props.className}>
      <Select
        placeholder={t?.("channel_picker.placeholder_select_channel")}
        isDisabled
        isSearchable={props.isSearchable}
        menuPortalTarget={props.menuPortalTarget}
        styles={{
          menu: styles => ({
            ...styles,
            fontSize: "0.85rem"
          }),
          option: styles => ({
            ...styles,
            fontSize: "0.85rem"
          }),
          control: styles => ({
            ...styles,
            fontSize: "0.85rem"
          }),
          ...(props?.customStyles || {})
        }}
      />
    </PickerWrapper>
  )
}

export default ChannelPicker
