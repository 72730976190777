import React, { useState } from "react"
import {
  usePaginationFragment
} from "react-relay"
import graphql from "babel-plugin-relay/macro"
import {
  faChevronDown,
  faCircleNotch
} from "@fortawesome/free-solid-svg-icons"

import ChannelRow from "./ChannelRow"
import * as  Styles from "./SettingsModalStyles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import theme from "../../stylesheets/theme"
import { useTranslation } from "react-i18next"

const SettingsChannelsContainer = ({
  ownUserId,
  isAdmin,
  isInitialAdmin,
  ...props
}) => {
  const {
    data,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(
    graphql`
      fragment SettingsChannelsContainer_userViewer on UserScope
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
      )
      @refetchable(queryName: "SettingsChannelsContainerPaginateQuery") {
        woztellChannels (
          first: $first, 
          after: $after
        ) @connection(key: "SettingsChannelsContainer_woztellChannels") {
          edges {
            node {
              wChannelId
              integrationIcon
              integrationName
              name
              connected
              etag
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
          }
        }
      }
    `,
    props.data
  )

  const { t } = useTranslation(["settings", "common"])

  const [error, setError] = useState("")

  return (
    <Styles.SettingsChannelsContainer>
      <Styles.ErrorContainer>
        {error && <div className="error">{error}</div>}
      </Styles.ErrorContainer>
      <table>
        <Styles.RowTHead>
          <tr>
            <td className="status">{t?.("channels_settings.STATUS_table_header")}</td>
            <td className="channel">{t?.("channels_settings.CHANNELS_table_header")}</td>
            <td className="connect"></td>
          </tr>
        </Styles.RowTHead>
        {(data?.woztellChannels?.edges || [])?.map((channel) => (
          <ChannelRow
            channel={channel?.node}
            onError={(errMsg) => {
              setError(errMsg)
            }}
          />
        ))}
        {hasNext &&
          <tr>
            <Styles.LoadMoreButton
              colSpan="3" 
              style={{  }}
              onClick={() => {
                loadNext(10, {
                  onComplete: (err) => {
                   console.log(err)
                  }
                })
              }}
            >
              {isLoadingNext ?
                <FontAwesomeIcon
                  style={{ color: theme.gray6 }}
                  icon={faCircleNotch}
                  spin
                  fixedWidth
                />
                :
                <Styles.LoadMoreText>
                  <FontAwesomeIcon
                    style={{ paddingRight: "8px" }}
                    icon={faChevronDown}
                    fixedWidth
                  />
                  {t?.("common:buttons.load_more")}
                </Styles.LoadMoreText>
              }
            </Styles.LoadMoreButton>
          </tr>
        }
      </table>
    </Styles.SettingsChannelsContainer>
  )
}

export default SettingsChannelsContainer
