import React from "react"
import { useTranslation } from "react-i18next"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Text, Flex, Button } from "theme-ui"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"

const FollowUpActionModal = ({
  targetFolderName,
  mismatchChannels = [],
  onCancel,
  onSkip,
  onAssociate,
  role,
}) => {
  const { t } = useTranslation("common")
  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "4px",
        overflow: "hidden",
        maxWidth: "40%",
        position: "relative",
      }}
    >
      <Flex
        sx={{
          p: 4,
          justifyContent: "space-between",
          borderBottom: "1px solid",
          borderColor: "gray1"
        }}
      >
        <Text
          sx={{
            color: "gray9",
            fontSize: "19px"
          }}
        >
          {t?.("channel_folder_mismatch.channel_folder_mismatch")}
        </Text>
        <Button
          sx={{
            cursor: "pointer",
            p: 0,
            background: "transparent",
            color: "gray4",
            "&:hover": {
              color: "gray5",
            }
          }}
          onClick={() => {
            onCancel()
          }}
        >
          <FontAwesomeIcon
            icon={faXmark}
            fixedWidth
          />
        </Button>
      </Flex>
      <Box
        sx={{
          px: 4,
          py: 3,
        }}
      >
        <Text
          sx={{
            color: "gray7",
            fontSize: "14px",
          }}
        >
          <span>{t?.("channel_folder_mismatch.threads_belong_to", { count: mismatchChannels?.length })} </span>
          <b>{mismatchChannels.join(", ")}</b>
          <span> {t?.("channel_folder_mismatch.cannot_be_moved_to_the_folder")} </span>
          <b>{targetFolderName}</b>
          <span> {t?.("channel_folder_mismatch.because_the_folder_is_not_associated_with_these_channels")}.</span>
          <br />
          <br />
          {role === "ADMIN" || role === "SUPER_ADMIN" ? (
            <>
              {t?.("channel_folder_mismatch.to_proceed_you_can_either")}:
              <br />
              <ul>
                <li>
                  <span>{t?.("channel_folder_mismatch.associate_channels", { count: mismatchChannels?.length })} </span>
                  <b>{mismatchChannels.join(", ")}</b>
                  <span> {t?.("channel_folder_mismatch.with")} </span>
                  <b>{targetFolderName}</b>
                </li>
                <li>{t?.("channel_folder_mismatch.skip_moving_threads_for_now")}</li>
              </ul>
            </>
          ) : (
            <>
              <span>{t?.("channel_folder_mismatch.to_proceed_you_can_skip_moving_threads")}.</span>
              <br />
              <br />
            </>
          )}

          <i>{t?.("channel_folder_mismatch.if_you_skip_threads_remain_in_current_folder")}.</i>
        </Text>
      </Box>
      
      <Flex
        sx={{
          justifyContent: "flex-end",
          borderTop: "1px solid",
          borderColor: "gray1",
        }}
      >
        <NewBoxButton
          text={t?.("channel_folder_mismatch.skip_button")}
          primary
          inverted
          sx={{
            m: "12px",
          }}
          onClick={onSkip}
        />
        {role === "ADMIN" || role === "SUPER_ADMIN" ? (
          <NewBoxButton
            text={t?.("channel_folder_mismatch.associate_channels_button")}
            primary
            inverted
            sx={{
              m: "12px",
              ml: 0,
            }}
            onClick={onAssociate}
          />
        ) : null}
      </Flex>
    </Box>
  )
}

export default FollowUpActionModal
