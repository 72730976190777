import React, { useState, useEffect, useTransition, useRef } from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import graphql from "babel-plugin-relay/macro"
import { AsyncPaginate } from "react-select-async-paginate"
import Select from "react-select"
import styled from "@emotion/styled"
import { Flex, Text } from "theme-ui"
import {
  useQueryLoader,
  usePreloadedQuery,
  usePaginationFragment
} from "react-relay"

import IntegrationIconDisplay from "../../components/IntegrationIconDisplay/IntegrationIconDisplay"

const PickerWrapper = styled.div`
  position: relative;

  >.overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    cursor: inherit;
    display: flex;
    justify-content:  center;
    z-index: 998;
  }
`

const LoadingContainer = styled.div`
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  background: #f1f0f1;
  opacity: 0.4;
  width: 100%;

  .dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #999999;
    animation: anim 1.5s infinite;
    margin-right: 2px;

    &:nth-of-type(2) {
      animation-delay: 0.1s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.2s;
    }
  }

  @keyframes anim {
    0% {
      transform: scale(1);
      opacity:1;
    }
    50% {
      transform: scale(0.1);
      opacity: 0.5;
    }

    100% {
      transform: scale(1);
      opacity:1;
    }
  }
`

const ChannelsPickerQuery = graphql`
  query ChannelsPickerQuery ($wChannelIds: [ID]!, $hasSelectedChannels: Boolean!) {
    userViewer {
      ...ChannelsPicker_userViewer @arguments(
        wChannelIds: $wChannelIds
        hasSelectedChannels: $hasSelectedChannels
      )
    }
  }
`

const InnerContainer = ({
  queryRef,
  preSelectFirstOption,
  ...props
}) => {
  const data = usePreloadedQuery(
    ChannelsPickerQuery,
    queryRef,
  )
  return (
    <InnerChannelsPicker
      data={data}
      {...props}
    />
  )
}
const InnerChannelsPicker = ({
  queryRef,
  onChange,
  wChannelIds,
  placeholder,
  className,
  showAllChannels,
  isDisabled,
  components,
  styles,
  preSelectFirstOption,
  ...props
}) => {
  const firstLoaded = useRef(false)
  const initialQueryLoading = useRef(true)
  const [selectedChannels, setSelectedChannels] = useState(wChannelIds)
  const [loading, startTransition] = useTransition()
  const { t } = useTranslation("common")

  const {
    data,
    loadNext,
    hasNext,
    refetch
  } = usePaginationFragment(
    graphql`
      fragment ChannelsPicker_userViewer on UserScope
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
        wChannelIds: { type: "[ID]!" }
        hasSelectedChannels: { type: "Boolean!" }
      )
      @refetchable(queryName: "ChannelsPickerPaginateQuery") {
        channels (
          first: $first, 
          after: $after
        ) @connection(key: "ChannelsPicker_channels") {
          edges {
            node {
              id
              wChannelId
              name
              integrationName
              integrationIcon
            }
          }
        }
        channelsMulti (wChannelIds: $wChannelIds) @include(if: $hasSelectedChannels)  {
          id
          wChannelId
          name
          integrationName
          integrationIcon
        }
      }
    `,
    props.data.userViewer
  )
  // console.log("data", data)

  useEffect(() => {
    if (_.isArray(wChannelIds)) {
      const newSelectedChannels = (wChannelIds || []).map((id) => {
        const target = selectedChannels.find((o) => {
          if (typeof o === "string") {
            return o === id
          } else {
            return o.value === id
          }
        })
        if (target) {
          return target
        }
        return id
      })
      setSelectedChannels(newSelectedChannels)
    } else {
      setSelectedChannels([])
    }
  }, [JSON.stringify(wChannelIds)])

  useEffect(() => {
    if (selectedChannels?.length) {
      if (showAllChannels) {
        const channelsNeedToFetch = (selectedChannels || []).filter(o => typeof o === "string")
        if (channelsNeedToFetch?.length) {
          // console.log("channels refetch")
          startTransition(() => {
            refetch({
              wChannelIds: channelsNeedToFetch || [],
              hasSelectedChannels: true
            }, {
              fetchPolicy: "network-only",
            })
          })
        }
      }
    } else {
      setSelectedChannels([])
    }
  }, [JSON.stringify(selectedChannels), refetch, showAllChannels])
  useEffect(() => {
    const newSelectedChannels = (selectedChannels || []).map((o) => {
      if (typeof o === "string") {
        const targetChannel = (data?.channelsMulti || []).find(c => c.wChannelId === o)
        if (targetChannel) {
          return {
            label: (
              <Flex sx={{ alignItems: "center" }}>
                <IntegrationIconDisplay
                  style={{ marginLeft: "8px" }}
                  icon={targetChannel?.integrationIcon}
                  name={targetChannel?.integrationName}
                  size="20px"
                />
                <Text pl={1}>{targetChannel.name}</Text>
              </Flex>
            ),
            value: targetChannel.wChannelId
          }
        }
      }
      return o
    })
    setSelectedChannels(newSelectedChannels)
  }, [JSON.stringify(data?.channelsMulti)])

  const channels = (data?.channels?.edges ?? []).map(o => o.node)

  useEffect(() => {
    if (!firstLoaded.current && _.isArray(data?.channels?.edges)) {
      firstLoaded.current = true
      initialQueryLoading.current = false
      if (preSelectFirstOption) {
        const firstChannel = data?.channels?.edges[0]?.node
          setSelectedChannels([{
            label: (
              <Flex sx={{ alignItems: "center" }}>
                <IntegrationIconDisplay
                  icon={firstChannel?.integrationIcon}
                  name={firstChannel?.integrationName}
                  size="20px"
                />
                <Text pl={1}>{firstChannel.name}</Text>
              </Flex>
            ),
            value: firstChannel.wChannelId
          }])
        onChange([`${firstChannel?.wChannelId}`], {
          label: (
            <Flex sx={{ alignItems: "center" }}>
              <IntegrationIconDisplay
                icon={firstChannel?.integrationIcon}
                name={firstChannel?.integrationName}
                size="20px"
              />
              <Text pl={1}>{firstChannel.name}</Text>
            </Flex>
          ),
          value: firstChannel.wChannelId
        })
      }

    }
  }, [JSON.stringify(data?.channels?.edges), preSelectFirstOption])

  const getColor = (isSelected, isFocused, isDisabled) => {
    if (isDisabled) {
      return "#cccccc"
    }
    if (isSelected && isFocused) {
      return "#cccccc"
    } else if (isSelected) {
      return "#cccccc"
    }
    return "#3c3c3c"
  }

  const generateDefaultOptionStyles = (styles, { isSelected, isFocused, isDisabled }) => ({
    ...styles,
    color: getColor(isSelected, isFocused, isDisabled),
    backgroundColor: isFocused && !(isDisabled || isSelected) ? "#ecf3ff" : "#ffffff",
    fontSize: "0.85rem",
    ":active": {
      ...styles[":active"],
    },

    ":hover": {
      color: isSelected && "#cccccc",
    }
  })

  const loadOptions = async (search, loadedOptions, additional) => {
    // console.log("search", search)
    if (additional && additional.search === search) {
      // console.log("load next in loadOptions")
      loadNext()
    } else {
      // console.log("refetch in loadOptions")
      if (!initialQueryLoading.current) {
        refetch({
          wChannelIds: wChannelIds || []
        }, {
          fetchPolicy: "network-only",
        })
      }
    }
    
    return {
      options: channels.map(o => ({
        value: o.wChannelId,
        label: (
          <Flex sx={{ alignItems: "center" }}>
            <IntegrationIconDisplay
              icon={o?.integrationIcon}
              name={o?.integrationName}
              size="20px"
            />
            <Text pl={1}>{o.name}</Text>
          </Flex>
        ),
      })),
      hasMore: hasNext,
      additional: {
        search
      }
    }
  }
  let selectedValue = (selectedChannels || []).filter(o => typeof o !== "string")
  // console.log("selectedValue", selectedValue)
  if (!showAllChannels && selectedChannels?.length > 1) {
    selectedValue = [{
      label: `${selectedChannels.length} channels selected`,
      value: "CHANNELS_SELECTED"
    }]
  }
  const isHydratingData = selectedChannels?.length > 0 && _.isEmpty(selectedValue) && data?.channelsMulti?.length
  return (
    <PickerWrapper className={className}>
      {(!!loading || isHydratingData) &&
        <div className="overlay">
          <LoadingContainer>
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </LoadingContainer>
        </div>
      }
      <AsyncPaginate
        isMulti
        isClearable={selectedValue?.[0]?.value !== "CHANNELS_SELECTED" && selectedChannels?.length > 1}
        isSearchable={false}
        value={selectedValue}
        loadOptions={loadOptions}
        isOptionDisabled={(option) => {
          const selectedIndex = (selectedChannels || []).findIndex((c) => {
            if (typeof c === "string") {
              return c === option?.value
            } else {
              return c?.value === option?.value
            }
          })
          return selectedIndex > -1
        }}
        // components={!showAllChannels ? { ValueContainer, MultiValueContainer } : null}
        defaultOptions
        reduceOptions={(p, n) => n}
        menuShouldScrollIntoView={true}
        hideSelectedOptions={false}
        cacheUniqs={(channels ?? []).map(o => o.wChannelId)}
        onChange={(value) => {
          if (value) {
            if (!showAllChannels && selectedChannels?.length > 1) {
              setSelectedChannels((prev) => {
                let next = [...prev, ...value]
                next = next.filter((o => o.value !== "CHANNELS_SELECTED"))
                const data = next.map(o => o.value)
                onChange(data, next)
                return next
              })
            } else {
              setSelectedChannels(value)
              const data = value.map(o => o.value)
              onChange(data, value)
            }
          } else {
            setSelectedChannels([])
            onChange([])
          }
        }}
        placeholder={placeholder ?? t?.("placeholders.select")}
        styles={{
          multiValue: (defaultStyles) => ({
            ...defaultStyles,
            height: "26px",
            alignItems: "center"
          }),
          multiValueRemove: (defaultStyles) => ({
            ...defaultStyles,
            alignSelf: "stretch"
          }),
          menu: styles => ({
            ...styles,
            fontSize: "0.85rem"
          }),
          option: generateDefaultOptionStyles,
          control: styles => ({
            ...styles,
            fontSize: "0.85rem",
            backgroundColor: isDisabled && "#f8f8f8"
          }),
          container: styles => ({
            ...styles,
            backgroundColor: "#ffffff"
          }),
          ...styles
        }}
        components={components}
        isDisabled={isDisabled}
      />
    </PickerWrapper>
    
  )
}

const ChannelsPicker = (props) => {
  const [
    queryReference,
    loadQuery,
  ] = useQueryLoader(
    ChannelsPickerQuery,
    props.initialQueryRef, /* e.g. provided by router */
  )
  useEffect(() => {
    loadQuery({
      hasSelectedChannels: !!props.selectedChannelIds?.length,
      wChannelIds: props.selectedChannelIds || [],
    })
  }, [])

  if (queryReference) {
    return (
      <InnerContainer
        queryRef={queryReference}
        wChannelIds={props.selectedChannelIds}
        onChange={props.onChange}
        placeholder={props.placeholder}
        className={props.className}
        showAllChannels={props.showAllChannels}
        isDisabled={props.isDisabled}
        components={props.components}
        styles={props.styles}
        preSelectFirstOption={props.preSelectFirstOption}
      />
    )
  }
  
  return (
    <Select
      placeholder={props.placeholder}
      components={props?.components}
      isDisabled
      styles={{
        container: styles => ({
          ...styles,
          width: "100%"
        }),
        menu: styles => ({
          ...styles,
          fontSize: "0.85rem"
        }),
        option: styles => ({
          ...styles,
          fontSize: "0.85rem"
        }),
        control: styles => ({
          ...styles,
          fontSize: "0.85rem"
        })
      }}
    />
  )
}

export default ChannelsPicker
