import styled from "@emotion/styled"
import PopperContainer from "../Radiate/PopperContainer/PopperContainer"
import RadioSelectField from "../Radiate/RadioSelectField/RadioSelectField"

export const FolderInfoContainer = styled.div`
  width: 320px;

  >.label {
    color: ${(props) => props.theme.gray6};
    padding: ${(props) => props.$embedded ? "16px": "8px"};
    font-size: ${(props) => props.theme.textSm};
    font-weight: bold;
  }

  >.separator {
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.gray1};
  }

  .error {
    margin-top: 8px;
    font-size: 0.7rem;
    color: ${(props) => props.theme.error};
    text-align: center;
  }
`

export const StyledPopperContainer = styled(PopperContainer)`
  height: 22px;
`

export const StyledRadioSelectField = styled(RadioSelectField)`
  .radio-button-option-wrapper {
    padding-left: 0;
  }
`