import styled from "@emotion/styled"

import Creatable from "react-select/creatable"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"

export const InnerMemberContainer = styled.div`
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.gray7};
  overflow-y: scroll;


  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  .member-email {
    margin: 8px 0;
  }
`

export const TextM = styled.div`
  padding-top: 8px;
  font-size: ${(props) => props.theme.textMd};
  display: flex;
  text-align: center;
  font-weight: ${(props) => props.memberName && "600"};
`

export const TextS = styled.div`
  padding-top: 8px;
  font-size: ${(props) => props.theme.textSm};
  display: flex;
  text-align: center;
  font-weight: ${(props) => props.memberName && "600"};
`

export const StyledCreatable = styled(Creatable)`
  width: 100%;
  margin-top: 2px;

`

export const TagsContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.gray1};
  border-bottom: 1px solid ${(props) => props.theme.gray1};
  padding: 28px 0;

  .title-container {
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    .title {
      font-weight: bold;
      font-size: ${(props) => props.theme.textSm};
    }

    .error {
      font-size: ${(props) => props.theme.textSm};
      color: ${(props) => props.theme.error};
    }
  }


`
export const NotesContainer = styled.div`
  width: 100%;
  padding: 16px 0;

  >.title-container {
    >.title {
      font-weight: bold;
      font-size: ${(props) => props.theme.textSm};
    }
  }
`

export const NoteContainer = styled.div`
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 16px;
  padding: ${(props) => {
    if (props.isWrittenByMe) {
      return "8px 12px" 
    }
    return "25px 12px 8px 12px"
    }
  };

  &:last-child {
    border-bottom: none;
  }

  >.buttons-container {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 8px;

    .save-button {
      margin-left: 8px;
    }
  }

  .content {
    color: ${props => props.theme.gray9};
    font-size: ${(props) => props.theme.textSm};
    white-space: pre-wrap;
    word-break: break-word;
    line-height: 1.3;
  }

  .author {
    font-size: 0.7rem;
    line-height: 34px;
  }
`

export const EditButton = styled(NewBoxButton)`
  position: absolute ;
  right: -12px;
  top: -8px;
`

export const MemberInfo = styled.div`
  padding-bottom: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  >.edit-dropdown-button {
    position: absolute ;
    right: -12px;
    top: -8px;
  }
`

export const CloseMemberInfoButton = styled(NewBoxButton)`
  position: absolute ;
  left: -12px;
  top: -8px;
`

export const EmailRow = styled.tr`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.textSm};
  padding-bottom: 4px;
  cursor: pointer;

  &:last-child {
    padding-bottom: 0px;
  }

  >.icon {
    padding-right: 8px;
  }

`

export const ChannelRow = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.textSm};

  .channel-name {
    padding-left: 4px;
  }
`
