import React, { useEffect, useTransition } from "react"
import graphql from "babel-plugin-relay/macro"
import styled from "@emotion/styled"
import {
  useQueryLoader,
  usePreloadedQuery,
  usePaginationFragment
} from "react-relay"

import { AsyncPaginate } from "react-select-async-paginate"
import Select from "react-select"

import { useRef } from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"

const PickerWrapper = styled.div`
  position: relative;

  >.overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    cursor: inherit;
    display: flex;
    justify-content:  center;
    z-index: 9999;
  }
`

const LoadingContainer = styled.div`
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  background: #f1f0f1;
  opacity: 0.4;
  width: 100%;

  .dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #999999;
    animation: anim 1.5s infinite;
    margin-right: 2px;

    &:nth-of-type(2) {
      animation-delay: 0.1s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.2s;
    }
  }

  @keyframes anim {
    0% {
      transform: scale(1);
      opacity:1;
    }
    50% {
      transform: scale(0.1);
      opacity: 0.5;
    }

    100% {
      transform: scale(1);
      opacity:1;
    }
  }
`

const AppPickerQuery = graphql`
  query AppPickerQuery ($wAppId: ID!, $hasSelectedApp: Boolean!) {
    userViewer {
      ...AppPicker_userViewer @arguments(
        wAppId: $wAppId
        hasSelectedApp: $hasSelectedApp
      )
    }
  }
`

const InnerContainer = ({
  queryRef,
  ...props
}) => {
  const data = usePreloadedQuery(
    AppPickerQuery,
    queryRef,
  )
  return (
    <InnerAppPicker
      data={data}
      {...props}
    />
  )
}
const InnerAppPicker = ({
  queryRef,
  onChange,
  wAppId,
  onFirstReceive,
  ...props
}) => {
  const firstLoadedData = useRef(false)
  const initialQueryLoading = useRef(true)
  const [loading, startTransition] = useTransition()

  const {
    data,
    loadNext,
    hasNext,
    refetch
  } = usePaginationFragment(
    graphql`
      fragment AppPicker_userViewer on UserScope
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
        wAppId: { type: "ID!" }
        hasSelectedApp: { type: "Boolean!" }
      )
      @refetchable(queryName: "AppPickerPaginateQuery") {
        appsPaginated (
          first: $first, 
          after: $after
        ) @connection(key: "AppPicker_appsPaginated") {
          edges {
            node {
              id
              wAppId
              name
            }
          }
        }
        app (wAppId: $wAppId) @include(if: $hasSelectedApp)  {
          id
          wAppId
          name
        }
      }
    `,
    props.data.userViewer
  )
  // console.log("data", data)

  useEffect(() => {
    if (wAppId) {
      // console.log("going to refetch", wAppId)
      startTransition(() => {
        refetch({
          wAppId: wAppId,
          hasSelectedApp: true
        }, {
          fetchPolicy: "network-only",
        })
      })
    }
  }, [wAppId, refetch])

  const apps = (data?.appsPaginated?.edges ?? []).map(o => o.node)

  useEffect(() => {
    if (!firstLoadedData.current && _.isArray(data?.appsPaginated?.edges)) {
      firstLoadedData.current = true
      initialQueryLoading.current = false
      if (onFirstReceive) {
        const processedEdges = data.appsPaginated.edges.map(o => o.node)
        onFirstReceive(processedEdges?.[0] ?? {})
      }
    }
  }, [JSON.stringify(data?.appsPaginated?.edges), onFirstReceive])

  const loadOptions = async (search, loadedOptions, additional) => {
    // console.log("search", search)
    if (additional && additional.search === search) {
      // console.log("load next in loadOptions")
      loadNext()
    } else {
      // console.log("refetch in loadOptions")
      if (!initialQueryLoading.current) {
        refetch({
          wAppId: wAppId,
          hasSelectedApp: !!wAppId
        }, {
          fetchPolicy: "network-only",
        })
      }
    }
    // console.log("data", data)
    return {
      options: apps.map(o => ({
        value: o.wAppId,
        label: o.name
      })),
      hasMore: hasNext,
      additional: {
        search
      }
    }
  }
  let selectedValue = null
  if (data?.app) {
    selectedValue = {
      label: data?.app?.name,
      value: data.app.id
    }
  }
  const { t } = useTranslation("common")
  return (
    <PickerWrapper className={props.className}>
      {!!loading &&
        <div className="overlay">
          <LoadingContainer className="loading-container">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </LoadingContainer>
        </div>
      }
      <AsyncPaginate
        isSearchable={props.isSearchable}
        value={selectedValue}
        loadOptions={loadOptions}
        menuPortalTarget={props.menuPortalTarget}
        defaultOptions
        reduceOptions={(p, n) => n}
        menuShouldScrollIntoView={true}
        cacheUniqs={(apps ?? []).map(o => o.wAppId)}
        onChange={(value) => {
          onChange(value.value)
        }}
        placeholder={t?.("app_picker.placeholder_select_app")}
        styles={{
          container: styles => ({
            ...styles,
            backgroundColor: "#ffffff"
            // width: "400px"
          }),
          menu: styles => ({
            ...styles,
            fontSize: "0.85rem"
          }),
          option: styles => ({
            ...styles,
            fontSize: "0.85rem"
          }),
          control: styles => ({
            ...styles,
            fontSize: "0.85rem"
          }),
          ...(props?.customStyles || {})
        }}
      />
    </PickerWrapper>
    
  )
}

const AppPicker = (props) => {
  const [
    queryReference,
    loadQuery,
  ] = useQueryLoader(
    AppPickerQuery,
    props.initialQueryRef, /* e.g. provided by router */
  )
  useEffect(() => {
    loadQuery({
      hasSelectedApp: !!props.selectedAppId,
      wAppId: props.selectedAppId || ""
    })
  }, [])

  const { t } = useTranslation("common")

  if (queryReference) {
    // const Inner = queryRenderer(InnerAppPicker, {
    //   queryRef: queryReference,
    //   query: AppPickerQuery
    // })
    return (
      <InnerContainer
        queryRef={queryReference}
        wAppId={props.selectedAppId}
        onChange={props.onChange}
        onFirstReceive={props.onFirstReceive}
        customStyles={props.customStyles}
        className={props.className}
        menuPortalTarget={props.menuPortalTarget}
        isSearchable={props.isSearchable}
      />
    )
  }
  return (
    <PickerWrapper className={props.className}>
      <Select
        placeholder={t?.("app_picker.placeholder_select_app")}
        isDisabled
        isSearchable={props.isSearchable}
        menuPortalTarget={props.menuPortalTarget}
        styles={{
          menu: styles => ({
            ...styles,
            fontSize: "0.85rem"
          }),
          option: styles => ({
            ...styles,
            fontSize: "0.85rem"
          }),
          control: styles => ({
            ...styles,
            fontSize: "0.85rem"
          }),
          ...(props?.customStyles || {})
        }}
      />
    </PickerWrapper>
  )
}

export default AppPicker
