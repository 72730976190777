import React from "react"
import _ from "lodash"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import PhoneButton from "../Buttons/PhoneButton"
import PostBackButton from "../Buttons/PostBackButton"
import ShareButton from "../Buttons/ShareButton"
import UrlButton from "../Buttons/UrlButton"

import { detectEmailAndUrl } from "../util"

import "./Carousel.scss"
import "./slick/slick.css";
import "./slick/slick-theme.css";

function carouselItemImage(item, isSquare) {
  if (_.get(item, "image_url.length") <= 0) {
    return null
  }
  if (_.get(item, "default_action.url.length") > 0) {
    const defaultAction = item.default_action
    return (
      <a
        className={`image-container ${isSquare ? "square" : ""}`}
        href={defaultAction.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className="image"
          style={{
            backgroundImage: `url("${item.image_url}")`
          }}
        ></div>
      </a>
    )
  }
  return (
    <div
      className={`image-container ${isSquare ? "square" : ""}`}
    >
      <div
        className="image"
        style={{
          backgroundImage: `url("${item.image_url}")`
        }}
      ></div>
    </div>
  )
}

class Carousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      carouselArray: []
    }
  }

  componentDidMount() {
    if (_.get(this.props, "data.carousel")) {
      const array = (_.get(this.props.data, "carousel") || []).map((item, i) => {
        let carouselObj = item
        return { ...carouselObj, modTitle: detectEmailAndUrl(item.title) }
      })
      this.setState({
        carouselArray: array
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(_.get(prevProps, "data.carousel"), _.get(this.props, "data.carousel"))) {
      const array = (_.get(this.props.data, "carousel") || []).map((item, i) => {
        let carouselObj = item
        return { ...carouselObj, modTitle: detectEmailAndUrl(item.title) }
      })
      this.setState({
        carouselArray: array
      })
    }
  }

  render() {
    const { data, itemWidth = 300 } = this.props

    const settings = {
      dots: false,
      adaptiveHeight: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      infinite: false,
      arrows: false,
    }

    return (
      <div className="carousel-template">
        {_.get(data, "carousel.length", 0) > 1 ?
          <div
            className="arrow next"
            onClick={() => {
              this.slider.slickNext()
            }}
          >
            <FontAwesomeIcon
              className="icon"
              icon={faCaretRight}
              fixedWidth
            />
          </div>
          :
          null
        }
        {_.get(data, "carousel.length", 0) > 1 ?
          <div
            className="arrow prev"
            onClick={() => {
              this.slider.slickPrev()
            }}
          >
            <FontAwesomeIcon
              className="icon"
              icon={faCaretLeft}
              fixedWidth
            />
          </div>
          :
          null
        }
        <Slider
          ref={c => (this.slider = c)}
          {...settings}
        >
          {(data.carousel || []).map((item, i) => {
            return (
              <div className="carousel-item" style={{ width: itemWidth }} key={i}>
                {carouselItemImage(item, data.image_aspect_ratio && data.image_aspect_ratio === "square")}
                <div className="content">
                  <div className="info">
                    <div className="title">{_.get(this.state.carouselArray[i], "modTitle")}</div>
                    <div className="subtitle">{item.subtitle}</div>
                    {/* {_.get(item, "default_action.url.length") > 0 ?
                      <div className="link"> {item.default_action.url} </div>
                      : null
                    } */}
                  </div>
                  {_.get(item, "buttons.length", 0) > 0 ?
                    <div className="buttons">
                      {item.buttons.map((button, j) => {
                        switch (button.type) {
                          case "web_url":
                            return (
                              <UrlButton
                                key={j}
                                data={button}
                              />
                            )
                          case "postback":
                            return (
                              <PostBackButton
                                key={j}
                                data={button}
                              />
                            )
                          case "phone_number":
                            return (
                              <PhoneButton
                                key={j}
                                data={button}
                              />
                            )
                          case "element_share":
                            return (
                              <ShareButton
                                key={j}
                                data={button}
                              />
                            )
                          default:
                            return (
                              <PostBackButton
                                key={j}
                                data={button}
                              />
                            )
                        }
                      })}
                    </div>
                    :
                    null
                  }
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    )
  }
}

export default Carousel
