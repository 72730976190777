import React, { useEffect, useTransition, useCallback, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Flex } from "theme-ui"
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons"
import _ from "lodash"
import moment from "moment"
import qs from "query-string"
import graphql from "babel-plugin-relay/macro"
import {
  usePreloadedQuery,
  usePaginationFragment,
} from "react-relay"
import ContentLoader from "react-content-loader"
import AutoSizer from "react-virtualized-auto-sizer"
import { FixedSizeList as List } from "react-window"
import InfiniteLoader from "react-window-infinite-loader"
import { useHistory } from "yarr"

import * as Styles from "../../pages/Inbox/InboxStyles"
import IconContainer from "../../components/IconContainer/IconContainer"
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"
import IntegrationIconDisplay from "../../components/IntegrationIconDisplay/IntegrationIconDisplay"
import { LoadingMask } from "../../CommonStyles"
import PopperContainer from "../Radiate/PopperContainer/PopperContainer"
import { renderUserNameInboxProfileFirst } from "../util"

const GUTTER_SIZE = 8

const Loader = (props) => (
  <ContentLoader
    speed={2}
    width={351}
    height={88}
    viewBox="0 0 351 88"
    backgroundColor="#e6e6e6"
    foregroundColor="#f0f0f0"
    {...props}
  >
    <circle cx="36" cy="42" r="20" />
    <rect x="62" y="22" rx="3" ry="3" width="180" height="6" />
    <rect x="62" y="39" rx="3" ry="3" width="100" height="6" />
    <rect x="62" y="56" rx="3" ry="3" width="150" height="6" />
  </ContentLoader>
)

const MemberResultListQuery = graphql`
  query MemberResultListQuery {
    userViewer {
      ...MemberResultList_userViewer
    }
  }
`

const MemberResultList = ({
  searchQuery,
  ...props
}) => {
  const data = usePreloadedQuery(
    MemberResultListQuery,
    searchQuery,
  )
  return (
    <InnerMemberResultList
      data={data}
      {...props}
    />
  )
}

const innerElementType = React.forwardRef(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height) + (GUTTER_SIZE * 2)}px`,
    }}
    {...rest}
  />
))

const minimumBatchSize = 10

const InnerMemberResultList = ({
  cacheSum,
  search,
  filter,
  onChangeThreadId,
  ...props
}) => {
  const { location } = useHistory()
  const query = qs.parse(location.search)
  const threadId = query?.thread
  const firstLoad = useRef(true)
  const [loading, startTransition] = useTransition()
  const [reverseSort, setReverseSort] = useState(false)

  const prevSearch = useRef(search)
  const { t } = useTranslation("common")

  useEffect(() => {
    setReverseSort(false)
  }, [cacheSum])

  const {
    data,
    loadNext,
    hasNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment(
    graphql`
      fragment MemberResultList_userViewer on UserScope
      @argumentDefinitions(
        search: { type: "String" }
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
        wChannelIds: { type: "[ID]" }
        lastChatAtFrom: { type: "Long" }
        lastChatAtTo: { type: "Long" }
        reverseSort: { type: "Boolean" }
      )
      @refetchable(queryName: "MemberResultListPaginateQuery") {
        threads (
          search: $search
          wChannelIds: $wChannelIds
          lastChatAtFrom: $lastChatAtFrom
          lastChatAtTo: $lastChatAtTo
          first: $first
          after: $after
          reverseSort: $reverseSort
        ) @connection(key: "MemberResultList_threads") {
          edges {
            node {
              threadId
              wMemberId
              wChannelId
              wChannel {
                name
                integrationIcon
                integrationName
              }
              title
              updatedAt
              lastChatAt
              lastMessage
              lastReadAt
              unreadCount
              title
              member {
                externalProfile
                inboxProfile {
                  firstName
                  lastName
                  name
                  profilePicUrl
                }
              }
            }
          }
        }
      }
    `,
    props.data.userViewer
  )

  const debounceSearch = useCallback(_.debounce(({
    search,
    filter,
    reverseSort,
  }) => {
    startTransition(() => {
      refetch({
        search,
        wChannelIds: filter?.wChannelIds,
        lastChatAtFrom: filter?.lastChatAt?.from,
        lastChatAtTo: filter?.lastChatAt?.to,
        reverseSort,
      }, {
        fetchPolicy: "network-only",
      })
    })
  }, 1000), [])

  useEffect(() => {
    
    if (firstLoad.current) {
      firstLoad.current = false
    } else {
      if (search === prevSearch.current) {
        startTransition(() => {
          refetch({
            search,
            wChannelIds: filter?.wChannelIds,
            lastChatAtFrom: filter?.lastChatAt?.from,
            lastChatAtTo: filter?.lastChatAt?.to,
            reverseSort,
          }, {
            fetchPolicy: "network-only",
          })
        })
      } else {
        debounceSearch({
          search,
          filter,
          reverseSort,
        })
        prevSearch.current = search
      }
    }
  }, [JSON.stringify(filter), search, reverseSort])

  const threads = _.cloneDeep(data?.threads?.edges) || []
  const isItemLoaded = (index) => !hasNext || index < threads?.length
  const itemCount = hasNext ? threads.length + 1 : threads.length
  const loadMoreItems = (startIndex, stopIndex) => {
    if (isLoadingNext) return () => { }
    return new Promise(resolve => {
      loadNext(minimumBatchSize, {
        onComplete: (err) => {
          if (!err) {
            resolve()
          }
        }
      })
    })
  }

  function getLastChatTimeText(momentDate) {
    const REFERENCE = moment()
    const TODAY = REFERENCE.clone().startOf('day');
    const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
    const A_WEEK_OLD = REFERENCE.clone().subtract(6, 'days').startOf('day');

    function isToday(momentDate) {
      return momentDate.isSame(TODAY, 'd');
    }

    function isYesterday(momentDate) {
      return momentDate.isSame(YESTERDAY, 'd');
    }
    function isWithinAWeek(momentDate) {
      return momentDate.isAfter(A_WEEK_OLD);
    }

    if (isToday(momentDate)) {
      return t?.("threads.last_chat_at_today", { time: momentDate })
    } else if (isYesterday(momentDate)) {
      return t?.("threads.last_chat_at_yesterday", { time: momentDate })
    } else if (isWithinAWeek(momentDate)) {
      return t?.("threads.last_chat_at_this_week", { time: momentDate })
    }
    return t?.("threads.last_chat_at_fallback", { time: momentDate })
  }

  return (
    <Styles.ThreadsContainer>
      <Flex
        sx={{
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Flex
          p={1}
          sx={{
            flexShrink: 0,
            justifyContent: "flex-end",
          }}
        >
          <PopperContainer
            display={(
              <NewBoxButton
                borderless
                primary={reverseSort}
                text={t?.("threads.member_last_message_time")}
                rightIcon={reverseSort? faSortUp : faSortDown}
                sx={{
                  ".icon": {
                    mb: reverseSort ? 0 : 1,
                    mt: reverseSort ? "8px" : 0,
                  }
                }}
                onClick={() => {
                  setReverseSort((v) => !v)
                }}
              />
            )}
          >
            {() => null}
          </PopperContainer>
        </Flex>
        <Flex
          sx={{
            flexGrow: 1,
          }}
        >
          {loading ?
            <Styles.MemberResultListLoadingContainer>
              <LoadingMask style={{ height: "100%", width: "100%" }}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </LoadingMask>
            </Styles.MemberResultListLoadingContainer>
            :
            <AutoSizer>
              {({ height, width }) => (
                <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={itemCount}
                  loadMoreItems={loadMoreItems}
                  minimumBatchSize={minimumBatchSize}
                  threshold={5}
                >
                  {({ onItemsRendered, ref }) => (
                    <List
                      height={height}
                      itemCount={itemCount}
                      innerElementType={innerElementType}
                      itemSize={92}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                      width={width}
                    >
                      {({ index, style }) => {
                        if (!isItemLoaded(index)) {
                          return <Loader style={style} />
                        } else {
                          const threadObj = threads?.[index]?.node
                          return (
                            <Styles.StyledLink
                              to={{
                                ...location,
                                search: qs.stringify({
                                  ...query ?? {},
                                  thread: threadObj?.threadId
                                })
                              }}
                              onClick={(e) => {
                                onChangeThreadId(threadObj?.threadId)
                              }}
                              style={{
                                ...style,
                                left: style.left + GUTTER_SIZE,
                                top: style.top + GUTTER_SIZE,
                                width: `calc(100% - ${GUTTER_SIZE * 2}px)`,
                                height: style.height - GUTTER_SIZE,
                                overflow: "hidden",
                              }}
                            >
                              <Styles.Thread
                                selected={threadId === threadObj?.threadId}
                              >
                                <IconContainer
                                  url={threadObj.member?.inboxProfile?.profilePicUrl}
                                  size="S"
                                  name={renderUserNameInboxProfileFirst({
                                    externalProfile: threadObj?.member?.externalProfile,
                                    inboxProfile: threadObj?.member?.inboxProfile
                                  })}
                                />
                                <Styles.CustomerDetails>
                                  <Flex sx={{ justifyContent: "space-between" }}>
                                    <div className="thread-title" title={threadObj?.title}>{threadObj?.title}</div>
                                    {threadObj?.lastChatAt ?
                                      <div className="thread-time-display">{getLastChatTimeText(moment(threadObj?.lastChatAt))}</div>
                                      :
                                      null
                                    }
                                  </Flex>
                                  <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <div className="channel-name-wrapper" >
                                      <IntegrationIconDisplay
                                        icon={threadObj.wChannel?.integrationIcon}
                                        name={threadObj.wChannel?.integrationName}
                                        size="16px"
                                      />
                                      <div className="channel-name">{threadObj.wChannel?.name}</div>
                                    </div>
                                    {(threadObj?.unreadCount || 0) > 0 ?
                                      <div className="thread-unread-count">{threadObj.unreadCount > 99 ? "99+" : threadObj.unreadCount}</div>
                                      :
                                      null
                                    }
                                  </Flex>
                                  <div className="thread-last-chat">{threadObj?.lastMessage}</div>
                                </Styles.CustomerDetails>
                              </Styles.Thread>
                            </Styles.StyledLink>
                          )
                        }
                      }}
                    </List>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          }

        </Flex>
      </Flex>
    </Styles.ThreadsContainer>
  )
}

export default MemberResultList
