import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { setLightness } from "polished"

import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"

const ROUTER_BASE = process.env.REACT_APP_ROUTER_BASE || ""

export const SettingsModalContainer = styled.div`
  width: 60%;
  min-width: 520px;
  height: 80%;
  box-shadow: ${(props) => props.theme.modalBoxShadow};
  border-radius: ${(props) => props.theme.modalBorderRadius};
  overflow: hidden;
  background: white;

  .close-button {
    color: ${(props) => props.theme.gray4};
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.gray5};
    }
  }

  .modal-title {
    color: ${(props) => props.theme.gray9};
    font-size: ${(props) => props.theme.textMd};
  }

  >.separator {
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.gray1};
  }

  .settings-tab-container {
    height: calc(100% - 86.5px) !important;
  }

  ${(props) => props.mobile && css`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    min-width: auto;
    transition: transform 0.4s ease-in-out;
    transform: ${props.show ? "translateY(0)" : "translateY(100%)"};
    min-width: auto;
  `}
`

export const SettingsUsersContainer = styled.div`
  padding: 0 32px 16px 32px;
  overflow-y: auto ;
  height: 100%;
`

export const SettingsProfileAndAgentContainer = styled.div`
  padding: 16px 32px;
  overflow-y: auto ;
  height: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  >.description {
    color: ${(props) => props.theme.gray6};
    font-size: ${(props) => props.theme.textSm};
    padding-bottom: 8px;
  }
`

export const SettingsChannelsContainer = styled.div`
  padding: 16px 32px;
  overflow-y: auto ;
  height: 100%;

    ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

export const AgentRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 8px;

  .dropdown-button {
    margin-right: 8px;
  }

  .agent-icon-container {
    width: 48px;
  }

  .agent-name-container {
    padding-left: 16px;
    flex-grow: 1;

    .agent-name {
      color: ${(props) => props.theme.gray9};
    }

    .agent-name-text-field {
      width: 160px;
    }

    .agent-profile-pic-text-field {
      margin-left: 8px;
      width: calc(100% - 160px);
    }
  }

  td {
    &:last-child {
      text-align: right;
    }
  }

  .button-wrapper {
    display: flex;
    padding-left: 8px;

    .save-button {
      margin-right: 8px;
    }

    .remove-button {
      margin-left: 8px;
    }
  }
`

export const AgentsContainer = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;

  .agent-title-container {
    display: flex;
    align-items: center;

    >.title {
      font-weight: bold;
      color: ${(props) => props.theme.gray9};
    }

    >.error {
      color: ${(props) => props.theme.error};
      font-size: ${(props) => props.theme.textSm};
      padding-left: 8px;
    }
  }
`

export const GridAgentsContainer = styled.div`
  margin: 0px auto;
  max-width: 792px;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(240px, 1fr));
  justify-items: center;
  padding-bottom: 16px;
`

export const AgentCard = styled.div`
  padding: 16px;
  border: 1px solid ${(props) => props.theme.gray1};
  border-radius: 6px;
  margin: 16px;
  width: 232px;
  height: 308px;
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.textSm};
  position: relative;

  .separator {
    width: 40%;
    height: 1px;
    background: ${(props) => props.theme.gray1};
    margin: 0px auto 0px auto;
  }

  .woztell-email {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .agent-card-dropdown-button {
    position: absolute;
    top: 8px;
    right: 8px;

    .box-button-container {
      span {
        white-space: nowrap;
      }
    }
  }

  .agent-card-text-field {
    margin-bottom: 8px;
  }

  .email, .agent-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: 8px;
  }

  .agent-name {
    font-weight: bold;
  }

  .email {
    color: ${(props) => props.theme.gray7};
  }
`

export const CreateAgentContainer = styled.div`
  width: 320px;
  height: 145px;

  >.label {
    color: ${(props) => props.theme.gray6};
    padding: 8px;
    font-size: ${(props) => props.theme.textSm};
    font-weight: bold;
  }

  >.separator {
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.gray1};
  }

  .error {
    display: ${(props) => !props.showError && "none"};
    font-size: 0.7rem;
    color: ${(props) => props.theme.error};
    text-align: center;
    height: 16px;
  }
`

export const WoztwellIcon = styled.div`
  width: 20px;
  height: 20px;
  background: url('${ROUTER_BASE}/woztell-logo-without-space.png') center no-repeat;
  background-size: contain;
  margin-right: 4px;
`

export const TagButtonBorder = styled.div`
  width: ${(props) => props.isManager ? "76px" : "59px"};
  height: 30px;
  border: ${(props) => {
    if (props.selected && props.isDisabled) {
      return `2px solid ${setLightness(0.75, props.color)}`
    } else if (props.selected) {
      return `2px solid ${props.color}`
    }
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: relative;
  cursor: ${(props) => props.isDisabled ? "default" :  "pointer"};

  /* &:hover {
    border: ${(props) => !props.selected && `2px solid ${setLightness(0.65, props.color)}`};
  } */
`

export const TagButton = styled.div`
  position: absolute;
  display: inline-block;
  white-space: nowrap;
  padding: 4px 6px;
  border-radius: 3px;
  background: ${(props) => {
  if (props.selected && props.isDisabled) {
    return setLightness(0.75, props.backgroundColor)
  } else if (props.selected) {
    return props.backgroundColor
  } else if (props.isDisabled) {
    return props.theme.gray2
  }
  return props.theme.gray3
  }};
  color: #ffffff;
  font-weight: normal;
  font-size: 0.7rem;
`

export const FlexRow = styled.tr`
  position: relative;
  background: #ffffff ;
  font-size: ${(props) => props.theme.textSm};
  color: ${(props) => props.theme.gray9};

  >td {
    padding: 8px;
  }

  >.email {

    >.name {
      color: ${(props) => props.theme.gray6};
    }
  }

  .wchannel-id {
    font-size: 0.7rem;
    color: ${(props) => props.theme.gray6};
  }
`

export const RowTHead = styled.thead`
  tr {
    color: ${(props) => props.theme.gray6};
    font-size: ${(props) => props.theme.textSm};

    td {
      padding-bottom: 16px;
      border-bottom: 1px solid ${(props) => props.theme.gray1};
    }

    >.icon {
      width: 56px;
      padding-right: 8px;
    }

    >.email {
      width: 220px;
      padding-right: 8px;
      padding-bottom: 16px;
    }

    >.name {
      width: 80px;
      padding-right: 8px;
      padding-bottom: 16px;
    }

    >.role-select {
      width: 160px;
      padding-right: 8px;
    }

    >.role {
      width: 60px;
      padding-right: 8px;
    }

    >.channels {
      min-width: 320px;
      width: 100%;
    }

    >.status {
      min-width: 116px;
    }

    >.channel {
      min-width: 320px;
      width: 100%;
    }
  }
`

export const ErrorContainer = styled.div`
  height: 24px;
  display: flex;
  align-items: center;

  >.error {
    color: ${(props) => props.theme.error};
    font-size: ${(props) => props.theme.textSm};
  }
`

export const StatusTag = styled.div`
  background: ${(props) => props.isConnected ? setLightness(0.85, props.theme.success) : props.theme.paleBackground};
  color: ${(props) => props.isConnected ? setLightness(0.4, props.theme.success) : props.theme.gray6};
  border-radius: 4px;
  padding: 6px 8px;
  font-size: ${(props) => props.theme.textSm};
  justify-content: center;
  display: flex;
  box-sizing: border-box;
`

export const ConnectButton = styled(NewBoxButton)`
  width: 108px;

  >.icon {
    font-size: 0.85rem;
  }
`

export const LoadMoreButton = styled.td`
  text-align: center;
  padding: 16px;
  cursor: pointer;
  color: ${(props) => props.theme.primary};
`

export const LoadMoreText = styled.div`
  font-size: ${(props) => props.theme.textSm};
  font-weight: bold;
`

export const ProfileContainer = styled.div`
  width: 100%;
  padding-top: 32px;

  .agent-name-text-field, .agent-email-text-field, .agent-icon-text-field{
    margin-bottom: 16px;
    width: 100%;
  }, 
`
export const HideAlways = styled.div`
  display: none;
`

export const MobileUISettings = styled.div`
  display: none;
  @media (max-width:989px) {
    display: block;
  }
`
export const DesktopUISettings = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  @media (max-width:989px) {
      display: none;
  }
`

export const MobileProfileBody = styled.div`
  overflow-y: auto;
  padding: 0px 20px;

  .accountSettingsHeader {
    background-image: linear-gradient(to right, #5150ed, #7c41dc);
    width: 125px;
    height: 125px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      object-fit: cover;
      width: 118px;
      height: 118px;
      border-radius: 50%;
    }
    .editButton {
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: 22px;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: linear-gradient(to right, #5150ed, #7c41dc);
      background-color: transparent;
      border: none;
      border-radius: 10px;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .inputGroup {
    margin-top: 20px;
    label {
      display: block;
      color: var(--greyscale-900, #212121);
      font-size: 16px;
      font-family: inter;
      font-weight: 600;
      line-height: 140%;
      margin-bottom: 10px;
    }
    input {
      width: 100%;
      padding: 15px;
      font-size: 14px;
      font-family: inter;
      line-height: 140%;
      letter-spacing: 0.2px;
      border-radius: 10px;
      border: 1px solid #eeeeee;
      &:focus {
        border: 1px solid #eeeeee;
        outline: none;
        box-shadow: none;
      }
    }
  }
`

export const MobileProfileFooter = styled.div`
  padding: 25px;
  button {
    width: 100%;
    border: none;
    color: #ffffff;
    cursor: pointer;
    border-radius: 25px;
    padding: 10px 15px;
    font-size: 16px;
    font-family: inter;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    background-image: linear-gradient(to right, #5150ed, #7c41dc);
  }
  button[disabled] {
    opacity: 0.5;
    background: gray;
  }
`