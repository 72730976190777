import { Tooltip } from "react-tooltip";

const ReactTooltip = ({
  style = {},
  ...props
}) => (
  <Tooltip
    {...props}
    style={{
      zIndex: 999,
      padding: "6px 8px",
      fontSize: "0.8rem",
      ...style,
    }}
  />
)

export default ReactTooltip
