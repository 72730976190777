import React, { Fragment } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { randomString } from "../../util"

const TabBar = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${(props) => {
    if (props.newTheme) {
      return "none"
    }
    return "0 0 8px 0 rgba(0, 0, 0, 0.1)"
  }};
  border-bottom: ${(props) => {
    if (props.newTheme) {
      return `1px solid ${props.theme.gray1}`
    }
    return "none"
  }};
  padding: ${(props) => {
    if (props.newTheme) {
      return "0px 32px"
    }
    return "0 12px"
  }};
  
  ${(props) => props.stickyTabBar && css`
    position: sticky;
    top: -16px;
    background: white;
    z-index: 10;
  `}

  .tabs {
    display: flex;
    align-items: center;
  }
`

const TabComponent = styled.div`
  padding: 0 10px;
  margin: 0 2px;
  line-height: 44px;
  height: 100%;
  font-size: 0.9rem;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  color: ${(props) => props.theme.gray6};

  &:after {
    transition: all 0.2s ease-in-out;
    position: absolute;
    content: "";
    bottom: 0;
    left: 0px;
    right: 0px;
    height: 3px;
    background-color: "transparent";
  }

  &:hover {
    color: ${(props) => {
    if (props.selected) {
      return props.theme.themeText
    }
    return props.theme.darkText
  }};
    

    &:after {
      background-color: ${(props) => {
      if (props.selected) {
        return props.theme.themeText
      } else if (props.disabled) {
        return "transparent"
      }
      return props.theme.gray4
    }};
    }
  }

  ${(props) => {
    if (props.selected) {
      return css`
        color: ${props.theme.themeText};
        &:after {
          background-color: ${props.theme.themeText};
        }
        font-weight: bold;
      `
    }
  }};

  ${(props) => {
    if (props.disabled) {
      return css`
        cursor: inherit;
        color: rgba(0, 0, 0, 0.1);

        &:hover {
          color: rgba(0, 0, 0, 0.1);
        }
      `
    }
  }}
`

const TabContent = styled.div`
  height: calc(100% - 44px); 
  overflow: auto;
`

const AddTabButton = styled.div`
  margin-left: 16px;
  color: ${(props) => props.theme.themeColor};
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  line-height: 44px;
  height: 100%;
  position: relative;

  .icon {
    font-size: 0.8rem;
    margin-right: 4px;
  }

  &:after {
    transition: all 0.2s ease-in-out;
    position: absolute;
    content: "";
    bottom: 0;
    left: -6px;
    right: -6px;
    height: 3px;
    background-color: "transparent";
  }

   &:hover {
    color: ${(props) => props.theme.themeColorLight};

    &:after {
      background-color: ${(props) => props.theme.themeColorLight};
    }
  }
`

const propTypes = {
  tabs: PropTypes.array,
  selectedTab: PropTypes.number,
  defaultSelected: PropTypes.number,
  onSelectTab: PropTypes.func,
  actions: PropTypes.array,
  className: PropTypes.string,
  canAddTab: PropTypes.bool,
  disabled: PropTypes.bool,
  addTabLabel: PropTypes.string,
  addTabIcon: PropTypes.string,
  onAddTab: PropTypes.func,
  newTheme: PropTypes.bool,
}

const defaultProps = {
  tabs: [],
  className: "",
  onSelectTab: () => {},
  defaultSelected: 0,
  actions: [],
  canAddTab: false,
  disabled: false,
  addTabLabel: "",
  addTabIcon: "plus",
  onAddTab: () => {},
  newTheme: false,
}

class Tab extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      randomId: randomString(10),
      selectedTab: !_.isNil(props.selectedTab) ? props.selectedTab : props.defaultSelected
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.selectedTab !== this.props.selectedTab) {
      this.setState({
        selectedTab: nextProps.selectedTab
      })
    }
  }

  render() {
    const {
      tabs,
      className,
      onSelectTab,
      actions,
      canAddTab,
      addTabLabel,
      addTabIcon,
      onAddTab,
      disabled,
      newTheme,
      stickyTabBar
    } = this.props

    const {
      selectedTab
    } = this.state

    return (
      <div className={`radiate-theme tab-container ${className}`} style={{ height: "100%" }}>
        <TabBar
          className="tab-bar"
          newTheme={newTheme}
          stickyTabBar={stickyTabBar}
        >
          <div className="tabs">
            {tabs.map((tab, i) => {
              let isSelected = false
              if (selectedTab === i) {
                isSelected = true
              }
              return (
                <Fragment key={i}>
                  {tab?.toolTipText ?
                    <>
                      <TabComponent
                        newTheme={newTheme}
                        className="tabs"
                        selected={isSelected}
                        data-tooltip-content={tab?.toolTipText}
                        data-tooltip-id={`${this.state.randomId}-${i}`}
                        disabled={tab.disabled || disabled}
                        onClick={() => {
                          if (disabled || tab?.disabled) {
                            return
                          }
                          if (this.state.selectedTab !== i) {
                            if (_.isNil(this.props.selectedTab)) {
                              this.setState({
                                selectedTab: i
                              })
                              onSelectTab(i, this.state.selectedTab)
                            } else {
                              onSelectTab(i, this.state.selectedTab)
                            }
                          }
                        }}
                      >
                        {tab.text}
                      </TabComponent>
                    </>
                    :
                    <TabComponent
                      key={i}
                      newTheme={newTheme}
                      className="tabs"
                      selected={isSelected}
                      disabled={tab.disabled || disabled}
                      onClick={() => {
                        if (disabled || tab?.disabled) {
                          return
                        }
                        if (this.state.selectedTab !== i) {
                          if (_.isNil(this.props.selectedTab)) {
                            this.setState({
                              selectedTab: i
                            })
                            onSelectTab(i, this.state.selectedTab)
                          } else {
                            onSelectTab(i, this.state.selectedTab)
                          }
                        }
                      }}
                    >
                      {tab.text}
                    </TabComponent>
                  }
                </Fragment>
              )
            })}
            {canAddTab ?
              <AddTabButton
                className="add-tab-button"
                onClick={() => {
                  onAddTab((index) => {
                    this.setState({
                      selectedTab: index || tabs.length
                    })
                  })
                }}
              >
                <FontAwesomeIcon
                  className="icon"
                  fixedWidth
                  icon={addTabIcon}
                />
                {addTabLabel}
              </AddTabButton>
              :
              null
            }
          </div>
          <div className="actions-container">
            {actions}
          </div>
        </TabBar>
        <TabContent className="tab-content">
          {_.get(tabs[selectedTab], "content", null)}
        </TabContent>
      </div>
    )
  }
}

Tab.propTypes = propTypes
Tab.defaultProps = defaultProps

export default Tab
