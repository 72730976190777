/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import { Box, Flex } from "theme-ui"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBell } from "@fortawesome/free-solid-svg-icons"

const NotificationRequestModal = ({
  forwardedRef,
  open,
  onAllow = () => {},
  onCancel = () => {},
}) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    setShow(true)
    if (open === false) {
      setShow(false)
    }
  }, [open])

  const { t } = useTranslation("common")

  return (
    <Box
      ref={forwardedRef}
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        background: "white",
        p: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "stretch",
        transform: show ? "translateY(0)" : "translateY(100%)",
        transition: "transform 0.4s ease-in-out",
      }}
    >
      <FontAwesomeIcon
        icon={faBell}
        sx={{
          fontSize: "60px",
          mb: 3,
          color: "hsl(215, 61%, 41%)"
        }}
      />
      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            fontSize: 3,
            fontWeight: "bold",
            color: "gray9",
            my: 3,
          }}
        >{t?.("notification_request.allow_notification_access")}</Box>
        <Box
          sx={{
            fontSize: 2,
            color: "gray7",
            mb: 4,
          }}
        >
          {t?.("notification_request.get_notified")}
        </Box>
      </Flex>
      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "stretch",
          gap: 2,
          flexShrink: 0,
        }}
      >
        <NewBoxButton
          text={t?.("buttons.confirm")}
          primary
          onClick={() => {
            onAllow()
          }}
        />
        <NewBoxButton
          text={t?.("notification_request.not_now_button")}
          borderless
          onClick={() => {
            onCancel()
          }}
        />
      </Flex>
      
    </Box>
  )
}

export default React.forwardRef((props, ref) => <NotificationRequestModal {...props} forwardedRef={ref} />)
