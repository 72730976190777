/* eslint-disable no-useless-escape */
/* eslint-disable arrow-parens */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
import React from "react"
import {
  Editor,
  Transforms,
} from "slate"
import { ReactEditor, useSelected, useFocused } from "slate-react"

export const VARIABLE_TYPE = "variable"
export const URL_TYPE = "url"
export const EMAIL_TYPE = "email"

const variableRegex = /\{\{\d+\}\}/
const emailRegex = /[\w\.]+@([\w-]+\.)+[\w-]{2,4}/
const urlRegex = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/[^ |\n|\r|\r\n]*)?/

export function isJsonString(string) {
  try {
    JSON.parse(string)
    return true
  } catch {
    return false
  }
}

function findEarliestMarkTypeWithIndex(str) {
  const boldIndices = []
  const italicIndices = []
  const strikethroughIndices = []
  for (let i = 0; i < str.length; i++) {
    if (str[i] === "*") {
      boldIndices.push(i)
    } else if (str[i] === "_") {
      italicIndices.push(i)
    } else if (str[i] === "~") {
      strikethroughIndices.push(i)
    }
  }

  const hasBoldMarks = boldIndices.length > 0 && boldIndices.length % 2 === 0
  const hasItalicMarks = italicIndices.length > 0 && italicIndices.length % 2 === 0
  const hasStrikethroughMarks = strikethroughIndices.length > 0 && strikethroughIndices.length % 2 === 0

  let start = null
  let end = null
  let markType = null

  if (hasBoldMarks) {
    start = boldIndices[0]
    end = boldIndices[1]
    markType = "bold"
  }
  if (hasItalicMarks && (start === null || italicIndices[0] < start)) {
    start = italicIndices[0]
    end = italicIndices[1]
    markType = "italics"
  }
  if (hasStrikethroughMarks && (start === null || strikethroughIndices[0] < start)) {
    start = strikethroughIndices[0]
    end = strikethroughIndices[1]
    markType = "strikethrough"
  }

  let marks = {}
  if (markType === "bold") marks = { bold: true }
  else if (markType === "italics") marks = { italics: true }
  else if (markType === "strikethrough") marks = { strikethrough: true }

  return {
    start,
    end,
    markType,
    marks,
  }
}

export const deserialize = (string, marks = {}) => {
  const lines = string.split("\n")
  return lines.map((line) => {
    const { start, end, markType, marks: newMarks } = findEarliestMarkTypeWithIndex(line)
    if (markType !== null) {
      const deseralizeLeft = line.slice(0, start).length > 0 && deserialize(line.slice(0, start), marks)
      const deseralizeRight = line.slice(end + 1).length > 0 && deserialize(line.slice(end + 1), marks)
      const deserializeMiddle = deserialize(line.slice(start + 1, end), { ...marks, ...newMarks })
      return {
        children: [
          ...(deseralizeLeft?.[0]?.children || []),
          ...(deserializeMiddle?.[0]?.children || []),
          ...(deseralizeRight?.[0]?.children || []),
        ],
      }
    }

    const variableMatch = line.match(variableRegex)
    const emailMatch = line.match(emailRegex)
    const urlMatch = line.match(urlRegex)
    if (variableMatch) {
      // eslint-disable-next-line no-shadow
      const start = variableMatch.index
      // eslint-disable-next-line no-shadow
      const end = (start + variableMatch[0].length) - 1

      const deseralizeLeft = line.slice(0, start).length > 0 && deserialize(line.slice(0, start), marks)
      const deseralizeRight = line.slice(end + 1).length > 0 && deserialize(line.slice(end + 1), marks)

      const variableLeaf = [{
        type: VARIABLE_TYPE,
        character: line.slice(start, end + 1),
        children: [{
          text: "",
          ...marks,
        }],
      }]

      return {
        children: [
          ...(deseralizeLeft?.[0]?.children || []),
          ...variableLeaf,
          ...(deseralizeRight?.[0]?.children || []),
        ],
      }
    } else if (emailMatch) {
      // eslint-disable-next-line no-shadow
      const start = emailMatch.index
      // eslint-disable-next-line no-shadow
      const end = (start + emailMatch[0].length) - 1

      const deseralizeLeft = line.slice(0, start).length > 0 && deserialize(line.slice(0, start), marks)
      const deseralizeRight = line.slice(end + 1).length > 0 && deserialize(line.slice(end + 1), marks)

      const emailLeaf = [{
        type: EMAIL_TYPE,
        character: line.slice(start, end + 1),
        children: [{
          text: "",
          ...marks,
        }],
      }]

      return {
        children: [
          ...(deseralizeLeft?.[0]?.children || []),
          ...emailLeaf,
          ...(deseralizeRight?.[0]?.children || []),
        ],
      }
    } else if (urlMatch) {
      // eslint-disable-next-line no-shadow
      const start = urlMatch.index
      // eslint-disable-next-line no-shadow
      const end = (start + urlMatch[0].length) - 1

      const deseralizeLeft = line.slice(0, start).length > 0 && deserialize(line.slice(0, start), marks)
      const deseralizeRight = line.slice(end + 1).length > 0 && deserialize(line.slice(end + 1), marks)

      const urlLeaf = [{
        type: URL_TYPE,
        character: line.slice(start, end + 1),
        children: [{
          text: "",
          ...marks,
        }],
      }]

      return {
        children: [
          ...(deseralizeLeft?.[0]?.children || []),
          ...urlLeaf,
          ...(deseralizeRight?.[0]?.children || []),
        ],
      }
    }

    return {
      children: [
        {
          text: line,
          ...marks,
        }
      ],
    }
  })
}
export const serialize = (nodes) => {
  /*
   * bold mark is *
   * italics mark is _
   * strikethrough mark is ~
  */
  const cases = [
    { bold: true, italics: true, strikethrough: true, addMarksToText: (s) => `*_~${s}~_*` },
    { bold: true, italics: true, strikethrough: false, addMarksToText: (s) => `*_${s}_*` },
    { bold: true, italics: false, strikethrough: true, addMarksToText: (s) => `*~${s}~*` },
    { bold: true, italics: false, strikethrough: false, addMarksToText: (s) => `*${s}*` },
    { bold: false, italics: true, strikethrough: true, addMarksToText: (s) => `_~${s}~_` },
    { bold: false, italics: true, strikethrough: false, addMarksToText: (s) => `_${s}_` },
    { bold: false, italics: false, strikethrough: true, addMarksToText: (s) => `~${s}~` },
    { bold: false, italics: false, strikethrough: false, addMarksToText: (s) => s },
  ]
  const paragraphArray = nodes.map((node) => {
    const array = []
    node.children.forEach((child) => {
      if (child.text) {
        for (const c of cases) {
          if (c.bold === Boolean(child.bold) && c.italics === Boolean(child.italics) && c.strikethrough === Boolean(child.strikethrough)) {
            array.push(c.addMarksToText(child.text))
          }
        }
      } else if (child.type === "variable") {
        const variableChild = child?.children?.[0]
        for (const c of cases) {
          if (c.bold === Boolean(variableChild?.bold) && c.italics === Boolean(variableChild?.italics) && c.strikethrough === Boolean(variableChild?.strikethrough)) {
            array.push(c.addMarksToText(child.character))
          }
        }
      }
    })
    return array.join("")
  })
  return paragraphArray.join("\n")
}

export const CustomEditorCommands = {
  ...Editor,
  isBoldMarkActive(editor) {
    const marks = Editor.marks(editor)
    return marks ? marks.bold === true : false
  },
  toggleBoldMark(editor) {
    const isActive = CustomEditorCommands.isBoldMarkActive(editor)
    if (isActive) {
      Editor.removeMark(editor, "bold")
    } else {
      Editor.addMark(editor, "bold", true)
    }
  },
  isItalicsMarkActive(editor) {
    const marks = Editor.marks(editor)
    return marks ? marks.italics === true : false
  },
  toggleItalicsMark(editor) {
    const isActive = CustomEditorCommands.isItalicsMarkActive(editor)
    if (isActive) {
      Editor.removeMark(editor, "italics")
    } else {
      Editor.addMark(editor, "italics", true)
    }
  },
  isStrikethroughMarkActive(editor) {
    const marks = Editor.marks(editor)
    return marks ? marks.strikethrough === true : false
  },
  toggleStrikethroughMark(editor) {
    const isActive = CustomEditorCommands.isStrikethroughMarkActive(editor)
    if (isActive) {
      Editor.removeMark(editor, "strikethrough")
    } else {
      Editor.addMark(editor, "strikethrough", true)
    }
  },
  checkIfMaxLengthReached(editor, maxLength) {
    const text = serialize(editor.children)
    return text.length >= maxLength
  },
  getLengthOfRichText(editor) {
    const text = serialize(editor.children)
    return text.length
  },
  isMarkActive(editor, format) {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  },
  getCurrentPossibleVariables(editor) {
    const currentText = serialize(editor.children)
    const regexMatches = currentText.matchAll(/\{\{\d+\}\}/g)
    const variableMatches = [...regexMatches].map((match) => match[0])

    if (variableMatches.length === 0) return ["{{1}}"]

    const matchesAsNums = variableMatches.map((match) => parseInt(match.slice(2, -2), 10)).sort((a, b) => a - b)
    const maxNumPlusOne = Math.max(...matchesAsNums) + 1

    return [`{{${maxNumPlusOne}}}`]
  },
  getNextHighestVariableAsText(editor) {
    const currentText = serialize(editor.children)
    const regexMatches = currentText.matchAll(/\{\{\d+\}\}/g)
    const variableMatches = [...regexMatches].map((match) => match[0])
    const matchesAsNums = variableMatches.map((match) => parseInt(match.slice(2, -2), 10)).sort((a, b) => a - b)
    if (matchesAsNums.length > 0) {
      const largestNum = Math.max(...matchesAsNums)
      return `{{${largestNum + 1}}}`
    }
    return "{{1}}"
  },
  focusEditor(editor) {
    setTimeout(() => {
      ReactEditor.focus(editor, { retries: 3 })
    }, 0)
  },
}

export const CodeElement = (props) => (
  <pre {...props.attributes}>
    <code>{props.children}</code>
  </pre>
)

export const DefaultElement = (props) => <p {...props.attributes}>{props.children}</p>

// Define a React component to render leaves with bold text.
export const Leaf = (props) => (
  <span
    {...props.attributes}
    style={{
      fontWeight: props.leaf.bold ? "bold" : "normal",
      fontStyle: props.leaf.italics ? "italic" : "normal",
      textDecoration: props.leaf.strikethrough ? "line-through" : "none",
    }}
  >
    {props.children}
  </span>
)

export const withVariables = (editor) => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = (element) => (element.type === VARIABLE_TYPE ? true : isInline(element))

  editor.isVoid = (element) => (element.type === VARIABLE_TYPE ? true : isVoid(element))

  editor.markableVoid = (element) => element.type === VARIABLE_TYPE || markableVoid(element)

  return editor
}

export const insertVariable = (editor, character) => {
  const variableNode = {
    type: VARIABLE_TYPE,
    character,
    children: [{ text: "" }],
  }
  Transforms.insertNodes(editor, variableNode)
  Transforms.move(editor)
}

export const Variable = ({ attributes, children, element, invertColors = false }) => {
  const selected = useSelected()
  const focused = useFocused()
  const style = {
    padding: "3px 3px 2px",
    margin: "0",
    verticalAlign: "baseline",
    display: "inline-block",
    borderRadius: "4px",
    backgroundColor: invertColors ? "black" : "#eee",
    fontSize: "0.9em",
    boxShadow: selected && focused ? "0 0 0 2px #B4D5FF" : "none",
  }
  if (element.children[0].bold) {
    style.fontWeight = "bold"
  }
  if (element.children[0].italics) {
    style.fontStyle = "italic"
  }
  if (element.children[0].strikethrough) {
    style.textDecoration = "line-through"
  }
  return (
    <span
      {...attributes}
      contentEditable={false}
      style={style}
    >
      {element.character}
      {children}
    </span>
  )
}

export const Url = ({ attributes, children, element }) => {
  const style = {
    padding: "0",
    margin: "0",
    verticalAlign: "baseline",
    display: "inline-block",
    fontSize: "1em",
    wordBreak: "break-word",
  }
  if (element.children[0].bold) {
    style.fontWeight = "bold"
  }
  if (element.children[0].italics) {
    style.fontStyle = "italic"
  }
  if (element.children[0].strikethrough) {
    style.textDecoration = "line-through"
  }
  return (
    <a
      {...attributes}
      contentEditable={false}
      style={style}
      href={element.character}
      target="_blank"
      rel="noreferrer noopener"
    >
      {element.character}
      {children}
    </a>
  )
}

export const Email = ({ attributes, children, element }) => {
  const style = {
    padding: "0",
    margin: "0",
    verticalAlign: "baseline",
    display: "inline-block",
    fontSize: "1em",
    wordBreak: "break-word",
  }
  if (element.children[0].bold) {
    style.fontWeight = "bold"
  }
  if (element.children[0].italics) {
    style.fontStyle = "italic"
  }
  if (element.children[0].strikethrough) {
    style.textDecoration = "line-through"
  }
  return (
    <a
      {...attributes}
      contentEditable={false}
      style={style}
      target="_blank"
      rel="noreferrer noopener"
      href={`mailto:${element.character}`}
    >
      {element.character}
      {children}
    </a>
  )
}
