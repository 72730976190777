/** @jsxImportSource theme-ui */

import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  useMutation,
} from "react-relay"

import IconContainer from "../IconContainer/IconContainer"

import {
  SwitchAppMutation
} from "./SettingsModalQuery"
import AppPicker from "../AppPicker/AppPicker"

const PUBLIC_URL = process.env.PUBLIC_URL

const SettingsAccount = ({
  currentWAppId,
  account,
  ...props
}) => {

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState("")
  const [switchApp] = useMutation(
    SwitchAppMutation
  )

  const { t } = useTranslation("settings")

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: 5,
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        sx={{
          p: "16px",
          display: "flex",
          alignItems: "center"
        }}
      >
        <IconContainer
          url={account?.profilePicUrl}
          size="M"
          name={account?.name}
          sx={{
            mr: "16px",
          }}
        />
        <div>
          <div>{account?.name ?? null}</div>
          {account?.name === account?.email ?
            null
            :
            <div>{account?.email}</div>
          }
        </div>
      </div>
      <div
        sx={{
          width: "300px",
          mt: 2,
        }}
      >
        <div
          sx={{
            fontSize: "0.8333em",
            mb: 1,
            color: "gray9"
          }}
        >
          {t?.("account_settings.switch_organization")}
        </div>
        <AppPicker
          selectedAppId={currentWAppId}
          onChange={(option) => {
            switchApp({
              variables: {
                input: {
                  wAppId: option,
                }
              },
              onCompleted: (response, errs) => {
                if (!response.switchAppForSession?.error) {
                  window.location = `${PUBLIC_URL}/inbox`
                } else {
                  setError(response.switchAppForSession?.error?.message)
                }
              },
              onError: (err) => {
                const errorMsg = err.res?.json?.errors[0]?.message || err
                setError(errorMsg)
              }
            })
          }}
        />
      </div>
      
    </div>
    
  )
}

export default SettingsAccount
