import "regenerator-runtime/runtime"
import _ from "lodash"
import { createClient } from "graphql-ws"
import { RelayNetworkLayer, urlMiddleware, loggerMiddleware, retryMiddleware } from "react-relay-network-modern"
import { Environment, RecordSource, Store, Observable } from "relay-runtime"

const NODE_ENV = process.env.NODE_ENV
const GRAPHQLAPI_SUBSCRIPTION_PATH = process.env.REACT_APP_GRAPHQLAPI_SUBSCRIPTION_PATH
const GRAPHQLAPI_PATH = process.env.REACT_APP_GRAPHQLAPI_PATH

let cachedPayload
let cachedSignedContext
let cachedEnvironment

const setupSubscription = ({
  operation,
  variables,
  cache,
  payload,
  signedContext
}) => {
  // console.log("payload", payload)
  // console.log("signedContext", signedContext)
  const wsClient = createClient({
    url: GRAPHQLAPI_SUBSCRIPTION_PATH,
    connectionParams: () => {
      return {
        payload,
        signedContext
      }
    }
  })
  return Observable.create((sink) => {
    return wsClient.subscribe(
      {
        operationName: operation.name,
        query: operation.text,
        variables,
      },
      sink,
    )
  })
}

export const RelayEnvironment = ({
  payload,
  signedContext,
} = {}, options = {}) => {
  const headers = {}
  if (!_.isEmpty(payload) && signedContext) {
    headers["X-Woztell-Payload"] = JSON.stringify(payload)
    headers["X-Woztell-SignedContext"] = signedContext
  }
  if (_.isEqual(payload, cachedPayload) && cachedSignedContext === signedContext && cachedEnvironment) {
    return cachedEnvironment
  }
  cachedPayload = payload
  cachedSignedContext = signedContext
  const newEnv = new Environment({
    network: new RelayNetworkLayer([
      urlMiddleware({
        url: GRAPHQLAPI_PATH,
        headers,
        credentials: "include"
      }),
      navigator.userAgent !== "ReactSnap" ? retryMiddleware({
        retryDelays: (attempt) => {
          if (attempt > 10) {
            return false
          }
          return Math.pow(2, attempt + 4) * 100
        }
      }) : null,
      NODE_ENV === "production" ? null : loggerMiddleware()
    ], {
      subscribeFn: (operation, variables, cache) => {
        return setupSubscription({ operation, variables, cache, payload, signedContext } )
      }
    }),
    store: new Store(new RecordSource()),
  })
  cachedEnvironment = newEnv
  return cachedEnvironment
}
