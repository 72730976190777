import styled, { css } from "styled-components"
import React from "react"
import Color from "color"
import _ from "lodash"

const LUM_THRESHOLD = 0.6

export function detectEmailAndUrl(text) {
  const regexEmailTest = /[\w-.]+@([\w-]+\.)+[\w-]{2,4}/g
  const regexEmail = /[\w-.]+@([\w-]+\.)+[\w-]{2,4}/g
  const haveEmail = regexEmailTest.test(text)
  const regexUrlTest = /((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/[^( |\n|\r|\r\n)]*)?)/g
  const regexUrl = /((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/[^( |\n|\r|\r\n)]*)?)/g
  const haveUrl = regexUrlTest.test(text)

  if (haveEmail || haveUrl) {
    let matchUrl = regexUrl.exec(text) || null
    let matchEmail = regexEmail.exec(text) || null
    const splitText = []
    let startIndex

    while (matchUrl || matchEmail) {
      startIndex = 0
      if (matchUrl && matchEmail) {
        if (_.get(matchEmail, "length") && matchEmail.index < matchUrl.index && matchEmail.index - startIndex >= 0) {
          splitText.push({ text: text.substr(startIndex, (matchEmail.index - startIndex)), type: "text" })
          const cleanedEmail = text.substr(matchEmail.index, matchEmail[0].length)
          splitText.push({ text: cleanedEmail, type: "email" })
          startIndex = matchEmail.index + matchEmail[0].length
        } else {
          splitText.push({ text: text.substr(startIndex, (matchUrl.index - startIndex)), type: "text" })
          const cleanedLink = text.substr(matchUrl.index, matchUrl[0].length)
          splitText.push({ text: cleanedLink, type: "link" })
          startIndex = matchUrl.index + matchUrl[0].length
        }
      } else if (matchEmail) {
        if (_.get(matchEmail, "length") && matchEmail.index - startIndex >= 0) {
          splitText.push({ text: text.substr(startIndex, (matchEmail.index - startIndex)), type: "text" })
          const cleanedEmail = text.substr(matchEmail.index, matchEmail[0].length)
          splitText.push({ text: cleanedEmail, type: "email" })
          startIndex = matchEmail.index + matchEmail[0].length
        }
      } else if (matchUrl) {
        if (matchUrl.length && matchUrl.index - startIndex >= 0) {
          splitText.push({ text: text.substr(startIndex, (matchUrl.index - startIndex)), type: "text" })
          const cleanedLink = text.substr(matchUrl.index, matchUrl[0].length)
          splitText.push({ text: cleanedLink, type: "link" })
          startIndex = matchUrl.index + matchUrl[0].length
        }
      }

      text = text.substr(startIndex)
      matchUrl = matchUrl ? (/((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/[^( |\n|\r|\r\n)]*)?)/g.exec(text) || null) : null
      matchEmail = matchEmail ? (/[\w-.]+@([\w-]+\.)+[\w-]{2,4}/g.exec(text) || null) : null
    }

    if (text.length) splitText.push({ text: text, type: "text" })
    // return splitText
    return (
      <>
        {splitText.map((o, i) => {
          if (o.type === "link") {
            let url = o.text
            if (!/^http/.test(o.text)) {
              url = `http://${url}`
            }
            return (
              <a
                key={i}
                href={url}
                target="_blank"
                rel="noreferrer noopener"
              >
                {o.text}
              </a>
            )
          } else if (o.type === "email") {
            return (
              <a
                key={i}
                href={`mailto:${o.text}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {o.text}
              </a>
            )
          }
          return (
            <span
              key={i}
            >
              {o.text}
            </span>
          )
        })}
      </>
    )
    // return "url"
  }
  return text
}

export function timestampNormalizer(timestamp) {
  const split = `${timestamp}`.split(".")
  let result = timestamp
  if (split.length >= 2) {
    if (split[1].length >= 3) {
      return parseFloat(timestamp) * 1000
    }
  }
  if (typeof timestamp === "string") {
    result = parseInt(timestamp, 10)
  }
  if (result && result.toString().length <= 10) {
    result = result * 1000
  }

  return result
}

export const WhatsAppFlowPayloadTitle = styled.div`
  max-width: 100%;
  padding: 0 10px;
  border-radius: 8px;
  display: inline-block;
  overflow: hidden;
  line-height: 1.33;
  vertical-align: top;

  &.text {
    white-space: pre-wrap;
    word-break: break-word;
  }
  font-size: 0.8rem;
  color: #555;
`

export const MessageBubble = styled.div`
  max-width: 100%;
  padding: 8px 10px;
  border-radius: 8px;
  display: inline-block;
  overflow: hidden;
  line-height: 1.33;
  vertical-align: top;

  &.text {
    white-space: pre-wrap;
    word-break: break-word;
  }
  background-color: ${(props) => {
    if (props.isAgent) {
      return props.color
    }
    return "white"
  }};
  color: ${(props) => {
    if (props.isAgent) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return "#555"
      }
      return "#ffffff"
    }
    return "#555"
  }};
`

export const MessageBubbleBasic = styled.div`
  max-width: 100%;
  padding: 8px 10px;
  border-radius: 8px;
  display: inline-block;
  overflow: hidden;
  line-height: 1.33;
  vertical-align: top;

  &.text {
    white-space: pre-wrap;
    word-break: break-word;
  }
  background-color: ${(props) => {
    if (props.backgroundColor) {
      return props.backgroundColor
    }
    return "white"
  }};
  color: ${(props) => {
    if (props.textColor) {
      return props.textColor
    }
    const bg = Color(props.backgroundColor || "#ffffff")
    if (bg.luminosity() > LUM_THRESHOLD) {
      return "#555"
    }
    return "#ffffff"
  }};
`

export const Button = styled.div`
  min-height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: ${(props) => {
    if (props.color) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return "#0084FF"
      }
      return props.color
    }
    return "#0084FF"
  }};
  padding: ${props => props.isUrlButton ? "0" : "0 16px"};
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;

  a:link, a:visited {
    color: ${(props) => {
      if (props.color) {
        const bg = Color(props.color)
        if (bg.luminosity() > LUM_THRESHOLD) {
          return "#0084FF"
        }
        return props.color
      }
      return "#0084FF"
    }};
    text-decoration: none;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    padding: ${props => props.isUrlButton ? "0 16px" : "auto"};
  }

  ${(props) => {
    let color
    const bg = Color(props.color)
    if (bg.luminosity() > LUM_THRESHOLD) {
      color = "#0084FF"
    } else {
      color = props.color
    }
    return props.isListButton && css`
      border: 1px solid ${color}};
      border-radius: 4px;
      color: ${color};
      margin: 8px 0 0 0;
      display: inline-flex;
      height: 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: ${props.isUrlButton ? "0" : "0 8px"};
      align-items: center;
      justify-content: center;

      a {
        color: ${color};
        padding: ${props.isUrlButton ? "0 8px" : "auto"};
      }
    `
  }}
`
