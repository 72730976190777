/* eslint-disable no-unused-vars */
/** @jsxImportSource theme-ui */

import React, { useEffect, useState, useRef, useContext } from "react"
import {
  useMutation
} from "react-relay"
import _ from "lodash"
import { Box } from "theme-ui"
import { useHistory, useNavigation } from "yarr"
import qs from "query-string"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import styled from "@emotion/styled"
import { ProductFruits } from "react-product-fruits"
import { WoztellContext } from "../../contexts/WoztellContextProvider"
import NetworkStatus from "../../components/NetworkStatus/Status"
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"
import { LoadingMask } from "../../CommonStyles"
import { CheckSessionMutation } from "./LayoutQuery"
import { useSWStore } from "../../index.js"
import { useTranslation } from "react-i18next"

const PUBLIC_URL = process.env.PUBLIC_URL
const PRODUCT_FRUIT_WORKSPACE_CODE = process.env.REACT_APP_PRODUCT_FRUIT_WORKSPACE_CODE

export const UnauthContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.gray7};
  font-size: 0.8rem;
  flex-direction: column;
  overflow: hidden ;
`

const Layout = ({
  children
}) => {
  const { t } = useTranslation("common")
  const { payload, signedContext, loading: contextLoading, productFruitKey } = useContext(WoztellContext)
  const userInfo = {
    username: productFruitKey,
  }
  const { location } = useHistory()
  const navigation = useNavigation()
  const query = qs.parse(location.search)
  const embedded = query.embed === "woztell"
  const [loading, setLoading] = useState(true)
  const [invalid, setInvalid] = useState(false)
  const [checkSession] = useMutation(
    CheckSessionMutation
  )
  const swUpdated = useSWStore((state) => state.swUpdated)
  const swRegistration = useSWStore((state) => state.swRegistration)

  useEffect(() => {
    if (embedded) {
      if (!contextLoading) {
        if (!_.isEmpty(payload) && signedContext) {
          checkSession({
            onCompleted: (data) => {
              setLoading(false)
              if (!data?.checkSession?.valid) {
                setInvalid(true)
              }
            }
          })
        } else {
          setLoading(false)
          setInvalid(true)
        }
      }
    } else {
      // standalone app
      if (!/(login|error|logout)/.test(location.pathname)) {
        checkSession({
          onCompleted: (data) => {
            console.log("checkSession result", JSON.stringify(data))
            setLoading(false)
            if (!data?.checkSession?.valid) {
              // navigation.replace("/login")
              window.location = `${PUBLIC_URL}/login/`
            } else if (data?.checkSession?.error?.code === 404) {
              navigation.replace({
                ...location,
                pathname: "/error",
                search: qs.stringify({ ..._.omit(query, ["thread"]), e: "NO_ORG_FOUND" })
              })
            }
          }
        })
      } else {
        setLoading(false)
      }
    }
  }, [location.pathname, contextLoading, JSON.stringify(payload), signedContext, embedded])

  if (!loading && invalid && embedded) {
    return (
      <UnauthContainer>
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          style={{
            width: "2rem",
            height: "2rem",
            paddingBottom: "4px"
          }}
        />
        {t?.("errors.something_went_wrong_please_try_again_later")}
      </UnauthContainer>
    )
  }
  if (loading) {
    return (
      <LoadingMask sx={{
        width: "100%",
        height: "100%",
        background: "white",
      }}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </LoadingMask>
    )
  }
  return (
    <>
      {children}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "fixed",
          top: "8px",
          left: 0,
          width: "100%",
          zIndex: 999,
          transition: "all 0.2s ease-in-out",
          opacity: swUpdated && swRegistration && !/login_redirect/.test(location.pathname) ? 1 : 0,
          transform: swUpdated && swRegistration && !/login_redirect/.test(location.pathname) ? "translateY(0)" : "translateY(calc(-100% - 16px))",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background: "white",
            boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.15)",
            px: 3,
            py: 2,
            borderRadius: "8px",
            color: "#444",
          }}
        >
          <div>{t?.("new_app_version.a_new_version_of_inbox_is_available")}</div>
          <NewBoxButton
            sx={{
              ml: 2,
            }}
            text={t?.("buttons.reload")}
            primary
            onClick={() => {
              // console.log("swRegistration", swRegistration)
              // console.log("swRegistration.waiting", swRegistration.waiting)
              swRegistration.waiting.postMessage({ type: "SKIP_WAITING" })
              setTimeout(() => {
                window.location.reload()
              }, 100)
            }}
          />
        </Box>

      </Box>
      <NetworkStatus />
      {userInfo?.username && PRODUCT_FRUIT_WORKSPACE_CODE && (
        <ProductFruits workspaceCode={PRODUCT_FRUIT_WORKSPACE_CODE} language="en" user={userInfo} />
      )}
    </>
  )
}


export default Layout
