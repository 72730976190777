import React, { useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { Flex, Box } from "theme-ui"
import {
  faTrash,
  faPen,
  faPeopleArrows
} from "@fortawesome/free-solid-svg-icons"
import {
  useMutation
} from "react-relay"

import IconContainer from "../IconContainer/IconContainer"
import DropdownMenu from "../Radiate/DropdownMenu/DropdownMenu"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import TextField from "../Radiate/TextField/TextField"
import Tag from "../Tag/Tag"
import theme from "../../stylesheets/theme"
import { UserContext } from "../../contexts/UserContext"

import * as  Styles from "./SettingsModalStyles"

import {
  RemoveUserFromChannelMutation,
  UpdateUserMutation,
  UpdateAgentMutation
} from "./SettingsModalQuery"

const AgentCard = ({ 
  agent, 
  wChannelId,
  isAdmin,
  isManager,
  isInitialAdmin,
  isInitialSuperAdmin,
  ownUserId,
  onRefetchData,
  onError,
}) => {
  const { isSuperAdmin, isSuperAuditor } = useContext(UserContext)
  const [editing, setEditing] = useState(false)
  const [agentName, setAgentName] = useState(agent?.name)
  const [agentEmail, setAgentEmail] = useState(agent?.email)
  const [agentIcon, setAgentIcon] = useState(agent?.icon)
  const [agentRole, setAgentRole] = useState(agent?.role)
  const [removeUserFromChannel, isInFlightRemoveUserFromChannel] = useMutation(
    RemoveUserFromChannelMutation
  )
  const [updateUser, isInFlightUpdateUser] = useMutation(
    UpdateUserMutation
  )

  const [updateAgent, isInFlightUpdateAgent] = useMutation(
    UpdateAgentMutation
  )

  const { t } = useTranslation(["settings", "common"])

  const removeUserFromChannelFunction = ({
    wChannelId,
    wUserId
  }) => {
    let clientMutationId = 0
    const mutationParams = {
      variables: {
        input: {
          wChannelId,
          wUserId,
          clientMutationId: clientMutationId++,
        },
      },
      onCompleted: (response, errs) => {
        if (response.removeUserFromChannel?.error) {
          onError(response.removeUserFromChannel?.error?.message || "Something went wrong.")
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        onError(errorMsg)
      }
    }
    removeUserFromChannel(mutationParams)
  }

  const updateUserFunction = ({
    wChannelId,
    wUserId,
    role
  }) => {
    let clientMutationId = 0
    const mutationParams = {
      variables: {
        input: {
          wChannelIds: [wChannelId],
          wUserId,
          role,
          clientMutationId: clientMutationId++,
        },
      },
      onCompleted: (response, errs) => {
        if (!response.updateUser?.error) {
          onRefetchData()
        } else {
          onError(response.updateUser?.error?.message || "Something went wrong.")
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        onError(errorMsg)
      }
    }
    updateUser(mutationParams)
  }

  const UpdateAgenFunction = ({
    email,
    icon,
    name,
    role,
  }) => {
    let clientMutationId = 0
    const mutationParams = {
      variables: {
        input: {
          agentId: agent?._id,
          etag: agent?.etag,
          email,
          icon,
          name,
          role,
          clientMutationId: clientMutationId++,
        },
      },
      onCompleted: (response, errs) => {
        if (!response.updateAgent?.error) {
          setEditing(false)
        } else {
          onError(response.updateAgent?.error?.message)
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        onError(errorMsg)
      }
    }
    updateAgent(mutationParams)
  }
  const renderButtons = () => {
    let buttons = []
    if (isManager) {
      buttons = [
        ...buttons,
        {
          label: t?.("common:buttons.edit"),
          icon: faPen,
          primary: true,
          inverted: true,
          disabled: isSuperAdmin || isSuperAuditor,
          onClick: () => {
            setEditing(true)
          }
        }
      ]
    }
    if (
      (isInitialAdmin || isInitialSuperAdmin)
      && agent?.user?.wUserId !== ownUserId
      && agent?.user?.role === "ADMIN"
      && agent?.user?.initialRole !== "ADMIN"
    ) {
      buttons = [
        ...buttons,
        {
          label: t?.("agent_settings.buttons.demote_to_user_role"),
          icon: faPeopleArrows,
          inverted: true,
          primary: true,
          loading: isInFlightUpdateUser,
          onClick: () => {
            updateUserFunction({
              wUserId: agent?.user?.wUserId,
              wChannelId,
              role: "USER"
            })
          }
        }
      ]
    }
    if (
      isInitialAdmin
      && agent?.user?.wUserId !== ownUserId
      && agent?.user?.role === "USER"
      && agent?.user?.initialRole !== "ADMIN"
    ) {
      buttons = [
        ...buttons,
        {
          label: t?.("agent_settings.buttons.promote_to_admin_role"),
          icon: faPeopleArrows,
          inverted: true,
          primary: true,
          loading: isInFlightUpdateUser,
          onClick: () => {
            updateUserFunction({
              wUserId: agent?.user?.wUserId,
              wChannelId,
              role: "ADMIN"
            })
          }
        }
      ]
    }
    if (
      isAdmin
      && agent?.user?.wUserId !== ownUserId
      && agent?.user?.role === "USER"
    ) {
      buttons = [
        ...buttons,
        {
          label: t?.("common:buttons.remove"),
          icon: faTrash,
          danger: true,
          loading: isInFlightRemoveUserFromChannel,
          inverted: true,
          onClick: () => {
            removeUserFromChannelFunction({
              wUserId: agent?.user?.wUserId,
              wChannelId,
            })
          }
        }
      ]
    }
    return buttons
  }

  let agentRoleTooltipMessage = null
  if (agent?.user?.wUserId === ownUserId) {
    agentRoleTooltipMessage = t?.("agent_settings.tooltip.not_allowed_to_edit_own_role")
  } else if (agent?.user?.role === "ADMIN") {
    agentRoleTooltipMessage = t?.("agent_settings.tooltip.user_with_admin_role_can_only_be_manager")
  }

  function passAgentRoleThroughTranslation(role) {
    if (role === "MANAGER") return t?.("agent_settings.MANAGER_tag")
    if (role === "AGENT") return t?.("agent_settings.AGENT_tag")
    return role
  }

  return (
    <Styles.AgentCard>
      {renderButtons()?.length ?
        (
          <DropdownMenu
            className="agent-card-dropdown-button"
            icon="ellipsis-h"
            inverted
            borderless
            placement="bottom"
            buttons={renderButtons()}
          />
        )
        :
        null
      }
      <Flex sx={{ justifyContent: "center" }}>
        <IconContainer
          className="chat-header-profile-pic"
          url={agentIcon}
          size="M"
          name={agent?.name || agent?.email}
        />
      </Flex>
      <Flex pt={3} sx={{ flexDirection: "column", justifyContent: "space-between", height: "calc(100% - 60px)"}}>
        <Box sx={{ flexGrow: 1 }}>
          {editing ?
            <>
              <TextField
                className="agent-card-text-field"
                placeholder={t?.("common:placeholders.name")}
                value={agentName}
                borderStyle="S"
                useInput
                onChange={(e) => {
                  setAgentName(e.target.value)
                }}
              />
              <TextField
                className="agent-card-text-field"
                placeholder={t?.("common:placeholders.email")}
                value={agentEmail}
                borderStyle="S"
                useInput
                onChange={(e) => {
                  setAgentEmail(e.target.value)
                }}
              />
              <TextField
                className="agent-card-text-field"
                placeholder={t?.("agent_settings.placeholders.profile_image")}
                value={agentIcon}
                borderStyle="S"
                useInput
                onChange={(e) => {
                  setAgentIcon(e.target.value)
                }}
              />
              <Flex 
                mb={2} 
                sx={{ 
                  justifyContent: "center", 
                  gap: "8px", 
                  marginRight: "18px"
                }}
              >
                <Styles.TagButtonBorder
                  isDisabled={agent?.user?.role === "ADMIN" || agent?.user?.wUserId === ownUserId}
                  selected={agentRole === "MANAGER"} 
                  color={theme.themeColor} 
                  isManager
                  data-tooltip-content={agentRoleTooltipMessage}
                  data-tooltip-id="settings-agent-role-tooltip"
                  onClick={() => {
                    if (agent?.user?.role === "ADMIN" || agent?.user?.wUserId === ownUserId) {
                      return
                    }
                    setAgentRole("MANAGER")
                  }}
                >
                  <Styles.TagButton 
                    backgroundColor={theme.themeColor}
                    isDisabled={agent?.user?.role === "ADMIN"}
                    selected={agentRole === "MANAGER"} 
                  >
                    {t?.("agent_settings.MANAGER_tag")}
                  </Styles.TagButton>
                </Styles.TagButtonBorder>
                <Styles.TagButtonBorder 
                  isDisabled={agent?.user?.role === "ADMIN" || agent?.user?.wUserId === ownUserId}
                  selected={agentRole === "AGENT"} 
                  color="#f4ac44" 
                  isAgent
                  data-tooltip-content={agentRoleTooltipMessage}
                  data-tooltip-id="settings-agent-role-tooltip"
                  onClick={() => {
                    if (agent?.user?.role === "ADMIN" || agent?.user?.wUserId === ownUserId) {
                      return
                    }
                    if (agent?.user?.role !== "ADMIN") {
                      setAgentRole("AGENT")
                    }
                  }}
                >
                  <Styles.TagButton
                    backgroundColor="#f4ac44"
                    isDisabled={agent?.user?.role === "ADMIN"}
                    selected={agentRole === "AGENT"} 
                  >
                    {t?.("agent_settings.AGENT_tag")}
                  </Styles.TagButton>
                </Styles.TagButtonBorder>
              </Flex>
            </>
            :
            <>
              <div className="agent-name">{agent?.name || t?.("agent_settings.unamed")}</div>
              <div className="email">{agent?.email}</div>
              <Box my={1}>
                <Tag backgroundColor={agent?.role === "MANAGER" ? theme.themeColor : "#f4ac44"} color="#ffffff">{passAgentRoleThroughTranslation(agent?.role)}</Tag>
              </Box>
            </>
          }
        </Box>
        <Box sx={{ textAlign: "center"}}>
          <div className="separator"></div>
          {editing ?
            <Flex sx={{ width: "100%", gap: "8px" }} pt={2}>
              <NewBoxButton
                style={{ width: "50%" }}
                inverted
                text={t?.("common:buttons.cancel")}
                onClick={() => {
                  setEditing(false)
                  setAgentName(agent?.name)
                  setAgentEmail(agent?.email)
                  setAgentIcon(agent?.icon)
                  setAgentRole(agent?.role)
                }}
              />
              <NewBoxButton
                style={{ width: "50%" }}
                primary
                text={t?.("common:buttons.save")}
                loading={isInFlightUpdateAgent}
                onClick={() => {
                  UpdateAgenFunction({
                    name: agentName,
                    email: agentEmail,
                    icon: agentIcon,
                    role: agentRole
                  })
                }}
              />
            </Flex>
            :
            <Flex
              pt={3}
              sx={{ alignItems: "center", justifyContent: "center" }}
              data-tooltip-content={JSON.stringify(agent?.user)}
              data-tooltip-id="settings-agent-email-tooltip"
              data-user-role={agent?.user?.role}
            >
              <Styles.WoztwellIcon />
              <div className="woztell-email">{agent?.user?.email?.email}</div>
            </Flex>
          }
        </Box>
      </Flex>
    </Styles.AgentCard>
  )
}

export default AgentCard
