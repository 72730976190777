import React, { useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { Box, Text, Flex } from "theme-ui"
import { BlockPicker } from "react-color"
import {
  useMutation,
} from "react-relay"

import ChannelsPicker from "../ChannelsPicker/ChannelsPicker"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import TextField from "../Radiate/TextField/TextField"
import Modal from "../Radiate/Modal/Modal"
import FolderFollowUpActionModal from "./FolderFollowUpActionModal"

import { CreateFolderMutation, UpdateFolderMutation } from "./FolderInfoPopperQuery"
import * as Styles from "./FolderInfoPopperStyles.js"
import styled from "@emotion/styled"
import UserPicker from "../UserPicker/UserPicker"
import { UserContext } from "../../contexts/UserContext.js"

const StyledBlockPicker = styled(BlockPicker)`
  border-radius: 4px !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;

  div:nth-of-type(2) {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }
`

const FolderInfoPopper = ({
  action = "CREATE",
  embedded = false,
  wChannelIds,
  folderId,
  info = {},
  onSuccess,
}) => {
  const { isSuperAdmin, isSuperAuditor } = useContext(UserContext)
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null)
  const [channelIds, setChannelIds] = useState(info?.channelIds ?? [])
  const [name, setName] = useState(info?.name ?? null)
  const [color, setColor] = useState(info?.color ?? "#833AB4")
  const [isPrivate, setPrivate] = useState(info?.isPrivate ?? false)
  const [selectedUserIds, setSelectedUserIds] = useState(info?.userIds ?? [])
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [followUpInfo, setFollowUpInfo] = useState(null)
  const [openFollowUpModal, setOpenFollowUpModal] = useState(null)
  const [commitCreateFolder, isCreatingFolder] = useMutation(
    CreateFolderMutation
  )
  const [commitUpdateFolder, isUpdatingFolder] = useMutation(
    UpdateFolderMutation
  )

  const { t } = useTranslation("common")

  const createFolder = ({ userOption } = {}) => {
    setError("")
    if (name && name.trim()?.length === 0) {
      const folderNameCannotBeEmpty = t?.("new_folder.folder_name_cannot_be_empty")
      setError(folderNameCannotBeEmpty)
      return
    }
    let clientMutationId = 0
    const mutationParams = {
      variables: {
        input: {
          wChannelIds: channelIds,
          name: name.trim(),
          color,
          isPrivate,
          userIds: selectedUserIds,
          userOption,
          clientMutationId: clientMutationId++
        },
      },
      updater: (store) => {
        const createFolderResult = store.getRootField("createFolder")
        const newFolder = createFolderResult.getLinkedRecord("folder")
        if (newFolder) {
          const folderWChannelIds = newFolder.getValue("wChannelIds")
          const userViewer = store.getRoot().getLinkedRecord("userViewer")
          if (!wChannelIds || _.intersection(wChannelIds, folderWChannelIds)) {
            const folders = userViewer.getLinkedRecords("folders", {
              wChannelIds: wChannelIds?.length ? wChannelIds : null,
            })
            userViewer.setLinkedRecords([...folders, newFolder], "folders", { wChannelIds })
          }
        }
      },
      onCompleted: (response, errs) => {
        if (!response.createFolder?.error) {
          if (response.createFolder?.followUpInfo) {
            setFollowUpInfo(response.createFolder.followUpInfo)
            setOpenFollowUpModal(true)
          } else {
            onSuccess()
            setChannelIds([])
            setName("")
            setPrivate(false)
            setSelectedUserIds([])
            setOpenFollowUpModal(false)
            setFollowUpInfo(null)
          }
        } else {
          setError(response.createFolder?.error?.message)
        }
      },
      onError: (err) => {
        console.log(err)
        const errorMsg = err.res?.json?.errors[0]?.message || err
        setError(errorMsg)
      }
    }
    commitCreateFolder(mutationParams)
  }

  const updateFolder = ({ userOption } = {}) => {
    setError("")
    if (name && name.trim()?.length === 0) {
      const folderNameCannotBeEmpty = t?.("new_folder.folder_name_cannot_be_empty")
      setError(folderNameCannotBeEmpty)
      return
    }
    let clientMutationId = 0
    const mutationParams = {
      variables: {
        input: {
          wChannelIds: channelIds,
          name: name.trim(),
          folderId,
          color,
          isPrivate,
          userIds: selectedUserIds,
          userOption,
          clientMutationId: clientMutationId++
        },
      },
      onCompleted: (response, errs) => {
        if (!response.updateFolder?.error) {
          if (response.updateFolder?.followUpInfo) {
            setFollowUpInfo(response.updateFolder.followUpInfo)
            setOpenFollowUpModal(true)
          } else {
            const resultFolder = response?.updateFolder?.folder ?? {}
            setChannelIds(resultFolder?.wChannelIds)
            setName(resultFolder?.name)
            setColor(resultFolder?.color)
            setPrivate(resultFolder?.isPrivate)
            setSelectedUserIds(resultFolder?.userIds)
            setOpenFollowUpModal(false)
            setFollowUpInfo(null)
          }
        } else {
          setError(response.updateFolder?.error?.message)
        }
      },
      onError: (err) => {
        // console.log(err)
        const errorMsg = err.res?.json?.errors[0]?.message || err
        setError(errorMsg)
      }
    }
    commitUpdateFolder(mutationParams)
  }

  let canSave = true
  if (action === "CREATE") {
    if (!name || !channelIds?.length) {
      canSave = false
    }
  } else {
    if (
      (!_.isEqual(info?.channelIds, channelIds) && channelIds?.length)
      || (name !== info?.name && name)
      || color !== info?.color
      || isPrivate !== info?.isPrivate
      || !_.isEqual(selectedUserIds, info?.userIds)
    ) {
      canSave = true
    } else {
      canSave = false
    }
  }

  return (
    <>
      <Styles.FolderInfoContainer
        $embedded={embedded}
      >
        <div className="label">{action === "CREATE" ? t?.("new_folder.create_folder") : t?.("new_folder.edit_folder")}</div>
        {!embedded && <div className="separator"></div>}
        <Box p={3} pt={embedded ? 0 : 3}>
          {info.default ?
            null
            :
            <>
              <Text
                sx={{
                  fontSize: "12px",
                  mb: "2px",
                  display: "inline-block",
                  color: "gray9",
                }}
              >{t?.("new_folder.associated_channels")}</Text>
              <ChannelsPicker
                isDisabled={isSuperAdmin || isSuperAuditor}
                placeholder={t?.("new_folder.select_channels_placeholder")}
                selectedChannelIds={channelIds}
                onChange={(channelIds) => {
                  setChannelIds(channelIds)
                }}
              // showAllChannels
              />
              <Text
                sx={{
                  fontSize: "12px",
                  mb: "2px",
                  display: "inline-block",
                  color: "gray9",
                  mt: 2,
                }}
              >{t?.("new_folder.name")}</Text>
              <TextField
                disabled={isSuperAdmin || isSuperAuditor}
                className="folder-name-text-field"
                borderStyle="M"
                placeholder={t?.("new_folder.folder_name_placeholder")}
                onChange={(e) => {
                  setName(e.target.value)
                }}
                useInput
                value={name}
              />
            </>
          }
          <Text
            sx={{
              mt: 2,
              mb: 1,
              fontSize: "12px",
              display: "block",
              color: "gray9",
            }}
          >{t?.("new_folder.visibility")}</Text>
          <Styles.StyledRadioSelectField
            disabled={isSuperAdmin || isSuperAuditor}
            options={[{
              text: t?.("new_folder.public"),
              description: t?.("new_folder.public_description"),
              value: "public",
            }, {
              text: t?.("new_folder.private"),
              description: t?.("new_folder.private_description"),
              value: "private",
            }]}
            value={isPrivate ? "private" : "public"}
            onChange={(value) => {
              setPrivate(value === "private")
            }}
          />
          {isPrivate && (
            <>
              <Text
                sx={{
                  fontSize: "12px",
                  mb: "2px",
                  display: "inline-block",
                  color: "gray9",
                  mt: 2,
                }}
              >{t?.("new_folder.users")}</Text>
              <UserPicker
                onlyActivated
                isMulti
                onChange={(userIds) => setSelectedUserIds(userIds)}
                userId={selectedUserIds}
              />
            </>
          )}

          <Flex
            sx={{
              mt: 3,
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Text
              sx={{
                fontSize: "12px",
                display: "block",
                color: "gray9",
              }}
            >{t?.("new_folder.color")}</Text>
            <Styles.StyledPopperContainer
              show={showColorPicker}
              placement="left"
              addDefaultWrapper={false}
              display={(
                <Box
                  sx={{
                    background: color,
                    width: "44px",
                    height: "22px",
                    borderRadius: "4px",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    setShowColorPicker((s) => !s)
                  }}
                ></Box>
              )}
              modifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [0, 8],
                  },
                },
              ]}
              onClickOutside={() => {
                if (showColorPicker) {
                  setShowColorPicker(false)
                }
              }}
            >
              {() => (
                <StyledBlockPicker
                  triangle="hide"
                  color={color}
                  onChangeComplete={(color) => {
                    setColor(color.hex)
                  }}
                />
              )}
            </Styles.StyledPopperContainer>
          </Flex>
          <NewBoxButton
            style={{ marginTop: "16px", width: "100%" }}
            primary
            text={t?.("buttons.confirm")}
            disabled={!canSave || isSuperAdmin || isSuperAuditor}
            loading={isCreatingFolder || isUpdatingFolder}
            onClick={() => {
              if (action === "CREATE") {
                createFolder()
              } else {
                updateFolder()
              }
            }}
          />
          {error ?
            <div className="error">{error}</div>
            :
            null
          }
        </Box>
      </Styles.FolderInfoContainer>
      <Modal
        open={openFollowUpModal}
      >
        {() => (
          <FolderFollowUpActionModal
            followUpInfo={followUpInfo}
            onCancel={() => {
              setOpenFollowUpModal(false)
            }}
            onSkip={() => {
              if (action === "CREATE") {
                createFolder({
                  userOption: "skip"
                })
              } else {
                updateFolder({
                  userOption: "skip"
                })
              }
            }}
            onAssociate={() => {
              if (action === "CREATE") {
                createFolder({
                  userOption: "associate"
                })
              } else {
                updateFolder({
                  userOption: "associate"
                })
              }
            }}
          />
        )}
      </Modal>
    </>
  )
}

export default FolderInfoPopper
