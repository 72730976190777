/* eslint-disable no-restricted-globals */

import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  faPen,
  faTrash
} from "@fortawesome/free-solid-svg-icons"
import { Flex } from "theme-ui"
import moment from "moment"
import {
  useMutation
} from "react-relay"
import TextField from "../Radiate/TextField/TextField"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import DropdownMenu from "../Radiate/DropdownMenu/DropdownMenu"

import {
  MemberContainerAddNoteToMemberMutation,
  MemberContainerRemoveNoteFromMemberMutation,
  MemberContainerUpdateNoteMutation,
} from "./MemberContainerQuery"
import * as  Styles from "./MemberContainerStyles"

const Note = ({
  wMemberId,
  ownUserId,
  noteInfo,
  onCancelUnsavedNote
}) => {
  const { t } = useTranslation("common")
  const [commitAddNote, isInFlighAddNote] = useMutation(MemberContainerAddNoteToMemberMutation)
  const [commitRemoveNote, isInFlighRemoveNote] = useMutation(MemberContainerRemoveNoteFromMemberMutation)
  const [commitUpdateNote, isInFlighUpdateNote] = useMutation(MemberContainerUpdateNoteMutation)
  const [content, setContent] = useState(noteInfo?.content)
  const [editing, setEditing] = useState(noteInfo?._id ? false : true)
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState("")

  const addNoteFunction = ({content, wMemberId }) => {
    let clientMutationId = 0
    let variables = {
      wMemberId,
      note: {
        content
      },
      clientMutationId: clientMutationId++,
    }
    const mutationParams = {
      variables: {
        input: variables,
      },
      onCompleted: (response, errs) => {
        if (!response.addNoteToMember?.error) {
          setEditing(false)
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        console.log("errorMsg", errorMsg)
        setError(errorMsg)
      }
    }
    commitAddNote(mutationParams)
  }

  const updateNoteFunction = ({ noteId, content, wMemberId, etag }) => {
    let clientMutationId = 0
    let variables = {
      content,
      wMemberId,
      noteId,
      etag,
      clientMutationId: clientMutationId++,
    }
    const mutationParams = {
      variables: {
        input: variables,
      },
      onCompleted: (response, errs) => {
        if (!response.updateNote?.error) {
          setEditing(false)
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        setError(errorMsg)
      }
    }
    commitUpdateNote(mutationParams)
  }

  const removeNoteFunction = ({ noteId, wMemberId }) => {
    let clientMutationId = 0
    let variables = {
      wMemberId,
      noteId,
      clientMutationId: clientMutationId++,
    }
    const mutationParams = {
      variables: {
        input: variables,
      },
      onCompleted: (response, errs) => {
        if (!response.removeNoteFromMember?.error) {
          setEditing(false)
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        setError(errorMsg)
      }
    }
    commitRemoveNote(mutationParams)
  }

  const isWrittenByMe = noteInfo?.authorId === ownUserId
  return (
    <>
      {editing || !noteInfo?._id ?
        <Styles.NoteContainer isWrittenByMe={isWrittenByMe}>
          <div className="buttons-container">
            <NewBoxButton
              text={t?.("buttons.cancel")}
              onClick={() => {
                if (noteInfo?._id) {
                  setEditing(false)
                } else {
                  onCancelUnsavedNote(noteInfo?.noteId)
                }
              }}
            />
            <NewBoxButton
              className="save-button"
              text={t?.("buttons.save")}
              loading={isInFlighAddNote || isInFlighUpdateNote}
              disabled={!content?.length}
              primary
              onClick={() => {
                if (noteInfo?._id) {
                  updateNoteFunction({
                    content,
                    wMemberId,
                    noteId: noteInfo?._id,
                    etag: noteInfo?.etag
                  })
                } else {
                  addNoteFunction({ content, wMemberId })
                }
              }}
            />
          </div>
          <TextField
            className="note-text-field"
            borderStyle="M"
            value={content}
            onChange={(e) => {
              setContent(e.target.value)
            }}
          />
          {noteInfo?._id &&
            <div className="author">{t?.("notes.by")} {isWrittenByMe ? t?.("notes.me") : noteInfo?.author?.email?.email} {t?.("notes.at")} {t?.("notes.updated_at_date", { date: moment(noteInfo?.updatedAt) })}</div>
          }
        </Styles.NoteContainer>
        :
        <Styles.NoteContainer isWrittenByMe={isWrittenByMe}>
          {
            isWrittenByMe && (
              <Flex sx={{ alignItems: "center", justifyContent: "flex-end" }}>
                <DropdownMenu
                  icon="ellipsis-h"
                  inverted
                  borderless
                  placement="bottom"
                  buttons={[
                    {
                      label: t?.("buttons.edit"),
                      icon: faPen,
                      primary: true,
                      inverted: true,
                      onClick: () => {
                        setEditing(true)
                      },
                    }, {
                      label: t?.("buttons.remove"),
                      icon: faTrash,
                      danger: true,
                      inverted: true,
                      loading: isInFlighRemoveNote,
                      onClick: () => {
                        removeNoteFunction({ noteId: noteInfo?._id, wMemberId })
                      },
                    }
                  ]}
                />
              </Flex>
            )
          }
          <div className="content">{content}</div>
          <div className="author">{t?.("notes.by")} {noteInfo?.authorId === ownUserId ? t?.("notes.me") : noteInfo?.author?.email?.email} {t?.("notes.at")} {t?.("notes.updated_at_date", { date: moment(noteInfo?.updatedAt) })}</div>
        </Styles.NoteContainer>
      }
    </>
  )
}

export default Note
