/**
 * @generated SignedSource<<21b1ec3789bffd6a60bd3c5b6938ef83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "externalId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "wChannelId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "wMemberId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "withoutNavHint"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./InboxNavHintRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "Inbox_navHintViewer",
  "selections": [
    {
      "condition": "withoutNavHint",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "externalId",
              "variableName": "externalId"
            },
            {
              "kind": "Variable",
              "name": "wChannelId",
              "variableName": "wChannelId"
            },
            {
              "kind": "Variable",
              "name": "wMemberId",
              "variableName": "wMemberId"
            }
          ],
          "concreteType": "Thread",
          "kind": "LinkedField",
          "name": "thread",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "threadId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "folderId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "UserScope",
  "abstractKey": null
};

node.hash = "0c7c45ffbca192b923424986308275cd";

module.exports = node;
