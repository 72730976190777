import React from "react"
import { withTranslation } from "react-i18next"
import _ from "lodash"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faImage
} from "@fortawesome/free-solid-svg-icons"

const ImageBubblePlaceholder = styled.div`
  border-radius: 8px;
  min-height: 160px;
  background-color: ${(props) => props.isAgent ? props.theme.themeColorGreen : "#FFFFFF"};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.messageBubbleBoxShadow};

  .icon-wrapper {
    padding: 16px;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 160px;
    height: 160px;
    justify-content: center;
    border-radius: 8px;

    ${props => props.hasCaption && css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

    .icon {
      font-size: 50px;
      color: ${(props) => props.isAgent ? "#ffffff" : props.theme.gray4};
      margin-bottom: 8px;
    }

    span {
      font-size: 0.7rem;
      text-align: center;
      color: ${(props) => props.isAgent ? "#ffffff" : props.theme.gray7};
    }
  }

  .separator {
    height: 1px;
    width: 100%;
    background: ${(props) => props.isAgent ? props.theme.gray4 : props.theme.gray1};
  }
  
  .caption {
    width: 100%;
    padding: 4px 8px;
    word-break: break-word;
    line-height: 1.2rem;
    color: ${(props) => props.isAgent ? "#ffffff" : props.theme.gray7};
    white-space: pre-wrap;
  }

`
const ImageBubble = styled.div`
  position: relative;
  box-shadow: ${(props) => props.theme.messageBubbleBoxShadow};
  border-radius: 8px;
  background-color: ${(props) => props.isAgent ? props.theme.themeColorGreen : "#FFFFFF"};

  img {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    vertical-align: bottom;

    ${props => props.hasCaption && css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
  }

  .separator {
    height: 1px;
    width: 100%;
    background: ${(props) => props.isAgent ? props.theme.gray4 : props.theme.gray1};

  }
  
  .caption {
    width: 100%;
    padding: 4px 8px;
    word-break: break-word;
    line-height: 1.2rem;
    color: ${(props) => props.isAgent ? "#ffffff" : props.theme.gray7};
    white-space: pre-wrap;
  }
`
const ImageLinkOutWrapper = styled.a`
  position: absolute;
  top: 0;
  right: ${props => !props.isAgent && "-80px"};
  left: ${props => props.isAgent && "-80px"};
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: auto;
  opacity: ${props => props.show ? "1" : "0"};
  pointer-event: ${props => props.show ? "all" : "none"};
  transition: all 0.2s linear;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;

  .button {
    background: #F1F0F0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    color: #888;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
`

const ImageWrapper = styled.a`
  href: ${props => props.href};
  cursor: ${props => props.hover ? "pointer" : "default"};
`

class Image extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hover: false
    }
  }

  render() {
    const t = this.props.t
    const { data, isAgent, color } = this.props
    const url = data.url || data.downloadUrl || _.get(data, "attachments[0].url")
    if (url) {
      return (
        <ImageBubble
          isAgent={isAgent}
          hasCaption={!!(data.text || data.caption)}
          onFocus={() => {
            this.setState({
              hover: true
            })
          }}
          onBlur={() => {
            this.setState({
              hover: false
            })
          }}
          onMouseOver={() => {
            this.setState({
              hover: true
            })
          }}
          onMouseLeave={() => {
            this.setState({
              hover: false
            })
          }}
          onClick={() => {
            this.setState({
              hover: !this.state.hover
            })
          }}
        >
          <ImageWrapper href={url} hover={this.state.hover} target="_blank" rel="noopener noreferrer">
            <img src={url} alt="" />
          </ImageWrapper>
          <ImageLinkOutWrapper
            isAgent={isAgent}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            show={this.state.hover}
          >
            <div className="button">
              <FontAwesomeIcon
                icon="external-link"
                className="icon"
                size="sm"
              />
            </div>
          </ImageLinkOutWrapper>
          {
            data.text || data.caption ?
              <>
                <div className="separator"></div>
                <div className="caption">{data.text || data.caption}</div>
              </>
              :
              null
          }
        </ImageBubble>
      )
    }
    return (
      <ImageBubblePlaceholder
        isAgent={isAgent}
        color={color}
        hasCaption={!!(data.text || data.caption)}
      >
        <div className="icon-wrapper">
          <FontAwesomeIcon
            className="icon"
            icon={faImage}
          />
          <span>{t?.("message_types.image.image_cannot_be_loaded")}</span>
        </div>
        {
          data.text || data.caption ?
            <>
              <div className="separator"></div>
              <div className="caption">{data.text || data.caption}</div>
            </>
            :
            null
        }
      </ImageBubblePlaceholder>
    )
  }
}

export default withTranslation("common", { withRef: true })(Image)
