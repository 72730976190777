/** @jsxImportSource theme-ui */

import React from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { Flex } from "theme-ui"
import Color from "color"

const LUM_THRESHOLD = 0.6


const UserIcon = styled(FontAwesomeIcon)`
    color: ${(props) => props.isPlaceholder ? "#ababab" : props.theme.gray5};
    width: 36px;
    height: 36px;
`

const Text = styled.div`
  padding-left: 8px;
  color: ${(props) => props.isPlaceholder ? "#ababab" : props.theme.gray7};
`

const ContactRow = styled.div`
  padding: 8px 12px;
  border-top: 1px solid #e8e7e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #626262;
  box-sizing: border-box;
  font-size: ${(props) => props.theme.textSm};

  .phone-type {
     width: 54px;
     font-weight: bold;
  }
`

const MessageBubble = styled.div`
  max-width: 100%;
  border-radius: 8px;
  display: inline-block;
  overflow: hidden;
  line-height: 1.33;
  vertical-align: top;
  margin-bottom: 8px;

  &.text {
    white-space: pre-wrap;
    word-break: break-word;
  }
  background-color: ${(props) => {
    if (props.isAgent) {
      const bg = Color(props.color)
      if (bg.luminosity() > LUM_THRESHOLD) {
        return "#0084FF"
      }
      return props.color
    }
    return "white"
  }};
  color: ${(props) => {
    if (props.isAgent) {
      return "#ffffff"
    }
    return "#555"
  }};
`

const Contacts = ({ data, isAgent = false, color }) => {
  const { t } = useTranslation("common")
  console.log("data", data)
  if (data?.contact) {
    return (
      <MessageBubble isAgent={isAgent} color={color} >
        <Flex
          sx={{
            padding: "12px",
            height: "60px",
            alignItems: "center",
            boxSizing: "border-box"
          }}
        >
          <UserIcon
            className="icon"
            icon={faUserCircle}
            sx={{ color: "#ababab" }}
          />
          <div sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", paddingLeft: "16px", color: "#626262" }}>
            {_.get(data.contact, "name.formatted_name") || _.get(data.contact, "name.first_name") || ""}
          </div>
        </Flex>
        {_.get(data.contact, "org.company") && (
          <ContactRow style={{ justifyContent: "flex-start" }}>
            <div style={{ width: "64px", fontWeight: "bold" }}>{t?.("message_types.contacts.company")}</div>
            <div style={{ width: "calc(100% - 64px)" }}>{data.contact.org.company}</div>
          </ContactRow>
        )}
        {(_.get(data.contact, "phones") || []).map((phone) => {
          return (
            <ContactRow sx={{ justifyContent: "flex-start" }}>
              <div className="phone-type">{_.startCase(phone.type.toLowerCase())}</div>
              <div>{phone.phone}</div>
            </ContactRow>
          )
        })}
        {(_.get(data.contact, "emails") || []).map((email, i) => {
          return (
            <ContactRow key={i} style={{ justifyContent: "flex-start" }}>
              <div style={{ width: "54px", fontWeight: "bold" }}>{_.get(email, "type") ? _.startCase(email.type.toLowerCase()) : t?.("message_types.contacts.email")}</div>
              <div style={{ width: "calc(100% - 54px)" }}>
                <a href={`mailto:${email.email}`} target="_blank" rel="noreferrer noopener" style={{ lineHeight: "1.2" }}>{email.email}</a>
              </div>
            </ContactRow>
          )
        })}
        {(_.get(data.contact, "addresses") || []).map((address, i) => {
          const street = address.street
          return (
            <ContactRow key={i} style={{ justifyContent: "flex-start" }}>
              <div style={{ width: "54px", fontWeight: "bold" }}>{_.get(address, "type") ? _.startCase(address.type.toLowerCase()) : t?.("message_types.contacts.address")}</div>
              <div style={{ width: "calc(100% - 54px)" }}>
                <div style={{ lineHeight: "1.2" }}>{address.country}</div>
                <div style={{ lineHeight: "1.2" }}>{address.city}</div>
                <div style={{ lineHeight: "1.2" }}>{address.zip}</div>
                <div style={{ lineHeight: "1.2" }}>{street}</div>
              </div>
            </ContactRow>
          )
        })}
        {(_.get(data.contact, "urls") || []).map((url, i) => {
          return (
            <ContactRow key={i} style={{ justifyContent: "flex-start" }}>
              <div style={{ width: "72px", fontWeight: "bold" }}>{_.get(url, "type") ? _.startCase(url.type.toLowerCase()) : t?.("message_types.contacts.url")}</div>
              <div style={{ width: "calc(100% - 72px)" }}>
                <a href={url.url} target="_blank" rel="noreferrer noopener" style={{ lineHeight: "1.2" }}>{url.url}</a>
              </div>
            </ContactRow>
          )
        })}
      </MessageBubble>
    )
  }
  return (
    <Flex sx={{ padding: "12px 8px" }} isPlaceholder>
      <UserIcon
        className="icon"
        icon={faUserCircle}
      />
      <Text isPlaceholder>{t?.("message_types.contacts.contact")}</Text>
    </Flex>
  )
}

export default Contacts
