import React from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faVideo
} from "@fortawesome/free-solid-svg-icons"
import platform from "platform"

const VideoBubble = styled.div`
  padding: 0;
  max-width: 100%;
  border-radius: 8px;

  >a {
    width: 100%;
    height: 100%;
    display: block;
  }
  video {
    width: 100%;
    vertical-align: bottom;
    border-radius: 8px;
  }

  &.android {
    video {
      min-height: 150px;
    }
  }
`

const VideoBubblePlaceholder = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 160px;
  height: 160px;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.isAgent ? props.theme.themeColorGreen : "#FFFFFF"};

  .icon {
    font-size: 50px;
    color: ${(props) => props.isAgent ? "#ffffff" : props.theme.gray4};
    margin-bottom: 8px;
  }

  span {
    font-size: ${(props) => props.theme.textSm};
    text-align: center;
    color: ${(props) => props.isAgent ? "#ffffff" : props.theme.gray7};
  }
`

const Video = ({ data, isAgent }) => {
  const url = data.url || data.downloadUrl || _.get(data, "attachments[0].url")
  const { t } = useTranslation("common")
  if (url) {
    return (
      <VideoBubble isAgent={isAgent} className={`${platform.os.family === "Android" || platform.os.family === "Windows Phone" ? "android" : ""}`}>
        <video src={url} controls>
        </video>
      </VideoBubble>
    )
  }
  return (
    <VideoBubblePlaceholder isAgent={isAgent}>
      <FontAwesomeIcon
        className="icon"
        icon={faVideo}
      />
      <span>{t?.("message_types.video.video_cannot_be_loaded")}</span>
    </VideoBubblePlaceholder>
  )
}

export default Video
