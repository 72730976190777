/**
 * @generated SignedSource<<79573d778be9c7cca71abca78f43cf1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./UserContextRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "UserContext_userViewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "UserScope",
  "abstractKey": null
};

node.hash = "23c593a950243df45b42adf809881e52";

module.exports = node;
