/**
 * @generated SignedSource<<89174297549033865d33597c1bde6a3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "InitializeLogoutPayload",
    "kind": "LinkedField",
    "name": "initializeLogout",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsModalQuery_initializeLogoutMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SettingsModalQuery_initializeLogoutMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d2a17f09be83594b9459a17245b8d2c5",
    "id": null,
    "metadata": {},
    "name": "SettingsModalQuery_initializeLogoutMutation",
    "operationKind": "mutation",
    "text": "mutation SettingsModalQuery_initializeLogoutMutation {\n  initializeLogout {\n    ok\n    token\n    state\n    error {\n      code\n      message\n    }\n  }\n}\n"
  }
};
})();

node.hash = "f49f840e52631e31c83d979f42f83b68";

module.exports = node;
