/**
 * @generated SignedSource<<b2dfd9b890e3b4b1c9d863b95af3cd3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserScope",
        "kind": "LinkedField",
        "name": "userViewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserContext_userViewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserScope",
        "kind": "LinkedField",
        "name": "userViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d07f67e81d89f0b1638d0359598f8e48",
    "id": null,
    "metadata": {},
    "name": "UserContextQuery",
    "operationKind": "query",
    "text": "query UserContextQuery {\n  userViewer {\n    ...UserContext_userViewer\n    id\n  }\n}\n\nfragment UserContext_userViewer on UserScope {\n  role\n  id\n}\n"
  }
};

node.hash = "d0ad471f5ecaf0381f4b36f39b45726a";

module.exports = node;
