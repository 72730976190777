/** @jsxImportSource theme-ui */

import React from "react"
import _ from "lodash"
import moment from "moment"
import styled from "@emotion/styled"
import styledComponent from "styled-components/macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons"
import Slider from "react-slick"
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel"

import { timestampNormalizer } from "../util"
import Audio from "../Audio/Audio"
import ButtonTemplate from "../ButtonTemplate/ButtonTemplate"
import File from "../File/File"
import Carousel from "../Carousel/Carousel"
import Image from "../Image/Image"
import Animation from "../Animation/Animation"
import List from "../List/List"
import Media from "../Media/Media"
import Payload from "../Payload/Payload"
import Text from "../Text/Text"
import Video from "../Video/Video"
import Misc from "../Misc/Misc"
import Raw from "../Raw/Raw"
import MediaDownloadWrapper from "../MediaDownload/MediaDownload"
import IconContainer from "../../IconContainer/IconContainer"
import ExternalLinkOutWrapper from "../../ExternalLinkOutWrapper/ExternalLinkOutWrapper"
import PopperContainer from "../../Radiate/PopperContainer/PopperContainer"
import PopupMenuCollapseButton from "../PopupMenu/CollapseButton"
import { detectEmailAndUrl } from "../../ChatVisualizer/util"
import { Buffer } from "buffer"
import { MOBILE_BREAKPOINT, MOBILE_MESSAGE_MAX_WIDTH } from "../../../const"
import { ReactLive } from "../../ReactLive"

const unreadColor = "#0063f00f"
const unreadHoverColor = "#0063f005"

const Row = styled.div`
  transition: background 0.1s linear;
  background: ${(props) => props.unread ? unreadColor : "transparent"};

  .popup-menu {
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    background: ${(props) => props.unread ? unreadHoverColor : "rgba(255, 255, 255, 0.5)"};

    .popup-menu {
      opacity: 1;
      pointer-events: all;
    }
  }
  
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .content-info > .content {
      max-width: ${MOBILE_MESSAGE_MAX_WIDTH};
    }
  }
`

function renderMessage(message, options, color) {
  switch (message.type) {
    case "BUTTON":
      return (<ButtonTemplate data={message.data} color={color} />)
    case "FILE":
      return (<File data={message.data} color={color} />)
    case "VIDEO":
      return (<Video data={message.data} color={color} />)
    case "AUDIO":
    case "TEAMWORK_VOICE":
      return (<Audio data={message.data} color={color} />)
    case "IMAGE":
      return (<Image data={message.data} color={color} />)
    case "ANIMATION":
      return (<Animation data={message.data} color={color} />)
    case "ATTACHMENT":
    case "TEXT":
    case "QUICK_REPLIES":
      return (<Text data={message.data} color={color} />)
    case "LIST":
      return (<List data={message.data} color={color} />)
    case "CAROUSEL":
      return (<Carousel data={message.data} itemWidth={options.carouselItemWidth} color={color} />)
    case "MEDIA":
      return (<Media data={message.data} color={color} />)
    case "PAYLOAD":
      return (<Payload data={message.data} color={color} />)
    case "MISC":
      return (<Misc data={message.data} color={color} />)  
    default:
      return (<Raw data={message} />)
  }
}

class User extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      contentWidth: 300,
      rowAlignItems: "flex-start",
      openMenu: false,
    }
  }
  componentDidMount() {
    if (this.content) {
      const width = this.content.offsetWidth
      if (width < this.state.contentWidth) {
        this.setState({
          contentWidth: width
        })
      }
    }
    setTimeout(() => {
      if (this.row) {
        if (this.row.offsetHeight <= 68) {
          this.setState({
            rowAlignItems: "center"
          })
        }
      }
    }, 500)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !_.isEqual(this.props.message, nextProps.message)
      || !_.isEqual(this.props.resourceTemplate, nextProps.resourceTemplate)
      || this.props.profilePic !== nextProps.profilePic
      || this.props.readOnly !== nextProps.readOnly
      || this.props.isLast !== nextProps.isLast
      || this.props.noProfilePic !== nextProps.noProfilePic
      || this.props.noTimeDisplay !== nextProps.noTimeDisplay
      || this.props.themeColor !== nextProps.themeColor
      || this.props.firstName !== nextProps.firstName
      || this.props.readAt !== nextProps.readAt
      || this.props.deliveredAt !== nextProps.deliveredAt
      || this.props.failedAt !== nextProps.failedAt
      || this.props.chatId !== nextProps.chatId
      || this.props.error !== nextProps.error
      || this.props.unread !== nextProps.unread
      || this.state.rowAlignItems !== nextState.rowAlignItems
      || this.state.openMenu !== nextState.openMenu
    ) {
      return true
    }
    return false
  }

  render() {
    const {
      message,
      profilePic,
      noProfilePic,
      noTimeDisplay,
      themeColor,
      firstName,
      resourceTemplate = null,
      readAt,
      deliveredAt,
      failedAt,
      error,
      app,
      appIntegration,
      channel,
      appIntegrationSignature,
      isConversation = false,
      messageMenu = null,
      messageMenuStyle,
      messageMenuPlacement,
      unread,
    } = this.props

    const rowStyle = {
      alignItems: this.state.rowAlignItems
    }

    const payload = {
      app,
      appIntegration,
      channel,
    }
    let encodedPayload = null
    if (Buffer) {
      encodedPayload = Buffer.from(JSON.stringify(payload)).toString("base64")
    }
    const signedContext = `${appIntegrationSignature}.${encodedPayload}`
    const MediaDownload = MediaDownloadWrapper({
      isConversation,
      from: "MEMBER",
    })

    const scope = {
      styled: styledComponent,
      Slider,
      FontAwesomeIcon,
      ResponsiveCarousel,
      detectEmailAndUrl,
      serverAPIPath: `${process.env.REACT_APP_WOZTELL_URL}/api`,
      data: {
        ...message?.data,
        isConversation,
        from: "MEMBER",
        isInbox: true,
        ExternalLinkOutWrapper,
      },
      MediaDownload,
      // do not use "for" as a key for scope since it is a reserved word
      forMember: true,
      payload,
      signedContext,
    }

    // console.log("INBOX FRONT-END USER SCOPE: ", scope)

    const timeInfo = {
      readAt,
      deliveredAt,
      failedAt
    }
    
    return (
      <Row
        ref={(c) => { this.row = c }}
        style={rowStyle}
        className="row user"
        onMouseLeave={() => {
          this.setState({
            openMenu: false,
          })
        }}
        unread={unread}
      >
        {noProfilePic ?
          null
          :
          <div style={{ marginRight: "8px" }}>
            <IconContainer
              url={profilePic}
              size="S"
              name={firstName}
            />
          </div>
        }
        <div
          className="content-info"
          sx={{
            position: "relative"
          }}
        >
          <div
            className="content"
            ref={(c) => { this.content = c }}
          >
            {resourceTemplate?.preview ?
              <ReactLive 
                resourceTemplate={resourceTemplate}
                scope={scope}
              />
              :
              <>
                {renderMessage(message, { carouselItemWidth: this.state.contentWidth }, themeColor)}
              </>
            }
          </div>
          <div>
            {error &&
              <>
                <FontAwesomeIcon
                  className="error-icon"
                  icon={faExclamationTriangle}
                  data-tooltip-content={error}
                  data-tooltip-id="chat-visualizer-tooltip"
                  data-tooltip-place="right"
                />
              </>
            }
            {noTimeDisplay ?
              null
              :
              <div
                className="time-display"
                data-tooltip-content={(failedAt || readAt || deliveredAt) ? JSON.stringify(timeInfo) : null}
                data-tooltip-id="chat-visualizer-tooltip"
                data-tt-type="time-display"
                data-tooltip-place="right"
              >
                {timestampNormalizer(message.timestamp) ? moment(timestampNormalizer(message.timestamp)).format("HH:mm") : null}
              </div>
            }
            {messageMenu && (
              <PopperContainer
                className="popup-menu"
                display={(
                  <PopupMenuCollapseButton
                    onClick={() => {
                      this.setState({
                        openMenu: true,
                      })
                    }}
                  />
                )}
                sx={messageMenuStyle}
                placement={messageMenuPlacement}
                show={this.state.openMenu}
                onClickOutside={() => {
                  this.setState({
                    openMenu: false,
                  })
                }}
                addDefaultWrapper={false}
              >
                {() => messageMenu}
              </PopperContainer>
            )}
          </div>
        </div>
      </Row>
    )
  }
}

export default React.memo(User, (prevProps, props) => {
  if (prevProps.readAt !== props.readAt) {
    return false
  }
  if (prevProps.deliveredAt !== props.deliveredAt) {
    return false
  }
  if (prevProps.failedAt !== props.failedAt) {
    return false
  }
  if (prevProps.error !== props.error) {
    return false
  }
  if (prevProps.chatId !== props.chatId) {
    return false
  }
  if (prevProps.unread !== props.unread) {
    return false
  }
  if (!_.isEqual(prevProps.messageMenu, props.messageMenu)) {
    return false
  }
  return true
})
