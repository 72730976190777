/**
 * @generated SignedSource<<23db1405e3912673a5e2bcb224b56efc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "expectedMediaTypes"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fileId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "firstLoad"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "Variable",
  "name": "expectedMediaTypes",
  "variableName": "expectedMediaTypes"
},
v3 = {
  "kind": "Variable",
  "name": "fileId",
  "variableName": "fileId"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MediaDownloadRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "Variable",
                "name": "firstLoad",
                "variableName": "firstLoad"
              }
            ],
            "kind": "FragmentSpread",
            "name": "MediaDownload_userViewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MediaDownloadRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "condition": "firstLoad",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "concreteType": "FilePayload",
                    "kind": "LinkedField",
                    "name": "file",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "file",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FileType",
                            "kind": "LinkedField",
                            "name": "fileType",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "codec",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "default",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "size",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Error",
                        "kind": "LinkedField",
                        "name": "error",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "type": "UserScope",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6ba0bd8af9b8d75e5a477aa113be96b1",
    "id": null,
    "metadata": {},
    "name": "MediaDownloadRefetchQuery",
    "operationKind": "query",
    "text": "query MediaDownloadRefetchQuery(\n  $expectedMediaTypes: [FileTypeInput]\n  $fileId: ID!\n  $firstLoad: Boolean!\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...MediaDownload_userViewer_325wFT\n    id\n  }\n}\n\nfragment MediaDownload_userViewer_325wFT on UserScope {\n  file(fileId: $fileId, expectedMediaTypes: $expectedMediaTypes) @skip(if: $firstLoad) {\n    file {\n      fileId\n      fileType {\n        codec\n        default\n        type\n      }\n      url\n      size\n    }\n    error {\n      code\n      message\n    }\n  }\n  id\n}\n"
  }
};
})();

node.hash = "168f566b4e7df978c4dcb8ba29bed088";

module.exports = node;
