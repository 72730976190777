import styled from "@emotion/styled"
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"

const StatusWrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: ${(props) => props.show ? 'visible' : 'hidden'};
    opacity: ${(props) => props.show ? '1' : '0'};
    z-index: 101;
    .popup {
        margin: auto auto;
        padding: 20px;
        width: 100%;
        position: relative;
        -webkit-transition: all 5s ease-in-out;
        transition: all 5s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: white;
	    background: #000000a6;
        .content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            h4 {
                font-size: 25px;
                text-align: center;
                margin: 0;
            }
            p {
                font-size: 14px;
                text-align: center;
                margin: 0;
            }
            svg {
                font-size: 100px;
            }
        }
    }
`

const NetworkStatus = () => {
  const [isOffOnline, SetOffOnline] = useState(false)

  useEffect(() => {
    const checking = (e) => {
      console.log(e, 'offline');
      if (e.type === 'online') {
        SetOffOnline(false)
      }
      if (e.type === 'offline') {
        SetOffOnline(true)
      }
    }
    window.addEventListener('online', checking)
    window.addEventListener('offline', checking)
    return () => {
      window.removeEventListener('online', checking)
      window.removeEventListener('offline', checking)
    }
  }, [])

  return (
    <>
      <StatusWrapper show={isOffOnline}>
        <div className="popup">
          <div className="content">
            <FontAwesomeIcon icon={faCloudArrowDown} />
            <h4>Your Connection was Offline.</h4>
            <p>Please check your internet connection.</p>
          </div>
        </div>
      </StatusWrapper>
    </>
  )
}

export default NetworkStatus