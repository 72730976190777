/* eslint-disable no-restricted-globals */
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Flex, Text } from "theme-ui"
import {
  faPlug,
  faPlugCircleXmark
} from "@fortawesome/free-solid-svg-icons"

import IntegrationIconDisplay from "../IntegrationIconDisplay/IntegrationIconDisplay"

import {
  useMutation
} from "react-relay"
import {
  ConnectChannelMutation,
  DisconnectChannelMutation,
} from "./SettingsModalQuery"
import { UserContext } from "../../contexts/UserContext"

import * as  Styles from "./SettingsModalStyles"

const ChannelRow = ({
  channel,
  onError
}) => {
  const { isSuperAdmin, isSuperAuditor } = useContext(UserContext)
  const { t } = useTranslation(["settings", "common"])
  const [connectChannel, isInFlightConnectChannel] = useMutation(
    ConnectChannelMutation
  )
  const [disconnectChannel, isInFlightDisconnectChannel] = useMutation(
    DisconnectChannelMutation
  )

  const connectChannelFunction = ({
    wChannelId,
  }) => {
    let clientMutationId = 0
    const mutationParams = {
      variables: {
        input: {
          wChannelId,
          clientMutationId: clientMutationId++,
        },
      },
      onCompleted: (response, errs) => {
        if (!response.connectChannel?.error) {
        } else {
          const somethingWentWrong = t?.("common:errors.something_went_wrong")
          onError(response.connectChannel?.error?.message || somethingWentWrong)
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        onError(errorMsg)
      }
    }
    connectChannel(mutationParams)
  }

  const disconnectChannelFunction = ({
    wChannelId,
  }) => {
    let clientMutationId = 0
    const mutationParams = {
      variables: {
        input: {
          wChannelId,
          clientMutationId: clientMutationId++,
        },
      },
      onCompleted: (response, errs) => {
        if (!response.disconnectChannel?.error) {
        } else {
          const somethingWentWrong = t?.("common:errors.something_went_wrong")
          onError(response.disconnectChannel?.error?.message || somethingWentWrong)
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        onError(errorMsg)
      }
    }
    disconnectChannel(mutationParams)
  }

  return (
    <Styles.FlexRow>
      <td className="status">
        <Styles.StatusTag isConnected={channel?.connected}>
          {channel?.connected ?
            t?.("channels_settings.connected")
            :
            t?.("channels_settings.not_connected")
          }
        </Styles.StatusTag>
      </td>
      <td className="channel">
        <Flex sx={{ alignItems: "center"}}>
          <IntegrationIconDisplay
            icon={channel?.integrationIcon}
            size="32px"
            name={channel?.integrationName}
          />
          <Flex pl={2} sx={{ flexDirection: "column" }}>
            <Text className="name">{channel?.name}</Text>
            <Text className="wchannel-id">{t?.("channels_settings.channel_id")} {channel?.wChannelId}</Text>
          </Flex>
        </Flex>
      </td>
      <td className="connect">
        <Styles.ConnectButton
          primary
          disabled={isSuperAdmin || isSuperAuditor}
          inverted={channel?.connected}
          text={channel?.connected ? t?.("common:buttons.disconnect"): t?.("common:buttons.connect")}
          icon={channel?.connected ? faPlugCircleXmark : faPlug}
          loading={isInFlightConnectChannel || isInFlightDisconnectChannel}
          onClick={() => {
            if (channel?.connected) {
              disconnectChannelFunction({ wChannelId: channel?.wChannelId })
            }
            else {
              connectChannelFunction({ wChannelId: channel?.wChannelId })
            }
          }}
        />
      </td>
    </Styles.FlexRow>
  )
}

export default ChannelRow
