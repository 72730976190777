/** @jsxImportSource theme-ui */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { setLightness } from "polished"
import { Button } from "theme-ui"

const MenuButton = ({
  icon,
  onClick,
  children
}) => (
  <Button
    sx={{
      cursor: "pointer",
      width: "100%",
      borderRadius: 0,
      whiteSpace: "nowrap",
      background: "white",
      color: "gray9",
      minWidth: "120px",
      fontSize: "14px",
      display: "inline-flex",
      alignItems: "center",
      ":hover": {
        background: setLightness(0.95, "#73877B"),
      }
    }}
    px={3}
    py={2}
    onClick={onClick}
  >
    {icon && (
      <FontAwesomeIcon
        icon={icon}
        sx={{
          fontSize: "12px",
          mr: 2,
          color: "#0084FF",          
        }}
      />
    )}
    {children}
  </Button>
)

export default MenuButton
