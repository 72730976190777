import React from "react"
import PropTypes from "prop-types"
import TextareaAutosize from "react-autosize-textarea"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _ from "lodash"
import { Flex } from "theme-ui"
import ReactTooltip from "../../ReactTooltip/ReactTooltip"

const Container = styled.div`
  padding: ${(props) => {
    if (props.borderStyle) {
      return "0"
    } if (props.hasLengthCounter) {
      return "4px 0 2px 0"
    }
    return "4px 0 16px 0"
  }};

  ${(props) => props.hasError && !props.hasLongError && !props.hasLengthCounter && css`
    padding-bottom: 2px;
  `}

  >.content {
    background: ${(props) => (props.disabled && props.borderStyle ? "#f2f2f2" : "#ffffff")};
    margin-left: ${(props) => (props.offsetLabel ? "12px" : "0")};
    padding: ${(props) => (props.borderStyle ? "4px" : "0")};
    display: flex;
    align-items: center;
    border-bottom: 2px solid ${(props) => {
    if (!props.borderStyle) {
      if (props.hasError) {
        return props.theme.dangerText
      }
      if (props.focused) {
        return props.theme.themeColor
      }
      return props.theme.borderColorDark
    }
  }};
    min-height: ${(props) => {
    if (props.borderStyle === "L") {
      return "48px"
    } if (props.borderStyle === "M") {
      return "38px"
    } if (props.borderStyle === "S") {
      return "28px"
    }
  }};
    border-radius: ${(props) => (props.borderStyle ? "4px" : "0px")};
    box-sizing: border-box;
    font-size: ${(props) => {
    if (props.borderStyle === "L" || props.size === "L") {
      return "1.3em"
    } if (props.borderStyle === "M" || props.size === "M") {
      return "1em"
    } if (props.borderStyle === "S" || props.size === "S") {
      return "0.9em"
    }
    return "1em"
  }};
    border: ${(props) => {
    if (props.borderStyle) {
      if (props.hasError) {
        return `1px solid ${props.theme.dangerText}`
      }
      if (props.focused) {
        return `1px solid ${props.theme.themeColor}`
      }
      if (props.disabled) {
        return "1px solid #e6e6e6"
      }
      return `1px solid ${props.theme.gray2}`
    }
  }};

    textarea {
      border: none;
      width: 100%;
      resize: none;
      /* font-size: 1em; */
      background: #ffffff;
      color: ${(props) => (props.disabled ? "#686666" : props.theme.gray7)};
      outline: none;
      height: 16px;
      font-family: ${(props) => props.theme.mainFont};

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: ${(props) => props.theme.placeholderText};
        font-family: ${(props) => props.theme.mainFont};

      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: ${(props) => props.theme.placeholderText};
        font-family: ${(props) => props.theme.mainFont};
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: ${(props) => props.theme.placeholderText};
        font-family: ${(props) => props.theme.mainFont};
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: ${(props) => props.theme.placeholderText};
        font-family: ${(props) => props.theme.mainFont};
      }
    }

    input {
      border: none;
      width: 100%;
      /* font-size: 1em; */
      background: inherit;
      color: ${(props) => props.theme.gray7};
      outline: none;
      margin-bottom: 3px;
      margin-top: 2px;
      margin-left: ${(props) => (props.borderStyle ? "4px" : "0px")};
      margin-right: ${(props) => (props.borderStyle ? "4px" : "0px")};
      border-style: none;
      font-family: ${(props) => props.theme.mainFont} !important;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: ${(props) => props.theme.placeholderText};
        font-family: ${(props) => props.theme.mainFont};
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: ${(props) => props.theme.placeholderText};
        font-family: ${(props) => props.theme.mainFont};
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: ${(props) => props.theme.placeholderText};
        font-family: ${(props) => props.theme.mainFont};
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: ${(props) => props.theme.placeholderText};
        font-family: ${(props) => props.theme.mainFont};
      }
    }

    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { /* Chrome, Safari, Edge, Opera */
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] { /* Firefox */
      -moz-appearance: textfield;
    }
  }
`

const Label = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => {
    if (props.hasError) {
      return props.theme.dangerText
    }
    if (props.focused) {
      return props.theme.themeColor
    }
    return props.theme.gray9
  }};
  font-size: ${(props) => props.theme.textSm};
  margin-bottom: 2px;

  span {
    margin-right: 4px;
  }

  .tooltip-icon.text-field-tooltip-icon {
    font-size: 12px;
    color: ${(props) => {
    if (props.hasError) {
      return props.theme.dangerText
    }
    if (props.focused) {
      return props.theme.themeColor
    }
    return props.theme.darkButtonText
  }};
  }
`

const CharacterCounter = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 0.65rem;
  color: ${(props) => props.theme.gray5};
`

const propTypes = {
  label: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.object,
}

const defaultProps = {
  onChange: () => {},
  disabled: false,
  readOnly: false,
  placeholder: "",
  className: "",
  value: "",
  label: "",
  error: {},
}

class TextField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      focused: false,
    }
  }

  render() {
    const {
      label,
      className,
      disabled,
      error,
      readOnly,
      tooltipText,
      tooltipOptions,
      borderStyle,
      size,
      offsetLabel = false,
      maxLength,
      hideLengthCounter = false,
      useInput,
    } = this.props
    let { value } = this.props
    const propsToPassDown = _.omit(this.props, [
      "label",
      "className",
      "value",
      "error",
      "tooltipText",
      "readOnly",
      "tooltipOptions",
      "borderStyle",
      "useInput",
    ])
    if (!value) {
      value = ""
    }
    const hasError = !_.isEmpty(error)
    const hasLabel = !!label
    const hasLengthCounter = _.isNumber(maxLength) && !hideLengthCounter
    return (
      <Container
        className={`radiate-theme field-container text-field-container ${className}`}
        disabled={disabled}
        hasError={hasError}
        hasLabel={hasLabel}
        borderStyle={borderStyle}
        size={size}
        offsetLabel={offsetLabel}
        focused={this.state.focused}
        hasLengthCounter={hasLengthCounter}
      >
        <Flex sx={{ alignItems: "center", justifyContent: "space-between"}}>
          {label
            ? (
              <Label focused={this.state.focused}>
                <span>{label}</span>
                {tooltipText ? (
                  <div className="tooltip inline">
                    <FontAwesomeIcon
                      icon="question-circle"
                      data-tooltip-content={tooltipText}
                      data-tooltip-id={_.kebabCase(tooltipText)}
                    />
                    <ReactTooltip
                      id={_.kebabCase(tooltipText)}
                      noArrow
                      style={{
                        whiteSpace: "pre-line"
                      }}
                      {...tooltipOptions}
                    />
                  </div>
                ) : null}
              </Label>
            )
            : null}
          {_.isNumber(maxLength) && !hideLengthCounter
            ? (
              <CharacterCounter>
                {_.get(value, "length", 0)}
                /
                {maxLength}
              </CharacterCounter>
            )
            : null}
        </Flex>
        
        <div className="content">
          {useInput ?
            <input
              value={value}
              {...propsToPassDown}
              disabled={disabled || readOnly}
              onFocus={() => {
                if (this.props.onFocus) {
                  this.props.onFocus()
                }
                this.setState({
                  focused: true,
                })
              }}
              onBlur={() => {
                if (this.props.onBlur) {
                  this.props.onBlur()
                }
                this.setState({
                  focused: false,
                })
              }}
            />
            :
            <TextareaAutosize
              value={value}
              {...propsToPassDown}
              disabled={disabled || readOnly}
              onFocus={() => {
                if (this.props.onFocus) {
                  this.props.onFocus()
                }
                this.setState({
                  focused: true,
                })
              }}
              onBlur={() => {
                if (this.props.onBlur) {
                  this.props.onBlur()
                }
                this.setState({
                  focused: false,
                })
              }}
            />
          }
          
        </div>
        {hasError
          ? (
            <div className="error-container">
              <div className="text">{error.text}</div>
            </div>
          )
          : null}
        
      </Container>
    )
  }
}

TextField.propTypes = propTypes
TextField.defaultProps = defaultProps

export default TextField
