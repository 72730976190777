import { useRef } from "react";

export function useRequestAnimationFrameTimer(cb) {
  const requestRef = useRef();
  const previousTimeRef = useRef();

  const animate = (time) => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;

      // Pass on a function to the setter of the state
      cb?.(deltaTime);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  function stop() {
    previousTimeRef.current = undefined;
    cancelAnimationFrame(requestRef.current);
  }

  function start() {
    // Canceling in case this is called multiple times
    cancelAnimationFrame(requestRef.current);
    requestRef.current = requestAnimationFrame(animate);
  }

  function reset() {
    stop();
  }

  return { start, stop, reset };
}
