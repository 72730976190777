/** @jsxImportSource theme-ui */

import { Box } from "theme-ui"

const ScopedTag = ({
  className,
  scope,
  backgroundColor,
  color,
  children
}) => (
  <Box
    className={className}
    sx={{
      display: "inline-flex",
      alignItems: "center",
      borderRadius: "4px",
      border: `1px solid ${backgroundColor}`,
      overflow: "hidden",
    }}
  >
    <Box
      sx={{
        whiteSpace: "nowrap",
        px: "5px",
        py: "3px",
        background: backgroundColor,
        color: color,
        fontWeight: "bold",
        fontSize: "0.7rem",
      }}
    >{scope}</Box>
    <Box
      sx={{
        whiteSpace: "nowrap",
        px: "5px",
        py: "3px",
        background: "white",
        color: backgroundColor,
        fontWeight: "normal",
        fontSize: "0.7rem",
      }}
    >{children}</Box>
  </Box>
)

export default ScopedTag
