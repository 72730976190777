import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFile
} from "@fortawesome/free-solid-svg-icons"
import "fetch-everywhere"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import "./File.scss"

const FileBubblePlaceholder = styled.div`
  border-radius: 8px;
  min-height: 160px;
  background-color: ${(props) => props.isAgent ? props.theme.themeColorGreen : "#FFFFFF"};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.messageBubbleBoxShadow};

  .icon-wrapper {
    padding: 16px;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 160px;
    height: 160px;
    justify-content: center;
    border-radius: 8px;

    ${props => props.hasCaption && css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

    .icon {
      font-size: 50px;
      color: ${(props) => props.isAgent ? "#ffffff" : props.theme.gray4};
      margin-bottom: 8px;
    }

    span {
      font-size: ${(props) => props.theme.textSm};
      text-align: center;
      color: ${(props) => props.isAgent ? "#ffffff" : props.theme.gray7};
    }
  }

  .separator {
    height: 1px;
    width: 100%;
    background: ${(props) => props.isAgent ? props.theme.gray4 : props.theme.gray1};
  }
  
  .caption {
    width: 100%;
    padding: 4px 8px;
    word-break: break-word;
    line-height: 1.2rem;
    color: ${(props) => props.isAgent ? "#ffffff" : props.theme.gray7};
    white-space: pre-wrap;
  }
`

const FileBubble = styled.div`
  box-shadow: ${(props) => props.theme.messageBubbleBoxShadow};
  border-radius: 8px;
  background-color: ${(props) => props.isAgent ? props.theme.themeColorGreen : "#FFFFFF"};
  min-height: 160px;

  >a {
    width: 100%;
    height: 100%;
    color: ${(props) => props.isAgent ? "#ffffff" : props.theme.gray7};
    text-decoration: none;
  }

  @media only screen and (max-width: 801px) {
    width: 200px;
  }

  .file-file {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 160px;
    height: 160px;

    .icon {
      font-size: 50px;
      margin-bottom: 8px;
      color: ${(props) => props.isAgent ? "#ffffff" : props.theme.gray4};
    }

    span, div {
      font-size: ${(props) => props.theme.textSm};
      text-align: center;
      word-break: break-word;
      padding: 0 4px;
      color: ${(props) => props.isAgent ? "#ffffff" : props.theme.gray7};
    }
  }

  .separator {
    height: 1px;
    width: 100%;
    background: ${(props) => props.isAgent ? props.theme.gray4 : props.theme.gray1};
  }
  
  .caption {
    width: 100%;
    padding: 4px 8px;
    word-break: break-word;
    line-height: 1.2rem;
    color: ${(props) => props.isAgent ? "#ffffff" : props.theme.gray7};
    white-space: pre-wrap;
  }
`

const File = ({ data, color, isAgent = false }) => {
  const { t } = useTranslation("common")
  const [url] = useState(data.url || data.downloadUrl || _.get(data, "attachments[0].url"))
  if (url) {
    const startIndex = (url.indexOf("\\") >= 0 ? url.lastIndexOf("\\") : url.lastIndexOf("/"))
    let filename = url.substring(startIndex)
    if (filename.indexOf("\\") === 0 || filename.indexOf("/") === 0) {
      filename = filename.substring(1)
    }

    return (
      <FileBubble isAgent={isAgent} color={color}>
        <a className="file-file" href={url} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon
            className="icon"
            icon={faFile}
          />
          <span className="file-name">{data.filename ? data.filename : filename}</span>
        </a>
        {
          data.text || data.caption ?
            <>
              <div className="separator"></div>
              <div className="caption">{data.text || data.caption}</div>
            </>
            :
            null
        }
      </FileBubble>
    )
  }
  return (
    <FileBubblePlaceholder isAgent={isAgent}>
      <div className="icon-wrapper">
        <FontAwesomeIcon
          className="icon"
          icon={faFile}
        />
        <span>{t?.("message_types.file.file_cannot_be_loaded")}</span>
      </div>
      {
        data.text || data.caption ?
          <>
            <div className="separator"></div>
            <div className="caption">{data.text || data.caption}</div>
          </>
          :
          null
      }
    </FileBubblePlaceholder>
  )
}

export default File
