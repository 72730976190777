/**
 * @generated SignedSource<<979618e3a0837a2fc9a717fb531f8646>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CheckSessionPayload",
    "kind": "LinkedField",
    "name": "checkSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expiresAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "valid",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LayoutQuery_checkSessionMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LayoutQuery_checkSessionMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "43f996cf923884ed48e64fb11566fdae",
    "id": null,
    "metadata": {},
    "name": "LayoutQuery_checkSessionMutation",
    "operationKind": "mutation",
    "text": "mutation LayoutQuery_checkSessionMutation {\n  checkSession {\n    expiresAt\n    error {\n      code\n      message\n    }\n    valid\n  }\n}\n"
  }
};
})();

node.hash = "eb328d7d99daab4fae22f02832b8e584";

module.exports = node;
