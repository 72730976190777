/** @jsxImportSource theme-ui */

import React, { useEffect, useState, useTransition, useRef } from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import {
  useRefetchableFragment,
  useMutation
} from "react-relay"
import graphql from "babel-plugin-relay/macro"
import { Flex, Box } from "theme-ui"

import ChannelPicker from "../ChannelPicker/ChannelPicker"
import IconContainer from "../IconContainer/IconContainer"
import TextField from "../Radiate/TextField/TextField"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"

import { LoadingMask } from "../../CommonStyles"
import * as Styles from "./SettingsModalStyles"

import {
  UpdateAgentMutation
} from "./SettingsModalQuery"

import { ConversationHistorySignUrlMutation } from "../ConversationHistory/ConversationHistoryQuery"
import { Section, Item, Separator } from "../SimpleMobileNavigation/SimpleMobileNavigation"
import { faPen } from "@fortawesome/free-solid-svg-icons"

const SettingsProfileContainer = ({
  ownUserId,
  isAdmin,
  isInitialAdmin,
  isModal,
  mobile,
  ...props
}) => {
  const [
    data,
    refetch
  ] = useRefetchableFragment(
    graphql`
      fragment SettingsProfileContainer_userViewer on UserScope
      @argumentDefinitions(
        wChannelId: { type: "ID!" }
        hasWChannelId: { type: "Boolean!" }
      ) @refetchable(queryName: "SettingsProfileContainerRefetchQuery") {
        agent (
           wChannelId: $wChannelId
        ) @include(if: $hasWChannelId) {
          _id
          etag
          email
          icon
          name
          role
        }
      }
    `,
    props.data,
  )

  const { t } = useTranslation(["settings", "common"])

  const [fetching, startTransition] = useTransition()
  const [loading, setLoading] = useState(true)
  const [waitingForDefaultChannel, setWaitingForDefaultChannel] = useState(true)
  const [selectedChannelId, setSelectedChannelId] = useState("")
  const [error, setError] = useState("")
  const [fileToUpload, setFileToUpload] = useState(null)
  const [filePreviewUrl, setFilePreviewUrl] = useState(null)
  const [uploadingFile, setUploadingFile] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [fileUploadError, setFileUploadError] = useState(null)
  const [agentName, setAgentName] = useState(data?.agent?.name)
  const [agentEmail, setAgentEmail] = useState(data?.agent?.email)
  const [agentIcon, setAgentIcon] = useState(data?.agent?.icon)
  const mobilePageContainer = useRef(null)
  const [commitSignUrl] = useMutation(
    ConversationHistorySignUrlMutation
  )

  const [updateAgent, isInFlightUpdateAgent] = useMutation(
    UpdateAgentMutation
  )

  const refetchData = () => {
    startTransition(() => {
      refetch({
        wChannelId: selectedChannelId,
        hasWChannelId: !!selectedChannelId,
      }, {
        fetchPolicy: "network-only",
      })
    })
  }

  useEffect(() => {
    if (fetching) {
      setLoading(true)
    } else {
      setTimeout(() => {
        setLoading(false)
      }, 100)
    }
  }, [fetching])

  useEffect(() => {
    if (selectedChannelId) {
      refetchData()
    }
  }, [selectedChannelId])

  useEffect(() => {
    setAgentName(data?.agent?.name)
    setAgentEmail(data?.agent?.email)
    setAgentIcon(data?.agent?.icon)
  }, [JSON.stringify(data?.agent)])
  
  const updateAgenFunction = ({
    email,
    icon,
    name,
  }) => {
    let clientMutationId = 0
    const mutationParams = {
      variables: {
        input: {
          agentId: data?.agent?._id,
          etag: data?.agent?.etag,
          email,
          icon,
          name,
          clientMutationId: clientMutationId++,
        },
      },
      onCompleted: (response, errs) => {
        if (!response.updateAgent?.error) {
          setFilePreviewUrl(null)
          setFileToUpload(null)
        } else {
          setError(response.updateAgent?.error?.message)
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        setError(errorMsg)
      }
    }
    updateAgent(mutationParams)
  }

  const disableSaveButton = () => {
    let disabled = true
    if (
      data?.agent && (
        data?.agent?.name !== agentName ||
        data?.agent?.icon !== agentIcon ||
        data?.agent?.email !== agentEmail ||
        fileToUpload
      )
    ) {
      disabled = false
    }
    return disabled
  }

  const signUrl = (file) => {
    return new Promise((resolve, reject) => {
      let clientMutationId = 0
      commitSignUrl({
        variables: {
          input: {
            fileName: file.name,
            fileType: file.type,
            clientMutationId: clientMutationId++
          },
        },
        onCompleted: (response, errs) => {
          console.log("response", response)
          resolve(response?.signUrl?.signUrl)
        },
        onError: (err) => {
          console.log(err)
          reject(err)
        }
      })
    })
  }

  const uploadFile = async (file) => {
    setUploadingFile(true)
    const signedUpload = await signUrl(file)
    const form = new FormData()
    _.forEach(signedUpload.fields, (value, key) => {
      form.append(key, value)
    })
    form.append("file", file)
    const finalUrl = await fetch(signedUpload.url, { method: "POST", body: form })
      .then((res) => {
        if (res.status === 204) {
          const split = signedUpload.fields.key.split("/")
          const encodedSplit = split.map(o => encodeURIComponent(o))
          return Promise.resolve(`${signedUpload.url}/${encodedSplit.join("/")}`)
        }
        return null
      })
    setUploadingFile(false)
    return finalUrl
  }

  const renderContent = () => {
    if (loading || waitingForDefaultChannel || fetching) {
      return (
        <div style={{ height: "calc(100% - 62px)", width: "100%", background: "#ffffff" }}>
          <LoadingMask style={{ height: "100%", width: "100%", background: "#ffffff" }}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </LoadingMask>
        </div>
      )
    } else if (selectedChannelId) {
      return (
        <Styles.ProfileContainer>
          <Flex pb={3} sx={{ width: "100%", gap: "16px", alignItems: "center" }}>
            <label
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                mr: 2,
              }}
              htmlFor="upload-profile-pic"
            >
              <IconContainer
                className="agent-icon"
                url={filePreviewUrl || agentIcon}
                size="XL"
                name={agentName || agentEmail}
                sx={{
                  background: "white"
                }}
              />
              <NewBoxButton
                primary
                icon={faPen}
                borderless
                text={t?.("common:buttons.edit")}
                sx={{
                  pointerEvents: "none",
                }}
              />
              <input
                type="file"
                id="upload-profile-pic"
                sx={{
                  width: "0.1px",
                  height: "0.1px",
                  opacity: 0,
                  overflow: "hidden",
                  position: "absolute",
                  zIndex: -1,
                }}
                onChange={(e) => {
                  const file = e.target.files?.[0]
                  if (file.size > 2.048 * 1000 * 1000) {
                    const fileSizeError = t?.("common:member_profile.file_size_limit_error")
                    setFileUploadError(fileSizeError)
                    return
                  }
                  if (!/image\/png/i.test(file.type) && !/image\/jpeg/i.test(file.type) && !/image\/jpg/i.test(file.type)) {
                    const fileTypeError = t?.("common:member_profile.file_type_error")
                    setFileUploadError(fileTypeError)
                    return
                  }
                  setFilePreviewUrl(URL.createObjectURL(file))
                  setFileToUpload(file)
                  e.target.value = null
                }}
              />
            </label>
            <Box sx={{ width: "100%" }}>
              <TextField
                className="agent-name-text-field"
                label={t?.("common:labels.name")}
                placeholder={t?.("common:placeholders.name")}
                value={agentName}
                borderStyle="M"
                useInput
                onChange={(e) => {
                  setAgentName(e.target.value)
                }}
              />
              <TextField
                className="agent-email-text-field"
                placeholder={t?.("common:placeholders.email")}
                label={t?.("common:labels.email")}
                value={agentEmail}
                borderStyle="M"
                useInput
                onChange={(e) => {
                  setAgentEmail(e.target.value)
                }}
              />
            </Box>

          </Flex>
          <Flex sx={{ justifyContent: "flex-end" }}>
            <NewBoxButton
              sx={{ width: "108px" }}
              primary
              text={t?.("common:buttons.save")}
              loading={isInFlightUpdateAgent}
              disabled={disableSaveButton()}
              onClick={async () => {
                let icon = agentIcon
                if (fileToUpload) {
                  icon = await uploadFile(fileToUpload)
                }
                updateAgenFunction({
                  name: agentName,
                  icon,
                  email: agentEmail
                })
              }}
            />
          </Flex>
          <Styles.ErrorContainer>
            {error && <div className="error">{error}</div>}
          </Styles.ErrorContainer>
        </Styles.ProfileContainer>
      )
    }
    return null
  }

  const renderContentMobile = () => {
    if (loading || waitingForDefaultChannel || fetching) {
      return (
        <LoadingMask style={{ height: "100%", width: "100%" }}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </LoadingMask>
      )
    }
    return (
      <>
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: 4,
            pb: 3,
          }}
        >
          <label
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            htmlFor="upload-profile-pic"
          >
            <IconContainer
              className="agent-icon"
              url={filePreviewUrl || agentIcon}
              size="XL"
              name={agentName || agentEmail}
              sx={{
                background: "white"
              }}
            />
            <NewBoxButton
              primary
              icon={faPen}
              borderless
              text={t?.("common:buttons.edit")}
              sx={{
                pointerEvents: "none",
              }}
            />
            <input
              type="file"
              id="upload-profile-pic"
              sx={{
                width: "0.1px",
                height: "0.1px",
                opacity: 0,
                overflow: "hidden",
                position: "absolute",
                zIndex: -1,
              }}
              onChange={(e) => {
                const file = e.target.files?.[0]
                if (file.size > 2.048 * 1000 * 1000) {
                  const fileSizeError = t?.("common:member_profile.file_size_limit_error")
                  setFileUploadError(fileSizeError)
                  return
                }
                if (!/image\/png/i.test(file.type) && !/image\/jpeg/i.test(file.type) && !/image\/jpg/i.test(file.type)) {
                  const fileTypeError = t?.("common:member_profile.file_type_error")
                  setFileUploadError(fileTypeError)
                  return
                }
                setFilePreviewUrl(URL.createObjectURL(file))
                setFileToUpload(file)
                e.target.value = null
              }}
            />
          </label>
          
        </div>
        <Section>
          <Item
            sx={{
              justifyContent: "flex-start"
            }}
          >
            <TextField
              placeholder={t?.("common:placeholders.name")}
              value={agentName}
              useInput
              onChange={(e) => {
                setAgentName(e.target.value)
              }}
              sx={{
                p: 0,
                width: "100%",
                ".content": {
                  border: "none",
                  background: "transparent",
                  input: {
                    m: 0,
                    p: 0,
                    lineHeight: "44px",
                    background: "transparent",
                    fontSize: "16px",
                    color: "#000",
                  }
                }
              }}
            />
          </Item>
          <Separator />
          <Item>
            <TextField
              placeholder={t?.("common:placeholders.email")}
              value={agentEmail}
              useInput
              onChange={(e) => {
                setAgentEmail(e.target.value)
              }}
              sx={{
                p: 0,
                width: "100%",
                ".content": {
                  border: "none",
                  background: "transparent",
                  input: {
                    m: 0,
                    p: 0,
                    lineHeight: "44px",
                    background: "transparent",
                    fontSize: "16px",
                    color: "#000",
                  }
                }
              }}
            />
          </Item>
        </Section>
        <NewBoxButton
          text={t?.("common:buttons.save")}
          primary
          sx={{
            width: "100%",
            height: "44px",
            borderRadius: "8px",
            fontSize: "1em",
            border: "none",
            my: "24px",
          }}
          loading={isInFlightUpdateAgent || uploadingFile}
          disabled={disableSaveButton()}
          onClick={async () => {
            let icon = agentIcon
            if (fileToUpload) {
              icon = await uploadFile(fileToUpload)
            }
            updateAgenFunction({
              name: agentName,
              icon,
              email: agentEmail
            })
          }}
        />
      </>
    )
  }

  if (mobile) {
    return (
      <div
        ref={mobilePageContainer}
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "#F2F2F7",
          py: "16px",
        }}
      >
        <Section
          sx={{
            overflow: "auto",
          }}
        >
          <Item>
            <div>{t?.("common:member_profile.channel")}</div>
            <ChannelPicker
              selectedChannelId={selectedChannelId}
              onReceiveFirstChannel={(channel) => {
                setSelectedChannelId(channel.wChannelId)
                setTimeout(() => {
                  setWaitingForDefaultChannel(false)
                }, 100)
              }}
              onChange={(channelId) => {
                setSelectedChannelId(channelId)
              }}
              menuPortalTarget={mobilePageContainer.current}
              isSearchable={false}
              customStyles={{
                container: styles => ({
                  ...styles,
                  backgroundColor: "transparent",
                }),
                control: (styles, state) => ({
                  ...styles,
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: "transparent !important",
                }),
                indicatorSeparator: () => ({
                  display: "none"
                }),
                dropdownIndicator: styles => ({
                  ...styles,
                  paddingRight: 0,
                  paddingLeft: 0,
                  color: "#bbb !important",
                }),
                valueContainer: styles => ({
                  ...styles,
                  justifyContent: "flex-end",
                  paddingRight: 0,
                }),
              }}
              sx={{
                ml: 3,
                flex: 1,
                ".overlay .loading-container": {
                  backgroundColor: "transparent",
                }
              }}
            />
          </Item>
        </Section>
        {renderContentMobile()}
      </div>
    )
  }

  return (
    <Styles.SettingsProfileAndAgentContainer>
      <div className="description">{t?.("profile_settings.please_choose_desired_channel_to_get_agent_info")}</div>
      <ChannelPicker
        selectedChannelId={selectedChannelId}
        onReceiveFirstChannel={(channel) => {
          setSelectedChannelId(channel.wChannelId)
          setTimeout(() => {
            setWaitingForDefaultChannel(false)
          }, 100)
        }}
        onChange={(channelId) => {
          setSelectedChannelId(channelId)
        }}
      />
      {renderContent()}
    </Styles.SettingsProfileAndAgentContainer>
  )
}

export default SettingsProfileContainer
