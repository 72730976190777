/** @jsxImportSource theme-ui */

import { Flex, Box } from "theme-ui"
import { faClose } from "@fortawesome/free-solid-svg-icons"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"

const TextOptionModal = ({
  textOptions,
  onConfirmOption = () => {},
  onClose = () => {},
}) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const { t } = useTranslation("common")

  return (
    <Box
      sx={{
        width: ["90%", "700px"],
        maxHeight: "80%",
        minHeight: "100px",
        backgroundColor: "white",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Flex
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          px: 3,
          py: 3,
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        <div
          sx={{
            color: "gray7",
          }}
        >{t?.("chat.select_an_option")}</div>
        <NewBoxButton
          borderless
          icon={faClose}
          onClick={onClose}
        />
      </Flex>
      <Box
        sx={{
          flexGrow: 1,
          px: 3,
          py: 2,
          overflowY: "scroll",
        }}
      >
        {(textOptions || []).map((option, i) => (
          <Box
            sx={{
              borderRadius: "4px",
              p: 3,
              border: "1px solid #e8e8e8",
              cursor: "pointer",
              marginBottom: i === textOptions.length - 1 ? 0 : 2,
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                background: selectedOption === i ? "#295ea8" :"gray02",
              },
              background: selectedOption === i ? "#295ea8" : "white",
              color: selectedOption === i ? "white" : "gray12",
            }}
            onClick={() => { setSelectedOption(i) }}
          >
            {option}
          </Box>
        ))}

      </Box>
      <Flex
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-end",
          px: 3,
          py: 2,
          borderTop: "1px solid #f0f0f0",
        }}
      >
        <NewBoxButton
          primary
          text={t?.("buttons.confirm")}
          disabled={selectedOption === null}
          onClick={() => {
            if (selectedOption !== null) {
              onConfirmOption(textOptions[selectedOption])
            } 
          }}
        />
      </Flex>
    </Box>
  )
}

export default TextOptionModal
