import graphql from "babel-plugin-relay/macro"

export const MemberContainerAddTagToMemberMutation = graphql`
  mutation MemberContainerQuery_addTagToMembertMutation (
    $input: AddTagToMemberInput!
  ) {
    addTagToMember(input: $input) {
      clientMutationId
      error {
        message
        code
      }
      member {
        id
        externalId
        wMemberId
        wChannelId
        wChannel {
          name
          integrationIcon
          integrationName
        }
        wAppId
        externalProfile
        inboxProfile {
          firstName
          lastName
          profilePicUrl
          dob {
            date
            month
            year
          }
          gender
          locale
          phones {
            number
            alias
          }
          emails {
            email
            alias
          }
          addresses {
            alias
            line1
            line2
            city
            state
            country
            zip
          }
        }
        tags
        liveChat
        meta
        notes {
          _id
          content
          updatedAt
          authorId
          author {
            email {
              email
            }
          }
          etag
        }
        etag
      }
    }
  }
`

export const MemberContainerRemoveTagFromMemberMutation = graphql`
  mutation MemberContainerQuery_removeTagFromMembertMutation (
    $input: RemoveTagFromMemberInput!
  ) {
    removeTagFromMember(input: $input) {
      clientMutationId
      error {
        message
        code
      }
      member {
        id
        externalId
        wMemberId
        wChannelId
        wChannel {
          name
          integrationIcon
          integrationName
        }
        wAppId
        externalProfile
        inboxProfile {
          firstName
          lastName
          profilePicUrl
          dob {
            date
            month
            year
          }
          gender
          locale
          phones {
            number
            alias
          }
          emails {
            email
            alias
          }
          addresses {
            alias
            line1
            line2
            city
            state
            country
            zip
          }
        }
        tags
        liveChat
        meta
        notes {
          _id
          content
          updatedAt
          authorId
          author {
            email {
              email
            }
          }
          etag
        }
        etag
      }
    }
  }
`

export const MemberContainerAddNoteToMemberMutation = graphql`
  mutation MemberContainerQuery_addNoteToMembertMutation (
    $input: AddNoteToMemberInput!
  ) {
    addNoteToMember(input: $input) {
      clientMutationId
      error {
        message
        code
      }
      member {
        id
        externalId
        wMemberId
        wChannelId
        wChannel {
          name
          integrationIcon
          integrationName
        }
        wAppId
        externalProfile
        inboxProfile {
          firstName
          lastName
          profilePicUrl
          dob {
            date
            month
            year
          }
          gender
          locale
          phones {
            number
            alias
          }
          emails {
            email
            alias
          }
          addresses {
            alias
            line1
            line2
            city
            state
            country
            zip
          }
        }
        tags
        liveChat
        meta
        notes {
          _id
          content
          updatedAt
          authorId
          author {
            email {
              email
            }
          }
          etag
        }
        etag
      }
    }
  }
`

export const MemberContainerRemoveNoteFromMemberMutation = graphql`
  mutation MemberContainerQuery_removeNoteFromMembertMutation (
    $input: RemoveNoteFromMemberInput!
  ) {
    removeNoteFromMember(input: $input) {
      clientMutationId
      error {
        message
        code
      }
      member {
        id
        externalId
        wMemberId
        wChannelId
        wChannel {
          name
          integrationIcon
          integrationName
        }
        wAppId
        externalProfile
        inboxProfile {
          firstName
          lastName
          profilePicUrl
          dob {
            date
            month
            year
          }
          gender
          locale
          phones {
            number
            alias
          }
          emails {
            email
            alias
          }
          addresses {
            alias
            line1
            line2
            city
            state
            country
            zip
          }
        }
        tags
        liveChat
        meta
        notes {
          _id
          content
          updatedAt
          authorId
          author {
            email {
              email
            }
          }
          etag
        }
        etag
      }
    }
  }
`

export const MemberContainerUpdateNoteMutation = graphql`
  mutation MemberContainerQuery_updateNotetMutation (
    $input: UpdateNoteInput!
  ) {
    updateNote(input: $input) {
      clientMutationId
      error {
        message
        code
      }
      note {
        _id
        content
        updatedAt
        authorId
        author {
          email {
            email
          }
        }
        etag
      }
    }
  }
`
