import styled from "@emotion/styled"
import { css } from "@emotion/react"
import SearchBar from "../Radiate/SearchBar/SearchBar"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"

export const MentionListContainer = styled.div`
  position: absolute;
  left: 0;
  width: 416px;
   min-width: 416px;
  height: calc(100% - 50px);
  transform: ${props => (!props.show ? "translateX(-100%)" : "translateX(0px)")};
  transition: opacity 0.4s ease, transform 0.4s ease-in-out;
  z-index: 99;

  ${(props) => props.mobile && css`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.4s ease-in-out;
    transform: ${props.show ? "translateY(0)" : "translateY(100%)"};
    min-width: auto;
  `}
`

export const MentionListTopContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid ${(props) => props.theme.gray1};
  background: ${(props) => props.theme.palerBackground};

  .close-button {
    >.icon {
      font-size: 0.9rem;
    }
  }
`

export const MemberSearchBar = styled(SearchBar)`
  margin-left: 8px;
`

export const DesktopUISearch = styled.div`
  display: block;
  @media (max-width:989px) {
    display: none;
  }
`

export const MentionRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  border-bottom: 1px solid ${(props) => props.theme.gray1};
  cursor: pointer;
  background: ${(props) => {
    return props.isRead ? props.theme.palerBackground : "#0063f00f"
  }};

  &:hover {
    ${'' /* color: ${(props) => props.theme.themeColorBlue}; */}
    ${'' /* border-bottom: 1px solid ${(props) => props.theme.themeColorBlue}; */}
    background: ${(props) => props.isRead ? "rgba(255, 255, 255, 0.5)" : "#0063f005"};
    
    ${'' /* .mention-content-container {
      .mock-image {
        .icon {
          color: ${(props) => props.theme.themeColorBlue};
        }
      }
    } */}

    .mention-content-container {
      .mention-content {
        .mention-creator {
          .mention-creator-name {
            ${'' /* color: ${(props) => props.theme.themeColorBlue}; */}
          }
        }
        .mention-message {
          ${'' /* color: ${(props) => props.theme.themeColorBlue}; */}
        }
      
      }
    }
  }
  
  .mention-creation-info {
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 12px;

    .icon {
      margin-right: 8px;
      color: ${(props) => props.theme.gray6};
    }

    .mention-thread-name {
      color: ${(props) => props.theme.themeColorBlue};
      font-weight: bold;
      margin-right: 8px;
    }

    .mention-creation-date {
      color: ${(props) => props.theme.gray7};
      font-size: 0.8rem;
    }
  }

  .mention-content-container {
    display: flex;
    align-items: start;


    .mock-image {
      margin-right: 8px;
      width: 40px;
      height: 40px;
      border-radius: 50%;

      .icon {
        width: 100%;
        height: 100%;
        color: ${(props) => props.theme.gray6};
      }
    }

    .mention-creator-avatar {
      margin-right: 8px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;      

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .mention-content {
      display: flex;
      flex-direction: column;
      font-size: 1rem;
      width: 80%;

      .mention-creator {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        color: ${(props) => props.theme.gray6};
        font-weight: 400;

        .mention-creator-name {
          font-weight: bold;
          color: ${(props) => props.theme.gray9};
          overflow:hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .text {
          white-space: nowrap;
          margin-left: 4px;
        }
      }

      .mention-message {
        color: ${(props) => props.theme.gray7};
        font-weight: 500;
        line-height: 24px;
    }
  }

`

export const LoadingContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.palerBackground};
`

export const LoadMoreButton = styled(NewBoxButton)`
  width: 100%;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  background: ${(props) => props.theme.palerBackground};
`
