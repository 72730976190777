/**
 * @generated SignedSource<<1e30d122e3b740ca1c18510b9c1c2128>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "onlyActivated"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "onlyActivated",
    "variableName": "onlyActivated"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wUserId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "UserEmail",
  "kind": "LinkedField",
  "name": "email",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verified",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePic",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserPickerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserScope",
        "kind": "LinkedField",
        "name": "userViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserPickerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserScope",
        "kind": "LinkedField",
        "name": "userViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cfa1b79222d4d6baf981aeb6c394fb92",
    "id": null,
    "metadata": {},
    "name": "UserPickerQuery",
    "operationKind": "query",
    "text": "query UserPickerQuery(\n  $onlyActivated: Boolean\n) {\n  userViewer {\n    users(onlyActivated: $onlyActivated) {\n      wUserId\n      email {\n        email\n        verified\n      }\n      firstName\n      lastName\n      profilePic\n      role\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

node.hash = "52870d0aa8cd194a78fd0362e774b867";

module.exports = node;
