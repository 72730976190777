/** @jsxImportSource theme-ui */

import { useState, useContext } from "react"
import { Box } from "theme-ui"
import _ from "lodash"
import { v4 as uuid } from "uuid"
import * as Styles from "./ConversationHistoryStyles"
import { ErrorBoundary } from "react-error-boundary"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faCircleXmark, faHeadset, faMessage, faMicrophone, faPaperclip, faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"

import TextEditor from "./TextEditor"
import { MAX_FILES_TO_UPLOAD, FileAttachmentsPreview } from "../ConversationHistoryPreview/ConversationHistoryPreview"
import { CancelRecordingButton } from "../AudioRecorder/AudioRecorderStyles"
import { AudioRecorder } from "../AudioRecorder/AudioRecorder"
import IntegrationActionPopperContainer from "../IntegrationActionPopperContainer/IntegrationActionPopperContainer"
import PopperContainer from "../Radiate/PopperContainer/PopperContainer"
import { UserContext } from "../../contexts/UserContext"

const FL_AUDIO_MSG = process.env.REACT_APP_FL_AUDIO_MSG !== undefined ? JSON.parse(process.env.REACT_APP_FL_AUDIO_MSG) : true

export function ChatFooter({
  loading,
  data,
  isInFlightAssignThread,
  isInFlightLiveChat,
  isInFlightUpdateThreadFolder,
  filesToUpload,
  isReadyToSendAudio,
  isSendingAudio,
  isIos,
  isAudioEnabled,
  isDraggingOverTextArea,
  previews,
  setPreviews,
  setFilesToUpload,
  isUploading,
  failedToUploadError,
  setFailedToUploadError,
  tooManyFilesAttachedError,
  setTooManyFilesAttachedError,
  threadId,
  cancelRecording,
  startRecording,
  isRecording,
  togglePauseResume,
  isPaused,
  stopRecording,
  audioUrl,
  errorName,
  fileTooLarge,
  isLoadingAudio,
  blobDuration,
  getRecordingTextToDisplay,
  randomKey,
  usersForMention,
  mentions,
  setMentions,
  dragndropSupport,
  setIsDraggingOverTextArea,
  convertFilesToBlobs,
  sendEvent,
  setRandomKey,
  onChangeNavHint,
  setTextOptions,
  setOpenTextOptionModal,
  setIsAudioEnabled,
  textEditorRef,
  mobile,
  showSendOptions,
  setShowSendOptions,
  toggleLiveChat,
  assignThread,
  uploadFiles,
  recordingBlob,
  blobToFile,
  mimeType,
  setIsSendingAudio,
}) {
  const { isSuperAdmin, isSuperAuditor } = useContext(UserContext)
  const { t } = useTranslation("common")
  const sendMessage = async () => {
    const member = data?.thread?.member
    if (filesToUpload?.length) {
      const failed = filesToUpload.filter(f => f.size > 20.48 * 1000 * 1000)
      const selected = filesToUpload.filter(f => f.size <= 20.48 * 1000 * 1000)
      if (failed.length) {
        const fileUploadExceedFileSizeLimit = t?.("file_upload.error_files_exceed_size_limit")
        const errorMessage = failed.reduce((memo, file) => {
          return `${memo}${file.name}\n`
        }, `${fileUploadExceedFileSizeLimit}:\n`)
        alert(errorMessage)
      }

      if (selected.length) {
        if (!member?.liveChat) {
          await toggleLiveChat(true)
        }
        if (!data?.thread?.wUserId) {
          await assignThread({ userId: data?.wUserId })
        }
        uploadFiles(selected)
      }
    } else if (isReadyToSendAudio && !isSendingAudio) {
      setIsSendingAudio(true)
      if (!member?.liveChat) {
        try {
          await toggleLiveChat(true)
        } catch (e) {
          cancelRecording()
        }
      }
      if (!data?.thread?.wUserId) {
        try {
          await assignThread({ userId: data?.wUserId })
        } catch (e) {
          cancelRecording()
        }
      }

      const audioFile = blobToFile({
        blob: recordingBlob,
        fileName: "audio-recording-from-inbox",
        mimeType
      })

      if (!audioFile) {
        alert(t?.("audio_recorder.error_converting_audio_into_file"))
        cancelRecording()
        return
      }
      try {
        await uploadFiles([audioFile])
      } catch (e) {
        alert(t?.("audio_recorder.error_uploading_audio_file"))
        console.error(e.message)
      } finally {
        cancelRecording()
      }
    } else if (textArea) {
      setTextArea("")
      setCharacterCount(0)
      let text = textArea
      if (/\n$/.test(textArea)) {
        text = textArea.slice(0, -1)
      }
      if (!member?.liveChat) {
        await toggleLiveChat(true)
      }
      if (!data?.thread?.wUserId) {
        await assignThread({ userId: data?.wUserId })
      }
      sendEvent({
        type: "TEXT",
        data: {
          text
        }
      })
    }
  }
  const [textArea, setTextArea] = useState("")
  const [characterCount, setCharacterCount] = useState(0)
  if (!loading && !data?.thread?.member?.externalId) {
    return null
  }
  const member = data?.thread?.member
  let shouldDisableThreadAction = false
  let shouldDisableSendButton = false
  if (isSuperAdmin || loading || isInFlightAssignThread || isInFlightLiveChat || isInFlightUpdateThreadFolder || isSuperAuditor) {
    shouldDisableThreadAction = true
    shouldDisableSendButton = true
  }
  if (!textArea && !filesToUpload && !isReadyToSendAudio) {
    shouldDisableSendButton = true
  }
  if (isSendingAudio) {
    shouldDisableSendButton = true
  }
  if (textArea && textArea?.length > 4000) {
    shouldDisableSendButton = true
  }
  return (
    <Styles.Footer className="chat-footer" ios={isIos()}>
      <Styles.ActionRow
        className="action-row"
        $isAudioEnabled={isAudioEnabled}
        highlight={isDraggingOverTextArea && !loading && !isSuperAdmin && !isSuperAuditor}
      >
        {(isSuperAdmin || isSuperAuditor) && <div className="overlay"></div>}
        {(previews || isAudioEnabled)
          ?
          <>
            {previews && (
              <FileAttachmentsPreview
                previews={previews}
                setPreviews={setPreviews}
                filesToUpload={filesToUpload}
                setFilesToUpload={setFilesToUpload}
                isUploading={isUploading}
                failedToUploadError={failedToUploadError}
                setFailedToUploadError={setFailedToUploadError}
                tooManyFilesAttachedError={tooManyFilesAttachedError}
                setTooManyFilesAttachedError={setTooManyFilesAttachedError}
              />
            )}
            {(isAudioEnabled) && (
              <ErrorBoundary
                key={threadId}
                fallbackRender={({ error }) => {
                  console.error(error)
                  return (
                    <div
                      style={{
                        display: "flex",
                        color: "#929292",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "32px",
                        fontSize: "12px",
                      }}
                    >
                      <div>
                        {t?.("errors.something_went_wrong_please_try_again_later")}
                      </div>
                      <div
                        style={{
                          cursor: "pointer",
                          display: "flex",
                        }}
                      >
                        <div
                          onClick={() => {
                            cancelRecording()
                          }}
                          style={{
                            padding: "4px",
                          }}
                        >
                          <CancelRecordingButton
                            className="icon"
                            icon={faCircleXmark}
                            fixedWidth
                            fontSize="24px"
                          />
                        </div>
                      </div>
                    </div>
                  )
                }}
              >
                <AudioRecorder
                  startRecording={startRecording}
                  isRecording={isRecording}
                  togglePauseResume={togglePauseResume}
                  isPaused={isPaused}
                  getRecordingState={getRecordingTextToDisplay}
                  stopRecording={stopRecording}
                  cancelRecording={cancelRecording}
                  audioUrl={audioUrl}
                  errorName={errorName}
                  fileTooLarge={fileTooLarge}
                  isLoadingAudio={isLoadingAudio}
                  isSendingAudio={isSendingAudio}
                  blobDuration={blobDuration}
                />
              </ErrorBoundary>
            )}
          </>
          :
          <Styles.TextFieldWrapper>
            <TextEditor
              key={randomKey}
              ref={textEditorRef}
              dataForMention={usersForMention}
              disabled={isSuperAdmin || isSuperAuditor || textArea?.length > 4000 || loading}
              mobile={mobile}
              onChange={(e, mentionsFromOnChange) => {
                let value = e
                if (e.length >= 4000) {
                  value = e.slice(0, 4000)
                }
                const hasMentionChanged = !_.isEqual(mentionsFromOnChange, mentions)
                if (hasMentionChanged) {
                  setMentions(mentionsFromOnChange)
                }
                setCharacterCount(value?.length)
                setTextArea(value)
              }}
              onDragOver={(e) => {
                e.preventDefault()
                if (dragndropSupport.current) {
                  setIsDraggingOverTextArea(true)
                }
              }}
              onDragEnter={(e) => {
                e.preventDefault()
                if (dragndropSupport.current) {
                  setIsDraggingOverTextArea(true)
                }
              }}
              onDragLeave={(e) => {
                e.preventDefault()
                if (dragndropSupport.current) {
                  setIsDraggingOverTextArea(false)
                }
              }}
              onDragEnd={(e) => {
                e.preventDefault()
                if (dragndropSupport.current) {
                  setIsDraggingOverTextArea(false)
                }
              }}
              onDrop={async (e) => {
                e.preventDefault()
                if (isSuperAdmin || isSuperAuditor || loading) {
                  return
                }
                setIsDraggingOverTextArea(false)
                const data = e?.dataTransfer
                if (!data?.files?.length) {
                  return
                }
                const failed = [...Array.from(data.files).filter(f => f.size > 20.48 * 1000 * 1000)]
                const selected = [...Array.from(data.files).filter(f => f.size <= 20.48 * 1000 * 1000).slice(0, MAX_FILES_TO_UPLOAD)]
                setFilesToUpload(selected)
                convertFilesToBlobs(selected)
                if (failed?.length > 0) {
                  const fileUploadExceedFileSizeLimit = t?.("file_upload.error_failed_to_upload_files_over_size_limit", { failedFiles: failed.map(f => f.name).join(", ")})
                  setFailedToUploadError({ error: true, message: `${fileUploadExceedFileSizeLimit}: ${failed.map(f => f.name).join(", ")}` })
                } else {
                  setFailedToUploadError({ error: false, message: "" })
                }

                if (Array.from(data.files).length > MAX_FILES_TO_UPLOAD) {
                  const errorCanOnlyUploadMaxFilesAtATime = t?.("file_upload.error_can_only_upload_max_files_at_a_time",{ count: MAX_FILES_TO_UPLOAD })
                  setTooManyFilesAttachedError({ error: true, message: errorCanOnlyUploadMaxFilesAtATime })
                } else {
                  setTooManyFilesAttachedError({ error: false, message: "" })
                }
              }}
              onPaste={async (e) => {
                const clipboardData = e.clipboardData || window.clipboardData
                if (!clipboardData?.files?.length) {
                  return
                }
                e.preventDefault()
                if (isSuperAdmin || isSuperAuditor || loading) {
                  return
                }
                const failed = [...Array.from(clipboardData.files).filter(f => f.size > 20.48 * 1000 * 1000)]
                const selected = [...Array.from(clipboardData.files).filter(f => f.size <= 20.48 * 1000 * 1000).slice(0, MAX_FILES_TO_UPLOAD)]
                setFilesToUpload(selected)
                convertFilesToBlobs(selected)
                if (failed?.length > 0) {
                  const failedToUploadError = t?.("file_upload.error_failed_to_upload_files_over_size_limit", { failedFiles: failed.map(f => f.name).join(", ")})
                  setFailedToUploadError({ error: true, message: `${failedToUploadError}: ${failed.map(f => f.name).join(", ")}` })
                } else {
                  setFailedToUploadError({ error: false, message: "" })
                }

                if (Array.from(clipboardData.files).length > MAX_FILES_TO_UPLOAD) {
                  const tooManyFilesAttachedError = t?.("file_upload.error_can_only_upload_max_files_at_a_time",{ count: MAX_FILES_TO_UPLOAD })
                  setTooManyFilesAttachedError({ error: true, message: tooManyFilesAttachedError })
                } else {
                  setTooManyFilesAttachedError({ error: false, message: "" })
                }
              }}
              onEnter={async (e) => {
                if (mentions?.length > 0) {
                  if (textArea) {
                    setTextArea("")
                    setCharacterCount(0)
                    let text = textArea
                    if (/\n$/.test(textArea)) {
                      text = textArea.slice(0, -1)
                    }
                    if (/^###.+/.test(text ?? "")) {
                      const chatContent = /^###(.+)/.exec(text)[1]
                      text = chatContent
                    }
                    sendEvent({
                      type: "TEXT",
                      data: {
                        text: `###${text}`
                      }
                    })
                    setRandomKey(uuid())
                  }
                } else {
                  await sendMessage()
                  setRandomKey(uuid())
                }
              }}
            />
          </Styles.TextFieldWrapper>
        }
      </Styles.ActionRow>
      <div className="action-wrapper">
        <div className="left-wrapper">
          {!!data?.thread?.inputActions?.length &&
            <IntegrationActionPopperContainer
              disabled={loading}
              shouldDisableThreadAction={shouldDisableThreadAction}
              actions={data?.thread?.inputActions}
              memberId={data?.thread?.wMemberId}
              onChangeNavHint={onChangeNavHint}
              userId={data?.wUserId}
              actionContext={{
                threadId: data?.thread?.threadId,
                text: textArea,
              }}
              onUpdateText={(text) => {
                textEditorRef?.current?.insertText(text)
              }}
              onUpdateOptions={(options) => {
                setTextOptions(options)
                setOpenTextOptionModal(true)
              }}
            />
          }
          <Styles.ButtonWrapper
            key="upload-button"
            disabled={isSuperAdmin || isSuperAuditor || Boolean(filesToUpload) || loading}
          >
            <label htmlFor="upload-file">
              <FontAwesomeIcon
                className="icon"
                icon={faPaperclip}
                fixedWidth
              />
              <Styles.FileInput
                type="file"
                id="upload-file"
                multiple
                disabled={isSuperAdmin || isSuperAuditor || Boolean(filesToUpload) || loading}
                onChange={(e) => {
                  // console.log("files", e.target.files)
                  const failed = [...Array.from(e.target.files).filter(f => f.size > 20.48 * 1000 * 1000)]
                  const selected = [...Array.from(e.target.files).filter(f => f.size <= 20.48 * 1000 * 1000).slice(0, MAX_FILES_TO_UPLOAD)]
                  setFilesToUpload(selected)
                  convertFilesToBlobs(selected)
                  if (failed?.length > 0) {
                    setFailedToUploadError({ error: true, message: `Failed to upload files over 20MB: ${failed.map(f => f.name).join(", ")}` })
                  } else {
                    setFailedToUploadError({ error: false, message: "" })
                  }

                  if (Array.from(e.target.files).length > MAX_FILES_TO_UPLOAD) {
                    setTooManyFilesAttachedError({ error: true, message: t?.("file_upload.error_can_only_upload_max_files_at_a_time", { count: MAX_FILES_TO_UPLOAD })})
                  } else {
                    setTooManyFilesAttachedError({ error: false, message: "" })
                  }

                  // this is needed to allow user to attach same file again
                  e.target.value = null
                }}
              />
            </label>
          </Styles.ButtonWrapper>
          {FL_AUDIO_MSG && (
            <Styles.ButtonWrapper
              key="audio-recording-button"
              disabled={isSuperAdmin || isSuperAuditor || loading}
              onClick={() => {
                if (isSuperAdmin || isSuperAuditor || loading) {
                  return
                }
                if (!isRecording && !isAudioEnabled) {
                  startRecording()
                  setIsAudioEnabled(true)
                } else {
                  cancelRecording()
                  setIsAudioEnabled(false)
                }
              }}
              $isAudioEnabled={isAudioEnabled}
            >
              <label>
                <FontAwesomeIcon
                  className="icon"
                  icon={faMicrophone}
                  fixedWidth
                />
              </label>
            </Styles.ButtonWrapper>
          )}
        </div>

        <div className="right-wrapper">
          <Styles.CharacterCounter className="character-counter">{characterCount}/4000</Styles.CharacterCounter>
          <div
            sx={{
              borderRadius: "6px",
              display: "flex",
              alignItems: "center",
              overflow: "hidden"
            }}
          >
            <Styles.SendMessageButton
              disabled={shouldDisableSendButton}
              onClick={async () => {
                setRandomKey(uuid())
                if (mentions?.length > 0) {
                  if (textArea) {
                    setTextArea("")
                    setCharacterCount(0)
                    let text = textArea
                    if (/\n$/.test(textArea)) {
                      text = textArea.slice(0, -1)
                    }
                    sendEvent({
                      type: "TEXT",
                      data: {
                        text: `###${text}`
                      }
                    })
                  }
                } else {
                  await sendMessage()
                }
              }}
            >

              {(mentions?.length > 0) ? (
                <>
                  {!mobile && (
                    <FontAwesomeIcon
                      className="icon"
                      icon={faHeadset}
                      fixedWidth
                    />
                  )}
                  <span>
                    {mobile ? t?.("chat.internal_comment") : t?.("chat.send_as_internal_comment")}
                  </span>
                </>
              ) : (
                  <>
                    <FontAwesomeIcon
                      className="icon"
                      icon={faHeadset}
                      fixedWidth
                    />
                    <span>{member?.liveChat ? t?.("chat.send"): t?.("chat.start_live_chat")}</span>
                  </>
                )}
            </Styles.SendMessageButton>
            {(previews === null && !isReadyToSendAudio && !(mentions?.length > 0)) && (
              <PopperContainer
                show={showSendOptions}
                display={(
                  <Styles.SendOptionsButton
                    inverted
                    disabled={shouldDisableSendButton}
                    onClick={() => {
                      if (!(isSuperAdmin || isSuperAuditor || !textArea)) {
                        setShowSendOptions(!showSendOptions)
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faChevronDown}
                      fixedWidth
                    />
                  </Styles.SendOptionsButton>
                )}
                placement="top-end"
                modifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [0, 8],
                    },
                  },
                ]}
                onClickOutside={() => {
                  if (showSendOptions) {
                    setShowSendOptions(false)
                  }
                }}
              >
                {() => (
                  <Box>
                    <Styles.SendOptionItem
                      onClick={() => {
                        if (textArea) {
                          setTextArea("")
                          setCharacterCount(0)
                          let text = textArea
                          if (/\n$/.test(textArea)) {
                            text = textArea.slice(0, -1)
                          }
                          sendEvent({
                            type: "TEXT",
                            data: {
                              text: `###${text}`
                            }
                          })
                          setShowSendOptions(false)
                          setRandomKey(uuid())
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        className="icon"
                        icon={faMessage}
                        fixedWidth
                      />
                      <span>{t?.("chat.share_as_internal_comment")}</span>
                    </Styles.SendOptionItem>
                    {!member?.liveChat && (
                      <Styles.SendOptionItem
                        onClick={() => {
                          if (textArea) {
                            setTextArea("")
                            setCharacterCount(0)
                            let text = textArea
                            if (/\n$/.test(textArea)) {
                              text = textArea.slice(0, -1)
                            }
                            sendEvent({
                              type: "TEXT",
                              data: {
                                text
                              }
                            })
                            setShowSendOptions(false)
                            setRandomKey(uuid())
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          className="icon"
                          icon={faPaperPlane}
                          style={{
                            color: "#0084FF"
                          }}
                          fixedWidth
                        />
                        <span>{t?.("chat.send_message_directly")}</span>
                      </Styles.SendOptionItem>
                    )}
                  </Box>
                )}
              </PopperContainer>
            )}
          </div>
        </div>
      </div>
    </Styles.Footer>
  )
}

