/* eslint no-undef: 0 */

import React from "react"
import PropTypes from "prop-types"
import { createUniversalPortal } from "react-portal-universal"
import { CSSTransition } from "react-transition-group"

import "./Modal.scss"

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.func.isRequired,
}

const defaultProps = {
  open: false,
  onClose: () => { },
}

const Modal = class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.modalRef = React.createRef()
    this.modalContainer = React.createRef()
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidMount() {
    document.addEventListener("keydown", this.closeModal)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.closeModal)
  }

  closeModal(e) {
    if (e.key === "Escape") {
      if (this.modalRef.current) {
        if (this.modalRef.current.onCloseModal) {
          return this.modalRef.current.onCloseModal()
        }
      }
      this.props.onClose()
    }
  }

  render() {
    const el = (
      <CSSTransition
        in={!!this.props.open}
        timeout={300}
        classNames="radiate-modal"
        unmountOnExit
      >
        <div
          ref={this.modalContainer}
          className={`modal-fixed-container ${this.props.modalClassName} ${!!this.props.open ? "initial-show" : ""}`}
          onMouseDown={(e) => {
            e.stopPropagation()
          }}
          onClick={(e) => {
            e.stopPropagation()
            if (this.props.onClose) {
              const { className } = e.target
              if (className.toString().indexOf("modal-fixed-container") !== -1 && className.toString().indexOf("open") !== -1) {
                this.props.onClose()
              }
            }
          }}
        >
          <div className={`modal-container ${this.props.className ? this.props.className : ""}`}>
            {this.props.children({
              modalRef: this.modalRef,
              modalContainerRef: this.modalContainer,
            })}
          </div>
        </div>
      </CSSTransition>
    )
    return createUniversalPortal(el, "#root")
  }
}

Modal.propTypes = propTypes
Modal.defaultProps = defaultProps

export default Modal
