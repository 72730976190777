import React from "react"
import $ from "jquery"
import PropsTypes from "prop-types"
import _ from "lodash"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import "./ScrollWaypointContainer.scss"

const propsTypes = {
  className: PropsTypes.string,
  onScrollToBottom: PropsTypes.func,
}

const defaultProps = {
  className: "",
  onScrollToBottom: null,
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

const LoadingContainer = styled.div`
  pointer-events: none;
  position: absolute;
  bottom: 0;
  height: 60px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.4s ease;
`

const LoadingWrapper = styled.div`
  border-radius: 4px;
  padding: 8px 16px;
  color: white;
  background: ${(props) => props.theme.themeColorGreen};
`

const Spinner = styled(FontAwesomeIcon)`

`

class ScrollWaypointContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingMore: false,
    }
    this.loadMore = _.debounce(this.loadMore, 1000, { leading: true, trailing: false })
  }

  loadMore() {
    console.log("calling")
    if (!this.state.loadingMore) {
      // const startTime = new Date().getTime()
      this.setState({
        loadingMore: true
      }, () => {
        this.props.onScrollToBottom(() => {
          this.setState({
            loadingMore: false
          })
          console.log("done loading")
        })
      })
    }
  }

  scrollToTop() {
    $(this.container).children().first().scrollTop(0)
  }

  render() {
    const {
      onScrollToBottom,
      className,
      hasMore = true,
      loadMoreOffset = 100
    } = this.props
    const { loadingMore } = this.state
    return (
      <Container className="scroll-waypoint">
        <div
          ref={(c) => { this.container = c }}
          className={`radiate-theme scroll-waypoint-container ${className}`}
          onScroll={(e) => {
            const bottom = e.target.scrollHeight - e.target.scrollTop - loadMoreOffset <= e.target.clientHeight
            if (bottom && onScrollToBottom && !loadingMore) {
              if (hasMore) {
                this.loadMore()
              }
            }
          }}

        >
          {this.props.children}
        </div>
        <LoadingContainer
          show={loadingMore}
        >
          <LoadingWrapper>
            <Spinner
              icon={faCircleNotch}
              spin
              fixedWidth
            />
            <span>Loading ...</span>
          </LoadingWrapper>
        </LoadingContainer>
      </Container>
    )
  }
}

ScrollWaypointContainer.propsTypes = propsTypes
ScrollWaypointContainer.defaultProps = defaultProps

export default ScrollWaypointContainer
