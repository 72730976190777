import styled from "@emotion/styled"
import css from "@emotion/css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { darken  } from "polished"
import ReactTooltip from "../ReactTooltip/ReactTooltip"

export const PausedBar = styled.div`
  width: 3px;
  height: 26px;
  background: ${(props) => props.theme.gray3};
  opacity: ${(props) => (props.$isPaused ? 1 : 0)};
  transition: 150ms all ease-in-out;
`

export const PlayPauseButton = styled.button`
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 15px;

  border-color: transparent transparent transparent ${(props) => props.theme.gray3};
  transition: 100ms all ease;
  cursor: pointer;

  border-style: solid;
  padding: 0 4px;
  border-width: 13px 0 13px 17px;

  ${(props) => {
    if (props.$isRecording && !props.$isPaused) {
      return css`
        height: 22px;
        border-style: double;
        border-width: 0px 0 0px 18px;
        transition: 150ms all ease-in-out;
      `
    }
  }}
`

export const RecordingCircle = styled.div`
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  width: 15px;
  height: 15px; 
  border-radius: 50%;
  height: 15px;
  background: ${props => props.theme.gray2};
  ${(props) => {
    if (props.$isRecording && !props.$isPaused) {
      return css`
        animation: fadeInOut 1s infinite;
        transition: 100ms all ease;
        background: #f87171;
      `
    } else {
      return css`
        animation: none !important;
      `
    }
  }}
  
`

export const IconWrapper = styled(FontAwesomeIcon)`
  font-size: 24px;
`

export const ReRecordButton = styled(FontAwesomeIcon)`
  color: #929292;
  &:hover {
    color: ${darken(0.1, "#929292")};
  }
`

export const CancelRecordingButton = styled(FontAwesomeIcon)`
  color: #cc0000;
  &:hover {
    color: ${darken(0.1, "#cc0000")};
  }
`

export const RecordingStopButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  color: ${props => props.theme.success};
  &:hover {
    color: ${darken(0.1, "hsl(94, 48%, 56%)")};
  }
`

export const AudioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #fb0d0d;
  &:hover {
    color: ${darken(0.1,  "#fb0d0d")};
  }
`

export const StyledAudio = styled.audio`
  @media (max-width: 500px) {
    max-width: 250px;
  }
  &::-webkit-media-controls-panel {
    background-color: white;
  }

`
export const Donut = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 0.20rem solid ${props => props.theme.gray2};
  border-top-color: ${props => props.theme.gray1};
  animation: 1.5s spin infinite linear;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

export const AudioRecorderWrapper = styled.div`
  display: flex;
  color: #929292;
  width: 100%;
  align-items: center;
`

export const AudioRecorderTooltip = styled(ReactTooltip)`
  @media only screen and (max-width: 500px) {
    display: none;
  }
`
