import { useState, useCallback, useEffect } from "react"
import mime from "mime"
import getBlobDuration from "get-blob-duration"

const AUDIO_FILE_SIZE_LIMIT = 10 * 1000 * 1000 // ~ 10 MB

export const useAudioRecorder = ({
  mediaRecorderOptions,
  setIsAudioEnabled,
  setIsSendingAudio,
}) => {
  const [isRecording, setIsRecording] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [isLoadingAudio, setIsLoadingAudio] = useState(false)
  const [mediaRecorder, setMediaRecorder] = useState()
  const [recordingBlob, setRecordingBlob] = useState()
  const [audioUrl, setAudioUrl] = useState("")
  const [mimeType, setMimeType] = useState("")
  const [errorName, setErrorName] = useState("")
  const [fileTooLarge, setFileTooLarge] = useState(false)
  const [isRecordingCancelled, setIsRecordingCancelled] = useState(false)
  const [blobDuration, setBlobDuration] = useState(Infinity)

  useEffect(() => {
    if (recordingBlob) {
      getBlobDuration?.(recordingBlob)
        .then((duration) => {
          setBlobDuration(duration)
        })
        .catch((e) => {
          console.error(e)
          setBlobDuration(Infinity)
        })
    } else {
      setBlobDuration(Infinity)
    }
  }, [recordingBlob])

  useEffect(() => {
    if (mediaRecorder?.mimeType) {
      setMimeType(mediaRecorder.mimeType)
    }
  }, [mediaRecorder?.mimeType])

  useEffect(() => {
    if (isRecordingCancelled && audioUrl) {
      setAudioUrl("")
    }
  }, [isRecordingCancelled, audioUrl])

  /**
   * Calling this method would result in the recording to start. Sets `isRecording` to true
   */
  const startRecording = useCallback(() => {
    if (!window?.navigator?.mediaDevices) {
      setErrorName("DeviceNotSupported")
      return
    }
    setIsLoadingAudio(true)

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setRecordingBlob(undefined)
        setAudioUrl("")
        setErrorName("")
        setMimeType("")
        setFileTooLarge(false)
        setIsRecordingCancelled(false)
        setIsAudioEnabled(true)

        if (!window?.MediaRecorder) {
          setIsLoadingAudio(false)
          setErrorName("DeviceNotSupported")
          return
        }
        const recorder = new MediaRecorder(stream, mediaRecorderOptions)
        recorder.start()

        recorder.onstart = () => {
          setIsRecording(true)
          setIsLoadingAudio(false)
        }

        recorder.onpause = () => {
          setIsPaused(true)
        }

        recorder.onresume = () => {
          setIsPaused(false)
        }

        recorder.onstop = () => {
          setIsLoadingAudio(true)
          setIsRecording(false)
          setIsPaused(false)
        }

        recorder.onerror = () => {
          setErrorName("MediaRecordingError")
        }

        setMediaRecorder(recorder)

        recorder.addEventListener("dataavailable", (event) => {
          setRecordingBlob(event.data)
          if (event.data.size > AUDIO_FILE_SIZE_LIMIT) {
            setFileTooLarge(true)
          } else {
            setFileTooLarge(false)
          }

          if (!window?.FileReader) {
            setTimeout(() => {
              setIsLoadingAudio(false)
            }, 0)
            setErrorName("DeviceNotSupported")
            return
          }

          const reader = new FileReader()
          reader.onload = (e) => {
            setAudioUrl(e.target.result)
            setIsLoadingAudio(false)
          }

          reader.readAsDataURL(event.data)

          recorder.stream.getTracks().forEach((t) => t.stop())
          setMediaRecorder(undefined)
        })
      })
      .catch((err) => {
        console.error(err.name, err.message, err.cause)
        setErrorName(err?.name)
        setIsLoadingAudio(false)
      })
  }, [
    setIsRecording,
    setMediaRecorder,
    setRecordingBlob,
    setIsAudioEnabled,
    mediaRecorderOptions,
    setIsLoadingAudio,
    setAudioUrl,
    setIsRecordingCancelled,
    setErrorName,
    setMimeType,
    setFileTooLarge,
  ])

  /**
   * Calling this method results in a recording in progress being stopped and the resulting audio being present in `recordingBlob`. Sets `isRecording` to false
   */
  const stopRecording = useCallback(() => {
    mediaRecorder?.stop()
  }, [mediaRecorder])

  const cancelRecording = useCallback(() => {
    stopRecording()
    setIsRecordingCancelled(true)
    setRecordingBlob(undefined)
    setAudioUrl("")
    setMimeType("")
    setErrorName("")
    setFileTooLarge(false)
    setIsAudioEnabled(false)
    setIsLoadingAudio(false)
    setIsSendingAudio(false)
  }, [
    stopRecording,
    setIsRecordingCancelled,
    setRecordingBlob,
    setAudioUrl,
    setMimeType,
    setErrorName,
    setFileTooLarge,
    setIsAudioEnabled,
    setIsLoadingAudio,
    setIsSendingAudio,
  ])

  /**
   * Calling this method would pause the recording if it is currently running or resume if it is paused. Toggles the value `isPaused`
   */
  const togglePauseResume = useCallback(() => {
    if (isPaused) {
      mediaRecorder?.resume()
    } else {
      mediaRecorder?.pause()
    }
  }, [mediaRecorder, isPaused])

  return {
    startRecording,
    stopRecording,
    cancelRecording,
    togglePauseResume,
    recordingBlob,
    blobDuration,
    isRecording,
    isPaused,
    isRecordingCancelled,
    mediaRecorder,
    audioUrl,
    mimeType,
    errorName,
    fileTooLarge,
    isLoadingAudio,
  }
}

export function blobToFile({ blob, fileName, mimeType }) {
  if (!blob || !fileName || !mimeType) {
    console.error(
      "blob, fileName and mimeType are required to convert audio into a file.",
    )
    return
  }

  const fileExtension = mime.getExtension(mimeType)
  const fullFileName = fileName + "." + fileExtension

  if (!fileExtension) {
    console.error(`Invalid mimeType ${mimeType} which cannot be converted into a file.`)
    return
  }

  const file = new File([blob], fullFileName, {
    type: mimeType,
  })

  return file
}
