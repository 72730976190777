import styled from "@emotion/styled"

export const MoveFolderContainer = styled.div`
  width: 320px;
  height: 145px;

  >.label {
    color: ${(props) => props.theme.gray6};
    padding: 8px;
    font-size: ${(props) => props.theme.textSm};
    font-weight: bold;
  }

  >.separator {
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.gray1};
  }

  .error {
    display: ${(props) => !props.showError && "none"};
    font-size: 0.7rem;
    color: ${(props) => props.theme.error};
    text-align: center;
    height: 16px;
  }
  @media (max-width:989px) {
    width: 100% !important;
  }
`