/** @jsxImportSource theme-ui */

import React, { useState } from "react"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import * as Styles from "./IntegrationActionPopperContainerStyles"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"

function replacePatternWithContext(string, context) {
  const placeholders = string.match(/{{\w+}}/g) || []
  const uniquePlaceholders = [...new Set(placeholders)]
  uniquePlaceholders.forEach((placeholder) => {
    const key = placeholder.slice(2, -2)
    if (context.hasOwnProperty(key)) {
      const pattern = new RegExp(placeholder, "g")
      string = string.replace(pattern, context[key])
    }
  })
  return string
}

async function invokeAPIAction({
  action,
  context,
  contextToken,
}) {
  // sample API action
  // {
  //   "title" : "API Call",
  //   "description" : "Invoking an API",
  //   "type" : "API",
  //   "url" : "https://woztell.com",
  //   "method": "POST",
  //   "headers": {
  //     additional custom headers
  //     "abc": "{{memberId}}"
  //   },
  //   "body": {
  //     custom body
  //     "threadId": "{{threadId}}",
  //     "appId": "{{appId}}",
  //     "type": "product"
  //   }
  // }
  const {
    url,
    method = "POST",
    headers = {},
    body = {},
  } = action
  if (url) {
    const processedHeaders = {}
    if (contextToken) processedHeaders["X-Woztell-Context"] = contextToken

    _.forEach(headers, (value, key) => {
      processedHeaders[key] = replacePatternWithContext(value, context)
    })

    const processedBody = {}
    _.forEach(body, (value, key) => {
      processedBody[key] = replacePatternWithContext(value, context)
    })
    try {
      const response = await fetch(url, {
        method: method || "POST",
        headers: processedHeaders,
        body: JSON.stringify(processedBody),
      })
      if (response.status >= 200 && response.status < 400) {
        try {
          const json = await response.json()
          return json
        } catch (error) {
          // response body is not JSON, but status is ok, so treat as success
          return {
            ok: 1
          }
        }
      } else {
        return {
          ok: 0,
          error: response.statusText
        }
      }
    } catch (e) {
      console.error("error", e)
      return {
        ok: 0,
        error: e.message
      }
    }
  }
}


const IntegrationCustomActionButton = ({
  shouldDisableThreadAction = false,
  customAction,
  memberId,
  userId,
  actionContext,
  contextToken,
  onOpenIntegrationModal = () => {},
  onUpdateOptions = () => {},
  onUpdateText = () => {},
  onClick = () => {},
  useInThread,
}) => {
  const [loading, setLoading] = useState(false)

  let modModalView = customAction?.modalView
  if (memberId) {
    modModalView = `${customAction?.modalView}?member=${memberId}`
  }
  return (
    <Styles.IntegrationCustomActionButton
      sx={{
        position: "relative"
      }}
      disabled={shouldDisableThreadAction}
      title={customAction?.description}
      onClick={async () => {
        if (shouldDisableThreadAction) {
          return
        }
        switch (customAction?.type) {
          case "MODAL":
            onOpenIntegrationModal(modModalView)
            break
          case "API":
            setLoading(true)
            const result = await invokeAPIAction({
              action: customAction,
              context: {
                memberId,
                userId,
                ...actionContext,
              },
              contextToken,
            })
            console.log("result", result)
            if (result?.ok) {
              if (!useInThread) {
                if (result?.options) {
                  onUpdateOptions(result.options)
                } else if (_.isString(result?.text)) {
                  onUpdateText(result.text)
                }
              }
            } else {
              alert(`Action Execution Error: ${result.error}`)
            }
            setLoading(false)
            break
          default:
            break
        }
        onClick()
      }}
    >
      <div className="title">{customAction?.title}</div>
      <div className="description">{customAction?.description}</div>
      <FontAwesomeIcon
        icon={faCircleNotch}
        spin
        sx={{
          display: loading ? "block" : "none",
          position: "absolute",
          right: 2,
          top: "22px",
          color: "gray3",
        }}
      />
    </Styles.IntegrationCustomActionButton>
  )
}

export default IntegrationCustomActionButton