import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  faCheckCircle,
  faTimesCircle,
  faMicrophone,
} from "@fortawesome/free-solid-svg-icons"
import * as Styles from "./AudioRecorderStyles"
import { AudioPlayer } from "../AudioPlayer/AudioPlayer"
import { useRequestAnimationFrameTimer } from "../../hooks/useTimer"
import { convertSecondsToMMSSformat } from "../AudioPlayer/AudioPlayer"

function convertPascalCaseToTitle(str) {
  const result = str.replace(/([A-Z])/g, " $1")
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
  return finalResult
}

function convertErrorNameToErrorText(errorName, t) {
  if (errorName === "NotAllowedError") {
    return t?.("audio_recorder.error_please_enable_your_microphone_and_try_again")
  }

  if (errorName === "TooLarge") {
    return t?.("audio_recorder.error_file_too_large")
  }
  if (errorName === "DeviceNotSupported") {
    return t?.("audio_recorder.error_device_not_supported")
  }

  return t?.("audio_recorder.error_sorry_we_experienced_a_error_please_try_again_later", { errorName: convertPascalCaseToTitle(errorName) })
}

export function AudioRecorder({
  isRecording,
  togglePauseResume,
  isPaused,
  getRecordingState,
  startRecording,
  stopRecording,
  audioUrl,
  cancelRecording,
  errorName,
  fileTooLarge,
  isLoadingAudio,
  isSendingAudio,
  blobDuration,
}) {
  const { t } = useTranslation("common")
  if (isLoadingAudio || isSendingAudio) {
    return (
      <Styles.AudioRecorderWrapper
        style={{
          justifyContent: "center",
          gap: "8px",
        }}
      >
        <div> {isLoadingAudio ? t?.("audio_recorder.loading") : t?.("audio_recorder.sending_audio")}</div>
        <Styles.Donut />
      </Styles.AudioRecorderWrapper>
    )
  }

  if (errorName || fileTooLarge) {
    return (
      <Styles.AudioRecorderWrapper
        style={{
          justifyContent: "space-evenly",
          gap: "32px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "12px",
            padding: "0 24px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "12px",
            }}
          >
            {convertErrorNameToErrorText(fileTooLarge ? "TooLarge" : errorName, t)}
          </div>
          <div
            style={{
              cursor: "pointer",
              display: "flex",
            }}
          >
            <div
              onClick={() => {
                startRecording()
              }}
              style={{
                padding: "4px",
              }}
            >
              <Styles.ReRecordButton
                className="icon"
                icon={faMicrophone}
                fixedWidth
                fontSize="24px"
              />
            </div>
            <div
              onClick={() => {
                cancelRecording()
              }}
              style={{
                padding: "4px",
              }}
            >
              <Styles.CancelRecordingButton
                className="icon"
                icon={faTimesCircle}
                fixedWidth
                fontSize="24px"
              />
            </div>
          </div>
        </div>
      </Styles.AudioRecorderWrapper>
    )
  }

  if (isRecording) {
    return (
      <Styles.AudioRecorderWrapper
        style={{
          justifyContent: "space-evenly",
          gap: "32px",
        }}
      >
        <div
          onClick={() => {
            togglePauseResume()
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "2px",
            }}
          >
            <Styles.PausedBar $isPaused={isPaused} />
            <Styles.PlayPauseButton
              $isPaused={isPaused}
              $isRecording={isRecording}
              data-tooltip-id="audio-recorder-state-tooltip"
              data-tooltip-content={
                isPaused ? t?.("audio_recorder.resume_recording"): t?.("audio_recorder.pause_recording")
              }
              data-tooltip-place="top"
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <div style={{ fontSize: "14px" }}>{getRecordingState()}</div>
          <Styles.RecordingCircle
            $isPaused={isPaused}
            $isRecording={isRecording}
          />
        </div>
        <div
          data-tooltip-content={t?.("audio_recorder.tooltip_complete_recording")}
          data-tooltip-id="audio-recorder-state-tooltip"
          data-tooltip-place="top"
          style={{
            display: "flex",
            gap: "4px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RecordingTimer isRecording={isRecording} isPaused={isPaused} />
          {isPaused && (
            <Styles.RecordingStopButtonWrapper
              onClick={() => {
                stopRecording()
              }}
            >
              <Styles.IconWrapper
                className="icon"
                icon={faCheckCircle}
                fixedWidth
                fontSize="24px"
              />
            </Styles.RecordingStopButtonWrapper>
          )}
        </div>
        <Styles.AudioRecorderTooltip
          id="audio-recorder-state-tooltip"
          place="top"
        />
      </Styles.AudioRecorderWrapper>
    )
  }

  if (!isRecording && audioUrl) {
    return (
      <Styles.AudioRecorderWrapper
        style={{
          justifyContent: "space-evenly",
          gap: "32px",
        }}
      >
        <Styles.AudioWrapper>
          <AudioPlayer
            src={audioUrl}
            blobDuration={blobDuration}
            cancelRecording={cancelRecording}
          />
        </Styles.AudioWrapper>
      </Styles.AudioRecorderWrapper>
    )
  }

  return null
}

function RecordingTimer({ isRecording, isPaused }) {
  const [recordingTime, setRecordingTime] = useState(0)

  const { start, stop } = useRequestAnimationFrameTimer((deltaTime) =>
    setRecordingTime((prevTime) => prevTime + deltaTime * 0.001),
  )

  /*
   * Need this effect to synchronize local timer state with the  media recorder state (recording, paused, playing)
   * without causing too many re-renders of the ConversationHistory component
  */
  useEffect(() => {
    if (isRecording) {
      if (isPaused) {
        stop()
      } else {
        start()
      }
    } else {
      setRecordingTime(0)
    }
  }, [isRecording, isPaused])

  if (isPaused) return null

  return (
    <div style={{ fontSize: "14px", color: "#bbbbbc" }}>{convertSecondsToMMSSformat(recordingTime)}</div>
  )
}
