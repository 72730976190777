const DatePickerField = ({selected, onChange}) => {

    const processDate = (date) => {
        let today = new Date()
        let stringDate = `${today.getFullYear().toString()}-${checkNumber(today.getMonth(), true)}-${checkNumber(today.getDate(), false)}`
        if (date.month && date.month && date.year) {
          stringDate =`${date.year.toString()}-${checkNumber(date.month, false)}-${checkNumber(date.date, false)}`
        }
        return stringDate
    }

    const checkNumber = (number, isMonth) => {
        let range = [1, 2, 3, 4, 5, 6, 7, 8, 9]
        let find = range.find((num) => num === number)
        if (find) {
            return isMonth ? `0${find + 1}`.toString() : `0${find}`.toString()
        } else {
            return isMonth ? `0${number + 1}`.toString() : `0${number}`.toString()
        }
    }

    const handleDate = (e) => {
        let date = new Date(e.target.value)
        onChange({
            date: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear()
        })
    }

    return (
        <input type="date" value={processDate(selected)} onChange={handleDate}  />
    )
}

export default DatePickerField