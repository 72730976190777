import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { Box } from "theme-ui"
import {
  useMutation,
} from "react-relay"

import { ThreadsUpdateThreadsFolderMutation } from "./ManageThreadsPopperQuery"
import FolderPicker from "../FolderPicker/FolderPicker"
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"
import * as Styles from "./ManageThreadsPopperStyles.js"
import Modal from "../Radiate/Modal/Modal"
import FollowUpActionModal from "./FollowUpActionModal"


const ManageThreadsPopper = ({
  selectedThreadCount,
  selectedThreadCountAll,
  selectedAllThreads,
  selectedThreads,
  currentFolder,
  currentChannelIds,
  role,
  onSuccess,
  search,
  threads
}) => {
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null)
  const [openFollowUpModal, setOpenFollowUpModal] = useState(false)
  const [followUpChannelNames, setFollowUpChannelNames] = useState([])
  const [followUpFolderName, setFollowUpFolderName] = useState(null)
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [commitThreadsFolder, isInFlightThreadsFolder] = useMutation(
    ThreadsUpdateThreadsFolderMutation
  )

  const { t } = useTranslation("common")

  const updateThreadsFolder = ({
    targetFolderId,
    selectedThreadIds,
    selectedAllThreads,
    userOption,
    search,
    threads
  }) => {
    let clientMutationId = 0
    let variables = {
      folderId: targetFolderId,
      clientMutationId: clientMutationId++,
    }
    if (selectedAllThreads) {
      if (search) {
        const threadIds = threads.map((thread) => thread?.node?.threadId)
        variables.threadIds = threadIds
      } else {
        variables.originFolderId = currentFolder
        variables.wChannelIds = currentChannelIds ? currentChannelIds : null
      }
    } else {
      variables.threadIds = selectedThreadIds
    }
    if (userOption) {
      variables.userOption = userOption
    }
    const mutationParams = {
      variables: {
        input: variables,
      },
      onCompleted: (response, errs) => {
        if (!response.updateThreadsFolder?.error) {
          setError(null)
          if (response.updateThreadsFolder?.followUpActions?.folderName) {
            setFollowUpChannelNames(response.updateThreadsFolder.followUpActions.channelNames)
            setFollowUpFolderName(response.updateThreadsFolder.followUpActions.folderName)
            setOpenFollowUpModal(true)
          } else {
            onSuccess()
            setOpenFollowUpModal(false)
          }
        } else {
          setError(response.updateThreadsFolder?.error?.message)
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        alert(`Cannot move to ${_.startCase(targetFolderId)} folder by following reason: ${errorMsg}\nPlease try again later.`)
      }
    }
    commitThreadsFolder(mutationParams)
  }

  return (
    <>
      <Styles.MoveFolderContainer
        showError={error}
      >
        <div className="label">
          {
            selectedAllThreads
              ? t?.("threads.threads_ready_to_move", { count: selectedThreadCountAll })
              : t?.("threads.threads_ready_to_move", { count: selectedThreadCount })
          }
        </div>
        <div className="separator"></div>
        <Box p={3}>
          <FolderPicker
            onChange={(folderId) => {
              setSelectedFolder(folderId)
            }}
            selectedFolderId={selectedFolder}
          />
          <NewBoxButton
            style={{ marginTop: "8px", width: "100%" }}
            primary
            text={t?.("buttons.confirm")}
            disabled={!selectedFolder || isInFlightThreadsFolder}
            loading={isInFlightThreadsFolder}
            onClick={() => {
              updateThreadsFolder({ targetFolderId: selectedFolder, selectedThreadIds: selectedThreads, selectedAllThreads, search, threads })
            }}
          />
          {error ?
            <div className="error">{error}</div>
            :
            null
          }
        </Box>
      </Styles.MoveFolderContainer>
      <Modal
        open={openFollowUpModal}
      >
        {() => (
          <FollowUpActionModal
            targetFolderName={followUpFolderName}
            mismatchChannels={followUpChannelNames}
            onCancel={() => {
              setOpenFollowUpModal(false)
            }}
            onSkip={() => {
              updateThreadsFolder({
                targetFolderId: selectedFolder,
                selectedThreadIds: selectedThreads,
                selectedAllThreads,
                userOption: "skip",
                search,
                threads
              })
            }}
            onAssociate={() => {
              updateThreadsFolder({
                targetFolderId: selectedFolder,
                selectedThreadIds: selectedThreads,
                selectedAllThreads,
                userOption: "createFolder",
                search,
                threads
              })
            }}
            role={role}
          />
        )}
      </Modal>
    </>
    

  )
}

export default ManageThreadsPopper
