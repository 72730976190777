import React, { useEffect, useTransition } from "react"
import { useTranslation } from "react-i18next"
import graphql from "babel-plugin-relay/macro"
import styled from "@emotion/styled"
import {
  useQueryLoader,
  usePreloadedQuery,
  usePaginationFragment
} from "react-relay"

import { AsyncPaginate } from "react-select-async-paginate"
import Select from "react-select"

import { useRef } from "react"
import _ from "lodash"

const PickerWrapper = styled.div`
  position: relative;

  >.overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    cursor: inherit;
    display: flex;
    justify-content:  center;
    z-index: 9999;
  }
`

const LoadingContainer = styled.div`
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  background: #f1f0f1;
  opacity: 0.4;
  width: 100%;

  .dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #999999;
    animation: anim 1.5s infinite;
    margin-right: 2px;

    &:nth-of-type(2) {
      animation-delay: 0.1s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.2s;
    }
  }

  @keyframes anim {
    0% {
      transform: scale(1);
      opacity:1;
    }
    50% {
      transform: scale(0.1);
      opacity: 0.5;
    }

    100% {
      transform: scale(1);
      opacity:1;
    }
  }
`

const FolderPickerQuery = graphql`
  query FolderPickerQuery ($wChannelIds: [ID], $folderId: ID, $hasSelectedFolder: Boolean!) {
    userViewer {
      ...FolderPicker_userViewer @arguments(
        folderId: $folderId
        wChannelIds: $wChannelIds
        hasSelectedFolder: $hasSelectedFolder
      )
    }
  }
`

const InnerContainer = ({
  queryRef,
  ...props
}) => {
  const data = usePreloadedQuery(
    FolderPickerQuery,
    queryRef,
  )
  return (
    <InnerFolderPicker
      data={data}
      {...props}
    />
  )
}
const InnerFolderPicker = ({
  queryRef,
  onChange,
  folderId,
  wChannelIds,
  ...props
}) => {
  // const [channelId, setChannelId] = useState(selectedChannelId)
  const firstLoadedData = useRef(false)
  const initialQueryLoading = useRef(true)
  const [loading, startTransition] = useTransition()

  const {
    data,
    loadNext,
    hasNext,
    refetch
  } = usePaginationFragment(
    graphql`
      fragment FolderPicker_userViewer on UserScope
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
        wChannelIds: { type: "[ID]" }
        folderId: { type: "ID" }
        hasSelectedFolder: { type: "Boolean!" }
      )
      @refetchable(queryName: "FolderPickerPaginateQuery") {
        foldersPaginated (
          first: $first,
          after: $after,
          wChannelIds: $wChannelIds,
        ) @connection(key: "FolderPicker_foldersPaginated") {
          edges {
            node {
              folderId
              name
            }
          }
        }
        folder (folderId: $folderId) @include(if: $hasSelectedFolder)  {
          folderId
          name
        }
      }
    `,
    props.data.userViewer
  )
  // console.log("data", data)

  useEffect(() => {
    if (folderId) {
      // console.log("going to refetch", wChannelId)
      startTransition(() => {
        refetch({
          wChannelIds: wChannelIds?.length ? wChannelIds : null,
          folderId: folderId,
          hasSelectedFolder: true
        }, {
          fetchPolicy: "network-only",
        })
      })
    }
  }, [folderId, JSON.stringify(wChannelIds), refetch])

  const folders = (data?.foldersPaginated?.edges ?? []).map(o => o.node)

  useEffect(() => {
    if (!firstLoadedData.current && _.isArray(data?.foldersPaginated?.edges)) {
      firstLoadedData.current = true
      initialQueryLoading.current = false
    }
  }, [JSON.stringify(data?.foldersPaginated?.edges)])

  const loadOptions = async (search, loadedOptions, additional) => {
    // console.log("search", search)
    if (additional && additional.search === search) {
      // console.log("load next in loadOptions")
      loadNext()
    } else {
      // console.log("refetch in loadOptions")
      if (!initialQueryLoading.current) {
        refetch({
          wChannelIds: wChannelIds?.length ? wChannelIds : null,
          folderId: folderId,
          hasSelectedFolder: !!folderId
        }, {
          fetchPolicy: "network-only",
        })
      }
    }
    // console.log("data", data)
    return {
      options: folders.map(o => ({
        value: o.folderId,
        label: o.name,
      })),
      hasMore: hasNext,
      additional: {
        search
      }
    }
  }
  let selectedValue = null
  if (data?.folder) {
    selectedValue = {
      label: data.folder.name,
      value: data.folder.folderId
    }
  }
  const { t } = useTranslation("common")
  return (
    <PickerWrapper>
      {!!loading &&
        <div className="overlay">
          <LoadingContainer>
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </LoadingContainer>
        </div>
      }
      <AsyncPaginate
        value={selectedValue}
        loadOptions={loadOptions}
        defaultOptions
        reduceOptions={(p, n) => n}
        menuShouldScrollIntoView={true}
        cacheUniqs={(folders ?? []).map(o => o.folderId)}
        onChange={(value) => {
          onChange(value.value)
        }}
        placeholder={t?.("placeholders.select_folder")}
        styles={{
          container: styles => ({
            ...styles,
            backgroundColor: "#ffffff"
            // width: "400px"
          }),
          menu: styles => ({
            ...styles,
            fontSize: "0.85rem"
          }),
          option: styles => ({
            ...styles,
            fontSize: "0.85rem"
          }),
          control: styles => ({
            ...styles,
            fontSize: "0.85rem"
          })
        }}
      />
    </PickerWrapper> 
  )
}

const FolderPicker = (props) => {
  const [
    queryReference,
    loadQuery,
  ] = useQueryLoader(
    FolderPickerQuery,
    props.initialQueryRef, /* e.g. provided by router */
  )
  const { t } = useTranslation("common")
  useEffect(() => {
    loadQuery({
      wChannelIds: props?.wChannelIds?.length ? props.wChannelIds : null,
      hasSelectedFolder: !!props.selectedFolderId,
      folderId: props.selectedFolderId
    })
  }, [])

  if (queryReference) {
    // const Inner = queryRenderer(InnerChannelPicker, {
    //   queryRef: queryReference,
    //   query: ChannelPickerQuery
    // })
    return (
      <InnerContainer
        queryRef={queryReference}
        folderId={props.selectedFolderId}
        onChange={props.onChange}
        wChannelIds={props.wChannelIds}
      />
    )
  }
  return (
    <Select
      placeholder={t?.("placeholders.select_folder")}
      isDisabled
      styles={{
        menu: styles => ({
          ...styles,
          fontSize: "0.85rem"
        }),
        option: styles => ({
          ...styles,
          fontSize: "0.85rem"
        }),
        control: styles => ({
          ...styles,
          fontSize: "0.85rem"
        })
      }}
    />
  )
}

export default FolderPicker
