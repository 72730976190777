import _ from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "@emotion/styled"
import { Flex, Box } from "theme-ui"
import moment from "moment"

import { timestampNormalizer } from "../../util"

export const RawMessage = styled.div`
  color: ${(props) => props?.textColor ?? props.theme.gray5};
  border: ${(props) => props?.borderless ? "none" : `1px solid ${props.theme.gray1}`};
  border-radius: 8px;
  background: ${(props) => props?.backgroundColor ?? "white"};
  min-width: 200px;

  .text {
    padding: 8px;
    white-space: pre-wrap;

    &.header {
      border-bottom: ${(props) => props?.borderless ? "none" : `1px solid ${props.theme.gray1}`};
      color: ${(props) => props?.textColor ?? props.theme.gray6};
      font-weight: bold;
    }

    &.raw {
      font-size: ${(props) => props.theme.textSm};
      word-break: break-word;
    }
  }

  .time-display {
    color: ${(props) => props?.textColor ?? "#555"} !important;
  }
`

const Button = styled.div`
  padding: 0 16px;
  text-align: center;
  cursor: pointer;
  height: 33px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.textSm};
  border-top: ${(props) => props.readMore && `1px solid ${props.theme.gray1}`};
  font-weight: bold;

  &:hover {
    background-color: ${(props) => props.theme.palerBackground};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

const Raw = ({
  data,
  type,
  header,
  timestamp,
  noTimeDisplay,
  ...props
}) => {
  const { t } = useTranslation("common")
  const [readMore, setReadMore] = useState(false)

  let modData
  if (data) {
    modData = _.omit(data, ["from", "to", "timestamp"])
    modData = {
      type: modData?.type,
      ...modData
    }
  } else {
    modData = {
      type: type,
      timestamp,
      ...props
    }
  }

  let modFullText = JSON.stringify(modData, null, 2)
  modFullText = modFullText.slice(3, -1)

  return (
    <RawMessage>
      <Flex
        className="text header"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>{header ? header : `${modData?.type ? _.startCase(modData?.type.toLowerCase()) : t?.("message_types.raw.message")} ${t?.("message_types.raw.sent")}.`}</Box>
        {noTimeDisplay ?
          null
          :
          <div
            className="time-display"
          >
            {timestampNormalizer(timestamp) ? moment(timestampNormalizer(timestamp)).format("HH:mm") : null}
          </div>
        }
      </Flex>
      {readMore &&
        <div className="text raw">{modFullText}</div>
      }
      <Button
        readMore={readMore}
        className="read-more"
        onClick={() => {
          setReadMore(!readMore)
        }}
      >
        {readMore ? t?.("message_types.raw.hide_the_details") : t?.("message_types.raw.view_the_details")}
      </Button>
    </RawMessage>
  )
}

export default Raw
